import Axios from './axios';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { env } from '../env'

export const runModellingService = async ({ modelId, toolId, data, callbackFunction, callbackFunctionStop, callbackFunctionError }) => {
  const token = localStorage.getItem('token');
  const result = fetchEventSource(`${env.REACT_APP_API_URL}/api/modelling/model/${modelId}/${toolId}`,
    {
      method: "POST",
      priority: "high",
      openWhenHidden: true,
      body: JSON.stringify(data),
      heartbeatTimeout: 12000000,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      onopen(res) {
        if (res.ok && res.status === 200) {
          // console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status <= 500 &&
          res.status !== 429
        ) {
          if (res.status == 500){
            console.log("Client side error ", res);
            callbackFunctionError("Could not process your request. Please try again. If the problem persists, please contact support.")
          }
        }
      },
      onmessage(event) {
        
        try {
          const parsedData = JSON.parse(event.data);
          callbackFunction(parsedData)
        }
        catch (err) {
          console.log(err)
          throw new Error("Failed to parse result from backend - contact support");
        }
        // setData((data) => [...data, parsedData]);
      },
      onclose() {
        callbackFunctionStop()
      },
      onerror(err) {
        console.log(err)
        callbackFunctionError(err)
        throw new Error(err);
        // throw "There was an error from server", err;
      },
    })

  return result;
};

export const runModellingExportService = (modelId, toolId, format) => {
  return Axios.get(`api/modelling/export/${modelId}/${toolId}/${format}`, { responseType: 'arraybuffer' }).then((res) => {
    return res?.data;
  });
};

export const runModellingGetSessionService = () => {
  return Axios.get(`api/modelling/getSession`).then((res) => {
    return res?.data;
  });
};

export const runModellingCheckSessionService = (session_id) => {
  return Axios.get(`api/modelling/checkSession/${session_id}`).then((res) => {
    return res?.data;
  });
};

export const runModellingClearCacheService = (modelId) => {
  return Axios.get(`api/modelling/clearCache/${modelId}`).then((res) => {
    return res?.data;
  });
};

export const runModellingRestartSessionService = () => {
  return Axios.get(`api/modelling/restartSession`).then((res) => {
    return res?.data;
  });
};
import React from 'react';
import PropTypes from 'prop-types';

// components
import TableRow from 'components/Tables/TableRow';
import ContextMenu from 'components/Dropdowns/ContextMenu';
import ShareWith from 'components/Tables/TableContent/SharedWith';

const FolderRow = ({ folder, openFolder, menu }) => {
  return (
    <tr
      className=" hover:bg-zinc-100 cursor-pointer dark:hover:bg-zinc-800"
      onClick={(e) => {
        if (!e.target.id.includes('headlessui-menu-item')) {
          openFolder(folder.id);
        }
      }}
    >
      <TableRow classes="max-w-[50px] w-[50px] text-center" content="-" />
      <TableRow
        content={
          <div className="ml-1 flex flex-row items-center justify-start gap-3">
            <div className="duration-400 relative rounded-md p-1.5 bg-zinc-50 dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-700">
              <svg className="h-[18px] w-[18px]" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M19.25 17.25V9.75C19.25 8.64543 18.3546 7.75 17.25 7.75H4.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25Z"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M13.5 7.5L12.5685 5.7923C12.2181 5.14977 11.5446 4.75 10.8127 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V11"
                ></path>
              </svg>
            </div>
            <span className="flex flex-col">
              <p className="text-xs font-medium text-zinc-700 dark:text-zinc-50">{folder.name}</p>
            </span>
          </div>
        }
      />
      <TableRow content={<ShareWith rulesets={folder.rulesets} />} />
      <TableRow content="" />
      <TableRow content="" />
      <TableRow classes="w-[125px]" content="" />
      <TableRow
        content={
          <div className="justify-center flex flex-row flex-1 gap-2 w-[75]">
            <ContextMenu options={menu} object={folder} />
          </div>
        }
      />
    </tr>
  );
};

FolderRow.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any,
    rulesets: PropTypes.any,
  }),
  menu: PropTypes.array,
  openFolder: PropTypes.func,
};

FolderRow.defaultProps = {
  folder: {},
  menu: [],
  openFolder: () => null,
};

export default FolderRow;

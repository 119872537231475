import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  getHomeDataService,
  getUserIpService,
  getWelcomeService,
  getKPIsService
} from 'services/home';

// First, create the thunk
export const getHomeDataAction = createAsyncThunk('home/fetch', async () => {
  const response = await getHomeDataService();
  return response;
});

export const getKPIsAction = createAsyncThunk('home/kpis', async () => {
  const response = await getKPIsService();
  return response;
});

export const getUserIpAction = createAsyncThunk('', async () => {
  const response = await getUserIpService();
  return response;
});

export const getWelcomeAction = createAsyncThunk('welcome', async () => {
  const response = await getWelcomeService();
  return response;
});

// Then, handle actions in your reducer
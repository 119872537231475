import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfileService,updateSettingsService, resetPasswordService, getTokensService, createTokenService, deleteTokenService } from 'services/profile';

export const updateProfileAction = createAsyncThunk('profile/update', async (data) => {
  const response = await updateProfileService(data);
  return response;
});

export const updateSettingsAction = createAsyncThunk('profile/update/settings', async (data) => {
  const response = await updateSettingsService(data);
  return response;
});

export const resetPasswordAction = createAsyncThunk('profile/resetPassword', async (data) => {
  const response = await resetPasswordService(data);
  return response;
});


export const getTokensAction = createAsyncThunk('profile/getTokens', async () => {

    const response = await getTokensService();
    return response.data;
});

export const createTokenAction = createAsyncThunk('profile/createToken', async (data, { rejectWithValue }) => {
  try {
    const response = await createTokenService(data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});


export const deleteTokenAction = createAsyncThunk('profile/createToken', async ({tokenId},{ rejectWithValue }) => {
  try {
    const response = await deleteTokenService(tokenId);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CogIcon } from '@heroicons/react/solid';

const OnlyIconBtn = ({ icon, onClick, useKeydown, keyDown, keyDownCondition, loader, classes, permanent, isDisabled }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(isDisabled);
  const [clicked, setClicked] = useState(true)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (useKeydown && eval(keyDownCondition) && event.keyCode === keyDown) {
        // console.log(event, keyDownCondition, event.keyCode)
        event.preventDefault();
        onClickHandler();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClick, useKeydown]);


  const onClickHandler = (event) => {
    event.stopPropagation();
    if (loader) {
      setLoading(true);
      setDisabled(true);
      onClick().then(() => {
        setLoading(false);
        if (!isDisabled) {
          setDisabled(false);
        }
      });
    } else {
      onClick();
      setClicked(!clicked)
    }
  };

  const renderIcon = loading ? <CogIcon className="animate-spin h-4 w-4" /> : icon;

  return (
    <button
      onClick={onClickHandler}
      className={`${loading ? 'bg-zinc-200 dark:bg-zinc-700' : ''
        } group z-1 text-zinc-700 ${permanent & clicked ? "bg-zinc-300" : ""} dark:text-zinc-100 items-center justify-center hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-md transition-all duration-150 ${classes}`}
      disabled={disabled}
    >
      <div className="p-[8px]">{renderIcon}</div>
    </button>
  );
};

OnlyIconBtn.propTypes = {
  icon: PropTypes.any,
  classes: PropTypes.any,
  isDisabled: PropTypes.bool,
  useKeydown: PropTypes.bool,
  keyDown: PropTypes.number,
  keyDownCondition: PropTypes.string,
  onClick: PropTypes.func,
  loader: PropTypes.bool,
  permanent: PropTypes.bool,
};

OnlyIconBtn.defaultProps = {
  useKeydown: false,
  loader: false,
  permanent: false,
  isDisabled: false,
};

export default OnlyIconBtn;

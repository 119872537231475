import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
//import classNames from 'classnames';

const BtnDropdown = ({ btnHeader, items }) => {
  const menuButtonRef = useRef(null);

  const closeMenu = () => {
    menuButtonRef.current?.click();
  };

  const handleAction = (action) => (e) => {
    action(e);
    closeMenu();
  };

  return (
    <Menu as="div" className="relative inline-block items-baseline text-left z-100">
      <div>
        <Menu.Button
          ref={menuButtonRef}
          className="group relative inline-flex items-center justify-center overflow-hidden  rounded-lg p-0.5 font-normal"
        >
          <div className="absolute h-full w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] active:bg-black group-hover:bg-gradient-to-br group-hover:from-black group-hover:to-black dark:active:bg-white dark:group-hover:bg-gradient-to-br dark:group-hover:from-white dark:group-hover:to-white"></div>
          <div className="border border-transparent relative inline-flex justify-center items-center rounded-full bg-white dark:bg-zinc-900 px-2.5 py-1 transition-all ease-out delay-50 duration-300  group-hover:bg-black dark:group-hover:bg-white">
            <p className=" relative text-xs text-black dark:text-zinc-50 dark:group-hover:text-black group-hover:text-zinc-50">
              {btnHeader}
            </p>
            <div className="mr-1 group-hover:text-white dark:group-hover:text-black dark:text-zinc-50">
              <ChevronDownIcon className="-mr-1 ml-0.5 h-4 w-4 group" aria-hidden="true" />
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`z-50  overflow-hidden origin-top-right absolute right-0 mt-1 w-44 rounded-md shadow  bg-white dark:bg-zinc-950 dark:shadow-zinc-700/80 ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 divide-y divide-zinc-100 focus:outline-none`}>
          <div className='flex items-center justify-center flex-col'>
            {items.map((item, i) => (
              <Menu.Item key={i}>
                  <button
                    disabled={!item.ability}
                    onClick={handleAction(item.action)}
                    className={`${item.ability ? "hover:bg-zinc-200 hover:dark:bg-zinc-700 hover:text-zinc-700 hover:dark:text-zinc-50" : 'opacity-30 !cursor-not-allowed pointer-events-none'} group text-zinc-500 dark:text-zinc-200 flex items-center w-[96%] my-[3px] pl-2 pr-4 py-2 text-xs rounded`}
                  >
                    {item.icon}
                    {item.header}
                  </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

BtnDropdown.propTypes = {
  btnHeader: PropTypes.any,
  items: PropTypes.array,
};

export default BtnDropdown;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextDescInput from 'components/Inputs/TextDesc';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import ModalHeader from 'components/Layout/ModalHeader';

const DatabaseNew = ({ onClose, onClick }) => {
  const [state, setState] = useState();

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  return (
    <div className="p-8">
      <ModalHeader
        header="New Database"
        content={
          <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
            All you have to do is give it a name! Send us an{' '}
            <a
              className="underline decoration-2 decoration-[#5DA6FC]
                                  underline-offset-[3px]"
              target="_newtab"
              href="mailto:thomas@less.tech?subject=Customize our Less database"
            >
              email
            </a>{' '}
            if you want to customize your database
          </p>
        }
      />

      <div>
        <div className="py-5">
          <TextDescInput
            header="Database name"
            placeholder="sensitive_data_DB"
            type="text"
            name="dbname"
            onChange={(e) => {
              handleChange('databasename', e);
            }}
          />
        </div>
      </div>
      <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-700 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={() => onClose()} />
          <PrimaryBtn text="Save Changes" onClick={() => onClick(state)} />
        </div>
      </div>
    </div>
  );
};

DatabaseNew.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default DatabaseNew;

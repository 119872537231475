import React, { useState } from 'react';
// import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { localTime } from 'utils/unitConversion';

// components
import Table from 'components/Tables/Table';
import TableRow from 'components/Tables/TableRow';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import StatusBadge from 'components/Badges/StatusBadge';
import { toDate, toReadDate } from 'utils/unitConversion';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import LongLogModel from 'components/Modals/ModelModals/LongLog';
import { StopIcon } from '@heroicons/react/solid';
import { stopTaskAction } from 'redux/actions/task';
import { useDispatch } from 'react-redux';

const LogGrid = ({ model, log, getTasks }) => {
  const [longLogModal, setLongLogModal] = useState(false);
  const [activeTask, setActiveTask] = useState({});


  function jobLog(task) {
    setActiveTask(task);
    setLongLogModal(true);
  }

  function closeJobLogHandler() {
    setActiveTask({});
    setLongLogModal(false);
  }

  const dispatch = useDispatch();


  const stopTask = async (model, data) => {
    await dispatch(stopTaskAction({ object_type: 'Datamodel', object_id: model.id, task_id: data.id }));
    await getTasks();
  };

  const headers = [
    { headerName: '', classes: 'text-center' },
    { headerName: '', classes: 'text-center w-[25px]' },
    { headerName: 'Date', classes: 'w-[150px]' },
    { headerName: 'Version', classes: 'w-[75px]' },
    { headerName: 'Duration', classes: 'w-[100px]' },
    { headerName: 'Message', classes: '' },
    { headerName: '', classes: '' },
  ];

  const renderTextWithLineBreaks = (text) => {
    const lines = text?.split('\n');
    return lines?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && (
          <React.Fragment>
            <br />
            <br />
          </React.Fragment>
        )}
      </React.Fragment>
    ));
  };



  const tableBody = log.map((data, index) => {
    return (
      <tr key={index}>
        <td className="text-center  justify-center w-[100px] h-full pb-1">
          <StatusBadge status={data?.run_code || 100} />
        </td>
        <TableRow content={
          (data?.run_code || 100) == 100 && (
            <OnlyIconBtn
              icon={<StopIcon className="h-4 w-4" aria-hidden="true" />}
              onClick={() => {
                stopTask(model, data);
              }}
            />
          )
        } />
        <TableRow classes="!whitespace-normal break-words" content={<p>{localTime(data?.executed_at || data?.created_at)}</p>} />
        <TableRow classes="!whitespace-normal break-words" content={<p>{data?.version_info?.version}</p>} />
        <TableRow
          classes="!whitespace-normal break-words"
          content={
            <a className="font-normal">
              {toDate(data?.finished_at) - toDate(data?.executed_at || data?.created_at) < -3600
                ? ''
                : toReadDate(toDate(data?.finished_at) - toDate(data?.executed_at || data?.created_at))}
            </a>
          }
        />

        <TableRow
          classes="!whitespace-normal !w-[400px flex items-start px-1"
          content={
            <div className="!whitespace-normal">
              <p className={`break-words `}>
                {data?.error_description === 'running' ? 'Running' : renderTextWithLineBreaks(data?.error_description)}
              </p>
            </div>
          }
        />
        <TableRow
          classes="w-[40px]"
          content={
            <RadixTooltip tooltipText="Detailed log">
              <OnlyIconBtn
                onClick={() => jobLog(data)}
                icon={<ExternalLinkIcon className="text-zinc-500 h-4 w-4" />}
              />
            </RadixTooltip>
          }
        />
      </tr>
    );
  });

  return (
    <div>
      {longLogModal && (
        <LongLogModel activeTask={activeTask} model={model} closeJobLogHandler={closeJobLogHandler} />
      )}
      <Table {...{ headers, tableBody }} />
    </div>
  );
};

LogGrid.propTypes = {
  log: PropTypes.array,
  model: PropTypes.object,
  getTasks: PropTypes.func,
};

LogGrid.defaultProps = {
  log: [],
  model: {},
};

export default LogGrid;

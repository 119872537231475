import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllConnectionsService,
  getConnectionService,
  executeConnectionService,
  exportConnectionTableService,
  addNewConnectionService,
  updateConnectionService,
  deleteConnectionService,
} from 'services/connection';

// First, create the thunk
export const getAllConnectionsAction = createAsyncThunk('connections/fetch', async () => {
  const response = await getAllConnectionsService();
  return response;
});

export const getConnectionAction = createAsyncThunk(
  'connections/get',
  async (connectionId) => {
    const response = await getConnectionService(connectionId);
    return response;
  }
);



export const executeConnectionAction = createAsyncThunk(
  'connections/refresh',
  async (connectionId, { rejectWithValue }) => {
    try {
      const response = await executeConnectionService(connectionId);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
  }
);

export const deleteConnectionAction = createAsyncThunk(
  'connections/delete',
  async (connectionId, { rejectWithValue }) => {
    try {
      const response = await deleteConnectionService(connectionId);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
  }
);

export const exportConnectionTableAction = createAsyncThunk(
  'connections/table/export',
  async ({ databaseId, table }, { rejectWithValue }) => {
    try {
      const response = await exportConnectionTableService(databaseId, table);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
  }
);

export const addNewConnectionAction = createAsyncThunk(
  'connections/add',
  async ({ data }, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('lastFolder') != "undefined" && localStorage.getItem('lastFolder') != "null"){
        data['folder_id'] = localStorage.getItem('lastFolder')
      }
      const response = await addNewConnectionService(data);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
  }
);

//TO HERE

export const updateConnectionAction = createAsyncThunk(
  'connections/update',
  async ({ connectionId, data }) => {
    const response = await updateConnectionService(connectionId, data);
    return response;
  }
);

// Then, handle actions in your reducer

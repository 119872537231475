import React from 'react';
import CanvasAction from 'components/Actions/CanvasAction';
import actionArray from 'data/tools';

import { Handle, Position } from 'reactflow';

const handleStylesRight = {
  height: 15,
  width: 15,
  right: -20,

  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const InputNode = (data) => {
  const { category, tooltip, text, icon } = actionArray.filter(
    (tool) => tool.name === data.data.configuration.tool_type
  )[0];
  const status = data.data.status;
  const selected = data.selected;
  const error_message = data?.errorMessage;
  const annotation = localStorage.getItem('annotations') == 'True';
  // console.log("Internal note status", data.data.status)
  return (
    <div>
      <CanvasAction
        data={data}
        desc={error_message || tooltip}
        icon={icon}
        name={text}
        category={category}
        status={status}
        selected={selected}
        description={
          annotation && data.data.configuration.tableId !== undefined
            ? `${data.data.configuration.tableId}`
            : undefined
        }
      // description={`Importing: ${data.data.configuration.tableId}`}
      />
      <Handle type="source" position={Position.Right} style={handleStylesRight} />
    </div>
  );
};

export default InputNode;

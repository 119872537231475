import FormInputData from 'data/FormInput';

const ConnectorNameInputField = (connectorId, defaultvalue) =>
  FormInputData({
    connectorId,
    defaultvalue,
    fieldtype: 'string',
    fieldname: 'name',
    formname: 'Connector Name',
    description:
      "It's optional, but you can give this connnection name that will show on the Less platform - remember to also give it a unique prefix. It's helpful if you connect to the same system multiple times.",
    placeholder: '{{CONNECTOR_NAME}}',
  });

export default ConnectorNameInputField;

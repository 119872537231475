import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Wrapper from 'components/Layout/Wrapper';
import Layout from 'components/Layout/Navbar';
import Login from 'components/Layout/Login';
import Home from 'pages/Home';
import Database from 'pages/Database';
// import Connectors from 'pages/Connectors';
import Folder from 'pages/Folder';
//import DataCatalogue from 'pages/DataCatalogue';
import ModellingCreate from 'pages/ModellingCreate';
import ForgotPassword from 'components/Layout/ForgotPassword';
import ResetPassword from 'components/Layout/ResetPassword';
//import Admin from 'pages/Admin';
import DataCatalogueV2 from 'pages/DataCatalogueV2';
import NewUser from 'pages/NewUser';
import { env } from './env';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ffe2bd51330d20164f3116db39b214bd@o4506932421591040.ingest.us.sentry.io/4506932853932032",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
Sentry.setTag("platform", env.REACT_APP_API_URL);


function App() {
  useEffect(() => {
    document.title = `Less - ${new URL(env.REACT_APP_API_URL)?.host}`;
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/database" element={<Database />} />
            {/* <Route path="/connectors" element={<Connectors />}>
              <Route path=":id" element={<Connectors />} />
              <Route path="edit/:id" element={<Connectors />} />
            </Route> */}
            <Route path="/modelling" element={<Navigate to="/folder" replace />} />
            <Route path="/folder" element={<Folder />} />
            <Route path="/folder/:paramFolder" element={<Folder />} />
            {/* <Route path="/admin" element={<Admin />} /> */}
            <Route path="/datacatalogueV2" element={<DataCatalogueV2 />} />
            {/* <Route path="/datacatalogue" element={<DataCatalogue />} /> */}
          </Route>
          {/* Admin routes */}
          {/* <Route path="/admin" element={<Admin />} /> */}
          <Route path="/modelling/edit/:modelId" element={<ModellingCreate />} />
          <Route path="/modelling/edit/:modelId/:versionId" element={<ModellingCreate />} />
          <Route path="/modelling/edit/:modelId/recover" element={<ModellingCreate recover />} />
          {/* <Route path="/register" element={<Register />} /> */}
        </Route>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/newuser" element={<NewUser />} />
      </Routes>
    </Provider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';
import { ClockIcon } from '@heroicons/react/solid';

const TextDescInput = ({
  value: defaultValue,
  onChange: onChangeCallback,
  read,
  optional,
  type,
  placeholder,
  name,
  minutes,
  readValue,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallback(e.target.value);
  };

  useEffect(() => setValue(defaultValue), [defaultValue]);

  return (
    <RegularInputTemplate {...props} optional={optional}>
      <div className="relative">
        <input
          {...props}
          readOnly={read === true ? true : false}
          required={optional === true ? false : true}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          onClick={read === true ? () => navigator.clipboard.writeText(readValue) : undefined}
          max={minutes === 'yes' ? '00:59' : ''}
          min={minutes === 'yes' ? '00:00' : ''}
          className={`${
            read === false
              ? 'placeholder:font-light focus:ring-[#5DA6FC]/50 focus:border-[#5DA6FC]/50 dark:focus:ring-[#5DA6FC] dark:focus:border-[#5DA6FC] text-zinc-700 dark:text-zinc-50 hover:border-zinc-300 duration-100'
              : 'cursor-copy dark:cursor-copywhite outline-none focus:ring-0 focus:ring-transparent focus:border-zinc-300 text-zinc-500 dark:text-zinc-200 bg-zinc-100 dark:bg-zinc-700'
          } shadow-xs dark:bg-zinc-700 placeholder:text-zinc-300 dark:placeholder:text-zinc-500 block w-full sm:text-sm border border-zinc-200 dark:border-zinc-600 rounded-md ${
            type === 'time' ? 'pl-8' : ''
          }`}
          autoComplete="off"
          aria-describedby={`${name} description`}
          value={read === true ? readValue : value}
          onChange={onChange}
          // withtooltip={undefined}
          // withdesc={undefined}
        />
        <div className="absolute top-[9px] left-2">
          {type === 'time' ? (
            <ClockIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-200" aria-hidden="true" />
          ) : (
            ''
          )}
        </div>
      </div>
    </RegularInputTemplate>
  );
};

TextDescInput.propTypes = {
  read: PropTypes.bool,
  optional: PropTypes.bool,
  readValue: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  minutes: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextDescInput.defaultProps = {
  read: false,
  type: '',
  placeholder: '',
  name: '',
  minutes: '',
  value: '',
  readValue: '',
  onChange: () => null,
};
export default TextDescInput;

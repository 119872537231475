import React, { useState, DragEvent } from 'react';
import PropTypes from 'prop-types';
import { CheckIcon, CogIcon, ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import PopoverBtn from 'components/Buttons/Popover';

const CanvasAction = ({ data, icon, name, category, status, selected, description, customAnnotation, disabled }) => {
  const [isDropzoneActive, setDropzoneActive] = useState(false);
  const onDrop = () => {
    setDropzoneActive(false);
  };

  const onDragEnter = () => {
    setDropzoneActive(true);
  };

  const onDragLeave = () => {
    setDropzoneActive(false);
  };

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  const className = isDropzoneActive ? '' : '';
  return (
    <div
      className={`hover:cursor-grab active:cursor-grabbing flex flex-col items-center gap-[2px]`}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <div className="items-center justify-center flex flex-row absolute bottom-9 gap-1.5 ">
        {customAnnotation && <PopoverBtn data={data} />}

        <div
          className={`h-5 w-5 flex items-center justify-center rounded-full ${status === 'OK'
            ? 'bg-emerald-100'
            : status === 'Error'
              ? 'bg-red-100'
              : status === 'Warning'
                ? 'bg-yellow-100'
                : status === 'Running'
                  ? 'bg-amber-100'
                  : 'hidden'
            } ${status === 'OK'
              ? 'text-emerald-500 border border-emerald-500'
              : status === 'Error'
                ? 'text-red-500 border border-red-500'
                : status === 'Warning'
                  ? 'text-yellow-500 border border-yellow-500'
                  : status === 'Running'
                    ? 'text-amber-500 border border-amber-500'
                    : 'bg-transparent'
            }`}
        >
          {status === 'OK' ? (
            <CheckIcon className="h-3 w-3" />
          ) : status === 'Error' ? (
            <ExclamationCircleIcon className="h-3 w-3" />
          ) : status === 'Warning' ? (
            <ExclamationIcon className="h-3 w-3" />
          ) : status === 'Running' ? (
            <CogIcon className="animate-spin h-3 w-3" />
          )
            : (
              ''
            )}
        </div>
      </div>
      <div
        className={` ${selected ? '!bg-transparent' : ''
          } relative flex justify-center overflow-hidden  items-center  w-full h-[32px] rounded-lg `}
      >
        {selected && (
          <div
            className={`rounded-xl  animate-fullSpin absolute bg-[conic-gradient(var(--tw-gradient-stops))] from-[#5da6fc] via-[#ff5478] to-transparent to-80% w-[200px] h-[150px]`}
          />
        )}
        <div
          className={`group justify-center overflow-hidden border items-center z-10 inline-flex w-[94%] h-[89%] top-[3%] left-[3%] rounded-md gap-0.5  ${name === 'Input' || name === 'Output'
            ? 'bg-zinc-800  text-white  border-zinc-200'
            : category === 'Basics'
              ? 'bg-slate-200  text-slate-800 border-slate-600/20'
              : category === 'New Columns'
                ? 'bg-[#ffe8e3]  text-[#664f4a] border-[#ffc6ba]'
                : category === 'Reshape'
                  ? 'bg-[#bedbfe] text-[#254265] border-[#5da6fc]/75'
                  : category === 'Utilities'
                    ? 'bg-white text-zinc-700 border-zinc-600/20'
                    : 'bg-[#ffbbc9] text-[#662230] border-[#ff5478]/75'
            } ${className}`}
        >
          <div
            className={`justify-center items-center inline-flex pr-1 ${disabled && 'pointer-events-none'
              }`}
          >
            <div
              className={`hover:cursor-context-menu	 pr-1 ${name === 'Input' || name === 'Output'
                ? ' border-black hover:bg-zinc-50 hover:text-zinc-800 border-zinc-200'
                : category === 'Basics'
                  ? ' border-slate-600/20 hover:bg-slate-600 hover:text-slate-200'
                  : category === 'New Columns'
                    ? ' border-[#ffc6ba] hover:bg-[#ffc6ba] hover:text-[#ffe8e3]'
                    : category === 'Reshape'
                      ? ' border-[#5da6fc]/75 hover:bg-[#5da6fc] hover:text-[#bedbfe]'
                      : category === 'Utilities'
                        ? 'border-zinc-600/20 hover:bg-zinc-600 hover:text-white'
                        : 'border-[#ff5478]/75 hover:bg-[#ff5478] hover:text-[#ffbbc9]'
                } border-r  pl-2 py-2  canvasActionIcon`}
            >
              {icon}
            </div>

            <div className="font-medium tracking-wider w-max pr-1.5 text-[10px] py-1.5 pl-1  text-center canvasActionText">
              {name}
            </div>
          </div>
        </div>
      </div>
      <p
        className={`${'absolute'} mt-9 max-w-[200px] text-left flex flex-col  whitespace-pre-wrap  max-h-[125px] overflow-hidden  text-[10px] w-max border border-zinc-200 dark:border-zinc-500 rounded p-1 bg-zinc-50 dark:bg-zinc-800 text-zinc-700 dark:text-zinc-50 font-medium`}
      >
        <span className="font-bold">Tool ID: {data?.id}</span>
        {description}
      </p>
    </div>
  );
};

CanvasAction.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  icon: PropTypes.object,
  //desc: PropTypes.any,
  input: PropTypes.string,
  output: PropTypes.string,
  status: PropTypes.string,
  selected: PropTypes.bool,
  category: PropTypes.string,
  description: PropTypes.string,
  customAnnotation: PropTypes.bool,
  disabled: PropTypes.bool,
};

CanvasAction.defaultProps = {
  customAnnotation: true,
  disabled: false,
};

export default CanvasAction;

import React from 'react';
import CanvasAction from 'components/Actions/CanvasAction';
import actionArray from 'data/tools';

import { Handle, Position } from 'reactflow';

const handleStylesLeft = {
  height: 15,
  width: 15,
  left: -20,

  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const OutputNode = (data) => {
  const { category, tooltip, text, icon, description } = actionArray.filter(
    (tool) => tool.name === data.data.configuration.tool_type
  )[0];
  const status = data.data.status;
  const selected = data.selected;
  const error_message = data?.errorMessage;
  const configured = data.data.configured;
  const annotation = localStorage.getItem('annotations') == 'True';

  return (
    <div>
      <CanvasAction
        data={data}
        desc={error_message || tooltip}
        icon={icon}
        name={text}
        category={category}
        status={status}
        selected={selected}
        description={
          annotation && text == "Output" ? description(configured, data.data.configuration.tableName) : annotation && text != "Output" ? description(configured, data.data.configuration.rowCount) : undefined
        }
      />
      <Handle
        type="target"
        className="hover:!bg-zinc-200"
        position={Position.Left}
        id="dataframe"
        style={handleStylesLeft}
      />
    </div>
  );
};

export default OutputNode;

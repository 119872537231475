import * as React from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal
} from "@floating-ui/react";
import PropTypes from 'prop-types';

import { Transition } from '@headlessui/react'

import { CheckIcon } from '@heroicons/react/solid';


const SimpleMultiselect = ({ items, defaulValue, onChange: onChangeCallback, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState({});

  React.useEffect(() => {
    // Set initial selected items when the component mounts
    if (defaulValue) {
      setSelectedIndex(defaulValue);
      onChangeCallback(defaulValue);
    }
  }, []);
  //console.log("INSIDE", selectedIndex)

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-center",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`
          });
        },
        padding: 10
      })
    ]
  });

  const listRef = React.useRef([]);
  const listContentRef = React.useRef(items);
  const isTypingRef = React.useRef(false);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true
  });

  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    }
  });

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([dismiss, role, listNav, typeahead, click]);

  const handleSelect = (index) => {
    const selectedOption = { ...selectedIndex };

    if (!selectedOption) {
      setSelectedIndex({});
    }

    if (!selectedOption[index]) {
      selectedOption[index] = true;
    } else {
      delete selectedOption[index];
    }

    setSelectedIndex(selectedOption);
    onChangeCallback(selectedOption);
  };



  return (
    <div>
      <div
        tabIndex={0}
        ref={refs.setReference}
        className={`relative w-min cursor-default hover:cursor-pointer shadow-sm shadow-zinc-200/20 ${isOpen ? "bg-zinc-50 dark:bg-zinc-600" : ""} hover:bg-zinc-50 transition-all duration-75 rounded-md bg-white dark:bg-zinc-700 dark:hover:bg-zinc-600 py-2 px-3 border focus:ring-[#5DA6FC] focus:border-[#5DA6FC] border-transparent ring-1 ring-zinc-950/10 dark:border-zinc-600 dark:text-zinc-50 text-zinc-700 sm:text-xs h-full `}
        {...getReferenceProps()}
      >
        <p className="block truncate">{props.placeholder}: <span className="font-medium">{Object.keys(selectedIndex).join(', ')}</span></p>
      </div>


      <Transition show={isOpen}>
        <FloatingPortal>
          <Transition.Child
            enter="ease-in-out duration-750"
            enterFrom="opacity-0 "
            enterTo="opacity-100 "
            leave="ease-in-out duration-75"
            leaveFrom="opacity-100 "
            leaveTo="opacity-0"
          >
            <FloatingFocusManager context={context} modal={false}>

              <div
                ref={refs.setFloating}
                className="ring-0 focus:ring-0 hover:ring-0 outline-none !max-h-60 bg-white dark:bg-zinc-700 shadow-lg z-[10000] border-separate border border-zinc-200 dark:border-zinc-600 overflow-auto rounded-md  dark:shadow-zinc-600/50 sm:text-xs"
                style={{
                  ...floatingStyles,

                }}
                {...getFloatingProps()}
              >
                {items.map((item, i) => (
                  <div
                    key={i}
                    className={`${selectedIndex[item.value] ? '' : ''
                      } pl-[25px] ring-0 focus:ring-0 hover:bg-zinc-100 select-none	flex items-center group text-xs py-2 pr-4 mx-1 my-1 rounded  hover:cursor-pointer text-zinc-600 dark:text-zinc-100`}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(item.value);
                      },

                    })}
                  >
                    {item.label}
                    <CheckIcon className={`absolute left-3 w-3 h-3 ${selectedIndex[item.value] ? "text-zinc-700" : "text-transparent"}`} />
                    {/* <span
                    aria-hidden
                    style={{
                      position: "absolute",
                      right: 10
                    }}
                  >
                    {i === selectedIndex ? " ✓" : ""}
                  </span> */}
                  </div>
                ))}
              </div>

            </FloatingFocusManager>
          </Transition.Child>
        </FloatingPortal>
      </Transition>
    </div>
  );
}

SimpleMultiselect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  defaulValue: PropTypes.any,
  stateTest: PropTypes.any,
  onChange: PropTypes.func,
};

SimpleMultiselect.defaultProps = {
  placeholder: '',
  items: [],
};

export default SimpleMultiselect


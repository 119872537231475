import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const RadioGroupModelling = ({ value: defaultValue, onChange, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const changeHandler = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <ModelingInputTemplate {...props}>
      <fieldset className="ml-1 mt-1.5">
        <div className="space-y-3">
          {props.items.map((option) => (
            <div key={option.value} className="flex items-center ">
              <input
                id={option.value}
                name={props.name}
                type="radio"
                checked={option.value === value}
                className="ring-0 offset-none focus:offset-none focus:ring-0 h-4 w-4 text-[#5DA6FC] border-zinc-300"
                onChange={changeHandler}
                value={option.value}
              />
              <label
                htmlFor={option.value}
                className="ml-2 block text-xs font-normal text-zinc-600 dark:text-zinc-100"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </ModelingInputTemplate>
  );
};

RadioGroupModelling.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

RadioGroupModelling.defaultProps = {
  name: '',
  items: [],
  value: '',
  onChange: () => null,
};

export default RadioGroupModelling;

import Axios from './axios';

export const getTasksService = (object_type, object_id) => {
  return Axios(`api/tasks/${object_type}/${object_id}`).then((res) => {
    return res?.data;
  });
}

export const getLogsService = (object_type, object_id, task_id) => {
  return Axios.get(`api/tasks/logs/${object_type}/${object_id}/${task_id}`).then((res) => {
    return res.data;
  });
};

export const stopTaskService = (object_type, object_id, task_id) => {
  return Axios.get(`api/tasks/stop/${object_type}/${object_id}/${task_id}`).then((res) => {
    return res.data;
  });
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import { ArrowCircleDownIcon, CubeIcon, DatabaseIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const TableClick = ({ defaultSelected, onChange: onChangeCallback, ...props }) => {
  const [selectedColumn, setSelectedColumn] = useState(null);
  const handleRowClick = (value) => {
    setSelectedRow(value);
    onChangeCallback(value);
  };

  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState(props.columns);

  const setSelectedRow = (defaultSelected) => {
    //For input tool
    var value = data.findIndex((item) => {
      return (
        item.db_id === defaultSelected.db_id &&
        item.value === defaultSelected.tableId &&
        item.source_id === defaultSelected.source_id &&
        item.type === defaultSelected.type
      );
    });
    //For legacy input tools now having above options
    if (value === -1) {
      value = data.findIndex(
        (item) => item.db_id === defaultSelected.dbId && item.value === defaultSelected.tableId
      );
    }
    //For other tools
    if (value === -1) {
      value = data.findIndex((item) => item.value === defaultSelected.value);
    }
    setSelectedColumn(value);
  };

  useEffect(() => {
    function runOnChange() {
      setSelectedRow(defaultSelected);
      handleRowClick(defaultSelected);
    }
    runOnChange();
  }, []);

  const excludeColumns = [];

  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setData(props.columns);
    else {
      const filteredData = props.columns.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  return (
    <ModelingInputTemplate {...props}>
      <label className="flex gap-x-2 items-baseline mb-2 -mt-2">
        <input
          type="text"
          className="text-xs mt-1 block w-full rounded-md focus:border-[#5DA6FC] focus:ring-[#5DA6FC] focus:ring-[1px]  border-zinc-300 dark:border-zinc-500 bg-zinc-100 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-100  placeholder:text-zinc-400"
          value={searchText}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={props.placeholder}
        />
      </label>

      <div className="flex flex-col  dark:bg-zinc-700  border-zinc-300 dark:border-zinc-500 rounded-md border overflow-hidden">
        <div className="-my-2 w-full max-h-80 overflow-y-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full text-left divide-y divide-zinc-200 dark:divide-zinc-600 ">
                <thead className=" bg-zinc-50 dark:bg-zinc-700 ">
                  <tr className="sticky top-0 ">
                    {props.columnHeaders.map((header, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className={`${header.classes} text-xs border-b px-2 font-medium text-zinc-700 dark:text-zinc-50`}
                        >
                          {header.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-600 bg-white dark:bg-zinc-700">
                  {data.map((column, index) => {
                    return (
                      <tr
                        onClick={() => handleRowClick(column)}
                        key={index}
                        className={`${
                          selectedColumn === index
                            ? '!bg-zinc-400 hover:bg-zinc-400 dark:!bg-zinc-800 hover:dark:bg-zinc-800  text-zinc-100 dark:text-zinc-200 font-medium'
                            : 'text-zinc-600 dark:text-zinc-100'
                        } hover:cursor-pointer hover:bg-zinc-100 hover:dark:bg-zinc-600 duration-150`}
                      >
                        {column?.type && (
                          <td
                            className={`text-center flex flex-row justify-center px-2 py-2 text-xs font-normal`}
                          >
                            {column?.type === 'Connection' ? (
                              <ArrowCircleDownIcon className="h-4 w-4" />
                            ) : column?.type === 'Datamodel' ? (
                              <CubeIcon className="h-4 w-4" />
                            ) : (
                              <DatabaseIcon className="h-4 w-4" />
                            )}
                          </td>
                        )}
                        {column?.source && (
                          <td className="whitespace-nowrap px-2 py-2 text-xs font-normal max-w-[200px] ">
                            {column?.source.length > 30 ? (
                              <RadixTooltip
                                childClasses="max-w-[190px] truncate !cursor-pointer"
                                tooltipText={column?.source}
                              >
                                {column?.source}
                              </RadixTooltip>
                            ) : (
                              column?.source
                            )}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-2 py-2 text-xs font-normal ">
                          {column.label}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModelingInputTemplate>
  );
};

TableClick.propTypes = {
  header: PropTypes.string,
  key: PropTypes.string,
  placeholder: PropTypes.string,
  columns: PropTypes.array,
  defaultValue: PropTypes.object,
  items: PropTypes.array,
  defaultSelected: PropTypes.object,
  columnHeaders: PropTypes.array,
  inputArr: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default TableClick;

import React from 'react';
import PropTypes from 'prop-types';
// import VideoTooltip from '../Tooltips/GifTooltip';

const BarAction = ({ tool, name, icon, classes }) => {
  const onMouseDown = (e) => {
    // Get the target
    const target = e.target;

    // Get the bounding rectangle of target
    const rect = target.getBoundingClientRect();

    // Mouse position
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    return [x, y];
  };

  const onDragStart = (event, node) => {
    const [x, y] = onMouseDown(event);
    const objectRect = event.target.getBoundingClientRect()
    node.cursorOffsetX = x;
    node.cursorOffsetY = y;
    node.height = objectRect.height;
    node.width = objectRect.width;
    event.dataTransfer.setData('application/reactflow', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <>
      <div
        draggable="true"
        onDragStart={(event) => onDragStart(event, tool)}
        className="group w-full hover:cursor-grab active:cursor-grabbing inline-flex items-center bg-transparent justify-center font-normal"
        style={{ opacity: 0.999 }}
      >
        <div className="hidden bg-zinc-800 text-white dark:border-zinc-700 " />
        <div className="hidden bg-[#e2e8f0]  text-slate-800 border-slate-600/20 " />
        <div className="hidden bg-[#ffe8e3]  text-[#664f4a] border-[#ffc6ba]" />
        <div className="hidden bg-[#bedbfe] text-[#254265] border-[#5da6fc]/75" />
        <div className="hidden bg-[#ffbbc9] text-[#662230] border-[#ff5478]/75" />
        <div
          className={`${classes} border  px-2 justify-center items-center inline-flex h-full w-full rounded-lg   gap-0.5`}
        >
          <div className="py-1.5 pr-1 ">{icon}</div>
          <div className="font-medium tracking-wider  w-max text-[10px] py-1.5 text-center">
            {name}
          </div>
        </div>
      </div>
    </>
  );
};

BarAction.propTypes = {
  icon: PropTypes.object,
  tool: PropTypes.object,
  margin: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.string,
  tttext: PropTypes.string,
  vidLink: PropTypes.object,
};

export default BarAction;

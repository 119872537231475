import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const ModelconfigAccordion = (props) => {
  return (
    <div className="w-full z-1">
      <div className="w-full rounded-2xl bg-white dark:bg-transparent pt-2 ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`relative w-full text-xs cursor-default rounded-md bg-zinc-100/75 dark:bg-zinc-700 py-3 pl-3 pr-10 mr-1 text-left shadow-sm  focus:ring-[#5DA6FC] focus:border-[#5DA6FC]  text-zinc-700 dark:text-zinc-50 text-xs">`}
              >
                <span className="block truncate font-medium">{props.header} </span>
                <span className="normal-case font-normal text-zinc-400 dark:text-zinc-300">
                  {props.description}
                </span>

                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className={`${
                      open ? 'rotate-180' : ''
                    } h-5 w-5 text-zinc-400 dark:text-zinc-300`}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-3 bg-zinc-100/75 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-200 rounded-md -space-y-5 mt-1.5">
                {props.children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

ModelconfigAccordion.propTypes = {
  header: PropTypes.any,
  description: PropTypes.any,
  children: PropTypes.any,
};

ModelconfigAccordion.defaultProps = {
  header: 'REMEMBER A HEADER!',
  description: '',
  children: null,
};

export default ModelconfigAccordion;

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DownloadIcon,
  TrashIcon,
  RefreshIcon,
  AdjustmentsIcon,
  DocumentDuplicateIcon,
  SelectorIcon,
  KeyIcon,
} from '@heroicons/react/solid';

// components
import Table from 'components/Tables/Table';
import AssetRowDatamodel from './AssetRowDatamodel';
import AssetRowConnection from './AssetRowConnection';

import FolderRow from './FolderRow';
// utils
import { useJune } from 'utils/useJune';
import { useSelector } from 'react-redux';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { env } from '../../../env';

const FolderGrid = ({
  setFilteredObjectCount,
  permissionShow,
  activeTab,
  models,
  connections,
  editConnection,
  deleteConnection,
  connectionLogs,
  executeConnection,
  getTablesHandler,
  subfolders,
  executeModel,
  //modelLogs,
  modelVersions,
  deleteModel,
  duplicateModel,
  editModel,
  emptyMessage,
  moveFolderHandler,
  editFolder,
  deleteFolder,
  openFolder,
  refreshFolders,
  shareObject,
  page,
  setPage,
}) => {
  const navigate = useNavigate();
  const lastLocation = useLocation()['pathname'];

  const tableHeaders = [
    { headerName: 'Status', classes: 'flex justify-center text-center w-[125px]', sortable: false },
    { headerName: 'Name', classes: 'w-[450px]', sortable: true },
    // { headerName: 'Description', classes: 'w-[350px]', sortable: false },
    { headerName: 'Sharing', classes: 'text-center justify-center w-[150px]', sortable: false },
    //{ headerName: 'Next Update', classes: 'w-[350px]', sortable: false },
    { headerName: 'Frequency', classes: 'w-[150px]', sortable: false },

    { headerName: 'Owner', classes: 'w-[150px]', sortable: false },
    {
      headerName: (
        <RadixTooltip tooltipText="Can only be accessed when the Connection/Model is updating">
          <p className="uppercase">Stop</p>
        </RadixTooltip>
      ),
      classes: 'flex justify-center w-[150px]',
      sortable: false,
    },
    { headerName: '', classes: 'w-[100px]', sortable: false },
  ];

  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.user,
    };
  });
  const analytics = useJune('f7aFCim6eVJaVfRf');
  const trackOpenModel = useCallback(
    (id) => {
      const company = new URL(env.REACT_APP_API_URL)?.host;
      const identifier = `${id}_${company}`;
      if (analytics)
        analytics.track({
          userId: identifier,
          event: 'Opened Existing Model',
          company: company,
          context: {
            groupId: company,
          },
        });
    },
    [analytics]
  );

  const pager = {
    size: 20,
    total: 0,
  };

  const optionsDatamodel = [
    {
      id: 'moveFolder',
      text: 'Move',
      ability: 'datamodel-manage',
      logo: <SelectorIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        const asset = 'Datamodel';
        const assetId = object.id;
        moveFolderHandler({
          asset,
          asset_id: assetId,
        });
      },
    },

    {
      id: 'download',
      text: 'Download',
      ability: 'datamodel-download',
      logo: <DownloadIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        getTablesHandler(object, 'Datamodel');
      },
    },
    {
      id: 'refresh',
      text: 'Refresh',
      ability: 'datamodel-execute',
      logo: <RefreshIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        object?.setPending();
        executeModel(object.id);
      },
    },
    {
      id: 'edit',
      text: 'Edit',
      ability: 'datamodel-update',
      logo: <AdjustmentsIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        editModel(object);
      },
    },
    {
      id: 'share',
      text: 'Sharing',
      logo: <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      ability: 'datamodel-manage',
      action: (object) => {
        shareObject('Datamodel', object);
      },
    },
    {
      id: 'versions',
      text: 'Logs & Versions',
      ability: 'datamodel-versions',
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="mr-2 w-4 h-4 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
          />
        </svg>
      ),
      action: (object) => {
        modelVersions(object);
      },
    },
    {
      id: 'duplicate',
      text: 'Duplicate',
      ability: 'datamodel-manage',
      logo: <DocumentDuplicateIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        duplicateModel(object);
      },
    },
    {
      id: 'delete',
      text: 'Delete',
      ability: 'datamodel-delete',
      logo: <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        deleteModel(object);
      },
    },
  ];

  const optionsConnection = [
    {
      id: 'moveFolder',
      text: 'Move',
      logo: <SelectorIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      ability: 'connection-manage',
      action: (object) => {
        const asset = 'Connection';
        const assetId = object.id;
        moveFolderHandler({
          asset,
          asset_id: assetId,
        });
      },
    },

    {
      id: 'download',
      text: 'Download',
      ability: 'connection-download',
      logo: <DownloadIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        getTablesHandler(object, 'Connection');
      },
    },
    {
      id: 'refresh',
      text: 'Refresh',
      ability: 'connection-execute',
      logo: <RefreshIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        object?.setPending();
        executeConnection(object.id);
      },
    },
    {
      id: 'edit',
      text: 'Edit',
      logo: <AdjustmentsIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      ability: "connection-update",
      action: (object) => {
        editConnection(object);
      },
    },
    {
      id: 'share',
      text: 'Sharing',
      logo: <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      ability: 'connection-manage',
      action: (object) => {
        shareObject('Connection', object);
      },
    },
    {
      id: 'logs',
      text: 'Logs',
      ability: 'connection-tasks',
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="mr-2 w-4 h-4 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
          />
        </svg>
      ),
      action: (object) => {
        connectionLogs(object);
      },
    },
    {
      id: 'delete',
      text: 'Delete',
      ability: 'connection-delete',
      logo: <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        deleteConnection(object);
      },
    },
  ];

  const folderMenu = [
    {
      id: 'editFolder',
      text: 'Edit Folder',
      ability: 'folder-manage',
      logo: <AdjustmentsIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        editFolder(object);
      },
    },
    {
      id: 'shareFolder',
      text: 'Sharing',
      ability: 'folder-manage',
      logo: <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        shareObject('Folder', object);
      },
    },
    {
      id: 'deleteFolder',
      text: 'Delete Folder',
      ability: 'folder-delete',
      logo: <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        deleteFolder(object);
      },
    },
  ];

  const [sortOrder, setSortOrder] = useState(localStorage.getItem('sortOrder') ? localStorage.getItem('sortOrder') : 'default');
  const [objects, setObjects] = useState([
    ...subfolders.map((folder) => {
      return { type: 'Folder', ...folder };
    }),
    ...connections.map((connection) => {
      return { type: 'Connection', ...connection };
    }),
    ...models.map((model) => {
      return { type: 'Datamodel', ...model };
    }),
  ]);
  const [array, setArray] = useState([]);

  const getSortedArray = () => {
    if (sortOrder === 'default') {
      return objects;
    } else if (sortOrder === 'asc') {
      return objects.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === 'desc') {
      return objects.sort((a, b) => b.name.localeCompare(a.name));
    }
  };

  const changeSortOrder = () => {
    if (sortOrder === 'default') {
      setSortOrder('asc');
    } else if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      setSortOrder('default');
    }
  };

  useEffect(() => {
    const sortedArray = getSortedArray();
    localStorage.setItem('sortOrder', sortOrder);
    setArray(sortedArray);
  }, [sortOrder, objects]);

  const tableBody = array.map((object) => {
    if (object?.type == 'Datamodel') {
      const onAssetClick = (e) => {
        if (!e.target.id.includes('headlessui-menu-item')) {
          trackOpenModel(user.user?.id);
          navigate(`/modelling/edit/${object.id}`, { state: { lastLocation: lastLocation } });
        }
      };

      return (
        <AssetRowDatamodel
          key={`model-${object.id}`}
          {...{ model: object, menu: optionsDatamodel, rowClick: onAssetClick, refreshFolders }}
        />
      );
    } else if (object?.type == 'Connection') {
      const onAssetClick = () => {
        return;
      };

      return (
        <AssetRowConnection
          key={`connection-${object.id}`}
          {...{
            connection: object,
            menu: optionsConnection,
            rowClick: onAssetClick,
            refreshFolders,
          }}
        />
      );
    } else if (object?.type == 'Folder') {
      return (
        <FolderRow
          key={`folder-${object.id}`}
          {...{ folder: object, openFolder, menu: folderMenu }}
        />
      );
    }
  });

  // useEffect(() => {
  //   if (activeTab == 1) {
  //     // setTableBody(subFoldersBody.concat(tableBodyConnections).concat(tableBodyDatamodels))
  //     setObjects(
  //       [...subfolders.map((folder) => { return { type: "Folder", ...folder } })
  //         , ...connections.map((connection) => { return { type: "Connection", ...connection } })
  //         , ...models.map((model) => { return { type: "Model", ...model } })]
  //     )
  //   } else if (activeTab == 2) {
  //     // setTableBody(subFoldersBody.concat(tableBodyConnections))
  //     setObjects([...subfolders.map((folder) => { return { type: "Folder", ...folder } })
  //       , ...connections.map((connection) => { return { type: "Connection", ...connection } })
  //     ])
  //   } else if (activeTab == 3) {
  //     // setTableBody(subFoldersBody.concat(tableBodyDatamodels))
  //     setObjects([...subfolders.map((folder) => { return { type: "Folder", ...folder } })
  //       , ...models.map((model) => { return { type: "Model", ...model } })])
  //   }

  // }, [activeTab, subfolders, models, connections]);

  // // OLD
  // useEffect(() => {
  //   const activeTabsArray = Object.keys(activeTab).filter((tab) => activeTab[tab]);

  //   // Generate objects based on active tabs
  //   const activeObjects = activeTabsArray.reduce((result, tab) => {
  //     switch (tab) {
  //       case 'Connections':
  //         result.push(...connections.map((connection) => ({ type: 'Connection', ...connection })));
  //         break;
  //       case 'Folders':
  //         result.push(...subfolders.map((folder) => ({ type: 'Folder', ...folder })));
  //         break;
  //       case 'Models':
  //         result.push(...models.map((model) => ({ type: 'Model', ...model })));
  //         break;
  //       default:
  //         break;
  //     }
  //     return result;
  //   }, []);

  //   const sortedObjects = activeObjects.sort((a, b) => {
  //     const order = ['Folder', 'Connection', 'Model'];
  //     return order.indexOf(a.type) - order.indexOf(b.type);
  //   });

  //   setObjects(sortedObjects);
  // }, [activeTab, subfolders, models, connections]);

  // NEW
  useEffect(() => {
    const activeTabsArray = Object.keys(activeTab).filter((tab) => activeTab[tab]);

    // Generate objects based on active tabs
    const activeObjects = activeTabsArray.reduce((result, tab) => {
      switch (tab) {
        case 'Connections':
          result.push(...connections.map((connection) => ({ type: 'Connection', ...connection })));
          break;
        case 'Folders':
          result.push(...subfolders.map((folder) => ({ type: 'Folder', ...folder })));
          break;
        case 'Models':
          result.push(...models.map((model) => ({ type: 'Datamodel', ...model })));
          break;
        default:
          break;
      }
      return result;
    }, []);

    const sortedObjects = activeObjects.sort((a, b) => {
      const order = ['Folder', 'Connection', 'Datamodel'];
      return order.indexOf(a.type) - order.indexOf(b.type);
    });

    const permissions = Object.keys(permissionShow);
    let filteredObjects = [];

    if (permissions.length === 2) {
      filteredObjects = sortedObjects;
    } else if (permissions.length == 0) {
      filteredObjects = [];
    } else if (!permissions.includes('Private')) {
      filteredObjects = sortedObjects.filter((item) => item.rulesets.length > 0);
    } else if (!permissions.includes('Shared')) {
      filteredObjects = sortedObjects.filter((item) => item.rulesets.length === 0);
    }

    setObjects(filteredObjects);
    setFilteredObjectCount(filteredObjects.length);
  }, [activeTab, subfolders, models, connections, permissionShow]);

  return (
    <Table
      type="assets"
      {...{
        headers: tableHeaders,
        tableBody: tableBody,
        pager,
        emptyMessage,
        sortOrder: sortOrder,
        headerOnclick: changeSortOrder,
        page,
        setPageCallback: setPage
      }}
    />
  );
};

FolderGrid.propTypes = {
  setFilteredObjectCount: PropTypes.any,
  permissionShow: PropTypes.any,
  activeTab: PropTypes.any,
  models: PropTypes.array,
  connections: PropTypes.array,
  editConnection: PropTypes.func,
  deleteConnection: PropTypes.func,
  connectionLogs: PropTypes.func,
  executeConnection: PropTypes.func,
  getTablesHandler: PropTypes.func,
  subfolders: PropTypes.array,
  executeModel: PropTypes.func,
  modelLogs: PropTypes.func,
  modelVersions: PropTypes.func,
  deleteModel: PropTypes.func,
  duplicateModel: PropTypes.func,
  editModel: PropTypes.func,
  moveFolderHandler: PropTypes.func,
  editFolder: PropTypes.func,
  deleteFolder: PropTypes.func,
  openFolder: PropTypes.func,
  refreshFolders: PropTypes.func,
  emptyMessage: PropTypes.string,
  shareObject: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
};

FolderGrid.defaultProps = {
  models: [],
  connections: [],
  executeModel: () => null,
  deleteModel: () => null,
  duplicateModel: () => null,
  editModel: () => null,
  addPermissionHandler: null,
  removePermissionHandler: null,
  emptyMessage: '',
  page: 0,
  setPage: () => null,
};

export default FolderGrid;

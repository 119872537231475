/* eslint-disable */
import { MinusCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import ModellingConfigBtn from 'components/Buttons/ModellingConfigBtn';
import Combobox2 from 'components/Inputs/Combobox2';

function KeyValueField({ leftOptions, rightOptions, ...props }) {
  const [inputValues, setInputValues] = useState(props.defaultSelected);

  function addAggregation() {
    setInputValues([...inputValues, { left: '', right: '', label: 'KeyValuePair' }]);
  }

  const onChangeCallback = (index, value) => {
    //console.log('valueFromCombo', index.split('_')[1], index.split('_')[0], value);
    //console.log('inputValues', inputValues);
    const newInputValues = [...inputValues];
    newInputValues[index.split('_')[1]][index.split('_')[0]] = value;
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  };

  function removeItem(index) {
    const newInputValues = inputValues.filter((value, i) => i !== index);
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  const completions = leftOptions.map((option) => {
    return { label: option.value, apply: `[${option.value}]`, detail: "(" + option?.type?.Name + ")" };
  });

  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col w-full">
        <div>
          {inputValues.map((value, index) => {
            return (
              <div key={index} className="relative">
                <div className="grid grid-cols-2 gap-1 mb-3 w-full ">
                  <td className="text-xs font-normal text-zinc-600 dark:text-zinc-100 !w-[95%]">
                    <Combobox2
                      key={`left_${index}`}
                      index={`left_${index}`}
                      completions={completions}
                      onChangeCallback={onChangeCallback}
                      initialValue={value['left']}
                    />
                  </td>
                  <td className="text-xs font-normal text-zinc-600 dark:text-zinc-100 !w-[95%]">
                    <Combobox2
                      key={`right_${index}`}
                      index={`right_${index}`}
                      completions={completions}
                      onChangeCallback={onChangeCallback}
                      initialValue={value['right']}
                    />
                  </td>
                </div>
                {index > 0 && (
                  <button
                    className="absolute -right-2.5 top-[32%]"
                    onClick={() => removeItem(index)}
                  >
                    <MinusCircleIcon className="w-4 h-4 duration-150 dark:hover:text-zinc-400 dark:text-zinc-300 text-zinc-700 hover:text-zinc-900" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div className="justify-center items-center inline-flex mt-2">
          <ModellingConfigBtn onClick={addAggregation} buttonText={props.buttonText} />
        </div>
      </div>
    </ModelingInputTemplate>
  );
}

KeyValueField.propTypes = {
  placeholder: PropTypes.string,
  leftOptions: PropTypes.string,
  onChange: PropTypes.func,
  rightOptions: PropTypes.array,
  buttonText: PropTypes.string,
  defaultSelected: PropTypes.array,
};

export default KeyValueField;

import React from 'react';
import CanvasAction from 'components/Actions/CanvasAction';
import actionArray from 'data/tools';

import { Handle, Position } from 'reactflow';

const handleStylesRight = {
  height: 15,
  width: 15,
  right: -20,
  marginTop: 0,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const handleStylesLeft = {
  height: 15,
  width: 15,
  left: -20,
  marginTop: 0,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const DefaultNode = (data) => {
  const { category, tooltip, text, icon, description } = actionArray.filter(
    (tool) => tool.name === data.data.configuration.tool_type
  )[0];
  const status = data.data.status;
  const selected = data.selected;
  const errorMessage = data?.data.errorMessage;
  const configured = data.data.configured;
  const annotation = localStorage.getItem('annotations') == 'True';

  
  return (
    <div>
      <CanvasAction
        data={data}
        desc={errorMessage || tooltip}
        icon={icon}
        name={text}
        status={status}
        selected={selected}
        category={category}
        description={
          annotation
            ? data.data.configuration.tool_type === 'sort'
              ? description(configured, data.data.configuration.columns)
              : data.data.configuration.tool_type === 'unique'
                ? description(configured, data.data.configuration.columnNames)
                : data.data.configuration.tool_type === 'rename'
                  ? description(configured, data.data.configuration.columns)
                  : data.data.configuration.tool_type === 'select'
                    ? description(configured, data.data.configuration.columnNames)
                    : data.data.configuration.tool_type === 'diff'
                      ? description(configured, data.data.configuration.columnNames)
                      : data.data.configuration.tool_type === 'formula'
                        ? description(
                          configured,
                          data.data.configuration.formula,
                          data.data.configuration.columnName
                        )
                        : data.data.configuration.tool_type === 'limit'
                          ? description(configured, data.data.configuration.rowLimit)
                          : data.data.configuration.tool_type === 'transpose'
                            ? description(
                              configured,
                              data.data.configuration.keyfields,
                              data.data.configuration.tfields
                            )
                            : data.data.configuration.tool_type === 'splitColumns'
                              ? description(
                                configured,
                                data.data.configuration.column,
                                data.data.configuration.direction,
                                data.data.configuration.seperator
                              )
                              : data.data.configuration.tool_type === 'runninginterval'
                                ? description(
                                  configured,
                                  data.data.configuration.column,
                                  data.data.configuration.offset,
                                  data.data.configuration.periods,
                                  data.data.configuration.function
                                )
                                : data.data.configuration.tool_type === 'ifelse'
                                  ? description(
                                    configured,
                                    data.data.configuration.columnName,
                                    data.data.configuration.statements,

                                    data.data.configuration.else
                                  )
                                  : data.data.configuration.tool_type === 'datemath'
                                    ? description(
                                      configured,
                                      data.data.configuration.column,
                                      data.data.configuration.columnName,
                                      data.data.configuration.number,
                                      data.data.configuration.unit
                                    )
                                    : data.data.configuration.tool_type === 'dateFormat'
                                      ? description(
                                        configured,
                                        data.data.configuration.columnName,
                                        data.data.configuration.columnNames
                                      )
                                      : data.data.configuration.tool_type === 'texttransform'
                                        ? description(
                                          configured,
                                          data.data.configuration.columns,
                                          data.data.configuration.operations
                                        )
                                        : data.data.configuration.tool_type === 'summarize'
                                          ? description(
                                            configured,
                                            data.data.configuration.groupby,
                                            data.data.configuration.columnNamesAgg
                                          )
                                          : data.data.configuration.tool_type === 'newtextcolumn'
                                            ? description(
                                              configured,
                                              data.data.configuration.columnName,
                                              data.data.configuration.columnConfig
                                            )
                                            : data.data.configuration.tool_type === 'pivot'
                                              ? description(
                                                configured,
                                                data.data.configuration.keyfields,
                                                data.data.configuration.header,
                                                data.data.configuration.value,
                                                data.data.configuration.aggfunc
                                              )
                                              : data.data.configuration.tool_type === 'datediff'
                                                ? description(
                                                  configured,
                                                  data.data.configuration.columnTwo,
                                                  data.data.configuration.columnOne,
                                                  data.data.configuration.unit
                                                )
                                                : data.data.configuration.tool_type === 'dateFormat'
                                                  ? description(
                                                    configured,
                                                    data.data.configuration.columnName,
                                                    data.data.configuration.columnNames
                                                  )
                                                  : data.data.configuration.tool_type === 'compare'
                                                    ? description(
                                                      configured,
                                                      data.data.configuration.columnNames,
                                                      data.data.configuration.shiftValue
                                                    )
                                                    : data.data.configuration.tool_type === 'filler'
                                                      ? description(configured, data.data.configuration.columns)
                                                      : data.data.configuration.tool_type === 'format'
                                                        ? description(configured, data.data.configuration.columns)
                                                        : data.data.configuration.tool_type === 'rowid'
                                                          ? description()
                                                          : data.data.configuration.tool_type === 'replace'
                                                            ? description(
                                                              configured,
                                                              data.data.configuration.columns,
                                                              data.data.configuration.valueToReplace,
                                                              data.data.configuration.replacement
                                                            )
                                                            : data.data.configuration.tool_type === 'cumulCalc'
                                                              ? description(
                                                                configured,
                                                                data.data.configuration.groupby,
                                                                data.data.configuration.columnNamesAgg
                                                              )
                                                              : data.data.configuration.tool_type === 'today'
                                                                ? description(
                                                                  configured,
                                                                  data.data.configuration.columnName,
                                                                  data.data.configuration.timezone
                                                                )
                                                                : data.data.configuration.tool_type === 'createRows'
                                                                  ? description(
                                                                    configured,
                                                                    data.data.configuration.splitNumber,
                                                                    data.data.configuration.splitColumns
                                                                  )
                                                                  : undefined
            : undefined
        }
      />
      <Handle type="source" position={Position.Right} style={handleStylesRight} />
      <Handle
        className="hover:!bg-zinc-200"
        type="target"
        position={Position.Left}
        id="dataframe"
        style={handleStylesLeft}
      />
    </div>
  );
};

export default DefaultNode;

/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import { Float } from '@headlessui-float/react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';

const ExampleHoverArrowMenu = ({ reference, tooltipText, animaPath }) => {
  const [show, setShow] = useState(false);

  const onDragStart = (event, node) => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  const container = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animaPath || require('components/Animations/filter.json'),
      });
    }, 1);
  }, [show]);

  return (
    <div
      className="bg-transparent items-center justify-center flex"
      onDragStart={() => onDragStart()}
    >
      <Float show={show} placement="bottom" offset={12}>
        <div className="items-center justify-center flex" onMouseEnter={open} onMouseLeave={close}>
          {reference}
        </div>
        <div className="p-2 rounded-lg bg-zinc-900 w-[166px] flex flex-col border border-zinc-200 dark:border-zinc-600 dark:shadow-zinc-600/25 shadow-md max-w-[250px]">
          <div
            style={{ height: 150, width: 150 }}
            className="container rounded w-fit"
            ref={container}
          ></div>
          <p className="mt-1 text-xs text-left text-zinc-200  font-medium">{tooltipText}</p>
        </div>
      </Float>
    </div>
  );
};

ExampleHoverArrowMenu.propTypes = {
  reference: PropTypes.any,
  tooltipText: PropTypes.any,
  animaPath: PropTypes.any,
};

export default ExampleHoverArrowMenu;

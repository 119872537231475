import React from 'react';
import PropTypes from 'prop-types';

function PrimaryTooltip({
  children,
  classesChild,
  tooltipText,
  bottom,
  classes,
  tooltipOpenCallback,
  tooltipCloseCallback,
  topClasses,
}) {
  const tipRef = React.createRef(null);

  function handleMouseEnter(e) {
    tipRef.current.style.visibility = 'visible';
    tooltipOpenCallback(e);
  }
  function handleMouseLeave(e) {
    tipRef.current.style.visibility = 'hidden';
    tooltipCloseCallback(e);
  }
  return (
    <div
      className={`${topClasses} relative text-center z-[30] flex bg-transparent w-auto justify-center`}
    >
      <div
        className={`${classes} absolute delay-100 bg-transparent w-max  invisible inline-flex items-center justify-center rounded-lg p-[1px] shadow-xl`}
        style={{ bottom: `${bottom}` }}
        ref={tipRef}
      >
        <span className="absolute p-[1px] h-full w-full rounded-lg bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></span>
        <span className="relative rounded-lg bg-black dark:bg-white opacity-90  max-w-[220px]">
          <div className=" font-medium text-xs text-left text-zinc-200 dark:text-zinc-700 break-words p-2 whitespace-normal">
            {tooltipText}
          </div>
        </span>
      </div>
      <div
        className={`${classesChild}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </div>
  );
}

PrimaryTooltip.propTypes = {
  bottom: PropTypes.string,
  children: PropTypes.any,
  classesChild: PropTypes.string,
  classes: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipOpenCallback: PropTypes.func,
  tooltipCloseCallback: PropTypes.func,
  topClasses: PropTypes.string,
};

PrimaryTooltip.defaultProps = {
  bottom: '100%',
  children: null,
  classes: '',
  topClasses: '',
  tooltipText: 'Tooltip',
  tooltipOpenCallback: () => null,
  tooltipCloseCallback: () => null,
};

export default PrimaryTooltip;

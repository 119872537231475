import React from 'react';
import PropTypes from 'prop-types';

//import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import { InformationCircleIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const RegularInputTemplate = (props) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <div
          className={`${props.optional == false ? 'items-center' : 'items-baseline'} flex gap-3`}
        >
          <label
            htmlFor={props.name}
            className="block text-sm pl-1 font-medium text-zinc-700 dark:text-zinc-50"
          >
            {props.header}
          </label>
          <div className={props.withtooltip == false ? 'hidden' : ''}>
            <RadixTooltip
              tooltipText={props.tooltip}>
               <InformationCircleIcon
                className="h-4 w-4 text-zinc-400 dark:text-zinc-300"
                aria-hidden="true"
              />
            </RadixTooltip>
            {/* <PrimaryTooltip
              classes={props.classes}
              tooltipText={props.tooltip}
              bottom={props.ttmargin}
            >
             
            </PrimaryTooltip> */}
          </div>
          <div className={props.optional == false ? 'hidden' : ''}>
            <span className="text-xs text-zinc-500 dark:text-zinc-200 opacity-60">Optional</span>
          </div>
        </div>
        <div className={props.withdesc == true ? '' : 'hidden'}>
          <p
            className="mt-0.5 pl-1 text-[13px] font-light text-zinc-400 dark:text-zinc-300"
            id={`${props.name} description`}
          >
            {props.subheader}
          </p>
        </div>

        <div className="mt-1.5 mx-0.5">{props.children}</div>
      </div>
    </div>
  );
};

RegularInputTemplate.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  withdesc: PropTypes.bool,
  optional: PropTypes.bool,
  classes: PropTypes.any,
  withtooltip: PropTypes.bool,
  tooltip: PropTypes.string,
  ttmargin: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.any,
};

RegularInputTemplate.defaultProps = {
  header: '',
  subheader: '',
  withdesc: true,
  optional: false,
  withtooltip: false,
  tooltip: '',
  ttmargin: '110%',
  name: '',
  children: null,
};

export default RegularInputTemplate;

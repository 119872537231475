import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { minimalSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { autocompletion } from '@codemirror/autocomplete';
import { MatchDecorator } from '@codemirror/view';
import { Decoration, ViewPlugin } from '@codemirror/view';

function Combobox2({
  // id, header, withtooltip, PrimaryTooltip, tooltip, ttmargin, InformationCircleIcon,
  index,
  initialValue,
  completions,
  onChangeCallback,
}) {
  const editor = useRef();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    onChangeCallback(index, value);
  }, [value]);

  const variableStyling =
    'bg-zinc-200 text-zinc-800 tracking-wide border-[1px] border-zinc-300 font-medium py-[1px] px-[1px] rounded';

  let markedGroup = Decoration.mark({
    class: variableStyling,
  }); // This adds a className to the text that matches the regex.

  const placeholderMatcher = new MatchDecorator({
    regexp: /\[([%]?[\w\s\d]+)\]/g,
    decoration: () => markedGroup,
  });

  const placeholders = ViewPlugin.fromClass(
    class {
      placeholders;
      constructor(view) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }
      update(update) {
        this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders);
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none;
        }),
    }
  );

  useEffect(() => {
    let myTheme = {
      '&': {
        color: '#334155',
        backgroundColor: '#ffffff',
      },
      '.cm-content': {
        caretColor: '#334155',
        fontSize: '12px',
        fontFamily: 'Inter',
        lineHeight: '20px',
      },
      '.world': {
        caretColor: '#334155',
        fontSize: '18px',
        fontFamily: 'Inter',
        backgroundColor: '#ffffff',
      },
      '.cm-completionInfo': {
        backgroundColor: 'rgb(234 179 8)',
        borderRadius: '0.5rem',
      },
      '.cm-tooltip-autocomplete': {
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        fontSize: '12px',
        position: 'absolute !important',
        top: '40px !important',
        left: '0 !important',
        zIndex: '101',
        rigth: '0 !important',
        width: '100% !important',
        border: '1px solid #e4e4e7 !important',
        boxShadow: '0px 1px 2px rgb(0 0 0 / 0.05)',
      },
      '.cm-tooltip-autocomplete ul li[aria-selected]': {
        backgroundColor: '#d4d4d8',
        color: '#3f3f46',
        padding: '5px !important',
        fontWeight: '600',
      },
      '.cm-tooltip-autocomplete ul li': {
        padding: '5px !important',
        borderRadius: '5px',
        marginTop: '2px',
        marginBottom: '2px',
        marginLeft: '2px',
        marginRight: '2px',
        fontFamily: 'Inter',
        color: '#71717a',
        //backgroundColor: 'red',
      },
      '&.cm-scroller': {
        overflow: 'auto',
        width: 'auto',

        border: '1px solid #e2e8f0 !important',
      },
      '&.cm-editor': {
        border: '1px solid #e2e8f0',
        padding: '4px',
        flexGrow: '1',
        width: 'auto',
        borderRadius: '6px',
        //minHeight: '300px',
        //overflow: 'hidden',
        boxShadow: '0px 1px 2px rgb(0 0 0 / 0.05)',
        position: 'relative !important',
      },
      '.ͼ2 .cm-selectionBackground': {
        backgroundColor: '#ff0000',
      },
      '&.cm-editor.cm-focused': { outline: 'none' },
      '&.cm-tooltip-autocomplete': { backgroundColor: '#e4e4e7' },
    };

    function myCompletions(context) {
      let before = context.matchBefore(/(%|\w+)/);
      //let before = context.matchBefore(/^(%|\w+)/);
      if (!context.explicit && !before) return null;
      return {
        from: before ? before.from : context.pos,
        options: completions,
        validFor: /^(%|\w)*$/,
      };
    }
    const state = EditorState.create({
      doc: value,
      extensions: [
        EditorView.updateListener.of(function (e) {
          setValue(e.state.doc.toString());
        }),
        EditorView.theme(myTheme, { dark: false }),
        minimalSetup,
        EditorView.lineWrapping,
        autocompletion({
          override: [myCompletions],
          icons: false,
        }),
        placeholders,
      ],
    });

    const view = new EditorView({
      lineWrapping: true,
      state,
      parent: editor.current,
    });

    return () => {
      view.destroy();
    };
  }, []);

  return (
    <div>
      {/* <Portal.Root container={document.getElementById('flying-modal-model')} asChild> */}
      <div ref={editor}>
        <div className={`hidden ${variableStyling}`}></div>
      </div>
      {/* </Portal.Root> */}
    </div>
  );
}

Combobox2.propTypes = {
  // id: PropTypes.str,
  // header: PropTypes.str,
  // withtooltip: PropTypes.str,
  // PrimaryTooltip: PropTypes.str,
  // tooltip: PropTypes.str,
  // ttmargin: PropTypes.str,
  // InformationCircleIcon: PropTypes.str,
  index: PropTypes.str,
  completions: PropTypes.array,
  initialValue: PropTypes.str,
  onChangeCallback: PropTypes.func,
};

Combobox2.defaultValues = {
  // id: "",
  // header: "",
  // withtooltip: "",
  // PrimaryTooltip: "",
  // tooltip: "",
  // ttmargin: "",
  // InformationCircleIcon: "",
  completions: PropTypes.array,
  initialValue: PropTypes.str,
  onChangeCallback: PropTypes.func,
};

export default Combobox2;

import React, { useRef, useCallback, useState, useEffect } from 'react';
import { ResizableBox } from 'react-resizable';
import { MinusIcon } from '@heroicons/react/solid';
import 'react-resizable/css/styles.css';
import { Handle, Position } from 'reactflow';
import actionArray from 'data/tools';
import CanvasAction from 'components/Actions/CanvasAction';

const handleStylesRight = {
  height: 15,
  width: 15,
  right: -20,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const MAX_ROWS = 50;
const MAX_COLUMNS = 10;

const TextInputNode = (toolData) => {
  const [data, setData] = useState(toolData.data?.configuration?.value || { column_a: [''] });
  const [columns, setColumns] = useState(toolData.data?.configuration?.columns || ['column_a']);
  const [editingHeader, setEditingHeader] = useState(null);
  const [columnWidths, setColumnWidths] = useState(
    toolData.data?.configuration?.columnWidths || { column_a: 100 }
  );
  const [isContentVisible, setIsContentVisible] = useState(true);
  const [focusedCell, setFocusedCell] = useState(
    toolData.data?.configuration?.focusedCell || { row: 0, col: 0 }
  );

  const { category, tooltip, text, icon } = actionArray.filter(
    (tool) => tool.name === toolData.data.configuration.tool_type
  )[0];
  const selected = toolData.selected;

  const [nextColumnLetter, setNextColumnLetter] = useState('b');

  useEffect(() => {
    toolData.data.configuration['value'] = data;
    toolData.data.configuration['columns'] = columns;
    toolData.data.configuration['columnWidths'] = columnWidths;
    toolData.data.configuration['focusedCell'] = focusedCell;
    toolData.data.configuration['cache'] = false;
  }, [data, columns, columnWidths, focusedCell]);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const addRow = () => {
    if (Object.values(data)[0].length < MAX_ROWS) {
      setData((prevData) => {
        const newData = { ...prevData };
        columns.forEach((key) => {
          newData[key] = [...newData[key], ''];
        });
        return newData;
      });
    }
  };

  const addColumn = () => {
    if (columns.length < MAX_COLUMNS) {
      const newColumnName = `column_${nextColumnLetter}`;
      setColumns((prevColumns) => [...prevColumns, newColumnName]);
      setData((prevData) => ({
        ...prevData,
        [newColumnName]: new Array(Object.values(prevData)[0].length).fill(''),
      }));
      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [newColumnName]: 100,
      }));
      setNextColumnLetter(String.fromCharCode(nextColumnLetter.charCodeAt(0) + 1));
    }
  };

  const removeColumn = (colName) => {
    if (columns.length > 1) {
      setColumns((prevColumns) => prevColumns.filter((col) => col !== colName));
      setData((prevData) => {
        const newData = { ...prevData };
        delete newData[colName];
        return newData;
      });
      setColumnWidths((prevWidths) => {
        const newWidths = { ...prevWidths };
        delete newWidths[colName];
        return newWidths;
      });
    }
  };

  const handleResize = (colName, width) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [colName]: width,
    }));
  };

  const updateCell = (colName, rowIndex, value) => {
    setData((prevData) => ({
      ...prevData,
      [colName]: prevData[colName].map((cell, index) => (index === rowIndex ? value : cell)),
    }));
  };

  const startEditingColumnName = (colName) => {
    setEditingHeader(colName);
  };

  const finishEditingColumnName = (oldName, newName) => {
    if (newName && newName !== oldName && !columns.includes(newName)) {
      setColumns((prevColumns) => prevColumns.map((col) => (col === oldName ? newName : col)));
      setData((prevData) => {
        const newData = { ...prevData };
        newData[newName] = prevData[oldName];
        delete newData[oldName];
        return newData;
      });
      setColumnWidths((prevWidths) => {
        const newWidths = { ...prevWidths };
        newWidths[newName] = prevWidths[oldName];
        delete newWidths[oldName];
        return newWidths;
      });
    }
    setEditingHeader(null);
  };

  const inputRefs = useRef({});

  const focusInput = useCallback((rowIndex, colIndex) => {
    const input = inputRefs.current[`${rowIndex}-${colIndex}`];
    if (input) {
      input.focus();
    }
  }, []);

  const handleKeyDown = useCallback(
    (e, rowIndex, colIndex) => {
      const totalRows = Object.values(data)[0].length;
      const totalCols = columns.length;

      switch (e.key) {
        case 'ArrowUp':
          if (rowIndex > 0) {
            e.preventDefault();
            focusInput(rowIndex - 1, colIndex);
          }
          break;
        case 'ArrowDown':
          if (rowIndex < totalRows - 1) {
            e.preventDefault();
            focusInput(rowIndex + 1, colIndex);
          }
          break;
        case 'ArrowLeft':
          if (colIndex > 0) {
            e.preventDefault();
            focusInput(rowIndex, colIndex - 1);
          }
          break;
        case 'ArrowRight':
          if (colIndex < totalCols - 1) {
            e.preventDefault();
            focusInput(rowIndex, colIndex + 1);
          }
          break;
        default:
          return;
      }
    },
    [columns, data, focusInput]
  );

  const handleFocus = (rowIndex, colIndex) => {
    setFocusedCell({ row: rowIndex, col: colIndex });
  };

  const removeRow = (index) => {
    if (Object.values(data)[0].length > 1) {
      setData((prevData) => {
        const newData = { ...prevData };
        columns.forEach((key) => {
          newData[key] = newData[key].filter((_, i) => i !== index);
        });
        return newData;
      });
    }
  };

  const handleInputPaste = (e, col, rowIndex) => {
    e.stopPropagation();
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    updateCell(col, rowIndex, pastedData);
  };

  const handleInputCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const selectedInput = document.activeElement;
    if (selectedInput && selectedInput.tagName === 'INPUT') {
      const selectedText = selectedInput.value.substring(
        selectedInput.selectionStart,
        selectedInput.selectionEnd
      );

      const textToCopy = selectedText || selectedInput.value;

      const nonString = textToCopy == null || textToCopy === true || textToCopy === false;
      let stringValue = '';

      if (String(textToCopy?.type) === 'Symbol(react.fragment)') {
        stringValue = textToCopy;
      } else {
        stringValue = nonString
          ? String(textToCopy).toUpperCase()
          : typeof textToCopy === 'object'
          ? JSON.stringify(textToCopy)
          : textToCopy;
      }

      navigator.clipboard
        .writeText(stringValue)
        .then(() => {
          console.log('Copied to clipboard:', stringValue);
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
        });
    }
  };

  return (
    <div className="text-xs w-fit h-fit relative ">
      <button
        onClick={toggleContent}
        className={`flex items-center justify-center p-[3px] absolute ${
          !isContentVisible && 'mx-auto right-0'
        } left-0 -top-7 h-6 w-6 bg-zinc-400 hover:bg-zinc-500 rounded-md hover:border-zinc-400 border-zinc-300 border-2 hover:shadow-md duration-300`}
      >
        {isContentVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="text-zinc-50"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="text-zinc-50"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
            />
          </svg>
        )}
      </button>
      <div className={`${isContentVisible && 'bg-white rounded border border-gray-300'}`}>
        {isContentVisible ? (
          <table className="w-fit border-collapse ">
            <thead>
              <tr>
                {columns.map((col, colIndex) => (
                  <th
                    key={col}
                    className={`${
                      colIndex === columns.length - 1 && columns.length > 1
                        ? 'rounded-tr-[3px]'
                        : colIndex === 0 && columns.length > 1
                        ? 'rounded-tl-[3px] border-r'
                        : colIndex === 0
                        ? 'rounded-t-[3px] border-r'
                        : 'border-r'
                    }  border-gray-300 bg-gray-100 relative`}
                    style={{ width: columnWidths[col] }}
                  >
                    <ResizableBox
                      width={columnWidths[col]}
                      height={30}
                      minConstraints={[100, 0]}
                      axis="x"
                      resizeHandles={['e']}
                      handle={
                        <span className="absolute -right-1 top-0 h-full w-2 cursor-col-resize nodrag" />
                      }
                      onResize={(e, { size }) => handleResize(col, size.width)}
                    >
                      <div className="min-w-[100px]" style={{ width: '100%' }}>
                        {editingHeader === col ? (
                          <input
                            type="text"
                            defaultValue={col}
                            onBlur={(e) => finishEditingColumnName(col, e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                finishEditingColumnName(col, e.target.value);
                              }
                            }}
                            className="h-[30px] w-full outline-none bg-transparent font-bold text-xs focus:ring-transparent border-1 focus:border-[#5da6fc] rounded-sm"
                            autoFocus
                            data-1p-ignore
                            style={{ userSelect: 'text' }}
                          />
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={() => startEditingColumnName(col)}
                          >
                            <p className="min-w-[100px] px-2 py-2 truncate">{col}</p>
                          </div>
                        )}
                        {columns.length > 1 && columns.indexOf(col) !== 0 && (
                          <button
                            className="absolute -top-5 bg-zinc-100 px-1.5 py-0.5 rounded z-10 transform -translate-x-1/2 border cursor-pointer text-zinc-500  border-zinc-300 opacity-50 hover:opacity-100 transition-all duration-200"
                            onClick={() => removeColumn(col)}
                          >
                            <MinusIcon className="w-2 h-2" />
                          </button>
                        )}
                      </div>
                    </ResizableBox>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(data)[0].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <td
                      key={`${col}-${rowIndex}`}
                      className={`${
                        colIndex === columns.length - 1 ? '' : 'border-r'
                      } border-t border-gray-300 relative`}
                    >
                      <input
                        ref={(el) => (inputRefs.current[`${rowIndex}-${colIndex}`] = el)}
                        type="text"
                        name={toolData.id}
                        value={data[col][rowIndex]}
                        onChange={(e) => updateCell(col, rowIndex, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                        onFocus={() => handleFocus(rowIndex, colIndex)}
                        className="w-full border-transparent outline-none text-xs focus:ring-transparent border-1 focus:border-[#5da6fc] rounded-sm"
                        data-row={rowIndex}
                        data-col={colIndex}
                        onCopy={handleInputCopy}
                        onPaste={(e) => handleInputPaste(e, col, rowIndex)}
                        style={{ userSelect: 'text' }}
                      />
                      {rowIndex > 0 && col === columns[0] && (
                        <button
                          className="absolute -left-7 bg-zinc-100 px-1.5 py-0.5 rounded z-10 top-1/2 transform -translate-y-1/2 cursor-pointer text-zinc-500 border border-zinc-300 opacity-50 hover:opacity-100 transition-all duration-200"
                          onClick={() => removeRow(rowIndex)}
                        >
                          <MinusIcon className="w-2 h-2" />
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <CanvasAction
            data={toolData}
            desc={tooltip}
            icon={icon}
            name={text}
            category={category}
            customAnnotation={false}
            selected={selected}
            disabled={true}
          />
        )}
        <Handle type="source" position={Position.Right} style={handleStylesRight} />
      </div>
      {isContentVisible && (
        <>
          <button
            onClick={addRow}
            disabled={Object.values(data)[0].length >= MAX_ROWS}
            className=" absolute -bottom-8   px-2 py-1 bg-[#5da6fc] text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
          >
            + Row{' '}
            <span className="opacity-50">
              {Object.values(data)[0].length}/{MAX_ROWS}
            </span>
          </button>
          <button
            onClick={addColumn}
            disabled={columns.length >= MAX_COLUMNS}
            className="absolute -right-[108px] top-0 px-2 py-1 bg-[#5da6fc] text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
          >
            + Column{' '}
            <span className="opacity-50">
              {columns.length}/{MAX_COLUMNS}
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default TextInputNode;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { localTime } from 'utils/unitConversion';

// components
import BasicModal from 'components/Modals/BasicModal';
import ModalHeader from 'components/Layout/ModalHeader';
import Table from 'components/Tables/Table';
import TableRow from 'components/Tables/TableRow';
import { epochToReadable } from 'utils/unitConversion';
import { getLogsAction } from 'redux/actions/task';

const LongLog = ({ activeTask, connection, closeJobLogHandler }) => {
  const [dLogs, setDLogs] = useState([]);
  const [tableBodyLong, setTableBodyLong] = useState([]);
  const dispatch = useDispatch();
  const [loadingLogs, setLoadingLogs] = useState(true);

  //console.log('activeTask', activeTask, connection);
  const headersLong = [
    { headerName: 'Type', classes: 'w-[8%]' },
    { headerName: 'Timestamp', classes: 'w-[22%]' },
    { headerName: 'Message', classes: 'w-[70%]' },
  ];

  useEffect(() => {
    const body = dLogs.map((data) => {
      return (
        <tr key={data?.id}>
          <td className="mx-2 text-center  justify-center w-[100px] h-full pb-1">
            <div
              className={`${data?.message?.severity == 'info'
                ? 'bg-zinc-200 text-zinc-600 border-zinc-600'
                : data?.message?.severity == 'action'
                  ? 'bg-green-50 text-green-600 border-green-600'
                  : data?.message?.severity == 'error'
                    ? 'bg-red-50 text-red-600 border-red-600'
                    : data?.message?.severity == 'warning'
                      ? 'bg-yellow-50 text-yellow-600 border-yellow-600'
                      : 'bg-blue-200'
                } m-2 w-[68px] h-auto inline-flex items-center tracking-wide border justify-center rounded-lg relative px-1 py-1 text-[10px] font-medium`}
            >
              <p className="px-1.5 py-0 uppercase">{data?.message?.severity}</p>
            </div>
          </td>
          <TableRow content={<p>{epochToReadable(data?.timestamp)}</p>} />
          <TableRow classes="!whitespace-normal break-words" content={<p>{data?.message?.message}</p>} />
        </tr>
      );
    });
    setTableBodyLong(body);
  }, [dLogs]);

  const getLogs = async (taskId) => {
    const result = await dispatch(
      getLogsAction({ object_type: connection.type, object_id: connection.id, task_id: taskId })
    );
    setLoadingLogs(false);
    setDLogs(result.payload || []);
  };

  useEffect(() => {
    getLogs(activeTask.id);
  }, []);

  useEffect(() => {
    const body = dLogs.map((data) => {
      return (
        <tr key={data?.id}>
          <td className="mx-2 text-center  justify-center w-[100px] h-full pb-1">
            <div
              className={`${data?.message?.severity == 'info'
                ? 'bg-zinc-200 text-zinc-600 border-zinc-600'
                : data?.message?.severity == 'action'
                  ? 'bg-green-50 text-green-600 border-green-600'
                  : data?.message?.severity == 'error'
                    ? 'bg-red-50 text-red-600 border-red-600'
                    : data?.message?.severity == 'warning'
                      ? 'bg-yellow-50 text-yellow-600 border-yellow-600'
                      : 'bg-blue-200'
                } m-2 w-[68px] h-auto inline-flex items-center tracking-wide border justify-center rounded-lg relative px-1 py-1 text-[10px] font-medium`}
            >
              <p className="px-1.5 py-0 uppercase">{data?.message?.severity}</p>
            </div>
          </td>
          <TableRow content={<p>{epochToReadable(data?.timestamp)}</p>} />
          <TableRow classes="!whitespace-normal break-words" content={<p>{data?.message?.message}</p>} />
        </tr>
      );
    });
    setTableBodyLong(body);
  }, [dLogs]);

  function closeJobLog() {
    closeJobLogHandler();
    setDLogs([]);
    setLoadingLogs(true);
  }


  return (
    <>
      <BasicModal
        open
        classes="w-[1000px] 2xl:w-[1200px] min-w-lg "
        onClose={() => closeJobLog()}
        content={

          <div className="p-8">
            <ModalHeader
              header={`${localTime(activeTask?.executed_at || activeTask?.created_at)} - ${connection?.name}`}
              content={<>
                <p className="mt-2 max-w-4xl text-sm align-baseline">Detailed job log</p>
              </>
              }
            />

            <div className="mt-3">
              <Table
                {...{
                  classes: '!max-h-[calc(90vh-225px)]',
                  headers: headersLong,
                  tableBody: tableBodyLong,
                  emptyMessage: loadingLogs ? 'Loading' : 'No Logs found for this job',
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

LongLog.propTypes = {
  activeTask: PropTypes.any,
  connection: PropTypes.any,
  closeJobLogHandler: PropTypes.func,
};

LongLog.defaultProps = {
  activeTask: {},
  connection: {},
};

export default LongLog;

import Axios, {jsonHeader} from './axios';

export const getResourceRulesetCollectionService = ({objectType, objectId}) => {
  return Axios.get(`api/ruleset/${objectType}/${objectId}`).then((res) => {
    return res?.data;
  });
};


export const setResourceRulesetService = (data) => {
  return Axios.post(`api/ruleset/setRules`, data, jsonHeader).then((res) => {
    return res?.data;
  });
};


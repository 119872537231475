import React from 'react';
import PropTypes from 'prop-types';

const LeftNavAvatar = ({ fullName }) => {
  const name = fullName.toLowerCase().replace(' ', '');

  const getInitials = function (string) {
    let names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <img
      className="inline-flex m-1 border-zinc-300/25 border-2 items-center justify-center h-auto w-9 rounded-full"
      src={`https://avatar.vercel.sh/${name}.svg?text=${getInitials(fullName)}`}
    />
  );
};

LeftNavAvatar.propTypes = {
  fullName: PropTypes.string,
};

LeftNavAvatar.defaultProps = {
  fullName: '',
};

export default LeftNavAvatar;

import React, { useState } from 'react';
import TextDescInput from 'components/Inputs/TextDesc';
import PropTypes from 'prop-types';

import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import RadioGroup from 'components/Inputs/RadioGroup';
import PasswordInput from 'components/Inputs/PasswordInput';
import ModalHeader from 'components/Layout/ModalHeader';

const confirm = [
  {
    id: 'understand',
    value: 'I understand',
    label: 'I understand that this can write data to the database',
  },
];

const DatabaseLink = ({ onClose, onClick }) => {
  const [state, setState] = useState();

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  return (
    <div className="p-8">
      <ModalHeader
        header="Link Database"
        content={
          <div className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
            You might want to use your own database as the foundation for Less. If you link a
            database, you can read and write data directly to your own data.
            <p className="mt-4 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
              If you need help, check out{' '}
              <a
                href="https://resources.less.tech/less-tech/advanced-databases-and-security/link-database"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm underline decoration-2 decoration-[#5DA6FC]
                                  underline-offset-[3px] text-zinc-500 dark:text-zinc-200 font-medium "
              >
                our guide
              </a>{' '}
              or{' '}
              <a
                href="https://calendly.com/thomas-less/speak-to-less"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm underline decoration-2 decoration-[#ff5478]
                                  underline-offset-[3px] text-zinc-500 dark:text-zinc-200 font-medium "
              >
                schedule a call
              </a>{' '}
              with us
            </p>
            <p className="mt-4 max-w-4xl text-sm font-semibold align-baseline  text-red-400">
              At this stage, you can only add MySQL databases.{' '}
            </p>
          </div>
        }
      />

      <div className="my-5 space-y-5">
        <TextDescInput
          required
          header="Database host name"
          placeholder="80.194.10.25"
          type="text"
          name="ip"
          onChange={(e) => {
            handleChange('hostIp', e);
          }}
        />

        <TextDescInput
          required
          header="Database name"
          placeholder="any_name_db"
          type="text"
          name="db_name"
          onChange={(e) => {
            handleChange('databasename', e);
          }}
        />

        <TextDescInput
          required
          header="Port"
          placeholder="3306"
          type="text"
          name="port"
          onChange={(e) => {
            handleChange('databaseport', e);
          }}
        />

        <TextDescInput
          required
          header="Username"
          subheader="Your user must have read and write access on the database"
          placeholder="database_user"
          type="text"
          name="username"
          onChange={(e) => {
            handleChange('username', e);
          }}
        />

        <PasswordInput
          header="Password"
          subheader="Your user must have read and write access on the database"
          placeholder="Insert password"
          withtooltip={false}
          onChange={(e) => {
            handleChange('password', e);
          }}
        />

        <RadioGroup
          header="Confirm"
          desc="Confirm that you understand that you will be able to write data to this database"
          legend="Confirm"
          items={confirm}
          onChange={(e) => {
            handleChange('confirm', e);
          }}
        />
      </div>
      <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-700 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={() => onClose()} />
          <PrimaryBtn text="Save Changes" onClick={() => onClick(state)} />
        </div>
      </div>
    </div>
  );
};

DatabaseLink.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default DatabaseLink;

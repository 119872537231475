import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from '@floating-ui/react';

const TemplatePopover = ({ children, btnClasses, btnText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(5), flip({ fallbackAxisSideDirection: 'end' }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const headingId = useId();

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <button className={btnClasses} ref={refs.setReference} {...getReferenceProps()}>
          {btnText}
        </button>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="z-10"
            onClick={() => setIsOpen(false)}
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            {children}
          </div>
        </FloatingFocusManager >
      )}
    </>
  );
};

TemplatePopover.propTypes = {
  children: PropTypes.any,
  btnText: PropTypes.any,
  btnClasses: PropTypes.any,
};

export default TemplatePopover;

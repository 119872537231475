import React from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'components/Inputs/FormBuilder';
import {
  FrequencySelectFields,
} from 'components/Inputs/DynamicInputFields';
import { timeToLocal } from 'utils/unitConversion'
import dayjs from 'dayjs';

const frequencySelector = FrequencySelectFields({
  types: [
    {
      value: 'hourly',
      label: 'Hourly',
    },
    {
      value: 'daily',
      label: 'Daily',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'first_of_month',
      label: 'First of month',
    },
    {
      value: 'never',
      label: 'Never',
    },
  ],
});

const inputs = [
  {
    "fieldname": "name",
    "formname": "Model Name",
    "description": "Give your model a name",
    "sort": "",
    "fieldtype": "string",
    "defaultvalue": "",
    "created_at": null,
    "updated_at": null,
    "placeholder": "Marketing Spend - Last 52 Weeks"
  },
  {
    "fieldname": "description",
    "formname": "Model Description",
    "description": "Add a description to help your team find the data they need",
    "sort": "",
    "fieldtype": "textarea",
    "defaultvalue": "",
    "created_at": null,
    "updated_at": null,
    "placeholder": "Max. 200 characters"
  },
]

const sortedInputs = [
  ...inputs,
  frequencySelector,
];

const ConfigModel = ({ model, ...props }) => {

  const getInitState = () => {
    let state = {};
    if (model) {
      // edit mode
      state = {
        name: model?.name,
        description: model?.description,
        frequency: model?.schedule?.frequency,
        days: model?.schedule?.days,
        time: timeToLocal(model?.schedule?.time)
      };
    } else {
      // add mode
      // default frequency values
      state = {
        name: model?.name,
        description: model?.description,
        frequency: 'daily',
        time: dayjs().format('HH:mm'),
      };
    }
    return state;
  };

  return (
    <div>
      <FormBuilder
        {...{
          ...props,
          inputs: sortedInputs,
          initState: getInitState(),
        }}
      />
    </div>
  );
};

ConfigModel.propTypes = {
  model: PropTypes.object,
  cancelHandler: PropTypes.func,
};


export default ConfigModel;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DeleteBtn = ({ text, onClick, loader }) => {
  const [loading, setLoading] = useState(false);

  const onClickHandler = (e) => {
    if (loader) {
      e.preventDefault();
      setLoading(true);
      onClick(e).then(() => {
        setLoading(false);
      });
    } else {
      onClick(e);
    }
  };

  const buttonContent = loading ? (
    <p className="relative animate-pulse inline-flex items-center text-sm text-zinc-700 group-hover:text-zinc-50 dark:text-zinc-50 dark:group-hover:text-zinc-700">
      Loading...
    </p>
  ) : (
    <>{text}</>
  );

  return (
    <>
      <button
        onClick={onClickHandler}
        disabled={loading}
        className="items-center hover:shadow justify-center text-sm text-rose-50 bg-rose-500 border-rose-500 border-2 px-4 py-1.5 rounded-full p-0.5 font-normal hover:bg-rose-600 hover:border-rose-600 hover:text-slate-50"
      >
        {buttonContent}
      </button>
    </>
  );
};
DeleteBtn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  loader: PropTypes.bool,
};

DeleteBtn.defaultProps = {
  onClick: () => null,
  text: 'Button',
  loader: false,
};

export default DeleteBtn;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNewPermissionService, deletePermissionService } from 'services/acl';

export const addNewPermissionAction = createAsyncThunk(
  'acl/asset/add',
  async ({ assetId, data }) => {
    const defaults = {
      group_id: 1,
      role_id: 1,
      ...data,
    };
    const formData = new FormData();
    Object.keys(defaults).forEach((key) => {
      formData.set(key, defaults[key]);
    });
    const response = await addNewPermissionService(assetId, formData);
    return response;
  }
);

export const deletePermissionAction = createAsyncThunk('acl/asset/delete', async (permissionId) => {
  const response = await deletePermissionService(permissionId);
  return response;
});

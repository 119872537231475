import React, { useEffect, useId, useState } from 'react';
import PropTypes from 'prop-types';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const TextInputModelling = (props) => {
  const id = useId();
  const [input, setInput] = useState(props?.value);

  const onChange = (item) => {
    setInput(item);
    props.onChange(item);
  };

  useEffect(() => {
    onChange(props?.value)
  }, [])

  return (
    <ModelingInputTemplate {...props}>
      <input
        id={id}
        readOnly={props.read === true ? true : false}
        onClick={
          props.read === true ? () => navigator.clipboard.writeText(props.readValue) : undefined
        }
        value={props.read === true ? props.readValue : input}
        name="columns"
        type="text"
        onInput={(e) => onChange(e.target.value)}
        placeholder={props.placeholder}
        className={`${props.read === false
          ? 'placeholder:font-light focus:ring-[#5DA6FC] focus:border-[#5DA6FC] text-zinc-700 dark:text-zinc-50'
          : 'cursor-copy dark:cursor-copywhite outline-none focus:ring-0 focus:ring-transparent focus:border-zinc-300 text-zinc-500 dark:text-zinc-200 bg-zinc-100 dark:bg-zinc-700'
          } shadow-sm dark:bg-zinc-700 placeholder:text-zinc-300 dark:placeholder:text-zinc-500 block w-full sm:text-xs border border-zinc-200 dark:border-zinc-600 rounded-md`}
      />
    </ModelingInputTemplate>
  );
};

TextInputModelling.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  read: PropTypes.bool,
  readValue: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  withtooltip: PropTypes.bool,
  ttmargin: PropTypes.string,
  onChange: PropTypes.func,
};

TextInputModelling.defaultProps = {
  read: false,
  readValue: '',
};

export default TextInputModelling;

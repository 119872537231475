import React, { useState } from 'react';
import PropTypes from 'prop-types';
// input components
import SelectCard from 'components/Cards/SelectCard';
import DropdownInput from './DropdownInput';
import RadioGroup from './RadioGroup';
import TextDescInput from './TextDesc';
import MultiToggle from './MultiToggle';
import FrequencySelectorRadio from './FrequencySelectorRadio';
import PasswordInput from './PasswordInput';
import TextArea from './TextArea';
import CodeArea from './CodeArea';
import FileInput from './FileInput';
import Checkbox from './Checkbox';

const FormBuilder = ({
  inputs,
  initState,
  submitHandler,
  // cancelHandler,
  deleteHandler,
  stateChangeCallback,
  // mode,
  actionDelay,
}) => {
  const [state, setState] = useState(initState);
  // eslint-disable-next-line no-unused-vars
  const [isSaving, setIsSaving] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false);

  stateChangeCallback(state);

  const handleChange = (key, value) => {
    const newState = Object.assign({}, state);
    newState[key] = value;
    setState(newState);
    stateChangeCallback(newState);
  };

  // eslint-disable-next-line no-unused-vars
  const doneHandler = () => {
    setIsSaving(true);
    setTimeout(() => {
      submitHandler(state)?.then?.(() => setIsSaving(false));
    }, actionDelay);
  };

  // eslint-disable-next-line no-unused-vars
  const localDeleteHandler = (...args) => {
    setIsDeleting(true);
    setTimeout(() => {
      deleteHandler(...args);
    }, actionDelay);
  };

  const frequencyCallback = ({ frequencyType, frequencyDays, runTime }) => {
    setState({
      ...state,
      frequency: frequencyType,
      days: frequencyDays,
      time: runTime,
    });
  };

  return (
    <div>
      <div className="divide-y divide-zinc-200 dark:divide-zinc-600 ">
        {inputs.map((ipt) => {
          const {
            fieldtype,
            fieldname,
            formname,
            description,
            defaultvalue,
            placeholder,
            withtooltip,
            tooltip,
          } = ipt;
          switch (fieldtype) {
            case 'string':
              return (
                <div className="py-5" key={fieldname}>
                  <TextDescInput
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    withtooltip={withtooltip}
                    tooltip={tooltip}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <div className="py-5" key={fieldname}>
                  <Checkbox
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    withtooltip={withtooltip}
                    tooltip={tooltip}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'textarea':
              return (
                <div className="py-5" key={fieldname}>
                  <TextArea
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'multiline':
              return (
                <div className="py-5" key={fieldname}>
                  <CodeArea
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    language={'python'}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'sql':
              return (
                <div className="py-5" key={fieldname}>
                  <CodeArea
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    language={'sql'}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'json':
              return (
                <div className="py-5" key={fieldname}>
                  <CodeArea
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    placeholder={placeholder}
                    language={'json'}
                    type="text"
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'textbox':
              return (
                <div className="py-5" key={fieldname}>
                  <TextDescInput
                    key={fieldname}
                    heading={formname}
                    subText={description}
                    name={fieldname}
                    value={state[fieldname] || defaultvalue}
                    placeholder={placeholder}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'files':
              return (
                <div className="py-5" key={fieldname}>
                  <FileInput
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    value={state[fieldname] || []}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'password':
              return (
                <div className="py-5" key={fieldname}>
                  <PasswordInput
                    key={fieldname}
                    header={formname}
                    subheader={description}
                    name={fieldname}
                    value={state[fieldname] || defaultvalue}
                    onChange={(val) => {
                      handleChange(fieldname, val);
                      ipt?.onChange?.(val);
                    }}
                  />
                </div>
              );
            case 'hidden':
              return (
                <input
                  type="hidden"
                  key={fieldname}
                  name={fieldname}
                  value={state[fieldname] || defaultvalue}
                />
              );
            case 'toggle': {
              const json = JSON.parse(fieldname);
              const name = Object.keys(json)?.[0];
              const options = (json?.[name] || []).map(({ name: label, value }) => ({
                label,
                value,
                checked: state[name]?.includes(value),
                onChange: () => {
                  const tables = new Set(state[name] || []);
                  tables.has(value) ? tables.delete(value) : tables.add(value);
                  handleChange(name, [...tables]);
                  ipt?.onChange?.(name, [...tables]);
                },
              }));
              return (
                <div className="py-5" key={fieldname}>
                  <MultiToggle key={formname} header={formname} subheader={description}>
                    {options.map((opt) => (
                      <div key={opt.label}>
                        <SelectCard {...opt} />
                      </div>
                    ))}
                  </MultiToggle>
                </div>
              );
            }
            case 'select': {
              const json = JSON.parse(fieldname);
              const name = Object.keys(json)?.[0];
              const items = json?.[name] || [];
              return (
                <div className="py-5" key={name}>
                  <DropdownInput
                    header={formname}
                    subheader={description}
                    key={name}
                    items={items}
                    onChange={(val) => {
                      handleChange(name, val);
                      ipt?.onChange?.(val);
                    }}
                    placeholder={placeholder}
                    defaultSelected={state[name] || defaultvalue}
                  />
                </div>
              );
            }
            case 'frequency': {
              const json = fieldname ? JSON.parse(fieldname) : {};
              return (
                <div className="gap-10 py-5" key={fieldname}>
                  <FrequencySelectorRadio
                    key={fieldname}
                    selectionCallback={frequencyCallback}
                    initState={
                      state
                        ? {
                            frequencyType: state?.frequency,
                            frequencyDays: state?.days,
                            runTime: state?.time,
                          }
                        : {
                            frequencyType: initState?.frequency,
                            frequencyDays: initState?.days,
                            runTime: initState?.time,
                          }
                    }
                    {...json}
                  />
                </div>
              );
            }
            case 'radiogroup': {
              const json = JSON.parse(fieldname);
              const name = Object.keys(json)?.[0];
              const items = json?.[name] || [];
              return (
                <div className="py-5" key={name}>
                  <RadioGroup
                    header={formname}
                    subheader={description}
                    key={name}
                    items={items}
                    onChange={(val) => {
                      handleChange(name, val);
                      ipt?.onChange?.(val);
                    }}
                    placeholder={placeholder}
                    value={state[name] || defaultvalue}
                  />
                </div>
              );
            }
            default:
              break;
          }
        })}
      </div>
    </div>
  );
};

FormBuilder.propTypes = {
  inputs: PropTypes.array,
  databases: PropTypes.array,
  submitHandler: PropTypes.func,
  // cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  stateChangeCallback: PropTypes.func,
  token: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  initState: PropTypes.shape({
    frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.string,
  }),
  mode: PropTypes.string,
  actionDelay: PropTypes.number,
};

FormBuilder.defaultProps = {
  inputs: [],
  databases: [],
  token: '',
  submitHandler: () => null,
  // cancelHandler: () => null,
  deleteHandler: () => null,
  stateChangeCallback: () => null,
  submitButtonLabel: 'Done',
  cancelButtonLabel: 'Cancel',
  deleteButtonLabel: 'Delete',
  initState: {
    frequency: 0,
    days: '',
    time: '',
  },
  mode: 'edit',
  actionDelay: 500,
};

export default FormBuilder;

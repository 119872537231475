import { MinusCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Combobox2 from 'components/Inputs/Combobox2';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import ModellingConfigBtn from 'components/Buttons/ModellingConfigBtn';

function Conditional({ ...props }) {
  const [inputValues, setInputValues] = useState(props.defaultSelected);

  const completions = props.options.map((option) => {
    return { label: option.value, apply: `[${option.value}]`, detail: "(" + option?.type?.Name + ")" };
  });

  function addAND() {
    const newInputValues = [...inputValues, { expression: '', thenValue: '', label: 'New' }];
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  function removeItem(index) {
    const newInputValues = inputValues.filter((value, i) => i !== index);
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  function handleInputChange(event, groupIndex, inputType) {
    const newInputValues = [...inputValues];
    newInputValues[groupIndex][inputType] = event;
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col">
        <div>
          {inputValues.map((value, index) => {
            return (
              <div key={index} className="relative">
                <div className="flex flex-col mb-2 border-zinc-200 dark:border-zinc-700 border-b-[0.5px] pb-2 border-spacing-2">
                  <div className="flex flex-row items-center mb-2.5">
                    <p className="text-zinc-700 dark:text-zinc-200 font-normal mr-1 w-[10%] text-[10px] text-right pr-0.5">
                      {value.label === 'Original' ? 'IF:' : 'ELSEIF:'}
                    </p>
                    <div className="w-[85%]">
                      <Combobox2
                        key={`expression_${index}`}
                        index={`expression_${index}`}
                        completions={completions}
                        onChangeCallback={(id, event) =>
                          handleInputChange(event, index, 'expression')
                        }
                        initialValue={value.expression}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center ">
                    <p className="text-zinc-700 dark:text-zinc-200 text-right font-normal w-[10%] mt-[1px] mr-1 text-[10px] pr-0.5">
                      THEN:
                    </p>
                    <div className="w-[85%]">
                      <Combobox2
                        key={`thenValue_${index}`}
                        index={`thenValue_${index}`}
                        completions={completions}
                        onChangeCallback={(id, event) =>
                          handleInputChange(event, index, 'thenValue')
                        }
                        initialValue={value.thenValue}
                      />
                    </div>
                  </div>
                </div>
                {index > 0 && (
                  <button className="absolute -right-2 top-[40%]" onClick={() => removeItem(index)}>
                    <MinusCircleIcon className="w-4 h-4 duration-150 dark:hover:text-zinc-400 dark:text-zinc-300 text-zinc-700 hover:text-zinc-900" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div className="justify-center items-center inline-flex mt-2">
          <ModellingConfigBtn onClick={addAND} buttonText="Add an ELSEIF" />
        </div>
      </div>
    </ModelingInputTemplate>
  );
}

Conditional.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  buttonText: PropTypes.string,
  defaultSelected: PropTypes.array,
};
export default Conditional;

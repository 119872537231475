import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import { EyeIcon, EyeOffIcon, InformationCircleIcon } from '@heroicons/react/solid';

const NonEditablePW = (props) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="object-center w-full">
      <div className="flex gap-3 items-center">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-zinc-700 dark:text-zinc-50"
        >
          {props.header}
        </label>
        <div className={props.withtooltip === 'false' ? 'invisible' : ''}>
          <PrimaryTooltip tooltipText={props.tooltip} bottom={props.ttmargin}>
            <InformationCircleIcon
              className="h-4 w-4 text-zinc-600 dark:text-zinc-100"
              aria-hidden="true"
            />
          </PrimaryTooltip>
        </div>
      </div>
      <div className="mt-1 ">
        <React.Fragment>
          <section>
            <div className="relative">
              <input
                className="cursor-copy dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:cursor-copywhite shadow-sm w-full !ring-0 !ring-transparent hover:!border-zinc-200 active:!border-zinc-300 focus:!border-zinc-300 focus:!ring-0 text-left py-2 px-3 text-zinc-500 dark:text-zinc-200 bg-zinc-100 block sm:text-sm border-zinc-200 border rounded-md"
                // dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600
                readOnly
                value={props.readValue}
                onClick={() => navigator.clipboard.writeText(props.readValue)}
                type={open === false ? 'password' : 'text'}
              />
              <div className="absolute top-2.5 right-5">
                {open === false ? (
                  <EyeIcon
                    onClick={toggle}
                    className="h-5 w-5 text-zinc-500 dark:text-zinc-200"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeOffIcon
                    onClick={toggle}
                    className="h-5 w-5 text-zinc-500 dark:text-zinc-200"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </section>
        </React.Fragment>
      </div>
    </div>
  );
};

NonEditablePW.propTypes = {
  header: PropTypes.string,
  readValue: PropTypes.string,
  tooltip: PropTypes.string,
  ttmargin: PropTypes.string,
  withtooltip: PropTypes.bool,
};
export default NonEditablePW;

export default function folderFlatten(folders, folderIdToRemove = -1) {
  const result = folders.reduce((flattened, folder) => {
    if (folder.id === folderIdToRemove) {
      return flattened;
    }
    flattened.push({
      label: folder.name,
      value: folder.id
    });
    if (folder.children.length) {
      return flattened.concat(folderFlatten(folder.children, folderIdToRemove));
    }
    return flattened;
  }, []);
  return result;
}


// const folderHierachy = []

// const test = (folder, parent_path = null) => {
//   console.log(folder)
//   console.log(parent_path, parent_path === null)
//   console.log("THIS IS the path before", parent_path)
//   let path = parent_path === null ? [] : structuredClone(parent_path)
//   path.push(folder.name)
//   console.log("THIS IS the path after", path)
//   const folderObject = {folder_id: folder.id, name: folder.name, path: path} 
//   folderHierachy.push(folderObject)
//   if (folder.children.length > 0){
//   console.log(folder.children)
//   folder.children.forEach((child) => {
//   test(child, folderObject.path)
//   })
//   }
// }
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const Checkbox = ({ items, onChangeCallback, ...props }) => {

  const [inputValues, setInputValues] = useState(props.defaultValue);

  function handleInputChange(event) {
    const newInputValues = { ...inputValues };
    newInputValues[event.target.name] = event.target.checked;
    setInputValues(newInputValues);
    onChangeCallback(newInputValues);
  }



  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col gap-3">
        {items?.map((item) => (
          <div className="flex flex-row items-center justify-start" key={item.id}>
            <input
              name={item.value}
              className="ml-1 mr-2 h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
              type="checkbox"
              onChange={handleInputChange}
              checked={inputValues?.[item.value] || false}
            />
            <p className="text-xs">{item.label}</p>
          </div>
        ))}
      </div>
    </ModelingInputTemplate>
  );
};

Checkbox.propTypes = {
  items: PropTypes.any,
  defaultValue: PropTypes.any,
  onChangeCallback: PropTypes.func,
};

export default Checkbox;

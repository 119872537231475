import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ content, classes }) => {
  return (
    <td
      className={` whitespace-nowrap text-left py-1.5 text-xs text-zinc-500 dark:text-zinc-200 ${classes}`}
    >
      {content}
    </td>
  );
};

TableRow.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.any,
};

export default TableRow;

import { useState, useCallback, useEffect, useRef } from 'react';
import { useReactFlow, getConnectedEdges, useStore } from 'reactflow';

export function useCopyPaste({ takeSnapshot }) {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);

  const { getNodes, setNodes, getEdges, setEdges, project } = useReactFlow();

  // Set up the paste buffers to store the copied nodes and edges.
  const [bufferedNodes, setBufferedNodes] = useState([]);
  const [bufferedEdges, setBufferedEdges] = useState([]);

  // initialize the copy/paste hook
  // 1. remove native copy/paste/cut handlers
  // 2. add mouse move handler to keep track of the current mouse position
  useEffect(() => {
    const events = ['cut', 'copy', 'paste'];

    if (rfDomNode) {
      const preventDefault = (e) => {
        if (e?.target?.offsetParent?.className.includes('react-flow__node-commentNode')) {
          return
        } else if (e?.target?.offsetParent?.className.includes('react-flow__node-textInputNode')) {
          return;
        } else {
          e.preventDefault()
        }
      };

      const onMouseMove = (event) => {
        const bounds = rfDomNode.getBoundingClientRect();
        mousePosRef.current = {
          x: event.clientX - (bounds?.left ?? 0),
          y: event.clientY - (bounds?.top ?? 0),
        };
      };

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }

      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  const copy = useCallback(() => {
    // console.log(window.document.getSelection())

    //Copy if react-flow__nodesselection-rect is present
    const selection = document.getElementsByClassName("react-flow__nodesselection-rect").length
    // console.log("selection", selection)

    //Copy if tool selected and config not present
    const markedNodes = getNodes().filter((node) => node.selected)
    // console.log("markedNodes", markedNodes)

    const confWindow = document.getElementById("confWindow")
    // console.log("confWindow", confWindow)

    const notComment = document.getSelection().anchorNode.parentNode.className?.includes("canvasActionText") || document.getSelection().anchorNode.className?.includes("canvasActionText")
    // console.log("notComment", notComment)
    //const copyTools = (window.document.getSelection().focusNode.id?.includes("react-flow") || window.document.getSelection().focusNode.className?.includes("react-flow")) && markedNodes.length > 0
    const copyTools = (selection || (markedNodes.length > 0 && !confWindow)) && !notComment

    if (copyTools) {
      // console.log("copyTools", copyTools)
      const selectedNodes = markedNodes;
      const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter((edge) => {
        const isExternalSource = selectedNodes.every((n) => n.id !== edge.source);
        const isExternalTarget = selectedNodes.every((n) => n.id !== edge.target);

        return !(isExternalSource || isExternalTarget);
      });
      const content = { nodes: selectedNodes, edges: selectedEdges }
      navigator.clipboard.writeText(btoa(JSON.stringify(content)))
      setBufferedNodes(selectedNodes);
      setBufferedEdges(selectedEdges);
    }
  }, [getNodes, getEdges]);

  const cut = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter((edge) => {
      const isExternalSource = selectedNodes.every((n) => n.id !== edge.source);
      const isExternalTarget = selectedNodes.every((n) => n.id !== edge.target);

      return !(isExternalSource || isExternalTarget);
    });

    setBufferedNodes(selectedNodes);
    setBufferedEdges(selectedEdges);

    // A cut action needs to remove the copied nodes and edges from the graph.
    setNodes((nodes) => nodes.filter((node) => !node.selected));
    setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge)));
  }, [getNodes, setNodes, getEdges, setEdges]);

  const paste = useCallback(
    () => {
      const readClip = () => {
        const content = navigator.clipboard.readText().then((clipText) => { return clipText })
        return content
      }
      const coordinates = project({ x: mousePosRef.current.x, y: mousePosRef.current.y })
      try {
        readClip().then((data) => {
          try {
            const content = JSON.parse(atob(data))
            const buffNodes = content.nodes
            const buffEdges = content.edges

            takeSnapshot()
            const minX = Math.min(...buffNodes.map((s) => s.position.x));
            const minY = Math.min(...buffNodes.map((s) => s.position.y));
            const nodes = getNodes()

            const getId = () => {
              let id = 0;
              if (nodes.length > 0) {
                id = Math.max(...nodes.map((item) => parseInt(item.id))) + 1;
              }
              return id;
            };

            let maxId = getId()
            const nodeMapping = {}
            const newNodes = buffNodes.map((node) => {
              const id = maxId.toString()
              nodeMapping[node.id] = id
              const x = coordinates.x + (node.position.x - minX);
              const y = coordinates.y + (node.position.y - minY);
              const data = JSON.parse(JSON.stringify(node.data))

              //Reset Tool ID and reset metadata and incoming dataframe(s)
              data.configuration.tool_id = id
              data.configuration.cache = false
              delete data.configuration.dataframe
              delete data.configuration.dataframe_metadata
              delete data.configuration.df_r
              delete data.configuration.df_r_metadata
              delete data.configuration.df_l
              delete data.configuration.df_l_metadata
              delete data.configuration.df_1
              delete data.configuration.df_1_metadata
              delete data.configuration.df_2
              delete data.configuration.df_2_metadata
              delete data.status

              maxId++
              return { ...node, id, position: { x, y }, data: data };
            });

            const newEdges = buffEdges.map((edge) => {
              const source = nodeMapping[edge.source];
              const target = nodeMapping[edge.target];
              const id = `${source}-${target}`;
              //add incoming dataframe to node
              const node = newNodes.find((node) => node.id == target);
              if (node) {
                node.data.configuration[edge.targetHandle] = source
              }
              return { ...edge, id, source, target };
            });
            setNodes((nodes) => [...nodes.map((node) => ({ ...node, selected: false })), ...newNodes]);
            setEdges((edges) => [...edges, ...newEdges]);
          } catch {
            // Ignore malformed lines.
          }
        })
      }
      catch {
        console.log("not nodes")
      }

    },
    [bufferedNodes, bufferedEdges, project, setNodes, setEdges]
  );

  // useShortcut(['Meta+x', 'Ctrl+x'], cut);
  // useShortcut(['Meta+c', 'Ctrl+c'], copy);
  // useShortcut(['Meta+v', 'Ctrl+v'], paste);

  useEffect(() => {
    // this effect is used to attach the global event handlers

    const keyDownHandler = (event) => {
      if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        copy();
      } else if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        paste();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [copy, paste]);

  return { cut, copy, paste, bufferedNodes, bufferedEdges };
}

// function useShortcut(keyCode, callback) {
//   const [didRun, setDidRun] = useState(false);
//   const shouldRun = useKeyPress(keyCode);

//   useEffect(() => {
//     if (shouldRun && !didRun) {
//       callback();
//       setDidRun(true);
//     } else {
//       setDidRun(shouldRun);
//     }
//   }, [shouldRun, didRun, callback]);
// }

export default useCopyPaste;

import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const SmallBtnDropdown = ({ btnHeader, items }) => {
  const menuButtonRef = useRef(null);

  const closeMenu = () => {
    menuButtonRef.current?.click();
  };

  const handleAction = (action) => (e) => {
    action(e);
    closeMenu();
  };

  return (
    <Menu as="div" className="relative inline-block items-baseline text-left">
      {({ open }) => (
        <>
          <Menu.Button
            ref={menuButtonRef}
            className="group relative inline-flex items-center justify-center overflow-hidden  rounded-lg p-0.5 font-normal"
          >
            <div className="absolute h-full w-full rounded-xl bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] active:bg-black group-hover:bg-gradient-to-br group-hover:from-black group-hover:to-black dark:active:bg-white dark:group-hover:bg-gradient-to-br dark:group-hover:from-white dark:group-hover:to-white"></div>
            <div className="border border-transparent relative inline-flex justify-center items-center rounded-[10px] bg-white dark:bg-zinc-900 px-2.5 py-0.5 transition-all ease-out delay-50 duration-300  group-hover:bg-black dark:group-hover:bg-white">
              <p className=" relative text-[12px] text-black dark:text-zinc-50 dark:group-hover:text-black group-hover:text-zinc-50">
                {btnHeader}
              </p>
              <div className="mr-1 group-hover:text-white dark:group-hover:text-black dark:text-zinc-50">
                <ChevronDownIcon
                  className={`-mr-1 ml-0.5 h-3.5 w-3.5 duration-300 transform group ${
                    open && 'rotate-180'
                  }`}
                  aria-hidden="true"
                />
              </div>
            </div>
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="group z-50 overflow-hidden origin-top-right absolute right-0 mt-1 w-max rounded-md shadow-md  bg-white dark:bg-zinc-900 dark:shadow-zinc-700/80 ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 divide-y divide-zinc-100 focus:outline-none">
              <div>
                {items.map((item, i) => (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      <button
                        onClick={handleAction(item.action)}
                        className={classNames(
                          active
                            ? 'bg-zinc-200 dark:bg-zinc-700  '
                            : 'text-zinc-500 dark:text-zinc-200',
                          ' flex ml-1 rounded my-1 mr-3 items-center w-[95%] px-4 py-2 text-xs text-zinc-500 dark:text-zinc-200  hover:text-zinc-700 dark:over:text-zinc-50'
                        )}
                      >
                        {item.icon}
                        {item.header}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

SmallBtnDropdown.propTypes = {
  btnHeader: PropTypes.any,
  items: PropTypes.array,
};

export default SmallBtnDropdown;

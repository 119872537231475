import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  getRolesService,
} from 'services/role';

// First, create the thunk
export const getRolesAction = createAsyncThunk('roles/fetch', async () => {
  const response = await getRolesService();
  return response;
});


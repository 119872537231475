import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/Grids/Pagination';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const Table = ({
  headers,
  tableBody,
  pager,
  type,
  emptyMessage,
  headerOnclick,
  sortOrder,
  classes,
  page,
  setPageCallback,
}) => {
  const [bodySlice, setBodySlice] = useState(tableBody?.slice(0, pager?.size));
  const [currentPage, setCurrentPage] = useState(page);
  const [maxObjects, setMaxObjects] = useState(localStorage.getItem('maxObjects') ? JSON.parse(localStorage.getItem('maxObjects')) : pager.size);


  useEffect(() => {
    localStorage.setItem('maxObjects', maxObjects);
  }, [maxObjects]);

  useEffect(() => {
    setPageCallback(currentPage)
  }, [currentPage])

  useEffect(() => {
    let lastPage = Math.ceil(tableBody?.length / maxObjects) - 1;
    if (lastPage < 0) {
      lastPage = 0;
    }

    if (currentPage > lastPage && tableBody.length > 0) {
      setCurrentPage(lastPage);
      const startIndex = lastPage * maxObjects;
      const newSlice = tableBody.slice(startIndex, startIndex + maxObjects);
      setBodySlice(newSlice);
    } else {
      const startIndex = currentPage * maxObjects;
      const newSlice = tableBody.slice(startIndex, startIndex + maxObjects);
      setBodySlice(newSlice);
    }
  }, [tableBody, maxObjects, currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col ">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 ">
          <div className="border border-zinc-100 rounded-md overflow-hidden ">
            <div
              className={`${classes} max-h-[calc(100vh-225px)] overflow-scroll overscroll-none rounded-t-md`}
            >
              <table className="min-w-full divide-y  divide-zinc-200 dark:divide-zinc-600 ">
                <thead className="">
                  <tr
                    key="headers"
                    className="sticky top-0 bg-zinc-50 z-[9] ring-[0.5px] ring-zinc-200 "
                  >
                    {tableBody.length ? (
                      headers.map((header, index) => (
                        <th
                          scope="col"
                          className={`py-2.5 text-left text-xs  font-medium uppercase tracking-wide text-zinc-500 dark:text-zinc-200 ${header.classes}`}
                          key={index}
                        >
                          <div className="flex items-base justify-between">
                            {header.headerName}
                            {header.sortable == true && sortOrder === 'desc' ? (
                              <ChevronDownIcon
                                onClick={headerOnclick}
                                className="bg-zinc-50 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-500 border cursor-pointer mr-10 h-4 w-4 stroke-[10px]"
                              />
                            ) : header.sortable == true && sortOrder === 'asc' ? (
                              <ChevronUpIcon
                                onClick={headerOnclick}
                                className="bg-zinc-50 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-500 border cursor-pointer mr-10 h-4 w-4 stroke-[10px]"
                              />
                            ) : header.sortable == true ? (
                              <svg
                                onClick={headerOnclick}
                                className="bg-zinc-50 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-500 border cursor-pointer mr-10 h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                />
                              </svg>
                            ) : (
                              ''
                            )}
                          </div>
                        </th>
                      ))
                    ) : (
                      <th
                        colSpan={headers.length}
                        scope="col"
                        className={`py-1 text-center text-[10px] font-medium uppercase tracking-wider text-zinc-500 dark:text-zinc-200`}
                      >
                        {emptyMessage}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 dark:divide-zinc-600  bg-white dark:bg-transparent">
                  {bodySlice}
                </tbody>
              </table>
            </div>
            <div
              className={`bg-zinc-50 z-[1000] rounded-b-md ${tableBody?.length > 20 ? 'border-t-[0.5px] border-zinc-200 ' : ''
                }`}
            >
              {tableBody.length > 0 && (
                <Pagination
                  {...pager}
                  setMaxObjects={setMaxObjects}
                  maxObjects={maxObjects}
                  total={tableBody?.length}
                  handlePageClick={handlePageClick}
                  //pageSize={pager.size}
                  currentPage={currentPage}
                  type={type}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.array,
  tableBody: PropTypes.array,
  pager: PropTypes.shape({
    size: PropTypes.number,
    total: PropTypes.number,
  }),
  type: PropTypes.string,
  emptyMessage: PropTypes.string,
  headerOnclick: PropTypes.any,
  sortOrder: PropTypes.any,
  classes: PropTypes.string,
  page: PropTypes.number,
  setPageCallback: PropTypes.func
};

Table.defaultProps = {
  headers: [],
  tableBody: [],
  pager: {
    size: 10,
    total: 0,
  },
  type: 'results',
  emptyMessage: 'No Data',
  headerOnclick: null,
  sortOrder: null,
  page: 0,
  setPageCallback: () => null,
};

export default Table;

import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const RadioGroup = ({ value: defaultValue, onChange: onChangeCallback, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const changeHandler = (e) => {
    setValue(e.target.value);
    onChangeCallback(e.target.value);
  };

  return (
    <RegularInputTemplate {...props}>
      <fieldset>
        <div className="space-y-3">
          {props.items.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value}
                name={props.name}
                type="radio"
                checked={option.value === value}
                onChange={changeHandler}
                className="ring-0 offset-none focus:offset-none dark:bg-zinc-700 focus:ring-0 h-4 w-4 text-[#5DA6FC] border-zinc-300 dark:border-zinc-500"
                value={option.value}
              />
              <label
                htmlFor={option.value}
                className="ml-3 block text-sm font-normal text-zinc-500 dark:text-zinc-200"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </RegularInputTemplate>
  );
};

RadioGroup.propTypes = {
  header: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  header: '',
  legend: '',
  desc: '',
  name: '',
  items: [],
  value: '',
  onChange: () => null,
};
export default RadioGroup;

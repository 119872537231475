import React from 'react';
import PropTypes from 'prop-types';

const DataCatalogueRow = (props) => {
  return (
    <div className="px-4 py-4 sm:grid sm:grid-cols-2 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-zinc-700 dark:text-zinc-100">{props.header}</dt>
        <dd className="mt-0.5 font-light text-sm leading-6 text-zinc-500 dark:text-zinc-200 sm:col-span-2 sm:mt-0">
        {props.content}
        </dd>
    </div>
  );
};

DataCatalogueRow.propTypes = {
  header: PropTypes.string,
  content: PropTypes.any,
};

export default DataCatalogueRow;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SelectCard = (props) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };

  return (
    <div
      className={`${props.checked
        ? 'border-[#5DA6FC] ring-1 ring-[#5DA6FC] shadow-md '
        : 'border-zinc-200 dark:border-zinc-600'
        }  border rounded-lg cursor-pointer ring-transparent bg-white dark:bg-zinc-700`}
    >
      <label className="flex flex-row gap-5 items-center flex-1 justify-between p-3 cursor-pointer line-clamp-1">
        <div className="bg-transparent flex flex-row flex-1 line-clamp-1 ">

          <p className="select-none block text-sm font-medium text-zinc-700 dark:text-zinc-50 line-clamp-1  truncate ">
            {props.label}
          </p>
        </div>
        <input
          id="comments"
          name="tablecheck"
          type="checkbox"
          checked={props.checked}
          onChange={handleChange}
          className="h-4 w-4 text-[#5DA6FC] !ring-transparent !outline-none border-zinc-300 dark:border-zinc-400 rounded-full"
        />
      </label>
    </div>
  );
};
SelectCard.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  checked: PropTypes.bool,
};
export default SelectCard;

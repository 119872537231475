/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import ConnectionLineConnect from '../ConnectionLineConnect';

const RadioText = ({ defaultValue, onChangeCallback, ...props }) => {
  const [value, setValue] = useState(defaultValue);
  const changeAction = (index) => {
    const newValue = { "operation": index }
    setValue(newValue)
    onChangeCallback(newValue)
  };

  const changeItemValue = (itemValue) => {
    const newValue = { ...value }
    newValue['value'] = itemValue
    setValue(newValue)
    onChangeCallback(newValue)
  };

  return (
    <ModelingInputTemplate {...props}>
      <fieldset>
        <div className="relative flex-col items-start -mt-1">
          {props.options?.map((item, index) => {
            return (
              <div key={index} className="relative flex items-center py-1.5">
                <div className="flex h-6 items-center">
                  <input
                    id={`${props.fieldName}_checkbox_${item.name}`}
                    aria-describedby="comments-description"
                    name={item.name}
                    type="radio"
                    checked={item.value == value?.operation}
                    onChange={() => changeAction(item.value)}
                  />
                </div>

                <div className="ml-3 text-xs leading-6">
                  <label
                    htmlFor="comments"
                    className="font-normal text-zinc-700 dark:text-zinc-50"
                  >
                    {item.label}
                    {(item?.extraValue && value?.operation == item.value) && (
                      <input
                        id={`${props.fieldName}_input_${item.name}`}
                        name={`${item.name}`}
                        type={item?.extraFormat || "text"}
                        placeholder={item.inputPlaceholder}
                        value={value?.value}
                        className={`${item.textBefore == '' ? 'mr-2.5' : 'mx-2.5'
                          } ${item?.extraFormat || "text" == 'text' ? 'w-48' : 'w-16'} appearance-none h-7 shadow-sm dark:shadow-zinc-600/50 text-zinc-700 dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:text-zinc-50 placeholder:text-zinc-300 placeholder:font-light focus:ring-[#5DA6FC] focus:border-[#5DA6FC] sm:text-xs border-zinc-300 border rounded-md`}
                        onChange={(e) => changeItemValue(e.target.value)}
                      />
                    )}
                  </label>
                </div>


              </div>
            );
          })}
        </div>
      </fieldset>
    </ModelingInputTemplate>
  );
};

RadioText.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      textBefore: PropTypes.string,
      name: PropTypes.string,
      textAfter: PropTypes.string,
      inputPlaceholder: PropTypes.string,
      max: PropTypes.number,
    })
  ).isRequired,
  onChangeCallback: PropTypes.func,
  defaultValue: PropTypes.object,
  fieldName: PropTypes.string,
};

RadioText.defaultProps = {
  options: [],
};
export default RadioText;

import Axios, { jsonHeader } from './axios';

export const allFolderService = () => {
  return Axios.get(`api/folders`).then((res) => {
    return res.data;
  });
};

export const getFolderService = (id) => {
  return Axios.get(`api/folders/${id}`).then((res) => {
    return res.data;
  });
};

export const addNewFolderService = (data) => {
  return Axios.post(`api/folders/`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const moveToFolderService = (data) => {
  return Axios.post(`api/folders/${data['folder_id']}/move`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const shareFolderService = (data) => {
  return Axios.post(`api/folders/${data['folder_id']}/share`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const deleteFolderService = (folderId) => {
  return Axios.delete(`api/folders/${folderId}`).then((res) => {
    return res.data;
  });
};

export const editFolderService = (folderId, data) => {
  return Axios.post(`api/folders/${folderId}`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allFolderService,
  getFolderService,
  addNewFolderService,
  moveToFolderService,
  deleteFolderService,
  editFolderService,
  shareFolderService,
} from 'services/folder';

export const allFolderAction = createAsyncThunk('folder/all', async () => {
  const response = await allFolderService();
  return response.data;
});

export const getFolderAction = createAsyncThunk('folder/get', async (id) => {
  const response = await getFolderService(id);
  return response.data;
});

export const addNewFolderAction = createAsyncThunk('folder/add', async (data) => {
  const response = await addNewFolderService(data);
  return response;
});

export const moveToFolderAction = createAsyncThunk('folder/move', async (data) => {
  const response = await moveToFolderService(data);
  return response;
});

export const deleteFolderAction = createAsyncThunk('folder/delete', async (folderId) => {
  const response = await deleteFolderService(folderId);
  return response;
});

export const editFolderAction = createAsyncThunk('folder/edit', async ({ folderId, ...data }) => {
  const response = await editFolderService(folderId, data);
  return response;
});

export const shareFolderAction = createAsyncThunk('folder/share', async ({ folderId, ...data }) => {
  const response = await shareFolderService(folderId, data);
  return response;
});
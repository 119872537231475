import Axios, { jsonHeader } from './axios';

export const createGroupService = (data) => {
  return Axios.post(`api/groups/`, data, jsonHeader).then((res) => {
    return res?.data.data;
  });
};

export const deleteGroupService = (data) => {
  return Axios.delete(`api/groups/${data}`).then((res) => {
    return res?.data.data;
  });
};


export const addUsersFromGroupService = (data) => {
  return Axios.post(`api/groups/${data.group_id}/addUsers`, data, jsonHeader).then((res) => {
    return res?.data.data;
  });
};

export const removeUsersFromGroupService = (data) => {
  return Axios.post(`api/groups/${data.group_id}/removeUsers`, data, jsonHeader).then((res) => {
    return res?.data.data;
  });
};
import Axios, { jsonHeader } from './axios';

export const getAllDatabasesService = () => {
  return Axios('api/dbs').then((res) => {
    return res?.data;
  });
};

export const getDatabaseService = (databaseId) => {
  return Axios(`api/dbs/get/${databaseId}`).then((res) => {
    return res?.data;
  });
};

export const deleteDatabaseService = (databaseId) => {
  return Axios.delete(`api/dbs/delete/${databaseId}`).then((res) => {
    return res.data;
  });
};

export const getDatabaseWhitelistService = (databaseId) => {
  return Axios.get(`api/dbs/whitelist/${databaseId}`).then((res) => {
    return res.data;
  });
};

export const createDatabaseService = (data) => {
  return Axios.post(`api/dbs/create`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const updateWhitelistService = (id, data) => {
  return Axios.post(`api/dbs/whitelist/${id}`, data, jsonHeader).then((res) => {
    return res.data;
  });
};
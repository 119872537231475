import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import {
  useFloating,
  //autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import { useSelector } from 'react-redux';

export default function ContextMenu({ options, object }) {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(5), flip(), shift()],
    //hileElementsMounted: autoUpdate,
  });

  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.user,
    };
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const abilities = object?.abilities || [];
  const user_id = object?.user?.id || object?.user_id;

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div onClick={(e) => e.stopPropagation()}>
        <Menu.Button
          ref={refs.setReference}
          {...getReferenceProps()}
          className="group mx-1 text-zinc-700 dark:text-zinc-50 p-1.5 items-center justify-center hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-md duration-150"
        >
          <DotsHorizontalIcon className="h-4 w-4" aria-hidden="true" />
        </Menu.Button>
      </div>

      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <Menu.Items
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              className={classNames(
                'absolute p-1 z-50 text-xs right-0 mt-1 w-48 origin-top-right rounded-md bg-white dark:bg-zinc-900 shadow-lg dark:shadow-zinc-800 ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 focus:outline-none'
              )}
            >
              {options.map((option) => (
                <Menu.Item key={option.id}>
                  {({ active }) => (
                    <button
                      disabled={
                        abilities.includes(option?.ability) || user_id == user.id ? false : true
                      }
                      onClick={() => {
                        option.action(object);
                        setIsOpen(false);
                      }}
                      className={`${
                        active
                          ? 'bg-zinc-200 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-50'
                          : 'text-zinc-500 dark:text-zinc-200'
                      } group flex w-full items-center rounded-md px-2 py-2 font-normal transition duration-150 ${
                        abilities.includes(option?.ability) || user_id == user.id
                          ? ''
                          : 'text-opacity-30 cursor-not-allowed'
                      } ${
                        option?.text.includes('Delete') &&
                        (abilities.includes(option?.ability) || user_id == user.id)
                          ? 'hover:text-red-500 hover:bg-red-100'
                          : ''
                      } ${option.classes}`}
                    >
                      {option.logo}
                      {option.text}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </Menu>
  );
}

ContextMenu.propTypes = {
  addPermissionHandler: PropTypes.func,
  removePermissionHandler: PropTypes.func,
  options: PropTypes.any,
  object: PropTypes.object,
};

ContextMenu.defaultProps = {
  addPermissionHandler: null,
  removePermissionHandler: null,
};

/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

const FlyingModal = (props) => {

  const { open, onClose } = props;

  const [newPosition, setNewPosition] = useState(null)

  useEffect(() => {
    // console.log("IS THIS OPEN", open)
    // console.log("CONFIGURATION WINDOW", document.getElementById('configWindow')?.getBoundingClientRect())

    const configWindow = document.getElementById('configWindow')?.getBoundingClientRect()
    //If Node X plus Node width plus Config window width < Pane width place to the right of tool
    //Else place the config window to the right of the tool if within pane
    //Else place in the middtle of the screen
    const position = {}
    const posX = open?.node?.event.clientX;
    const posY = open?.node?.event.clientY;

    if (posX + open?.node?.width + configWindow?.width < open?.pane?.width) {
      // console.log("OPEN RIGHT")
      position['left'] = posX + open?.node?.width
    } else if (posX - configWindow?.width > open?.pane?.x) {
      // console.log("OPEN LEFT")
      position['left'] = posX - configWindow?.width
    } else {
      // console.log("OPEN IN THE MIDDLE")
      position['top'] = open?.pane?.width - configWindow?.width / 2
    }

    // console.log("HEIGHT", posY - configWindow?.height / 2 > 0 + pushed.y, posY + configWindow?.height / 2 < open?.pane?.height + pushed.y)
    if (posY - configWindow?.height / 2 > 0 && posY + configWindow?.height / 2 < open?.pane?.height) {
      position['top'] = posY - configWindow?.height / 2
    } else if (posY - configWindow?.height / 2 < 0) {
      position['top'] = 0
    } else {
      position['top'] = open?.pane?.height - configWindow?.height
    }
    setNewPosition(position)
    // console.log(open?.pane, open?.reactFlowInstance.project(open?.pane))
  }, [open])

  return (
    <div className={`relative min-w-[600px] max-h-[90vh] z-50 ${newPosition ? "" : "opacity-0"}`} onClose={() => onClose(true)}>
      {open && (
        <Draggable handle=".dragHandle">
          <div className="fixed z-10 inset-0 h-fit mx-auto top-[10%]" style={newPosition}>
            <div className="flex items-end sm:items-center p-4 text-center sm:p-0">
              <div
                id={props.id}
                className={`${props.classes} border border-zinc-300 dark:border-zinc-600 relative bg-white dark:bg-zinc-900 rounded-lg text-left shadow-xl dark:shadow-zinc-800/70 max-w-[425px] min-w-[425px] max-h-[80vh] transform transition-all`}>
                <div>{props.content}</div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

FlyingModal.propTypes = {
  id: PropTypes.any,
  content: PropTypes.any,
  classes: PropTypes.any,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default FlyingModal;

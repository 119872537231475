import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Combobox2 from 'components/Inputs/Combobox2';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const ComboboxInput = ({
  defaultValue,
  options: options,
  onChange: onChangeCallback,
  columnCombo,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (index, eventValue) => {
    setValue(eventValue);
    onChangeCallback(eventValue);
  };

  const completions = options.map((option) => {
    return {
      label: option.value,
      apply: columnCombo ? `[${option.value}]` : option.value,
      detail: "(" + option?.type?.Name + ")",
    };
  });

  return (
    <ModelingInputTemplate {...props}>
      <div className="relative">
        <Combobox2
          key={'dddd'}
          index={'ledd'}
          completions={completions}
          onChangeCallback={onChange}
          initialValue={value}
        />
      </div>
    </ModelingInputTemplate>
  );
};

ComboboxInput.propTypes = {
  name: PropTypes.string,
  minutes: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  columnCombo: PropTypes.boolean,
};

ComboboxInput.defaultProps = {
  read: false,
  type: '',
  placeholder: '',
  name: '',
  minutes: '',
  defaultValue: '',
  readValue: '',
  onChange: () => null,
  columnCombo: true,
};
export default ComboboxInput;

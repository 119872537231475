import Axios from './axios';

export const getAllConnectorsService = () => {
  return Axios.get('api/connectors').then((res) => {
    return res?.data;
  });
};

export const getConnectorCategoriesService = () => {
  return Axios.get('api/connectors/categories').then((res) => {
    return res?.data.data;
  });
};

export const getConnectorInputsService = (connectorId) => {
  return Axios.get(`api/connectors/${connectorId}/inputs`).then((res) => {
    return res?.data?.data;
  });
};

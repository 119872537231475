
import React from 'react';
import PropTypes from 'prop-types';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const MultiToggle = (props) => {
  return (
    <RegularInputTemplate {...props}>
      {/* props.children should be the SelectCard with some mapping */}
      <div className="mt-2 grid grid-cols-3 gap-3">{props.children}</div>
    </RegularInputTemplate>
  );
};

MultiToggle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MultiToggle;



import PropTypes from 'prop-types';
import React from 'react';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import BasicModal from 'components/Modals/BasicModal';
import ModalHeader from 'components/Layout/ModalHeader';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';
import Select from 'components/Modelling/Config/Select';

const SharingModal = ({ sharing, setSharing, activeObject, roles, combinedArray, submitRules, changeRule }) => {

    const options = [...[{ label: "None", description: "Not shared with this group", value: null }], ...roles.map((role) => { return { label: role.name, description: role.description, value: role.id } })]

    return (
        <BasicModal
            open={sharing}
            onClose={() => setSharing(false)}
            classes={'w-[600px]'}
            content={
                <div className='p-8'>
                    <ModalHeader header={`Sharing for ${activeObject?.object?.name} ${activeObject?.objectType?.toLowerCase()}`} />
                    <div className='my-5'>
                        <RegularInputTemplate header='Currently shared with' subheader={`These are the groups with the ability to interact with the ${activeObject?.object?.name} ${activeObject?.objectType?.toLowerCase()}`}>
                            <div className="mt-4 text-xs text-zinc-400 font-medium uppercase tracking-wide grid grid-cols-2 gap-3 px-1 mb-2 " >
                                <p>Groups <div className='my-1 h-px bg-zinc-100 rounded-3xl' /></p>
                                <p>Roles <div className='my-1 h-px bg-zinc-100 rounded-3xl' /></p>
                            </div>
                            <div className='space-y-2.5'>
                                {combinedArray.filter(group => group?.set?.ability_set_id != null).map((set, index) => (

                                    <div className={`${set?.set?.new === true && "!bg-green-100 !p-2 rounded"} grid grid-cols-6 gap-3 items-center px-1 text-zinc-600`} key={index}>
                                        {set?.set?.new === true && <p className='text-[10px] text-green-500 uppercase font-medium tracking-wide col-span-6 px-px -mb-1'>Added</p>}
                                        <div className='h-full flex flex-row justify-between rounded bg-zinc-50 border border-zinc-200 p-2 col-span-3 text-xs'>
                                            <p>{set.title} <span className='text-zinc-400'>  {set.users.length} members</span></p>

                                            {set?.set?.inherited ? <RadixTooltip classes='!max-w-[320px]' tooltipText='This groups has inherited its role from its parent. If you manually change the role, you will remove all inherited roles and overwrite with your selection.'><span className='text-zinc-400'>Inherited</span></RadixTooltip> : ""}
                                        </div>
                                        <div className='col-span-3'>
                                            <Select key={`active_${set.title}`} childConClasses="!-mt-0" defaultSelected={set.set.ability_set_id} items={options} onChange={(e) => changeRule(set, e)} placeholder="Select role" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </RegularInputTemplate>
                        <div className='my-8 h-px bg-zinc-200' />
                        <RegularInputTemplate header='Other groups' subheader={`These groups do NOT have access to this ${activeObject?.objectType?.toLowerCase()}`}>
                            <div className="mt-4 text-xs text-zinc-400 font-medium uppercase tracking-wide grid grid-cols-2 gap-3 px-1 mb-2 " >
                                <p>Groups <div className='my-1 h-px bg-zinc-100 rounded-3xl' /></p>
                                <p>Roles <div className='my-1 h-px bg-zinc-100 rounded-3xl' /></p>
                            </div>
                            <div className='space-y-2.5'>
                                {combinedArray.filter(group => group?.set?.ability_set_id == null).map((set, index) => (
                                    <div className={`${set?.set?.new === true && "!bg-red-100 !p-2 rounded"} grid grid-cols-6 gap-3 items-center px-1 text-zinc-600`} key={index}>
                                        {set?.set?.new === true && <p className='text-[10px] text-red-500 uppercase font-medium tracking-wide col-span-6 px-px -mb-1'>Removed</p>}
                                        <div className='h-full flex flex-row justify-between rounded bg-zinc-50 border border-zinc-200 p-2 col-span-3 text-xs'>
                                            <p>{set.title} <span className='text-zinc-400'>  {set.users.length} members</span></p>

                                            {set.inherited && (<RadixTooltip classes='!max-w-[320px]' tooltipText='This group has inherited its role from its parent. If you manually change the role, you will remove all inherited roles and overwrite with your selection.'><span className='text-zinc-400'>Inherited</span></RadixTooltip>)}
                                        </div>
                                        <div className='col-span-3'>
                                            <Select key={`disabled_${set.title}`} childConClasses="!-mt-0" items={options} onChange={(e) => changeRule(set, e)} placeholder="Select role" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </RegularInputTemplate>
                    </div>
                    <div className="bottom-0 bg-zinc-50 dark:bg-zinc-900 dark:border-zinc-700 border-t mt-5 -mx-8 -mb-8 px-8 py-4 flex flex-row-reverse">
                        <div className="mr-2 space-x-3">
                            <SecondaryBtn text="Cancel" onClick={() => setSharing(false)} />
                            <PrimaryBtn text="Save changes" onClick={() => submitRules()} />
                        </div>
                    </div>
                </div>
            }

        />
    );
};

SharingModal.propTypes = {
    sharing: PropTypes.any,
    setSharing: PropTypes.any,
    activeObject: PropTypes.any,
    roles: PropTypes.any,
    combinedArray: PropTypes.any,
    submitRules: PropTypes.any,
    changeRule: PropTypes.any,
};

export default SharingModal;

import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/solid';
import { userLoginAction } from 'redux/actions/auth';
import { useJune } from 'utils/useJune';
import { env } from '../../env'

export default function loginModal() {
  const dispatch = useDispatch();

  const analytics = useJune('f7aFCim6eVJaVfRf');

  const [state, setState] = React.useState({
    email: '',
    emailError: false,
    passwordError: false,
    password: '',
  });

  /* eslint-disable */
  const { user } = useSelector(({ auth }) => {
    return {
      user: auth.user,
    };
  });
  /* eslint-enable */

  const stateRef = useRef(state);

  const loginUser = async () => {
    try {
      const requestData = await dispatch(
        userLoginAction({
          username: stateRef.current?.email,
          password: stateRef.current?.password,
        })
      );
      window.dispatchEvent(new Event("storage"));
      identity(
        requestData.payload.user?.id,
        requestData.payload.user?.name,
        requestData.payload.user?.email,
        new URL(env.REACT_APP_API_URL)?.host
      );
      trackCompany();

      trackLogin(requestData.payload.user?.id);

    } catch {
      //console.log('error’, error);
    }
  };

  const onChange = (e) => {
    const data = { ...state, [e.target.name]: e.target.value };
    stateRef.current = data;
    setState(data);
  };

  const identity = useCallback(
    (id, name, email) => {
      const company = new URL(env.REACT_APP_API_URL)?.host;
      const identifier = `${id}_${company}`;
      if (analytics)
        analytics.identify(identifier, {
          email: email,
          name: name,
          company: company,
        });
    },
    [analytics]
  );

  const trackLogin = useCallback(
    (id) => {
      const company = new URL(env.REACT_APP_API_URL)?.host;
      const identifier = `${id}_${company}`;
      if (analytics)
        analytics.track({
          userId: identifier,
          event: 'Signed In',
          company: company,
          context: {
            groupId: company,
          },
        });
    },
    [analytics]
  );

  const trackCompany = useCallback(() => {
    const comp = new URL(env.REACT_APP_API_URL)?.host;
    if (analytics)
      analytics.group(comp, {
        name: comp,
      });
  }, [analytics]);

  useEffect(() => {
    const listener = async (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        loginUser();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      <div className="space-y-8">
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="rounded-md shadow-sm space-y-4">
          <div>
            <label htmlFor="email-address" className="block text-sm font-medium text-slate-300">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={onChange}
                placeholder="your@email.here"
                required
                className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-slate-300">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="password"
                onChange={onChange}
                placeholder="safepassword123"
                required
                className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 items-center w-5/12 justify-center space-y-2 mx-auto">
          <button
            onClick={() => {
              loginUser();
            }}
            className="group w-full mb-2 relative inline-flex items-center justify-center rounded-full p-[1px] font-normal"
          >
            {' '}
            <div className="absolute h-full shadow-slate-800 shadow-lg w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-gray-200 group-hover:to-gray-200"></div>
            <div className="relative inline-flex w-full justify-center items-center rounded-full bg-zinc-900 px-5 py-2 transition-all ease-out delay-50 duration-300  group-hover:bg-gray-200">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-100 group-hover:text-[#13151D]"
                  aria-hidden="true"
                />
              </span>
              <p className="text-center text-gray-100 font-semibold text-base group-hover:text-[#13151D]">
                Login
              </p>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

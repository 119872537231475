import * as React from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal
} from "@floating-ui/react";
import PropTypes from 'prop-types';

import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const MSelect = ({ items, onChange: onChangeCallback, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(props.defaultSelected);

  React.useEffect(() => {

    const unknownFields = props.defaultSelected?.['**Unknown Columns**'] || false;
    //Calculate initial selected state
    const initialSelectedState = items.reduce((acc, item) => {
      acc[item.value] = unknownFields;
      return acc;
    }, {});

    const initialState = { ...props.defaultSelected };

    // Remove keys from defaultSelected that are not in initialSelectedState
    Object.keys(props.defaultSelected).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(initialSelectedState, key)) {
        delete initialState[key];
      }
    });

    //Combine defaultSelected with initialSelectedState
    const updatedSelectedState = { ...initialSelectedState, ...initialState };
    setSelectedIndex(updatedSelectedState);
    onChangeCallback(updatedSelectedState);
  }, []);


  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`
          });
        },
        padding: 10
      })
    ]
  });

  const listRef = React.useRef([]);
  const listContentRef = React.useRef(items);
  const isTypingRef = React.useRef(false);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true
  });

  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    }
  });

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([dismiss, role, listNav, typeahead, click]);

  const handleSelect = (index) => {
    const selectedOption = { ...selectedIndex };

    if (!selectedIndex?.[index]) {
      selectedOption[index] = true;
    } else {
      selectedOption[index] = false
    }
    setSelectedIndex(selectedOption)
    onChangeCallback(selectedOption);
  };

  const selectAllValues = () => {
    const allSelectedItems = {};
    items.map((item) => (allSelectedItems[item.value] = true));
    setSelectedIndex(allSelectedItems);

    onChangeCallback(allSelectedItems);
  };

  const deselectAllValues = () => {
    setSelectedIndex({});
    onChangeCallback({});
  };

  const selectedLabels = Object.keys(selectedIndex).filter(key => selectedIndex[key]).join(", ");


  return (
    <ModelingInputTemplate {...props}>
      <div
        tabIndex={0}
        ref={refs.setReference}
        className="relative w-full cursor-default rounded-md bg-white dark:bg-zinc-700 py-2 pl-3 pr-10 text-left shadow-sm dark:shadow-zinc-600/50 border focus:ring-[#5DA6FC] focus:border-[#5DA6FC] border-zinc-200 dark:border-zinc-600 dark:text-zinc-50 text-zinc-700 sm:text-xs"
        {...getReferenceProps()}
      >
        <p className={`block truncate ${selectedLabels.length > 0 ? "" : "text-zinc-400 dark:text-zinc-300 italic font-light"}`}>{selectedLabels.length > 0 ? selectedLabels : props.placeholder}</p>
        <span className=" absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 text-zinc-400 dark:text-zinc-300"
            aria-hidden="true"
          />
        </span>
      </div>
      <div className="ml-1 mb-1 mt-1.5 text-xs font-medium text-zinc-600 dark:text-zinc-100 ">
        <span className="font-light">
          <a
            className="hover:underline underline-offset-[1.5px] cursor-pointer opacity-50 hover:opacity-100 duration-150"
            onClick={() => {
              selectAllValues();
            }}
          >
            Select all
          </a>
          <a className="opacity-50"> | </a>
          <a
            className="hover:underline underline-offset-[1.5px] cursor-pointer opacity-50 hover:opacity-100 duration-150"
            onClick={() => {
              deselectAllValues();
            }}
          >
            Deselect all
          </a>
        </span>
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className="ring-0 focus:ring-0 hover:ring-0 outline-none  !max-h-60 bg-white dark:bg-zinc-700 shadow-lg z-50 border-separate border border-zinc-200 dark:border-zinc-600 overflow-auto rounded-md  dark:shadow-zinc-600/50 sm:text-xs"
              style={{
                ...floatingStyles,

              }}
              {...getFloatingProps()}
            >
              {items.map((item, i) => (
                <>
                
                <div
                  key={i}
                  className={`
                    ${selectedIndex[item.value] ? 'bg-zinc-200 font-semibold' : 'hover:bg-zinc-100'} 
                    pl-[25px] ring-0 focus:ring-0 flex items-center group text-xs py-2 pr-4 mx-1 my-1 rounded  hover:cursor-pointer text-zinc-600 dark:text-zinc-100`}
                  ref={(node) => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleSelect(item.value);
                    },
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === " " && !isTypingRef.current) {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    }
                  })}
                >
                  
                  {item.label}
                  {selectedIndex[item.value] && <CheckIcon className="absolute left-3 w-3 h-3 text-zinc-700" />}
                  {/* <span
                    aria-hidden
                    style={{
                      position: "absolute",
                      right: 10
                    }}
                  >
                    {i === selectedIndex ? " ✓" : ""}
                  </span> */}
                </div>
                {item.label == "**Unknown Columns**" && (<div style={{ width: 'calc(100% - 8px)' }} className="h-px bg-zinc-200 mx-auto rounded"/>)}
                </>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </ModelingInputTemplate>
  );
}

MSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultSelected: PropTypes.any,
};

MSelect.defaultProps = {
  placeholder: '',
  items: [],
  onChange: () => null,
};

export default MSelect


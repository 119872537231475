import React from 'react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/solid';

const FlyingModalHeader = (props) => {
  return (
    <div className="z-50 dragHandle sticky rounded-t-lg top-0 cursor-move bg-white dark:bg-transparent px-5 pt-5 pb-[0.5px] -mb-5 dark:hover:bg-zinc-700 hover:bg-zinc-100">
      <h3 className="text-lg leading-6 font-bold text-zinc-700 dark:text-zinc-50">
        {props.header}
      </h3>
      <div className="max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
        {props.content}
      </div>
      <div className="mt-2 -mx-5 relative flex-1 flex flex-row">
        <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
        <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
      </div>
      <button
        type="button"
        className="p-1 absolute top-[2px] right-[2px] rounded-md bg-none text-zinc-400 hover:text-zinc-500 dark:text-zinc-300 dark:hover:text-zinc-200  focus:outline-none focus:ring-0 transition duration-100"
        onClick={props.onClick}
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};
FlyingModalHeader.propTypes = {
  header: PropTypes.string,
  content: PropTypes.any,
  onClick: PropTypes.func,
};
export default FlyingModalHeader;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextDescInput from 'components/Inputs/TextDesc';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import ModalHeader from 'components/Layout/ModalHeader';

const WhitelistNew = ({ currentIp, onClose, onClick }) => {
  const [state, setState] = useState();

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <div className="p-8 ">
      <ModalHeader header="Whitelist New IP" />

      <div className="w-full items-start">
        <div className="w-full mt-5 ">
          <TextDescInput
            read={true}
            withtooltip={true}
            type="text"
            tooltip="This is the IP of the computer your using right now. If its the one you need, you can copy/paste it into the IP Address field below"
            ttmargin="150%"
            header="Your current IP address"
            readValue={currentIp}
          />
        </div>
        <div className="pt-5">
          <TextDescInput
            subheader="Input the IP address you want to whitelist"
            header="IP Address"
            placeholder="80.194.10.25"
            type="text"
            name="ip"
            required
            onChange={(e) => {
              handleChange('ip', e);
            }}
          />
        </div>
        <div className="py-5">
          <TextDescInput
            header="Description"
            subheader="IPs are hard to remember - add a short description"
            placeholder="Thomas Macbook Air"
            type="text"
            name="description"
            onChange={(e) => {
              handleChange('description', e);
            }}
          />
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-zinc-900 border-t border-zinc-200 dark:border-zinc-600 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={() => onClose()} />
          <PrimaryBtn text="Save Changes" onClick={() => onClick(state)} />
        </div>
      </div>
    </div>
  );
};

WhitelistNew.propTypes = {
  currentIp: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default WhitelistNew;

import React from 'react';
import { useDispatch } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/solid';
import { acceptInviteAction } from 'redux/actions/auth';

export default function NewUser() {
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password_confirmation, setPassword_confirmation] = React.useState('');
  const [status, setStatus] = React.useState('idle');

  const dispatch = useDispatch();

  const queryParameters = new URLSearchParams(window.location.search)
  const email = queryParameters.get("email")
  const token = queryParameters.get("token")

  async function handleSubmit(event) {
    event.preventDefault();

    setStatus('loading');
    if (password != password_confirmation) {
      setStatus('pw_mismatch');
      return;
    }

    try {
      await dispatch(acceptInviteAction({ name, password, password_confirmation, token, email })).unwrap();
      window.location.pathname = '/login';
    } catch (error) {
      setStatus('error');
    }
  }

  return (
    <>
      <div className="min-h-full h-screen bg-[#13151D] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full relative flex flex-col items-center max-w-screen-md border-[0.5px] border-zinc-600 bg-gradient-to-br from-slate-800 via-transparent to-zinc-900 px-28 py-16 rounded-2xl space-y-8">
          <div>
            <img
              className="mx-auto h-20 w-auto opacity-50"
              src="https://less-logos.s3.eu-central-1.amazonaws.com/random/logoLight.svg"
              alt="Logo"
            />
          </div>
          <input type="hidden" name="remember" defaultValue="true" />

          <form
            onSubmit={handleSubmit}
            className="max-w-[500px] w-[500px]  rounded-md shadow-sm space-y-7"
          >
            <div className="space-y-2">
              <label htmlFor="name-input" className="block text-sm font-medium text-slate-300">
                Name
              </label>
              <label htmlFor="name-input" className="block text-sm font-normal text-slate-400">
                Please enter your full name
              </label>
              <div className="">
                <input
                  id="name-input"
                  type="text"
                  requi
                  value={name}
                  autocCmplete="off"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  placeholder="your@email.here"
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="password_original"
                className="block text-sm font-medium text-slate-300"
              >
                Password
              </label>

              <div className="mt-1">
                <input
                  id="password_original"
                  type="password"
                  autoComplete="off"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  placeholder="safepassword123"
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="password_confirm"
                className="block text-sm font-medium text-slate-300"
              >
                Confirm password
              </label>

              <div className="mt-1">
                <input
                  id="password_confirm"
                  type="password"
                  autoComplete="off"
                  value={password_confirmation}
                  onChange={(event) => {
                    setPassword_confirmation(event.target.value);
                  }}
                  placeholder="safepassword123"
                  required
                  className="appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border-1 border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
                />
              </div>
            </div>
            {status == 'pw_mismatch' && (
              <p className="p-3 bg-red-200 border border-red-500 rounded-md  w-full text-center text-sm text-red-500">
                The passwords do not match. Please retry.
              </p>
            )}
            {status == 'error' && (
              <p className="p-3 bg-red-200 border border-red-500 rounded-md  w-full text-center text-sm text-red-500 ">
                There was an error creating your account. Please reach out to the support (bottom,
                right-hand corner) or get in touch at{' '}
                <a
                  href="mailto:thomas@less.tech"
                  className="hover:underline hover:decoration-red-500 hover:underline-offset-4"
                >
                  thomas@less.tech.
                </a>
              </p>
            )}
            <div className="flex flex-col flex-1 items-center w-9/12 justify-center space-y-2 mx-auto">
              <button className="group w-full mb-2 relative inline-flex items-center justify-center rounded-full p-[1px] font-normal">
                {' '}
                <div className="absolute h-full shadow-slate-800 shadow-lg w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-gray-200 group-hover:to-gray-200"></div>
                <div className="relative inline-flex w-full justify-center items-center rounded-full bg-zinc-900 px-5 py-2 transition-all ease-out delay-50 duration-300  group-hover:bg-gray-200">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-100 group-hover:text-[#13151D]"
                      aria-hidden="true"
                    />
                  </span>
                  <p className="text-center text-gray-100 font-medium text-base group-hover:text-[#13151D]">
                    {status === 'loading' ? 'Creating...' : 'Create account'}
                  </p>
                </div>
              </button>
            </div>
          </form>

          <p className="text-slate-500 text-xs absolute bottom-4">
            By confirming your account, you agree to our{' '}
            <a
              className="hover:text-slate-200  duration-150 underline-offset-4 underline decoration-slate-500 hover:decoration-slate-200"
              href="https://link.less.tech/terms"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className="hover:text-slate-200  duration-150 underline-offset-4 underline decoration-slate-500 hover:decoration-slate-200"
              href="https://link.less.tech/privacy-policy"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

import FormInputData from 'data/FormInput';

const DatabaseHiddenField = (connectorId, defaultvalue) =>
  FormInputData({
    connectorId,
    defaultvalue,
    fieldtype: 'hidden',
    fieldname: 'databaseId',
  });

export default DatabaseHiddenField;

import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { CheckCircleIcon, ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { unixTime } from 'utils/unix2time';

const Notification = ({ id, open, onClose, icon, header, subheader, timeout, isPaused }) => {
  const progressRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const startTimeRef = useRef(null);
  const pausedTimeRef = useRef(0);
  const lastPauseTimeRef = useRef(null);
  const animationRef = useRef(null);
  const [effectivePause, setEffectivePause] = useState(false);

  const debouncedSetEffectivePause = useCallback((value) => {
    if (value) {
      setEffectivePause(true);
    } else {
      setTimeout(() => setEffectivePause(false), 100); // 100ms debounce
    }
  }, []);

  useEffect(() => {
    debouncedSetEffectivePause(isPaused);
  }, [isPaused, debouncedSetEffectivePause]);

  useEffect(() => {
    const activateTimer = setTimeout(() => {
      setIsActive(true);
      startTimeRef.current = performance.now();
    }, 500);

    return () => clearTimeout(activateTimer);
  }, []);

  useEffect(() => {
    if (!isActive) return;

    const updateProgress = (timestamp) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsedTime = timestamp - startTimeRef.current - pausedTimeRef.current;
      const progress = Math.max(100 - (elapsedTime / timeout) * 100, 0);

      if (progressRef.current) {
        progressRef.current.style.width = `${progress}%`;
      }

      if (progress > 0 && !effectivePause) {
        animationRef.current = requestAnimationFrame(updateProgress);
      } else if (progress <= 0) {
        onClose(id);
      }
    };

    if (effectivePause) {
      if (lastPauseTimeRef.current === null) {
        lastPauseTimeRef.current = performance.now();
      }
      cancelAnimationFrame(animationRef.current);
    } else {
      if (lastPauseTimeRef.current !== null) {
        pausedTimeRef.current += performance.now() - lastPauseTimeRef.current;
        lastPauseTimeRef.current = null;
      }
      animationRef.current = requestAnimationFrame(updateProgress);
    }

    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [isActive, effectivePause, timeout, id, onClose]);

  const colors = {
    success: 'green',
    warning: 'yellow',
    error: 'red',
  };

  const presetIcons = {
    success: <CheckCircleIcon className={`h-6 w-6 text-${colors[icon]}-500`} aria-hidden="true" />,
    warning: <ExclamationIcon className={`h-6 w-6 text-${colors[icon]}-500`} aria-hidden="true" />,
    error: (
      <ExclamationCircleIcon className={`h-6 w-6 text-${colors[icon]}-500`} aria-hidden="true" />
    ),
  };

  const renderIcon = () => {
    if (typeof icon === 'string') {
      return presetIcons[icon];
    }
    return icon;
  };

  return (
    <Transition
      show={open}
      appear={true}
      as={Fragment}
      enter="transform ease-out duration-200 transition-all"
      enterFrom="-translate-y-20 opacity-0"
      enterTo="translate-y-0 opacity-200"
      leave="transform ease-out duration-100 transition-all"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-20"
    >
      <div
        className={`z-[100] relative group max-w-sm w-full bg-${colors[icon]}-50 dark:bg-zinc-700 shadow-lg dark:shadow-zinc-700/30 rounded-lg pointer-events-auto border border-${colors[icon]}-600/20 dark:border-zinc-500 `}
      >
        <div className="ml-4 hidden absolute -left-[26px] -top-2 flex-shrink-0 group-hover:flex">
          <button
            type="button"
            className="bg-transparent p-1 group bg-zinc-100 border-zinc-400 border hover:bg-zinc-200 dark:hover:bg-zinc-600 rounded-full inline-flex  focus:outline-none focus:ring-0 focus:ring-offset-0 transition-all duration-100"
            onClick={() => onClose(id)}
          >
            <XIcon className="h-3 w-3 text-zinc-400 " aria-hidden="true" />
          </button>
        </div>
        <div className="overflow-hidden">
          <div className="p-3">
            <div className="flex items-start">
              <div
                className={`flex-shrink-0 bg-${colors[icon]}-100 border border-${colors[icon]}-400 rounded p-1 mt-1`}
              >
                {renderIcon()}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5  mr-[10px]">
                <p className={`text-xs font-bold tracking-wide text-${colors[icon]}-900 `}>
                  {header}
                </p>
                <p className={`text-xs text-${colors[icon]}-800 dark:text-zinc-200`}>{subheader}</p>
                {header === 'Error' ? (
                  <p className="mt-0.5 text-xs text-zinc-400/80  dark:text-zinc-200">
                    {unixTime(id)}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div
            className={`w-full rounded-b-md overflow-hidden border-t border-${colors[icon]}-300 bg-${colors[icon]}-100 h-2`}
          >
            <div
              ref={progressRef}
              className={`h-full bg-${colors[icon]}-300 transition-[width] duration-[50ms] ease-linear`}
              style={{ width: '100%' }}
            ></div>
          </div>
        </div>
        <div className="hidden bg-yellow-100 bg-yellow-50 bg-yellow-300 text-yellow-400 text-yellow-800 text-yellow-900 border-yellow-600/20 border-yellow-300 border-yellow-400 text-yellow-500"></div>
        <div className="hidden bg-red-100 bg-red-50 bg-red-300 text-red-400 text-red-800 text-red-900 border-red-600/20 border-red-300 border-red-400 text-red-500" />
        <div className="hidden bg-green-100 bg-green-50 bg-green-300 text-green-400 text-green-800 text-green-900 border-green-600/20 border-green-300 border-green-400 text-green-500" />
      </div>
    </Transition>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  subheader: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.any,
  timeout: PropTypes.number,
  isPaused: PropTypes.bool,
};

Notification.defaultProps = {
  onClose: () => null,
  timeout: 10000,
  isPaused: false,
};

export default Notification;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const TextArea = (props) => {
  const [value, setValue] = useState(props.value);

  const onChange = (e) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <RegularInputTemplate
      header={props.header}
      subheader={props.subheader}
      withdesc={true}
      name={props.header}
    >
      <textarea
        rows={5}
        name="comment"
        id="comment"
        maxLength={200}
        placeholder={props.placeholder}
        className="dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:text-zinc-50 resize-none shadow-sm text-zinc-700 placeholder:text-zinc-300 placeholder:font-light focus:ring-[#5DA6FC] focus:border-[#5DA6FC] block w-full sm:text-sm border-zinc-300 border rounded-md"
        value={value}
        onChange={onChange}
      />
    </RegularInputTemplate>
  );
};

TextArea.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  value: '',
  onChange: () => null,
};

export default TextArea;

import axios from 'axios';
import {
  env
} from '../env'

export const jsonHeader = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// for all network calls to server (instance of axios)
const Axios = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${env.REACT_APP_API_URL}/`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    //utc_offset: new Date().getTimezoneOffset() / 60,
  },
  validateStatus: (status) => {
    return status >= 200 && status < 400; // default
  },
});

// ********************************
// Response Interceptors

// redirect to login if 401
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await Axios.post('api/auth/refresh');
        const {
          access_token
        } = response.data;
        localStorage.setItem('token', access_token);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return Axios(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        if (window.location.pathname == "/") {
          window.location.pathname = '/login';
          localStorage.removeItem('auth');

        } else {
          localStorage.setItem('auth', "False");
        }
      }
    }

    return Promise.reject(error);
  }
);

// download handler
Axios.interceptors.response.use((response) => {
  const dispos = response.headers['content-disposition'];
  if (dispos !== undefined) {
    const regex = /filename=(.*)/;
    const found = dispos.match(regex);
    const filename = found ? found.pop().split('=').pop() : 'export';
    const data = new File([response.data], filename);
    const downloadUrl = URL.createObjectURL(data);
    // trigger download modal
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = filename;
    document.body.append(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    return
  }
  return response;
});

// Global API error handler
Axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function ApiErrorHandler(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const response = error?.response ? {
    data: error.response.data,
    statusText: error.response.statusText,
    headers: error.response.headers
  } : {};
  const customError = {
    code: response,
    message: response?.data?.error,
  };
  throw customError;
});


// ********************************
// Request Interceptors
Axios.interceptors.request.use(
  async (config) => {

      const token = localStorage.getItem('token');
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }
      console.log(config.url)
      if ((config.url.includes("api/auth") && !config.url.includes('refresh')) ||(config.url.includes("invite"))) {
        return config
      }

      if (localStorage.getItem('auth') == "False") {
        return Promise.reject();
      }

      return config

    },
    (err) => {
      return Promise.reject(err);
    }
);

export default Axios;
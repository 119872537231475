import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Popover from '@radix-ui/react-popover';
import { XIcon } from '@heroicons/react/solid';
//import { InformationCircleIcon } from '@heroicons/react/solid';

import ContentEditable from 'react-contenteditable';

const PopoverBtn = ({ data }) => {
  const [text, setText] = React.useState(
    data?.data?.configuration?.customAnnotation || 'Click here to write a custom description'
  );
  const [fullDisabled, setFullDisabled] = React.useState(false);


  useEffect(() => {
    //Check if should be disabled
    if (localStorage.getItem('noModelling') === 'true') {
      setFullDisabled(true);
    }

  }, []);
  const handleChange2 = (evt) => {
    data.data.configuration['customAnnotation'] = evt.target.value;
    setText(evt.target.value);
  };
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="rounded-full cursor-default w-5 h-5   duration-300 outline-none"
          aria-label="Update dimensions"
        >
          {text === 'Click here to write a custom description' || text === '' ? (
            <svg
              className="h-5 w-5 bg-zinc-100 border border-zinc-200 rounded-full text-zinc-500"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V15"
              ></path>
              <circle cx="12" cy="9" r="1" fill="currentColor"></circle>
              <circle
                cx="12"
                cy="12"
                r="7.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></circle>
            </svg>
          ) : (
            <svg
              className="h-5 w-5 bg-zinc-700 border-zinc-500 border rounded-full text-zinc-50"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V15"
              ></path>
              <circle cx="12" cy="9" r="1" fill="currentColor"></circle>
              <circle
                cx="12"
                cy="12"
                r="7.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></circle>
            </svg>
          )}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="border rounded p-3 w-[260px] bg-zinc-200/60 backdrop-blur-sm will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
          side="top"
        >
          <ContentEditable
            className="nodrag min-h-full min-w-full max-w-full outline-transparent focus:ring-0 focus:outline-none ring-0 font-normal w-max text-zinc-700 text-xs text-center"
            html={text}
            onChange={handleChange2}
            disabled={fullDisabled}
          />
          <Popover.Close
            className="rounded-full hover:rotate-90 duration-300 h-3 w-3 inline-flex items-center justify-center text-zinc-500 absolute top-[5px] right-[5px]  outline-none cursor-pointer"
            aria-label="Close"
          >
            <XIcon />
          </Popover.Close>
          <Popover.Arrow className="fill-zinc-200" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

PopoverBtn.propTypes = { data: PropTypes.object };

export default PopoverBtn;

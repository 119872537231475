export function statusLabel(statusCode) {
  if (statusCode == 500) {
    return "Error"
  } else if (statusCode == 300) {
      return "Warning"
  }
  else if (statusCode == 200) {
      return "Success";
  }
      else if (statusCode == 100) {
      return "Updating";
  }
      else if (statusCode == 50) {
      return "In Queue";
  }
    else if (statusCode == 0) {
      return "Not Run";
  }
  else {
    return "Unknown";
  }
}


import React from 'react';
import PropTypes from 'prop-types';

const TertiaryBtn = ({ text, onClick, classes }) => {
  return (
    <button
      onClick={onClick}
      className={`${classes} items-center duration-300 transition-all  justify-center border-transparent  dark:bg-transparent text-sm text-zinc-500 dark:text-zinc-200 hover:border-zinc-300 dark:border-zinc-500 border-2 px-4 py-[5px] rounded-full font-normal hover:bg-zinc-100 dark:hover:bg-zinc-50 dark:hover:border-zinc-50 focus:ring-0 focus:ring-transparent dark:hover:text-zinc-700`}
    >
      {text}
    </button>
  );
};
TertiaryBtn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  classes: PropTypes.string,
};

TertiaryBtn.defaultProps = {
  onClick: () => null,
  text: 'Button',

};

export default TertiaryBtn;

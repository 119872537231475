import React from 'react';
import PropTypes from 'prop-types';

const ZoomBtn = ({ icon, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={` w-auto h-auto p-2 text-xs font-medium items-center text-center justify-center z-30 flex flex-col bg-zinc-50 dark:bg-zinc-800 text-zinc-400 border border-zinc-200 dark:border-zinc-700  dark:text-zinc-200 rounded-md shadow-xs dark:shadow-zinc-800 dark:hover:bg-zinc-500 hover:bg-zinc-200`}
    >
      {icon}
    </button>
  );
};
ZoomBtn.propTypes = {
  icon: PropTypes.any,
  onClick: PropTypes.func,
};
export default ZoomBtn;

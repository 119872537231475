import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const GridTextInput = (props) => {
  const [selected, setSelected] = useState(props.defaultSelected);

  const handleChange = (e, id) => {
    const { value } = e.target;

    const newSelected = { ...selected, [id]: value };
    if (value == "" && columns.filter((column) => column.label == id)[0]?.missing) {
      delete newSelected[id]
    }
    setSelected(newSelected);
    // I'm just using console.log to show that it updates
    // Right now, the handleChange just creates a new array that we need to use for overwriting the incoming value when moving to production
    props.onChange(newSelected);
  };

  const columnsData = props.columns.map((column) => column.label)
  const missing_columns = Object.keys(props.defaultSelected).filter((column) => !columnsData.includes(column)).map((column) => { return { label: column, value: props.defaultSelected[column], missing: true } })
  const columns = [...missing_columns, ...props.columns]

  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col shadow-sm ring-1 dark:bg-zinc-700 dark:shadow-zinc-600/50 ring-zinc-200 dark:ring-zinc-600 rounded-md overflow-hidden">
        <div className="-my-2 w-full max-h-80 overflow-y-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full text-left divide-y divide-zinc-200 dark:divide-zinc-600">
                <thead className="bg-zinc-50 dark:bg-zinc-700">
                  <tr>
                    <th
                      scope="col"
                      className="text-xs border-b px-2 font-medium text-zinc-700 dark:text-zinc-50"
                    >
                      Current name
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 border-b text-left text-xs font-medium text-zinc-700 dark:text-zinc-50"
                    >
                      New name
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-600 bg-white dark:bg-zinc-700">
                  {columns.map((column) => {
                    return (
                      <tr key={column.label}>
                        {column.label.length > 20 ? (
                          <td className={`px-2 py-2 text-xs font-normal text-zinc-600 dark:text-zinc-100`}>
                            <RadixTooltip tooltipText={column.label}>
                              {column.label.slice(0, 18)}...
                            </RadixTooltip>
                          </td>
                        ) : (
                          <td className={`${column?.missing ? '!bg-red-100' : ''} whitespace-nowrap px-2 py-2 text-xs font-normal text-zinc-600 dark:text-zinc-100 max-w-[100px] truncate`}>
                            {column.label}
                          </td>
                        )}
                        <td className="text-xs ">
                          <input
                            name="columns"
                            value={selected?.[column.label]}
                            onChange={(e) => handleChange(e, column.label)}
                            type="text"
                            placeholder="Start typing new column name"
                            className="dark:bg-zinc-700 -ml-1 text-zinc-600 dark:text-zinc-100 placeholder:text-zinc-300 dark:placeholder:text-zinc-400 placeholder:font-light outline-none focus:ring-0 block sm:text-xs border-none min-w-full"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModelingInputTemplate>
  );
};

GridTextInput.propTypes = {
  header: PropTypes.string,
  columns: PropTypes.array,
  defaultValue: PropTypes.object,
  items: PropTypes.array,
  defaultSelected: PropTypes.object,
  inputArr: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default GridTextInput;

import FormInputData from 'data/FormInput';

const DatabaseSelectField = (databases, connectorId, defaultvalue) =>
  FormInputData({
    connectorId,
    defaultvalue,
    fieldtype: 'select',
    formname: 'Select Destination Database',
    description: 'If you have more than one database, please select which one to output to.',
    fieldname: JSON.stringify({
      databaseId: databases.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    }),
    placeholder: 'Select Destination Database',
  });

export default DatabaseSelectField;

/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import { ExclamationIcon } from '@heroicons/react/outline';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import DeleteBtn from 'components/Buttons/DeleteBtn';

const DeleteModal = (props) => {
  const { open, onClose, deletepress, onCancel, header, description, deletetxt, canceltxt, loader } = props;

  const cancelHandler = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    } else {
      onClose(false);
    }
  };
  return (
    <div className="relative z-50" onClose={() => onClose(false)}>
      <AnimatePresence>
        {open && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className="fixed inset-0 bg-gray-500 bg-opacity-75"
            onClick={() => onClose(false)}
          >
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <div className="relative bg-white dark:bg-zinc-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div className="bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-rose-50 sm:mx-0 sm:h-10 sm:w-10 border border-transparent dark:border-rose-500">
                        <ExclamationIcon className="h-6 w-6 text-rose-500" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div
                          as="h3"
                          className="text-lg leading-6 font-medium text-zinc-700 dark:text-zinc-50"
                        >
                          {header}
                        </div>
                        <div className="mt-2">
                          <p className="text-sm text-zinc-500 dark:text-zinc-200">{description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-600 py-3 justify-end sm:px-6 sm:flex space-x-2">
                    <SecondaryBtn text={canceltxt} onClick={cancelHandler} />
                    <DeleteBtn text={deletetxt} onClick={deletepress} loader={loader} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  header: PropTypes.string,
  description: PropTypes.string,
  deletepress: PropTypes.func,
  deletetxt: PropTypes.string,
  canceltxt: PropTypes.string,
  open: PropTypes.bool,
  loader: PropTypes.bool,
};

DeleteModal.defaultProps = {
  onClose: () => null,
  onCancel: null,
  header: '',
  description: '',
  deletepress: () => null,
  deletetxt: 'Delete',
  canceltxt: 'Cancel',
  open: false,
  loader: false,
};

export default DeleteModal;

import React from 'react';
import { motion } from 'framer-motion';

function Loader() {
  const loadingCircleTransition = {
    duration: 0.6,
    repeat: Infinity,
    repeatType: 'reverse',
    ease: 'easeInOut',
  };

  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const loadingCircleVariants = {
    start: { y: '0%' },
    end: { y: '50%' },
  };

  return (
    <div className="h-screen w-screen flex flex-col gap-16 mx-auto items-center justify-center bg-[#13151D] absolute z-50">
      <div>
        <motion.div
          className="flex flex-row gap-4"
          variants={loadingContainerVariants}
          initial="start"
          animate="end"
        >
          <motion.span
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
            className="block w-10 h-10 border border-zinc-600 bg-gradient-to-tr  from-[#5DA6FC]/50 via-[#FFC6BA]/50 to-[#ff5478]/50 rounded-full"
          ></motion.span>
          <motion.span
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
            className="block w-10 h-10 border border-zinc-600 bg-gradient-to-tr  from-[#5DA6FC]/50 via-[#FFC6BA]/50 to-[#ff5478]/50 rounded-full"
          ></motion.span>
          <motion.span
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
            className="block w-10 h-10 border border-zinc-600 bg-gradient-to-tr from-[#5DA6FC]/50 via-[#FFC6BA]/50 to-[#ff5478]/50 rounded-full"
          ></motion.span>
        </motion.div>
      </div>
      <p className="font-extrabold text-xl text-zinc-400 animate-pulse">Initializing...</p>
    </div>
  );
}
export default Loader;

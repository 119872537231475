import React from 'react';
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import history from 'history/browser';
// component
import App from './App';
import './index.css';
import Gleap from 'gleap';
// June
import { AnalyticsBrowser } from '@june-so/analytics-next';

/* eslint-disable */
let analytics = AnalyticsBrowser.load({
  writeKey: 'f7aFCim6eVJaVfRf',
});
/* eslint-enable */

Gleap.initialize('4IsBWpkhCn0GpfBKOr78XvOzCN03Ci60');

const root = createRoot(document.getElementById('root'));

root.render(
  <HistoryRouter history={history}>
    <App />
  </HistoryRouter>
);

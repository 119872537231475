import React from 'react';
import PropTypes from 'prop-types';

const IconBtn = ({ text, icon, onPress }) => {
  return (
    <button
      onClick={onPress}
      className="group h-max w-max relative inline-flex items-center justify-center overflow-hidden rounded-lg p-0.5 font-normal"
    >
      <div className="absolute h-full w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-black group-hover:to-black dark:group-hover:from-white dark:group-hover:to-white"></div>
      <div className="relative inline-flex items-center rounded-full bg-white dark:bg-black px-4 py-1.5 transition-all ease-out delay-50 duration-300  group-hover:bg-black dark:group-hover:bg-white">
        <div className="mr-1 text-zinc-700 group-hover:text-zinc-50 dark:text-zinc-50 dark:group-hover:text-zinc-700">
          {icon}
        </div>
        <p className="relative text-sm text-zinc-700 group-hover:text-zinc-50 dark:text-zinc-50 dark:group-hover:text-zinc-700">
          {text}
        </p>
      </div>
    </button>
  );
};
IconBtn.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  onPress: PropTypes.func,
};
export default IconBtn;

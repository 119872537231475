import FormInputData from 'data/FormInput';

const ConnectorIdHiddenField = (defaultvalue) =>
  FormInputData({
    defaultvalue,
    fieldtype: 'hidden',
    fieldname: 'connection_id',
  });

export default ConnectorIdHiddenField;

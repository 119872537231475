import Axios from './axios';

export const getAllTablesService = () => {
  return Axios('api/tables/').then((res) => {
    return res?.data;
  });
}

export const getTablesService = (object_type, object_id) => {
  return Axios.get(`api/tables/${object_type}/${object_id}`).then((res) => {
    return res.data;
  });
};

export const metadataTableService = (object_type, object_id, table_name) => {
  return Axios.get(`api/tables/metadata/${object_type}/${object_id}/${table_name}`).then((res) => {
    return res.data;
  });
};

export const previewTableService = (object_type, object_id, table_name) => {
  return Axios.get(`api/tables/preview/${object_type}/${object_id}/${table_name}`).then((res) => {
    return res.data;
  });
};

export const deleteTableService = (object_type, object_id, table_name) => {
  return Axios.delete(`api/tables/${object_type}/${object_id}/${table_name}`).then((res) => {
    return res.data;
  });
};

export const exportTableService = (object_type, object_id, table_name, format) => {
  return Axios.get(`api/tables/export/${object_type}/${object_id}/${table_name}/${format}`, { responseType: 'arraybuffer' }).then((res) => {
    return res.data;
  });
};
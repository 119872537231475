import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const CodeArea = (props) => {
  const [value, setValue] = useState(props.value);

  const onChange = (e) => {
    setValue(e);
    props.onChange(e);
  };

  return (
    <RegularInputTemplate
      header={props.header}
      subheader={props.subheader}
      withdesc={true}
      name={props.header}
    >
      <div className="rounded overflow-hidden">
        <Editor
          height="300px"
          width={"100%"}
          theme="vs-dark"
          defaultLanguage={props.language}
          value={value}
          onChange={onChange}
        />
      </div>
    </RegularInputTemplate>
  );
};

CodeArea.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  language: PropTypes.string
};

CodeArea.defaultProps = {
  value: '',
  language: 'python',
  onChange: () => null,
};

export default CodeArea;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import PasswordInput from 'components/Inputs/PasswordInput';

const ChangePWModal = ({ submitHandler }) => {
  const [password, setPassword] = useState();
  const [newPw, setNewPw] = useState();
  const [confirmPw, setConfirmPw] = useState();

  const onClick = () => {
    submitHandler({ password, newPw, confirmPw });
  };

  return (
    <div className="p-8">
      <div className="space-y-5 mb-5">
        <PasswordInput
          header="Old password"
          name="old_password"
          placeholder="Enter your old password"
          value={password}
          onChange={setPassword}
        />
        <PasswordInput
          header="New password"
          name="new_password"
          placeholder="Enter your new password"
          value={newPw}
          onChange={setNewPw}
        />
        <PasswordInput
          header="Confirm new password"
          name="new_password_2"
          placeholder="Confirm your new password"
          value={confirmPw}
          onChange={setConfirmPw}
        />
      </div>

      <div className="bg-zinc-50 dark:bg-zinc-900 dark:border-zinc-700 border-t px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <PrimaryBtn text="Update password" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

ChangePWModal.propTypes = {
  onClose: PropTypes.func,
  submitHandler: PropTypes.func,
};

ChangePWModal.defaultProps = {
  onClose: () => null,
  submitHandler: () => null,
};

export default ChangePWModal;

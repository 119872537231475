import PropTypes from 'prop-types';
import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import { motion, AnimatePresence } from 'framer-motion';

const SlideOver = (props) => {
  const { open, onClose, submitHandler } = props;

  const onSave = (e) => {
    e.preventDefault();
    //onClose(false);
    return submitHandler(e);
  };

  return (
    <div className="relative z-50">
      <AnimatePresence>
        {open && (
          <div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeIn' }}
              className="fixed inset-0 bg-zinc-500 backdrop-blur-sm bg-opacity-75 transition-opacity "
              onClick={() => onClose(false)}
            />

            <div className="absolute inset-0  overflow-hidden">
              <div className="pointer-events-none fixed inset-y-3 right-3 flex  max-w-full pl-10 sm:pl-16">
                <div className={`pointer-events-auto w-screen  max-w-2xl ${props.classes}`}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1, ease: 'easeInOut' }}
                    className="flex h-full flex-col overflow-y-auto bg-zinc-50 rounded-md dark:bg-zinc-800 shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-zinc-100 dark:bg-zinc-800 border-b border-zinc-200 dark:border-zinc-600 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="space-y-1">
                            <p className="text-lg font-medium text-zinc-700 dark:text-zinc-50">
                              {props.title}
                            </p>
                            <div className="text-sm text-zinc-500 dark:text-zinc-200">
                              {props.subtitle}
                            </div>
                            <div className="text-sm text-zinc-500 dark:text-zinc-200">
                              {props.secondSubtitle}
                            </div>
                          </div>
                          <div className="flex absolute top-2 right-3 h-7 items-center">
                            <button
                              className="text-zinc-400 hover:text-zinc-500 dark:text-zinc-300 dark:hover:text-zinc-200"
                              onClick={() => onClose(false)}
                            >
                              <XIcon className="h-6 w-6" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="dark:bg-zinc-800">{props.children}</div>
                    </div>

                    {/* Action buttons */}
                    {props.actionbtn == true && (
                      <div className="w-full bg-zinc-100 dark:bg-zinc-800  bottom-0 sticky border-t border-zinc-200 dark:border-zinc-600 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <SecondaryBtn text="Cancel" onClick={() => onClose(false)} />
                          <PrimaryBtn text="Save Changes" onClick={onSave} useEnter={true} loader={true} />
                        </div>
                      </div>
                    )}
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

SlideOver.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.any,
  secondSubtitle: PropTypes.any,
  submitHandler: PropTypes.func,
  actionbtn: PropTypes.bool,
  classes: PropTypes.any,
};

SlideOver.defaultProps = {
  actionbtn: true,
};

export default SlideOver;

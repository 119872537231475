import React from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import PropTypes from 'prop-types';

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';

const ColorPicker = (props) => {
  //const [color, setColor] = React.useState("#ffffff");
  //const [textColor, setTextColor] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: 'top',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      {/* <div className='inline-flex rounded-t-md justify-center gap-5  items-center top-0.5 -ml-3 w-[calc(100%-4px)]   absolute  h-9 border-b border-zinc-200 '> */}
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={`${
          !open ? 'bg-zinc-100 text-zinc-700' : 'bg-zinc-400 text-zinc-50'
        } transition-all duration-200 rounded h-6 w-6 text-xs border-zinc-300 border`}
      >
        {props.text}
      </button>
      {/* </div> */}
      <FloatingPortal>
        <FloatingFocusManager context={context} modal={false}>
          {open && (
            <div
              {...getFloatingProps()}
              ref={refs.setFloating}
              style={floatingStyles}
              className={`p-4 bg-zinc-950/20 backdrop-blur z-[10000] border border-zinc-400 rounded-lg nodrag`}
            >
              <HexColorPicker
                className="!rounded-[0px]"
                style={{ width: '120px', height: '120px' }}
                color={props.color}
                onChange={props.setColor}
              />
              <HexColorInput
                className="focus:!ring-0 focus:outline-0 bg-zinc-100 mt-2 w-[120px] text-xs p-1 rounded text-zinc-700"
                color={props.color}
                onChange={props.setColor}
              />
            </div>
          )}
        </FloatingFocusManager>
      </FloatingPortal>
    </>
  );
};

ColorPicker.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  setColor: PropTypes.any,
  color: PropTypes.any,
  text: PropTypes.any,
};

export default ColorPicker;

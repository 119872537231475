import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NonEditablePW from 'components/Inputs/NonEditablePW';
import ModalHeader from 'components/Layout/ModalHeader';
import TextDescInput from 'components/Inputs/TextDesc';
import { getDatabaseAction } from 'redux/actions/database';

const ViewCredentials = ({ database }) => {

  const [activeDatabase, setActiveDatabase] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const databaseObject = await dispatch(getDatabaseAction(database.id)).unwrap();
      setActiveDatabase(databaseObject);
    }
    fetchData();
  }, [database]);

  return (
    <div className="p-8">
      <ModalHeader
        header="View Credentials"
        content={
          <div className="text-zinc-500 dark:text-zinc-200">
            <p className="mt-2 max-w-4xl text-sm align-baseline">
              Database credentials are used to connect to your Less database from third-party
              software such as Tableau or Excel.
            </p>

            <p className="mt-4 max-w-4xl text-sm align-baseline">
              If you need help configuring a connection to another software program{' '}
              <a
                href="https://calendly.com/thomas-less/speak-to-less"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm underline decoration-2 decoration-[#ff5478]
                                  underline-offset-[3px] font-medium "
              >
                schedule a call
              </a>{' '}
              with us.
            </p>
          </div>
        }
      />

      <div className="mt-5 space-y-5">
        <TextDescInput
          read={true}
          type="text"
          tooltip="This is the IP of your database"
          header="Database host name"
          name="db_host_name"
          readValue={activeDatabase.server}
          withtooltip={false}
        />

        <TextDescInput
          read={true}
          type="text"
          header="Database name"
          name="db_name"
          readValue={activeDatabase.database}
          withtooltip={false}
        />
        <TextDescInput
          type="text"
          read={true}
          ttmargin="150%"
          header="Port"
          name="port"
          readValue={activeDatabase.port}
          withtooltip={false}
          classes="-left-16"
        />
        <TextDescInput
          type="text"
          read={true}
          header="Username"
          name="username"
          readValue={activeDatabase.username}
          withtooltip={false}
          classes="-left-16"
        />
        <NonEditablePW
          read={true}
          header="Password"
          readValue={activeDatabase.password}
          withtooltip="true"
          tooltip="Only database owners can see the password."
        />
      </div>
    </div>
  );
};

ViewCredentials.propTypes = {
  database: PropTypes.object,
};

export default ViewCredentials;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Float } from '@headlessui-float/react';
// import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const DropdownInput = ({ defaultSelected, onChange: onChangeCallback, ...props }) => {
  const [selected, setSelected] = useState(defaultSelected);
  const onChange = (item) => {
    setSelected(item);
    onChangeCallback(item.value, item);
  };

  const found = props.items.find((obj) => {
    return obj.value === defaultSelected;
  });

  return (
    <RegularInputTemplate {...props}>
      <Listbox value={selected} onChange={onChange}>
        <Float
          as="div"
          className="relative"
          placement="bottom-end"
          floatingAs={Fragment}
          offset={2}
          strategy="fixed"
          adaptiveWidth
        >
          <Listbox.Button className=" w-full cursor-default rounded-md bg-zinc-50 py-2 pl-3 pr-10 text-left shadow-xs border focus:ring-[#5DA6FC] focus:border-[#5DA6FC] border-zinc-300 text-zinc-700 text-sm dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:text-zinc-50">
            <span className="block truncate">
              {found?.label || selected?.label || props.placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-5 w-5 text-zinc-500 dark:text-zinc-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
            </span>
          </Listbox.Button>

          <Listbox.Options className="overflow-scroll min-w-[300px] w-auto border border-zinc-200 dark:border-zinc-700  dark:bg-zinc-700 mt-1 max-h-60 rounded-md bg-white  shadow-lg text-sm">
            {props.items.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  `relative cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-700 select-none z-50 py-3 pl-4 bg-white pr-4 dark:bg-zinc-700 ${active
                    ? 'bg-zinc-100 dark:bg-zinc-800/50 dark:text-zinc-50 text-zinc-700 font-bold'
                    : 'text-zinc-600 dark:text-zinc-100'
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {item.label === 'Projects' ? 'Shared' : item.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Float>
      </Listbox>
    </RegularInputTemplate>
  );
};

DropdownInput.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultSelected: PropTypes.node,
  items: PropTypes.array,
  onChange: PropTypes.func,
};

DropdownInput.defaultProps = {
  header: '',
  subheader: '',
  placeholder: '',
  defaultSelected: {},
  items: [],
  onChange: () => null,
};

export default DropdownInput;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    changeUserCreatorService,
    deleteUserService,
} from 'services/user';

// First, create the thunk
export const changeUserCreatorAction = createAsyncThunk('user/makeCreator', async ({userId, data}, { rejectWithValue }) => {
    try {
        const response = await changeUserCreatorService(userId, data);
        return response;
      } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err)
      }
});

export const deleteUserAction = createAsyncThunk('user/delete', async ({userId}, { rejectWithValue }) => {
  try {
      const response = await deleteUserService(userId);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
});
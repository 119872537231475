export function searchTree(folder, folderId) {
  if (folder.id === folderId) {
    // start generating breadcrumbs where the folder is found
    return { ...folder, breadcrumbs: [folder] };
  } else if (folder.children.length) {
    var i;
    var result = null;
    for (i = 0; result === null && i < folder.children.length; i++) {
      result = searchTree(folder.children[i], folderId);
    }
    // add parent folder to breadcrumb list
    if (result) {
      result.breadcrumbs.push(folder);
    }
    return result;
  }
  return null;
}

export default function searchFolder(folders, folderId) {
  return searchTree(
    {
      id: null,
      children: folders,
    },
    folderId
  );
}

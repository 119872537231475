import Axios, {
  jsonHeader
} from './axios';

export const getAllModelsService = () => {
  return Axios('api/datamodels').then((res) => {
    return res?.data;
  });
}

export const executeModelService = (id) => {
  return Axios.get(`api/datamodels/${id}/execute`).then((res) => {
    return res?.data;
  });
};

export const deleteModelService = (modelId) => {
  return Axios.delete(`api/datamodels/${modelId}`).then((res) => {
    return res.data;
  });
};

export const getModelService = (modelId,versionId) => {
  const version = versionId ? `${versionId}` : '';
  return Axios.get(`api/datamodels/${modelId}/${version}`).then((res) => {
    return res.data;
  });
};

export const createModelService = (data) => {
  return Axios.post(`api/datamodels/`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const updateModelService = (modelId, data) => {
  return Axios.post(`api/datamodels/${modelId}`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const duplicateModelService = (modelId) => {
  return Axios.get(`api/datamodels/${modelId}/duplicate`).then((res) => {
    return res.data;
  });
};

export const saveModelVersionService = (modelId, data) => {
  return Axios.post(`api/datamodels/${modelId}/version`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const autoSaveModelService = (sessionId, modelId, data) => {
  return Axios.post(`api/datamodels/${sessionId}/${modelId}/autosave`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const getModelVersionsService = (modelId) => {
  return Axios.get(`api/datamodels/${modelId}/versions`).then((res) => {
    return res.data;
  });
};

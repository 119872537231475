import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TemplatePopover from 'components/Buttons/TemplatePopover';
import { ChevronDownIcon } from '@radix-ui/react-icons';

const ShareWith = ({ rulesets }) => {

    const { groups } = useSelector(({ platform }) => {
        return {
            groups: platform.groups,
        };
    });



    return (
        <TemplatePopover
            btnText={
                
                <div  className='gap-1 flex flex-row items-center justify-center'>
                    <p>
                        {rulesets.length == 0 ? "Private" : rulesets.length}
                    </p>
                    {rulesets.length > 0 && <ChevronDownIcon className='text-zinc-400 h-auto w-3' />}
                    </div>
                    } btnClasses={`!z-100 w-fit ${rulesets.length > 0 && "hover:bg-zinc-300 hover:cursor-pointer"} cursor-default rounded flex flex-row items-center justify-center px-2 py-1 gap-0.5 text-xs text-zinc-600 dark:text-zinc-300`}>
            {rulesets.length > 0 &&
                <div className='bg-zinc-100 px-3 py-3 flex flex-col gap-2 rounded border border-zinc-200 shadow-lg shadow-zinc-600/10'>
                    {rulesets.map((group) => {
                        return (
                            <div className="text-xs" key={group?.entity?.title}>
                                <p className='text-zinc-600 font-medium'>{group?.entity?.title}: <span className='font-normal'>{group?.ability_set?.name}</span></p>
                                <p className='text-zinc-400'>{groups.find((groupOrg) => groupOrg.id == group?.entity?.id)?.users.length} members</p>
                            </div>
                        );
                    })}
                </div>}
        </TemplatePopover>
    );
};

ShareWith.propTypes = {
    rulesets: PropTypes.array,
};

ShareWith.defaultProps = {
    rulesets: [],
};

export default ShareWith;

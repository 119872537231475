import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import DropdownInput from 'components/Inputs/DropdownInput';
import ModalHeader from 'components/Layout/ModalHeader';
import TextDescInput from 'components/Inputs/TextDesc';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';

const FolderModal = ({ onClose, onSubmit, folders, activFolder, parentFolder }) => {
  const [name, setName] = useState(activFolder?.name);
  const [parent, setParent] = useState(parentFolder);

  return (
    <div className="p-8">
      <ModalHeader
        header="Edit Folder"
        content={
          <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
            Rename and move your folder
          </p>
        }
      />
      <div>
        <div className="py-5 space-y-5">
          <TextDescInput
            header="Rename folder"
            placeholder={activFolder?.name}
            type="text"
            name="foldername"
            onChange={setName}
          />
          {folders && (
            <DropdownInput
              header="Move folder"
              items={[{ value: 0, label: 'Workspace root' }, ...folders]}
              defaultSelected={parent || { value: 0, label: 'Workspace root' }}
              onChange={(val, item) => {
                setParent(item)
              }}
            />
          )}
        </div>
      </div>
      <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-700 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={onClose} />
          <PrimaryBtn
            text="Update"
            onClick={() => {
              onSubmit({
                ...activFolder,
                name,
                parent_id: parent?.value,
              });
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

FolderModal.propTypes = {
  activFolder: PropTypes.shape({
    name: PropTypes.string,
  }),
  buttonText: PropTypes.string,
  folders: PropTypes.arrayOf(PropTypes.object),
  inputProps: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  parentFolder: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

FolderModal.defaultProps = {
  activFolder: {},
  buttonText: 'Save',
  folders: null,
  inputProps: {},
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: false,
  parentFolder: null,
};

export default FolderModal;

import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({ header, button }) => {
  return (
    <div>
      <div className="relative flex-1 flex flex-row justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-bold text-zinc-700 dark:text-zinc-50">{header}</h3>
        </div>
        <div className="mt-3 mb-[1px] sm:mt-0 sm:ml-4">{button}</div>
      </div>
      <div className="mt-2 relative flex-1 flex flex-row">
        <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
        <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
      </div>
    </div>
  );
};

Heading.propTypes = {
  button: PropTypes.element,
  desc: PropTypes.string,
  header: PropTypes.string.isRequired,
};

export default Heading;

import { MinusCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Combobox2 from 'components/Inputs/Combobox2';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import ModellingConfigBtn from 'components/Buttons/ModellingConfigBtn';
import BasicDropdown from './BasicDropdown';

function ModellingFilter({ rightOptions, ...props }) {
  const [inputValues, setInputValues] = useState(
    props.defaultSelected
  );

  const completions = props.options.map((option) => {
    return { label: option.value, apply: `[${option.value}]`, detail: "(" + option?.type?.Name + ")" };
  });


  function addAND() {
    setInputValues([...inputValues, { column: '', condition: '', value: '', label: 'AND' }]);
  }
  function addOR() {
    setInputValues([...inputValues, { column: '', condition: '', value: '', label: 'OR' }]);
  }

  function removeItem(index) {
    const newInputValues = inputValues.filter((value, i) => i !== index);
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  function handleInputChange(event, groupIndex, inputType) {
    const newInputValues = [...inputValues];
    newInputValues[groupIndex][inputType] = event;
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }


  const found = (value) => {
    return rightOptions.find((obj) => {
      return obj.value === value
    });
  };

  return (
    <ModelingInputTemplate {...props}>
      <div className="flex w-full flex-col justify-center items-center">
        <div className="w-full">
          {inputValues.map((value, index) => (
            <div key={index} className={`relative w-full`}>
              {value.label === 'OR' && (
                <div className="mx-2 relative bg-zinc-500 h-[1px] flex items-center justify-center my-4">
                  <p className="absolute bg-white dark:bg-zinc-900 text-zinc-500 rounded-xl text-[10px] font-bold px-3">
                    OR
                  </p>
                </div>
              )}
              {value.label === 'AND' && (
                <p className="text-zinc-500 ml-1 my-1 text-center text-[10px] font-bold">
                  {value.label}
                </p>
              )}

              <div className={`grid grid-cols-2 gap-2 p-1 w-[95%]}`}>
                <BasicDropdown items={props.options} placeholder="Select column" defaultSelected={value.column} show={value.column} onChange={(event) => handleInputChange(event, index, 'column')} />
                <BasicDropdown items={rightOptions} placeholder="Select condition" defaultSelected={value.condition} show={found(value.condition)?.label} onChange={(event) => handleInputChange(event, index, 'condition')} />
                <div className="col-span-2">
                  <Combobox2
                    key={`${index}`}
                    index={`${index}`}
                    completions={completions}
                    onChangeCallback={(id, event) => handleInputChange(event, index, 'value')}
                    initialValue={value.value}
                  />
                </div>
              </div>
              {index > 0 && (
                <button className="absolute -right-3.5 top-[44%]" onClick={() => removeItem(index)}>
                  <MinusCircleIcon className="w-4 h-4 duration-150 dark:hover:text-zinc-400 dark:text-zinc-300 text-zinc-700 hover:text-zinc-900" />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="justify-center items-center inline-flex mt-2 gap-2">
          <ModellingConfigBtn onClick={addAND} buttonText="+ AND clause" />
          <ModellingConfigBtn onClick={addOR} buttonText="+ OR clause" />
        </div>
      </div>
    </ModelingInputTemplate>
  );
}

ModellingFilter.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.string,
  onChange: PropTypes.func,
  rightOptions: PropTypes.array,
  buttonText: PropTypes.string,
  defaultSelected: PropTypes.array,
};

export default ModellingFilter;

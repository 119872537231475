import React, { useState } from 'react';
import { forgotPasswordAction } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

export default function ForgotPassword() {
  const [state, setState] = useState({
    email: '',
  });

  const { register, handleSubmit, formState, setError, clearErrors } = useForm({
    mode: 'all',
  });

  const onChange = (e) => {
    clearErrors();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  const onClick = async () => {
    const response = await dispatch(forgotPasswordAction(state?.email));

    if (response.type == 'user/forgotpassword/rejected') {
      setError('backendError', {
        type: 'custom',
        ref: 'email',
        message: response.payload?.code?.data?.error,
      });
    } else {
      setTimeout(() => {
        window.location.pathname = '/login';
      }, 2000);
    }
  };

  return (
    <>
      <div className="min-h-full h-screen bg-[#13151D] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-screen-md border-[0.5px] border-zinc-600 bg-gradient-to-br from-slate-800 via-transparent to-zinc-900 px-28 py-16 rounded-2xl space-y-8">
          <div>
            <img
              className="mx-auto h-20 w-auto opacity-50"
              src="https://less-logos.s3.eu-central-1.amazonaws.com/random/logoLight.svg"
              alt="Logo"
            />
            <h2 className="mt-12 -mb-3 text-center text-2xl font-extrabold text-slate-300">
              Forgot password
            </h2>
            <p className="text-slate-400 font-medium text-center mt-5 text-sm">
              Enter your email below and we will send you a temporary code
            </p>
          </div>
          <div id="request">
            <form onSubmit={handleSubmit(() => onClick())}>
              <input type="hidden" name="remember" defaultValue="true" />

              <label htmlFor="email-address" className="block text-sm font-medium text-slate-300">
                Email address
              </label>
              <input
                {...register('email', {
                  required: 'Email address is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter a valid email',
                  },
                })}
                id="email-address"
                name="email"
                autoComplete={'off'}
                onChange={onChange}
                placeholder="your@email.here"
                disabled={formState.isSubmitting}
                className="mt-1 appearance-none text-slate-200 bg-[#13151D] block w-full px-3 py-2 border !border-slate-600 rounded-md shadow-sm placeholder-slate-500 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
              />
              {formState.errors.email && (
                <p className="text-sm rounded-lg mt-4 py-3 px-4 border-red-600/60 border font-medium bg-red-200 text-red-800">
                  {formState.errors.email?.message}
                </p>
              )}
              {formState.errors.backendError && (
                <p className="text-sm rounded-lg mt-4 py-3 px-4  border-red-600/60 border font-medium bg-red-200 text-red-800">
                  {formState.errors.backendError?.message}
                </p>
              )}
              {formState.isSubmitSuccessful && (
                <p className="text-sm rounded-lg mt-4 py-3 px-4  font-medium bg-green-200 text-green-800">
                  Check your email for a password reset link. You will be redirected to the Login
                  screen shortly.
                </p>
              )}
              <div className="mt-8 flex flex-col flex-1 items-center w-5/12 justify-center space-y-2 mx-auto">
                <button
                  type="submit"
                  className="group w-full mb-2 relative inline-flex items-center justify-center rounded-full p-[1px] font-normal"
                >
                  {' '}
                  <div className="absolute h-full shadow-slate-800 shadow-lg w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478] group-hover:bg-gradient-to-br group-hover:from-gray-200 group-hover:to-gray-200"></div>
                  <div className="relative inline-flex w-full justify-center items-center rounded-full bg-zinc-900 px-5 py-2 transition-all ease-out delay-50 duration-300  group-hover:bg-gray-200">
                    <p className="text-center text-gray-100 font-semibold text-base group-hover:text-[#13151D]">
                      Request code
                    </p>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

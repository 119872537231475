import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  getAllModelsService,
  executeModelService,
  deleteModelService,
  getModelService,
  createModelService,
  updateModelService,
  saveModelVersionService,
  duplicateModelService,
  getModelVersionsService,
  autoSaveModelService
} from 'services/model';

// First, create the thunk
export const getAllModelsAction = createAsyncThunk('models/fetch', async () => {
  const response = await getAllModelsService();
  return response;
});

export const executeModelAction = createAsyncThunk('models/execute/model', async (modelId) => {
  const response = await executeModelService(modelId);
  return response;
});

export const deleteModelAction = createAsyncThunk('model/delete', async (modelId, { rejectWithValue }) => {
  try {
    const response = await deleteModelService(modelId);
  return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const getModelAction = createAsyncThunk('models/fetch/model', async ({modelId, versionId}) => {
  const response = await getModelService(modelId, versionId);
  return response.data;
});

export const duplicateModelAction = createAsyncThunk('models/duplicate/model', async (modelId) => {
  const response = await duplicateModelService(modelId);
  return response;
});

export const createModelAction = createAsyncThunk('models/create', async (data, { rejectWithValue }) => {
  
  try {

const response = await createModelService(data);
return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const updateModelAction = createAsyncThunk('models/update', async ({
  data,
  modelId
}, { rejectWithValue }) => {
  try {
    const response = await updateModelService(modelId, data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const saveModelVersionAction = createAsyncThunk('models/version', async ({
  data,
  modelId
}, { rejectWithValue }) => {
  try {
    const response = await saveModelVersionService(modelId, data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const autoSaveModelAction = createAsyncThunk('models/autosave', async ({
  sessionId,
  modelId,
  data
}, { rejectWithValue }) => {
  try {
    const response = await autoSaveModelService(sessionId, modelId, data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
  
});

export const getModelVersionsAction = createAsyncThunk(
  'datamodel/versions',
  async (modelId) => {
    const response = await getModelVersionsService(modelId);
    return response;
  }
);

// Then, handle actions in your reducer
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import notification from 'middlewares/notification';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(notification),
});

export default store;

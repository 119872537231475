import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import classNames from 'classnames';

const ParentFolder = ({ id, name, onClick, selected, children, level, openParent }) => {
  const inActiveStyles =
    'dark:text-zinc-200  dark:hover:bg-zinc-700/50 text-zinc-500 group hover:bg-zinc-100 w-full flex items-center pr-2 py-1.5 text-left text-xs font-medium rounded';
  const activeStyles =
    'dark:text-zinc-200  text-zinc-500 group w-full flex items-center pr-2 py-1.5 text-left text-xs font-medium rounded bg-zinc-200 text-zinc-700 dark:text-zinc-50 dark:bg-zinc-700';

  return (
    <Disclosure
      as="div"
      key={id + openParent}
      defaultOpen={openParent}
      className={`space-y-1 my-0.5 pl-${level}`}
    >
      {({ open }) => (
        <>
          <button
            className={classNames({
              [inActiveStyles]: id !== selected,
              [activeStyles]: id === selected,
            })}
            onClick={onClick}
            role="open"
            id={id}
          >
            <Disclosure.Button role="expand" className=" ml-1 mr-0.5">
              <ChevronRightIcon
                className={`${open ? 'rotate-90 transform' : ''
                  } h-4 w-4 text-zinc-500 dark:text-zinc-300 hover:bg-zinc-300 dark:hover:bg-zinc-600  rounded`}
              />
            </Disclosure.Button>
            {name}
          </button>
          <Disclosure.Panel unmount={false} className="space-y-1">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

ParentFolder.propTypes = {
  children: PropTypes.any,
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.number,
  level: PropTypes.number,
  openParent: PropTypes.bool,
};

export default ParentFolder;

import Axios from './axios';

export const addNewPermissionService = (assetId, data) => {
  return Axios.post(`api/assetpermissions/add/${assetId}`, data).then((res) => {
    return res.data;
  });
};

export const deletePermissionService = (permissionId) => {
  return Axios.post(`api/assetpermissions/delete/${permissionId}`).then((res) => {
    return res.data;
  });
};

import React, { useEffect } from 'react';
import { NodeResizeControl } from '@reactflow/node-resizer';
import PropTypes from 'prop-types';
import '@reactflow/node-resizer/dist/style.css';
import ContentEditable from 'react-contenteditable';
import { BsArrowsMove } from 'react-icons/bs';
import { ArrowDownIcon } from '@heroicons/react/outline';
// import { Handle, Position, useUpdateNodeInternals } from 'reactflow';

import ColorPicker from './NodeComponents/ColorPicker';

const ResizeRotateNode = ({ data }) => {
  const [text, setText] = React.useState(
    data.configuration?.comment ||
    `Click here to add your comment or documentation`
  );
  const [color, setColor] = React.useState(data.configuration?.color || '#ffffff');
  const [color2, setColor2] = React.useState(data.configuration?.color2 || '#27272A');
  const [editableDisabled, setEditableDisabled] = React.useState(true);
  const [fullDisabled, setFullDisabled] = React.useState(false);

  useEffect(() => {
    //Check if should be disabled
    if (localStorage.getItem('noModelling') === 'true') {
      setFullDisabled(true);
    }

  }, []);


  const handleChange2 = (evt) => {
    data.configuration['comment'] = evt.target.value;
    setText(evt.target.value);
  };

  useEffect(() => {
    data.configuration['color'] = color;
    data.configuration['color2'] = color2;
  }, [color, color2]);


  const controlStyle = {
    background: 'none',
    border: 'none',
  };

  const onBlurFunction = () => {
    setEditableDisabled(true)
    //Makes sure it does not appear as anchorNode afterwards
    document.getSelection().removeAllRanges()
  }

  return (
    <>
      <div className="flex p-[2px] min-w-full min-h-full overflow-hidden webkit-fillable rounded-lg bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]">
        <div
          style={{ backgroundColor: color }}
          className={`w-full p-3 rounded-md ${color ? `bg-[${color}]` : 'bg-white dark:bg-zinc-700'
            } overflow-hidden`}
        >
          <div className="flex items-center justify-center p-1 absolute mx-auto left-0 right-0 -top-7 h-6 w-6 bg-zinc-400 hover:bg-zinc-500 rounded-md hover:border-zinc-400 border-zinc-300 border-2 hover:shadow-md duration-300 hover:cursor-move">
            <BsArrowsMove className="stroke-[0.5px] text-zinc-50 " />
          </div>
          <NodeResizeControl minWidth={150} minHeight={40} style={controlStyle}>
            <ArrowDownIcon className="bg-none stroke-[3px] h-3 w-3 bottom-[5px] right-[5px] absolute text-zinc-500/80 dark:text-zinc-200 rotate-[320deg]" />
          </NodeResizeControl>

          <div className="inline-flex rounded-t-md justify-center gap-3  items-center top-0.5 -ml-3 w-[calc(100%-4px)] absolute  h-9 border-b border-zinc-200 ">
            <ColorPicker color={color2} setColor={setColor2} text={'Tx'} />
            <ColorPicker color={color} setColor={setColor} text={'Bg'} />
          </div>
          <div className="bg-[#ffffff] hidden" />
          <ContentEditable
            onClick={() => { setEditableDisabled(false) }}
            disabled={editableDisabled || fullDisabled}
            onBlur={onBlurFunction}
            style={{ color: color2 }}
            className={`select-none nodrag min-h-full [&>*]:h-min inline-block mt-8 min-w-full text-xs max-w-full focus:!outline-0 focus:!ring-0 outline-transparent font-normal w-max ${color2 ? `text-[${color2}]` : 'text-zinc-800 dark:text-zinc-50'
              } text-center canvasActionText`}
            html={data?.label || text}
            onChange={handleChange2}
          />
        </div>
      </div>
    </>
  );
};

ResizeRotateNode.propTypes = {
  data: PropTypes.object,
};

export default ResizeRotateNode;
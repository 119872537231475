import PropTypes from 'prop-types';
import React, { useState } from 'react';
// components
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import DropdownInput from 'components/Inputs/DropdownInput';
import ModalHeader from 'components/Layout/ModalHeader';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';

const MoveAssetModal = ({ activeFolder, folders, onSubmit, onClose }) => {
  const [value, setValue] = useState({ value: activeFolder?.id, label: activeFolder?.name });

  return (
    <div className="p-8">
      <ModalHeader
        header="Move model"
        content={
          <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
            Choose which folder you want to move your model to
          </p>
        }
      />
      <div className="my-5">
        <DropdownInput
          header="Select Folder"
          items={[{ label: 'Workspace root', value: 0 }, ...folders]}
          defaultSelected={value}
          onChange={setValue}
        />
      </div>
      <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-700 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={onClose} />
          <PrimaryBtn
            text="Move"
            onClick={() => {
              onSubmit(value);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

MoveAssetModal.propTypes = {
  activeFolder: PropTypes.object.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
};

MoveAssetModal.defaultProps = {
  folders: [],
  onClose: () => null,
  onSubmit: () => null,
  open: false,
};

export default MoveAssetModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';
import { useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/solid';


const FileInput = ({
  value: defaultValue,
  onChange: onChangeCallback,
  optional,
  name,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  const handleChange = (newFiles) => {
    const mergedFiles = [...files, ...newFiles];
    setFiles(mergedFiles);
    onChangeCallback([...mergedFiles]);
  };


  useEffect(() => {
    const existingFiles = defaultValue.map(element => {
      return new File([], element, {
        type: "application/placeholder",
      })
    });
    setFiles(existingFiles);
    onChangeCallback(existingFiles);
  }, [])

  const deleteFile = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);
    onChangeCallback(newFiles);
  }

  const fileTypes = ['xlsx', 'csv', 'xls'];

  return (
    <RegularInputTemplate {...props} optional={optional}>
      <div className="relative w-full">
        <FileUploader multiple={true} handleChange={handleChange} name={name} fileTypes={fileTypes}>
          <p className="text-zinc-500 dark:text-zinc-200  border-2 border-zinc-200 dark:border-zinc-600 p-3 rounded border-dotted text-sm">
            Click here or drag files to upload
          </p>
        </FileUploader>
        <p className="mt-2 text-[13px] font-light text-zinc-400 dark:text-zinc-300">
          Uploaded files:
          
        </p>
        <ul className="text-[13px] font-light text-zinc-400 dark:text-zinc-300 max-w-md list-none list-inside ">
            {[...files].map((file) => <div className='inline-flex py-1  justify-center items-center ' key={file.name}><button className='mr-3 p-1  rounded hover:bg-rose-200 hover:text-rose-400  duration-150 transition-all' onClick={() => { deleteFile(file) }}><TrashIcon className='h-4 w-4' /></button><li  >{file.name} </li></div>)}
          </ul>
      </div>
    </RegularInputTemplate>
  );
};

FileInput.propTypes = {
  optional: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  minutes: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  type: '',
  placeholder: '',
  name: '',
  minutes: '',
  value: [],
  onChange: () => null,
};
export default FileInput;

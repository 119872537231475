import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  userLoginService,
  userProfileService,
  refreshTokenService,
  forgotPasswordService,
  resetPasswordService,
  getUsersService,
  getGroupsService,
  sendInviteService,
  acceptInviteService
} from 'services/auth';

export const userLoginAction = createAsyncThunk('user/login', async ({
  username,
  password
}, {
  rejectWithValue
}) => {

  try {
    const response = await userLoginService({
      username,
      password
    });
    return response;
  } catch (err) {
    return rejectWithValue(err)
  }
});

export const userProfileAction = createAsyncThunk('user/profile', async () => {
  const response = await userProfileService();
  return response;
});

export const refreshTokenAction = createAsyncThunk('user/token', async () => {
  const response = await refreshTokenService();
  return response;
});


export const forgotPasswordAction = createAsyncThunk('user/forgotpassword', async (email, {
  rejectWithValue
}) => {
  try {
    const response = await forgotPasswordService(email);
    return response;
  } catch (err) {
    console.log("ddd", err);
    return rejectWithValue(err)
  }
});

export const resetPasswordAction = createAsyncThunk('user/resetpassword', async (data, {
  rejectWithValue
}) => {
  try {
    const response = await resetPasswordService(data);
    return response;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err)
  }
});

export const getUsersAction = createAsyncThunk('auth/users', async () => {
  const response = await getUsersService();
  return response;
});

export const getGroupsAction = createAsyncThunk('auth/groups', async () => {
  const response = await getGroupsService();
  return response;
});

export const sendInviteAction = createAsyncThunk('auth/invite', async (data, {
  rejectWithValue
}) => {
  try {
    const response = await sendInviteService(data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const acceptInviteAction = createAsyncThunk('auth/invite/accept', async (data, {
  rejectWithValue
}) => {
  try {
    const response = await acceptInviteService(data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});
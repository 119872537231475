import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllTablesService,
  getTablesService,
  metadataTableService,
  previewTableService,
  deleteTableService,
  exportTableService,
} from 'services/table';

// First, create the thunk
export const getAllTablesAction = createAsyncThunk('tables/fetch', async () => {
  const response = await getAllTablesService();
  return response;
});

export const getTablesAction = createAsyncThunk('tables/get', async ({ object_type, object_id }) => {
  const response = await getTablesService(object_type, object_id);
  return response;
});


export const metadataTableAction = createAsyncThunk('tables/metadata', async ({ object_type, object_id, table_name }, { rejectWithValue }) => {
  try {
    const response = await metadataTableService(object_type, object_id, table_name);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

export const previewTableAction = createAsyncThunk('tables/preview', async ({ object_type, object_id, table_name }, { rejectWithValue }) => {
  try {
    const response = await previewTableService(object_type, object_id, table_name);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
  
});

export const deleteTableAction = createAsyncThunk('tables/delete', async ({object_type, object_id, table_name}, { rejectWithValue }) => {
  try {
    const response = await deleteTableService(object_type, object_id, table_name);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
  
});

export const exportTableAction = createAsyncThunk('tables/export', async ({object_type, object_id, table_name, format}, { rejectWithValue }) => {
  try {
    const response = await exportTableService(object_type, object_id, table_name, format);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

// Then, handle actions in your reducer

import React from 'react';
import PropTypes from 'prop-types';

//import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import { InformationCircleIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const ModelingInputTemplate = (props) => {
  return (
    <div className="space-y-2.5">
      <div
        className={`flex flex-row justify-between ${
          props.optional === false ? 'items-center' : 'items-baseline'
        }`}
      >
        <div className="flex flex-row gap-2">
          <label
            htmlFor={props.id}
            className="text-xs font-medium text-zinc-700 dark:text-zinc-200"
          >
            {props.header}
          </label>
          <div className={`flex flex-row ${props.withtooltip === true ? '' : 'hidden'}`}>
            <RadixTooltip tooltipText={props.tooltip}>
              <InformationCircleIcon
                className={`h-4 w-4 text-zinc-700 dark:text-zinc-200 opacity-60`}
                aria-hidden="true"
              />
            </RadixTooltip>
          </div>
        </div>
        <div className={`${props.optional === false ? 'hidden' : ''}  ${props.classes}`}>
          <span className="text-xs text-zinc-500 dark:text-zinc-200 opacity-60">Optional</span>
        </div>
      </div>
      <div className={`${props.childConClasses} px-[0.5px]`}>{props.children}</div>
    </div>
  );
};

ModelingInputTemplate.propTypes = {
  header: PropTypes.string.isRequired,
  withtooltip: PropTypes.bool,
  optional: PropTypes.bool,
  classes: PropTypes.string,
  tooltip: PropTypes.string,
  ttmargin: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.object.isRequired,
  childConClasses: PropTypes.string
};

ModelingInputTemplate.defaultProps = {
  header: '',
  withtooltip: false,
  classes: '',
  optional: false,
  tooltip: '',
  ttmargin: '150%',
  name: '',
  children: {},
};

export default ModelingInputTemplate;

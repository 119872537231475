import { MinusCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
import ModellingConfigBtn from 'components/Buttons/ModellingConfigBtn';
//import SingleSelect from './SingleSelect';
import BasicDropdown from './BasicDropdown';

function TwoRowGroup({ leftOptions, rightOptions, leftKeyName, rightKeyName, ...props }) {
  const [inputValues, setInputValues] = useState(props.defaultSelected);

  function addRow() {
    setInputValues([...inputValues, { [leftKeyName]: '', [rightKeyName]: '', label: '' }]);
  }

  function removeItem(index) {
    const newInputValues = inputValues.filter((value, i) => i !== index);
    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }

  function handleInputChange(event, groupIndex, inputType) {
    const newInputValues = [...inputValues];
    newInputValues[groupIndex][inputType] = event;

    setInputValues(newInputValues);
    props.onChange(newInputValues);
  }


    const found = (value) => {
    return rightOptions.find((obj) => {
      return obj.value === value
    });
  };

  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col w-full">
        <div>
          <div className="grid grid-cols-2 gap-1 justify-items-start">
            <p className="font-medium w-[94%] text-center border-b pb-0.5 mb-2 text-[10px] border-zinc-100 text-zinc-500 dark:text-zinc-200">
              {props.leftHeader}
            </p>
            <p className="w-[94%] text-center border-b pb-0.5 mb-2 text-[10px] text-zinc-500 border-zinc-100 dark:text-zinc-200">
              {props.rightHeader}
            </p>
          </div>
          {inputValues.map((value, index) => {
            return (
              <div key={index} className="relative">
                <div className="grid grid-cols-2 gap-1 mb-3">
                  <BasicDropdown
                    classes={'!w-[95%]'}
                    items={leftOptions}
                    placeholder={props.placeholder}
                    onChange={(event) => handleInputChange(event, index, leftKeyName)}
                    defaultSelected={value[leftKeyName]}
                    show={value[leftKeyName]}
                  />
                  <BasicDropdown
                    classes={'!w-[95%]'}
                    items={rightOptions}
                    placeholder={props.placeholder}
                    onChange={(event) => handleInputChange(event, index, rightKeyName)}
                    defaultSelected={value[rightKeyName]}
                    show={found(value[rightKeyName])?.label}
                  />
                  {/* <SingleSelect
                    classes={'!w-[95%] -mt-[9px]'}
                    items={leftOptions}
                    placeholder={props.placeholder}
                    onChange={(event) => handleInputChange(event, index, leftKeyName)}
                    defaultSelected={value[leftKeyName]}
                  />
                  <SingleSelect
                    classes={'!w-[95%] -mt-[9px]'}
                    items={rightOptions}
                    placeholder={props.placeholder}
                    onChange={(event) => handleInputChange(event, index, rightKeyName)}
                    defaultSelected={value[rightKeyName]}
                  /> */}
                </div>
                {index > 0 && (
                  <button
                    className="absolute -right-3 top-[27.5%]"
                    onClick={() => removeItem(index)}
                  >
                    <MinusCircleIcon className="w-4 h-4 duration-150 dark:hover:text-zinc-400 dark:text-zinc-300 text-zinc-700 hover:text-zinc-900" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        {props.btnOn === true && (
          <div className="justify-center items-center inline-flex mt-2">
            <ModellingConfigBtn onClick={addRow} buttonText={props.buttonText} />
          </div>
        )}
      </div>
    </ModelingInputTemplate>
  );
}

TwoRowGroup.propTypes = {
  placeholder: PropTypes.string,
  leftOptions: PropTypes.string,
  onChange: PropTypes.func,
  rightOptions: PropTypes.array,
  buttonText: PropTypes.string,
  defaultSelected: PropTypes.array,
  leftHeader: PropTypes.string,
  rightHeader: PropTypes.string,
  btnOn: PropTypes.boolean,
  leftKeyName: PropTypes.string,
  rightKeyName: PropTypes.string,
};

TwoRowGroup.defaultProps = {
  btnOn: true,
  leftKeyName: 'left',
  rightKeyName: 'right',
};

export default TwoRowGroup;

import { ArrowUpIcon, ArrowDownIcon, DownloadIcon } from '@heroicons/react/solid';

import FormatIcon from 'components/Badges/FormatIcon';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import { FaUndo, FaRedo, FaStickyNote } from 'react-icons/fa';

import RadixTooltip from 'components/Tooltips/RadixTooltip';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const DataTable = (props) => {

  const { data, metadata } = props.input;
  let columns = [];
  if (props?.input?.metadata?.columns) {
    columns = Object.keys(props.input.metadata.columns)
  } else if (data?.length > 0) {
    columns = Object.keys(data[0]);
  } else {
    columns = []
  }

  const minValue = 200;
  const maxValue = 1000;
  const increment = 200;

  const [value, setValue] = useState(minValue);

  const increaseValue = () => {
    if (value + increment <= maxValue) {
      setValue(value + increment);
    } else {
      setValue(maxValue);
    }
  };

  const decreaseValue = () => {
    if (value - increment >= minValue) {
      setValue(value - increment);
    } else {
      setValue(minValue);
    }
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <div className={`relative w-[95vw] min-h-[200px] flex flex-col `}>
      <div className="hidden h-[200px]" />
      <div className="hidden h-[400px]" />
      <div className="hidden h-[600px]" />
      <div className="hidden h-[800px]" />
      <div className="hidden h-[1000px]" />
      <div className="hidden max-h-[600px]" />
      <div
        className={`${props.classes} flex flex-row transform duration-700  ${props.expand === true ? `max-h-[600px] h-[${value}px] 2xl:max-h-[1000px]` : ''
          }`}
      >
        {props.sidebar === true && (
          <div className="border border-zinc-200 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-800/50 rounded-lg mr-3 p-1">
            <div className="sticky flex flex-col top-0 w-max rounded-lg z-50">
              <RadixTooltip tooltipText="Download .csv">
                <OnlyIconBtn
                  onClick={props.exportActiveData}
                  icon={<DownloadIcon className="h-4 w-4" />}
                  loader={true}
                />
              </RadixTooltip>
              <RadixTooltip tooltipText="Undo">
                <OnlyIconBtn
                  useKeydown={true}
                  keyDown={90}
                  keyDownCondition="(event.metaKey || event.ctrlKey) && !event.shiftKey"
                  onClick={props.undo}
                  icon={<FaUndo className="m-1 h-[12px] w-[12px]" />}
                />
              </RadixTooltip>
              <RadixTooltip tooltipText="Redo">
                <OnlyIconBtn
                  onClick={props.redo}
                  useKeydown={true}
                  keyDown={90}
                  keyDownCondition="(event.metaKey || event.ctrlKey) && event.shiftKey"
                  icon={<FaRedo className="m-1 h-[12px] w-[12px]" />}
                />
              </RadixTooltip>
              <RadixTooltip tooltipText="Toogle Annotations">
                <OnlyIconBtn
                  onClick={props.annotationHandler}
                  icon={<FaStickyNote className="m-1 h-[12px] w-[12px]" />}
                />
              </RadixTooltip>
            </div>
          </div>
        )}
        <div
          className={`${props.expand === true ? ' rounded-b-lg rounded-tr-lg' : 'rounded-lg'
            } inline-block w-full align-middle overflow-scroll bg-zinc-50 border border-zinc-200 dark:border-zinc-600 dark:bg-zinc-800`}
        >
          {props.expand && (
            <div className="left-0 z-10 -top-[29px] absolute flex flex-row gap-3">
              <RadixTooltip tooltipText="Expand data table">
                <button
                  onClick={increaseValue}
                  className=" w-auto h-auto p-2 text-xs font-medium items-center text-center justify-center z-30 flex flex-col bg-zinc-50 dark:bg-zinc-800 text-zinc-400 border-t border-r border-l border-zinc-300 dark:border-zinc-700  dark:text-zinc-200 rounded-t-md shadow-xs dark:shadow-zinc-800 dark:hover:bg-zinc-500 hover:bg-zinc-200 transition-all duration-200 `}"
                >
                  <ArrowUpIcon className="h-3 w-3 dark:text-zinc-200 text-zinc-600" />
                </button>
              </RadixTooltip>
              <RadixTooltip tooltipText="Collapse data table">
                <button
                  onClick={decreaseValue}
                  className=" w-auto h-auto p-2 text-xs font-medium items-center text-center justify-center z-30 flex flex-col bg-zinc-50 dark:bg-zinc-800 text-zinc-400 border-t border-r border-l border-zinc-300 dark:border-zinc-700  dark:text-zinc-200 rounded-t-md shadow-xs dark:shadow-zinc-800 dark:hover:bg-zinc-500 hover:bg-zinc-200 transition-all duration-200 `}"
                >
                  <ArrowDownIcon className="h-3 w-3 text-zinc-600 dark:text-zinc-200" />
                </button>
              </RadixTooltip>
            </div>
          )}

          {columns?.length > 0 ? (
            <div>
              <table className="w-full divide-y divide-x divide-zinc-200 dark:divide-zinc-700">
                <thead className="">
                  <tr className="sticky  top-0 z-[45]  bg-zinc-50 dark:bg-zinc-800 divide-x divide-zinc-200 dark:divide-zinc-700">
                    {columns.map((header) => (
                      <th
                        key={header}
                        scope="col"
                        colSpan={1}
                        className="whitespace-nowrap mt-10 py-1.5 px-3 text-left text-xs font-medium text-zinc-700 dark:text-zinc-50 "
                      >
                        <div className="flex flex-row flex-1 items-center gap-5 ">
                          <p className="truncate max-w-[300px]">{header}</p>
                          {metadata?.columns && <FormatIcon format={metadata?.columns[header]['Name']} />}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-zinc-800/50 p-1 divide-y divide-x divide-zinc-200 dark:divide-zinc-700">
                  {data.map((row, index) => {
                    return (
                      <tr
                        className="divide-y divide-x divide-zinc-200 dark:divide-zinc-700"
                        key={`row-${index}`}
                      >
                        {columns.map((cell, index) => {
                          const nonString = row[cell] == null || row[cell] === true || row[cell] === false

                          let stringValue = ""
                          if (String(row[cell]?.type) == "Symbol(react.fragment)") {
                            stringValue = row[cell]
                          } else {
                            stringValue = nonString ? String(row[cell]).toUpperCase() : typeof (row[cell]) == "object" ? JSON.stringify(row[cell]) : row[cell]
                          }
                          const strLength = stringValue?.length
                          return strLength > 100 ? (
                            <td
                              onClick={() => navigator.clipboard.writeText(stringValue)}
                              key={index}
                              className={`whitespace-whitespace-pre-wrap py-1.5 px-3 text-xs ${nonString
                                ? 'text-zinc-300 dark:text-zinc-600'
                                : 'text-zinc-500 dark:text-zinc-200 '
                                } truncate max-w-[1000px] hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-copy`}
                            >
                              <div className="justify-left flex">
                                <RadixTooltip classes='!max-w-[520px]' tooltipText={stringValue}>
                                  {stringValue.substring(0, 100) + '...'}
                                </RadixTooltip>
                              </div>
                            </td>
                          ) : (
                            <td
                              onClick={() => navigator.clipboard.writeText(stringValue)}
                              key={index}
                              className={`whitespace-nowrap py-1.5 px-3 text-xs ${nonString
                                ? 'text-zinc-300 dark:text-zinc-600'
                                : 'text-zinc-500 dark:text-zinc-200'
                                } truncate max-w-[1000px] hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-copy`}
                            >
                              {stringValue}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <table
              className={`divide-y w-full] min-h-[200px] h-[${value}px] max-h-[400px] 2xl:max-h-[1000px] divide-zinc-200 dark:divide-zinc-700`}
            >
              <div className="text-sm text-zinc-500 dark:text-zinc-200 transform -translate-x-1/2 -translate-y-1/2  left-1/2 top-1/2 absolute flex flex-row flex-1 items-center gap-5 w-100 justify-center">
                No data - add an Input action or click on an existing action to show data
              </div>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  input: PropTypes.object,
  pager: PropTypes.shape({
    size: PropTypes.number,
    total: PropTypes.number,
  }),
  exportActiveData: PropTypes.func,
  undo: PropTypes.func,
  redo: PropTypes.func,
  annotationHandler: PropTypes.func,
  sidebar: PropTypes.bool,
  expand: PropTypes.bool,
  classes: PropTypes.string,
};

DataTable.defaultProps = {
  headers: [],
  tableBody: [],
  data: [],
  pager: {
    size: 10,
    total: 0,
  },
  sidebar: true,
  classes: '',
  expand: false,
};

export default DataTable;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

const CheckboxText = ({ defaultValue, onChangeCallback, ...props }) => {
  const [num, setNum] = useState(defaultValue);




  useEffect(() => {
    //add disabled values
    const newStates = num;
    props.options?.forEach((item) => {
      if (!newStates?.[item.name]) {
        newStates[item.name] = { disabled: true };
      }
    });
  }, []);

  const handleCheckboxChange = (index) => {
    const newStates = structuredClone(num);
    if (!newStates?.[index]) {
      newStates[index] = { disabled: true };
    }
    newStates[index]['disabled'] = !newStates[index]['disabled'];
    setNum(newStates);

    onChangeCallback(newStates);
  };

  const handleNumChange = (event) => {
    const { name, value } = event.target;
    const option = props.options?.find((o) => o.name === name);
    const limit = option ? option.max : 0;
    const newNum = structuredClone(num);
    newNum[name]['value'] = value.slice(0, limit);
    setNum(newNum);
    onChangeCallback(newNum);
  };

  return (
    <ModelingInputTemplate {...props}>
      <fieldset>
        <div className="relative flex-col items-start -mt-1">
          {props.options?.map((item, index) => {
            return (
              <div key={index} className="relative flex items-center py-1.5">
                <div className="flex h-6 items-center">
                  <input
                    id={`${props.fieldName}_checkbox_${item.name}`}
                    aria-describedby="comments-description"
                    name={item.name}
                    checked={!num?.[item.name]?.['disabled'] || false}
                    type="checkbox"
                    className="h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                    onChange={() => handleCheckboxChange(item.name)}
                  />
                </div>
                <form>
                  <fieldset
                    disabled={num?.[item.name]?.['disabled'] || false}
                    className={
                      num?.[item.name]?.['disabled'] || false ? 'opacity-50' : 'opacity-100'
                    }
                  >
                    <div className="ml-3 text-xs leading-6">
                      <label
                        htmlFor="comments"
                        className="font-normal text-zinc-700 dark:text-zinc-50"
                      >
                        {item.textBefore}
                        <input
                          id={`${props.fieldName}_input_${item.name}`}
                          name={`${item.name}`}
                          type="number"
                          placeholder={item.inputPlaceholder}
                          value={num?.[item.name]?.['value']}
                          max={item.max}
                          className={`${item.textBefore == '' ? 'mr-2.5' : 'mx-2.5'
                            } appearance-none w-16 h-7 shadow-sm dark:shadow-zinc-600/50 text-zinc-700 dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:text-zinc-50 placeholder:text-zinc-300 placeholder:font-light focus:ring-[#5DA6FC] focus:border-[#5DA6FC] sm:text-xs border-zinc-300 border rounded-md`}
                          onChange={handleNumChange}
                        />
                        {item.textAfter}
                      </label>
                    </div>
                  </fieldset>
                </form>
              </div>
            );
          })}
        </div>
      </fieldset>
    </ModelingInputTemplate>
  );
};

CheckboxText.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      textBefore: PropTypes.string,
      name: PropTypes.string,
      textAfter: PropTypes.string,
      inputPlaceholder: PropTypes.string,
      max: PropTypes.number,
    })
  ).isRequired,
  onChangeCallback: PropTypes.func,
  defaultValue: PropTypes.object,
  fieldName: PropTypes.string,
};

CheckboxText.defaultProps = {
  options: [],
};
export default CheckboxText;

import React from 'react';
import PropTypes from 'prop-types';
import LeafFolder from './LeafFolder';
import ParentFolder from './ParentFolder';

const Folders = ({ folders, openFolderHandler, selected, level, breadcrumbs }) => {
  const onClick = (e) => {
    if (e.target.role === 'open') {
      e.preventDefault();
      openFolderHandler(parseInt(e.target.id));
    }
  };

  return folders.map(({ id, name, children }) => {
    if (children.length === 0) {
      return <LeafFolder key={id} {...{ id, name, onClick, selected, level }} />;
    } else {
      const nextLevel = level + 1;
      const parentOpen = breadcrumbs?.some((obj) => obj.id === id);
      return (
        <ParentFolder key={id} {...{ id, name, onClick, selected, level, openParent: parentOpen }}>
          <Folders
            {...{ folders: children, openFolderHandler, selected, level: nextLevel, breadcrumbs }}
          />
          <div className="invisible pl-2 pl-5" />
        </ParentFolder>
      );
    }
  });
};

Folders.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openFolderHandler: PropTypes.func,
  breadcrumbs: PropTypes.array,
};

Folders.defaultProps = {
  folders: [],
  name: "Default",
  id: "0",
  level: 1,
  openFolderHandler: () => null,
  breadcrumbs: [],
};

export default Folders;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import RadioGroup from 'components/Inputs/RadioGroup';
import TextDescInput from 'components/Inputs/TextDesc';
import { frequencyType, days as allDays } from 'data/constants';

const FrequencySelectorRadio = ({ selectionCallback, initState, override }) => {
  const [state, setState] = useState(initState);
  // use overrides if available and fallback to original values
  const types = override?.types || frequencyType;
  const days = override?.days || allDays;
  const selectChangeHandler = (key, val) => {
    const newState = {
      ...state,
      [key]: val,
    };

    if (key === 'frequencyType' && val === 'hourly') {
      const [, minutes] = newState.runTime.split(':');
      newState.runTime = `00:${minutes}`;
    }

    if (key === 'frequencyType' && val === 'weekly' && !state?.frequencyDays) {
      newState.frequencyDays = `Monday`;
    }

    setState(newState);
    selectionCallback(newState);
  };

  return (
    <div className="space-y-5">
      <RadioGroup
        header="Update Frequency"
        subheader="Choose how often you want to update"
        name="frequencyType"
        items={types}
        value={state.frequencyType || 'never'}
        onChange={(val) => selectChangeHandler('frequencyType', val)}
        placeholder="Select Update Frequency"
      />
      {state.frequencyType === 'weekly' && (
        <RadioGroup
          header="Update Day"
          subheader="At what day do you want to update"
          name="frequencyDays"
          items={days}
          value={state.frequencyDays}
          onChange={(val) => selectChangeHandler('frequencyDays', val)}
          placeholder="Specify Frequency"
        />
      )}
      <TextDescInput
        header="Update Timing"
        subheader={
          state.frequencyType === 'hourly'
            ? 'Select the exact minute you want to update'
            : 'At what time do you want to update'
        }
        placeholder=""
        type="time"
        minutes={state.frequencyType === 'hourly' ? 'yes' : 'no'}
        name="runTime"
        value={state ? state.runTime : initState.runTime}
        onChange={(val) => selectChangeHandler('runTime', val)}
      />
    </div>
  );
};

FrequencySelectorRadio.propTypes = {
  selectionCallback: PropTypes.func,
  initState: PropTypes.shape({
    frequencyType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    frequencyDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    runTime: PropTypes.string,
  }),
  override: PropTypes.shape({
    types: PropTypes.array,
    days: PropTypes.array,
    dates: PropTypes.array,
  }),
};

FrequencySelectorRadio.defaultProps = {
  selectionCallback: () => null,
  initState: {
    frequencyType: 0,
    frequencyDays: '',
    runTime: '',
  },
  override: {
    types: [],
    days: [],
    dates: [],
  },
};

export default FrequencySelectorRadio;

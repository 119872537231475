import React from 'react';
import CanvasAction from 'components/Actions/CanvasAction';
import actionArray from 'data/tools';

import { Handle, Position } from 'reactflow';

const handleStylesRight = {
  height: 15,
  width: 15,
  right: -20,

  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const handleStylesLeft = {
  height: 15,
  width: 15,
  left: -20,

  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const stackNode = (data) => {
  const { category, tooltip, text, icon } = actionArray.filter(
    (tool) => tool.name === data.data.configuration.tool_type
  )[0];
  const status = data.data.status;
  const selected = data.selected;
  const errorMessage = data?.data.errorMessage;

  return (
    <div>
      <CanvasAction
        data={data}
        desc={errorMessage || tooltip}
        icon={icon}
        name={text}
        category={category}
        status={status}
        selected={selected}
      />
      <Handle type="source" position={Position.Right} style={handleStylesRight} />
      <Handle
        type="target"
        position={Position.Left}
        className="hover:!bg-zinc-200"
        id="dfs"
        style={handleStylesLeft}
      />
    </div>
  );
};

export default stackNode;

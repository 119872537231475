import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

const BasicModal = (props) => {
  const { open, onClose } = props;

  return (
    <div className="absolute max-h-[90vh] z-50">
      <AnimatePresence>
        {open && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeIn' }}
            className={`fixed inset-0 bg-zinc-500 backdrop-blur-sm ${props.bgClasses} bg-opacity-75`}
            onClick={() => onClose(false)}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="fixed z-10 inset-0 h-fit w-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg shadow-2xl dark:shadow-zinc-700/50"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1, ease: 'easeIn' }}
                className={`${props.classes} relative bg-white dark:bg-zinc-900 rounded-lg text-left shadow-xl  max-h-[90vh] overflow-auto transform transition-all`}
              >
                <div>{props.content}</div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

BasicModal.propTypes = {
  content: PropTypes.any,
  classes: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  bgClasses: PropTypes.string,
};

BasicModal.defaultProps = {
  content: '',
  classes: '',
  onClose: () => null,
  open: false,
};

export default BasicModal;

import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// import classNames from 'classnames';
// redux
import { userProfileAction, getGroupsAction } from 'redux/actions/auth';
import { getAllConnectorsAction, getConnectorCategoriesAction } from 'redux/actions/connector';
import { setInit } from 'redux/reducers/home';
import { allFolderAction } from 'redux/actions/folder';
import { getAllDatabasesAction } from 'redux/actions/database';
import { getRolesAction } from 'redux/actions/role';
// components

import Loader from 'components/Layout/Loader';
import NotificationManager from 'components/Alerts/NotificationManager';
// helper
import LoginModal from 'components/Modals/LoginModal';



export default function Wrapper() {
    const dispatch = useDispatch();
    const { initialized } = useSelector(({ auth, home }) => {
        return {
            user: auth.user,
            initialized: home.init,
        };
    });

    const [theme, setTheme] = useState();

    const refreshData = async () => {
        await dispatch(setInit(false));
        await dispatch(userProfileAction());
        await dispatch(getAllConnectorsAction());
        await dispatch(getConnectorCategoriesAction());
        await dispatch(allFolderAction());
        await dispatch(getGroupsAction());
        await dispatch(getRolesAction());
        await dispatch(getAllDatabasesAction());
        await dispatch(setInit(true));

    }

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        const onStorage = () => {
            setTheme(localStorage.getItem('theme'));
        };
        window.addEventListener('storage', onStorage);
        onStorage()
        return () => {
            window.removeEventListener('storage', onStorage);
        };
    }, []);


    return (
        <>
            <div id="theme-wrapper" className={`${theme} theme-changer`}>
                {!initialized && <Loader />}
                <NotificationManager />
                {localStorage.getItem('auth') == 'False' && (
                    <div className="absolute z-[10000] bg-[#13151D] bg-opacity-90 backdrop-blur-sm min-w-screen min-h-full h-screen w-screen">
                        {/* <Login /> */}
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] h-auto mx-auto items-center border-[0.5px] border-zinc-600 bg-gradient-to-br from-slate-800 via-black to-zinc-900 p-12 rounded-xl">
                            <div>
                                <h3 className="text-xl leading-6 font-bold text-slate-300 ">
                                    You have been logged out due to inactivity
                                </h3>
                                <div className="mt-2 max-w-4xl text-sm align-baseline text-slate-500">
                                    Log in below to continue where you left off with your work
                                </div>
                                <div className="mt-3 relative flex-1 flex flex-row">
                                    <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
                                    <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
                                </div>
                            </div>
                            <LoginModal />
                        </div>
                    </div>
                    // <div className="absolute w-screen h-screen bg-black z-[10000] bg-opacity-50 backdrop-blur text-black">
                    //   HEJSA
                    // </div>
                )}
                <Outlet />
            </div>
        </>
    );
}

export const frequencyType = [
  {
    value: 'hourly',
    label: 'Hourly',
  },
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'first_of_month',
    label: 'First of month',
  },
  {
    value: 'never',
    label: 'Never',
  },
];

export const days = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
  {
    value: 'Sunday',
    label: 'Sunday',
  },
];

export const monthly = [
  {
    value: 8,
    label: 'First Day of the Month',
  },
  {
    value: 9,
    label: 'Last Day of the Month',
  },
];


export const folderTypes = {
  ASSET: 'asset',
  FOLDER: 'folder',
};
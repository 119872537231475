function dataSize(mb) {
  if (mb < 500) {
    return `${mb.toFixed(2)} MB`;
  } else {
    return `${(mb / 1000).toFixed(2)} GB`;
  }
}

function stringToDate(dateString) {
  if (dateString !== undefined && dateString !== null) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7) - 1;
    const day = dateString.substring(8, 10);
    const hours = dateString.substring(11, 13);
    const minutes = dateString.substring(14, 16);
    const seconds = dateString.substring(17, 19);
    const date = new Date(year, month, day, hours, minutes, seconds);
    return date;
  } else {
    return new Date(null);
  }
}

function localTime(date) {
  if (date !== undefined) {
    const orgDate = stringToDate(date);
    const offset = new Date().getTimezoneOffset() * 60 * 1000 * 2;
    const localDatetime = new Date(orgDate - offset);
    return localDatetime.toISOString().replace(/T/, ' ').replace(/\..+/, '');
  } else {
    return date;
  }
}

function timeToLocal(time) {
  if (time !== undefined) {
    const todayString = new Date().toISOString().substring(0, 10);
    const date = localTime(`${todayString} ${time}`);
    const localTimeString = date.substring(11, 21);
    return localTimeString;
  } else {
    return new Date().toISOString().substring(11, 16);
  }
}

function timeToUTC(time) {
  const todayString = new Date().toISOString().substring(0, 10);
  const date = stringToDate(`${todayString} ${time}`);
  const localTimeString = date.toISOString().substring(11, 19);
  return localTimeString;
}

function numberToString(number) {
  if (number > 1000) {
    return Math.round(number / 1000).toString() + 'K';
  } else {
    return (Math.round(number * 100) / 100).toString();
  }
}

const toDate = (input) => {
  const output = stringToDate(input).getTime();
  return Math.floor(output / 1000);
};
const toReadDate = (input) => {
  const timestamp = input;
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;
  const formatted =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');

  return formatted;
};

const epochToReadable = (input) => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const readabledate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return readabledate;
};

function addThousandSeparators(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export {
  dataSize,
  localTime,
  timeToLocal,
  timeToUTC,
  numberToString,
  stringToDate,
  toDate,
  toReadDate,
  epochToReadable,
  addThousandSeparators,
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDatabasesService, getDatabaseService, deleteDatabaseService, getDatabaseWhitelistService, createDatabaseService, updateWhitelistService } from 'services/database';

// First, create the thunk
export const getAllDatabasesAction = createAsyncThunk('databases/fetch', async () => {
  const response = await getAllDatabasesService();
  return response;
});

export const getDatabaseAction = createAsyncThunk('databases/get', async (databaseId) => {
  const response = await getDatabaseService(databaseId);
  return response;
});

export const deleteDatabaseAction = createAsyncThunk('database/delete', async (databaseId) => {
  const response = await deleteDatabaseService(databaseId);
  return response;
});

export const getDatabaseWhitelistAction = createAsyncThunk('', async (databaseId) => {
  const response = await getDatabaseWhitelistService(databaseId);
  return response;
});

export const createDatabaseAction = createAsyncThunk(
  'database/create',
  async ({ data }, { rejectWithValue }) => {
  try {
    const response = await createDatabaseService(data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
  }
);

export const updateWhitelistAction = createAsyncThunk(
  'whitelist/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await updateWhitelistService(id, data);
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err)
    }
  }
);
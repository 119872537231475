import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import TextDescInput from 'components/Inputs/TextDesc.jsx';


const OAuthModal = (props) => {
  //Parse regex for {}

  const [parameterOptions, setParameterOptions] = useState([])
  const [oauthUrl, setOauthUrl] = useState(props.oauth_url)
  const [values, setValues] = useState({})

  useEffect(() => {
    const getparameterOptions = props?.oauth_url.match(/[^{}]+(?=\})/g)
    if (getparameterOptions) {
      setParameterOptions(getparameterOptions)
    }
  }, [])

  useEffect(() => {
    let newUrl = props.oauth_url
    parameterOptions.forEach((item, index) => {
      newUrl = newUrl.replace(`{${item}}`, values[index])
    })

    const url = new URL(newUrl)

    const urlState = url.searchParams.get('state')

    if (urlState) {
      const currentState = JSON.parse(atob(urlState));
      currentState['extra'] = values
      const newState = btoa(JSON.stringify(currentState))
      url.searchParams.set('state', newState)
      setOauthUrl(url.toString())
    } else {
      setOauthUrl(props.oauth_url)
    }

  }, [values])


  const onClick = () => (window.location = oauthUrl)

  return (
    <div className="p-8 flex flex-col justify-between items-center space-y-3">
      <div className="bg-white rounded-full p-5 border-4 border-zinc-100">{props.icon}</div>
      <h1 className="font-bold text-center text-transparent text-2xl bg-clip-text bg-gradient-to-r from-[#5DA6FC]/80 via-[#FFC6BA]/80 to-[#ff5478]/80">
        {props.title}
      </h1>
      <p className="text-sm font-medium text-center text-zinc-500 dark:text-zinc-200">{`You will be redirected to ${props.title} to authenticate. When you're done, you'll automatically be sent back to Less`}</p>
      {parameterOptions.map((ipt, index) => {
        return (
          <div key={index}>
            <TextDescInput
              key={index}
              header={ipt}
              // subheader={description}
              // name={fieldname}
              // placeholder={placeholder}
              // withtooltip={withtooltip}
              // tooltip={tooltip}
              type="text"
              value={values?.[index]}
              onChange={(val) => {
                const newValues = { ...values };
                newValues[index] = val;
                setValues(newValues);
              }}
            />
          </div>
        );
      })}
      <PrimaryBtn onClick={onClick} text={`Connect to ${props.title}`} />
    </div>
  );
};

OAuthModal.propTypes = {
  title: PropTypes.string,
  oauth_url: PropTypes.string,
  icon: PropTypes.element,
};

OAuthModal.defaultProps = {
  title: 'Unknown',
  oauth_url: '',
  icon: '<img />',
};

export default OAuthModal;

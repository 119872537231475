import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react'

export default function ContextMenu({
  items,
  top,
  left,
  right,
  bottom,
  onClick,
  ...props
}) {

  return (
    <Menu as="div" className="relative inline-block p-1 rounded-md shadow-sm items-baseline text-left z-200 bg-zinc-50 border border-zinc-200" open={true} style={{ zIndex: 200, top, left, right, bottom }} >
      {/* <Menu.Button className={'group flex items-center w-full px-4 py-2 text-xs'} open={true} >Actions</Menu.Button> */}
      <Menu.Items static>
        {items.map((item, i) => (
          <Menu.Item
            disabled={!item.requirement(props)}
            key={i} onClick={() => {
              item.action(props)
              onClick()
            }}>
            <div className={`w-full rounded  items-center px-4 py-2 text-xs text-zinc-500 dark:text-zinc-200   ${!item.requirement(props) ? "text-opacity-50 cursor-not-allowed" : "hover:text-zinc-700 dark:hover:text-zinc-50 hover:bg-zinc-200 hover:cursor-pointer "}`}>
              {item.name}
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu >
  );
}

ContextMenu.propTypes = {
  items: PropTypes.any,
  top: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  bottom: PropTypes.any,
  onClick: PropTypes.any,
};
import React from 'react';
import PropTypes from 'prop-types';
//import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const ModellingKPICard = ({ measure, metric, tooltipText, withtooltip }) => {
  return (
    <>
      {withtooltip && (
        <RadixTooltip side="bottom" tooltipText={tooltipText}>
          <div className="min-w-[70px] w-fit py-1 px-2 text-xs font-medium items-center text-center justify-center z-30 flex flex-col bg-zinc-50 dark:bg-zinc-800 dark:border-zinc-700 text-zinc-500 dark:text-zinc-200 border border-zinc-200 rounded-lg shadow-xs dark:shadow-zinc-800">
            <p className="text-base  flex text-center font-extrabold w-fit text-transparent bg-clip-text bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]">
              {metric}
            </p>
            <p className="text-[11px]">{measure}</p>
          </div>
        </RadixTooltip>
      )}
      {!withtooltip && (
        <div className="min-w-[70px] max-w-[70px] p-1 text-xs font-medium items-center text-center justify-center z-30 flex flex-col bg-zinc-50 dark:bg-zinc-800 dark:border-zinc-700 text-zinc-500 dark:text-zinc-200 border border-zinc-200 rounded-lg shadow-xs dark:shadow-zinc-800">
          <p className="text-base flex text-center font-extrabold w-fit text-transparent bg-clip-text bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]">
            {metric}
          </p>
          <p className="text-[11px]">{measure}</p>
        </div>
      )}
    </>
  );
};
ModellingKPICard.propTypes = {
  measure: PropTypes.string,
  metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipText: PropTypes.string,
  margin: PropTypes.string,
  withtooltip: PropTypes.bool,
};
export default ModellingKPICard;

/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Sentry from "@sentry/react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import history from 'history/browser';
import { addNotification } from 'redux/reducers/notification';
//import { XIcon } from '@heroicons/react/solid';
// import useUndoable from 'use-undoable';
import { useReactFlow, addEdge, ReactFlowProvider, useNodesState, useEdgesState } from 'reactflow';
import { timeToUTC } from 'utils/unitConversion';
import 'reactflow/dist/style.css';
//import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';

// components
import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import BasicModal from 'components/Modals/BasicModal';
import ModalHeader from 'components/Layout/ModalHeader';
import FormBuilderModelling from 'components/Inputs/FormBuilderModelling';
import FlyingModal from 'components/Modals/FlyingModal';
import ActionBar from 'components/Modelling/ActionBar';
import DataTable from 'components/Modelling/DataTable';
import Canvas from 'components/Modelling/Canvas';
import actionInputs from 'data/toolsConfiguration';
import FlyingModalHeader from 'components/Layout/FlyingModalHeader';
import ConfigModel from 'components/Modals/ConfigModel';
import DeleteModal from 'components/Modals/DeleteModal';

// redux
import { getAllDatabasesAction } from 'redux/actions/database';
import { getAllConnectionsAction } from 'redux/actions/connection';
import { getAllTablesAction } from 'redux/actions/table';
import { autoSaveModelAction } from 'redux/actions/model';
import {
  runModellingExportAction,
  runModellingGetSessionAction,
  runModellingCheckSessionAction,
  runModellingClearCacheAction,
  runModellingRestartSessionAction,
} from 'redux/actions/modelling';
import { runModellingService } from 'services/modelling';
import { getModelAction, saveModelVersionAction, createModelAction } from 'redux/actions/model';
import { allFolderAction } from 'redux/actions/folder';
// utils
// import debounce from 'utils/debouce';
import searchFolder from 'utils/searchFolder';
import useCopyPaste from 'components/Modelling/useCopyPaste';
import useUndoRedo from 'components/Modelling/useUndoRedo';
import TertiaryBtn from 'components/Buttons/TertiaryBtn';

const Modelling = ({ recover, setActiveData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [modelMeta, setModelMeta] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [pendingNodeChanges, setPendingNodeChanges] = useState({});
  const [activeNode, setActiveNode] = useState(null);
  const [activeDataNode, setActiveDataNode] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [ready, setReady] = useState(false);
  const [metrics, setMetrics] = useState({ rows: 0, columns: 0, tools: 0 });
  const [snapToGrid, setSnapToGrid] = useState(
    localStorage.getItem('snapToGrid') == 'True' ? true : false
  );
  const [annotations, setAnnotations] = useState(
    localStorage.getItem('annotations') == 'True' ? true : false
  );

  const { modelId, versionId } = useParams();
  const [createModal, setCreateModal] = useState(false);
  const modelFormState = useRef({});
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(useReactFlow());
  const [recoverModal, setRecoverModal] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [discardUnsavedHandler, setDiscardUnsavedHandler] = useState({ fn: () => null });
  const { undo, redo, takeSnapshot } = useUndoRedo();
  const [menu, setMenu] = useState(null);
  const [isModellingUser, setIsModellingUser] = useState(false);
  const [viewer, setViewer] = useState(false);
  const [sourceFolder, setSourceFolder] = useState(null);
  const [waking, setWaking] = useState(true);
  const [backendSession, setBackendSession] = useState(null);
  const nodesRef = useRef();
  const edgesRef = useRef();
  const undoRef = useRef();
  const redoRef = useRef();
  const executeFullModelRef = useRef();

  useEffect(() => {
    nodesRef.current = nodes;
    edgesRef.current = edges;
    undoRef.current = undo;
    redoRef.current = redo;
    executeFullModelRef.current = executeFullModel;
  }, [nodes, edges]);

  const cookieName = `model_${modelId}`;
  const stdfields = {
    limit: 200,
    sql_limit: 200,
    cache: false,
    depending_on: [],
  };

  //Refresh new tables every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getAllTablesAction());
    }, 45000);
    return () => clearInterval(interval);
  }, []);


  const updateSessionFunction = useCallback(() => {

    // console.log("BackendSession", backendSession)
    if (backendSession?.id != undefined) {
      // console.log("WAKING UP")
      dispatch(runModellingCheckSessionAction(backendSession.id)).then((data) => {
        const result = data.payload;
        // console.log("SeetingBackend session", result)
        setBackendSession(result);
      });
    } else {
      // console.log("You have a session")
    }
  }, [backendSession, setBackendSession]);

  //Refresh new tables every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      updateSessionFunction();
    }, 15000);
    return () => clearInterval(interval);
  }, [updateSessionFunction]);

  //Refresh new tables every 15 seconds
  useEffect(() => {
    //console.log("BACKEND SESSION UPDATED", backendSession)
    if (backendSession?.sessionId == 'temporary' && backendSession?.sessionId != undefined) {
      setWaking(true);
    } else {
      setWaking(false);
    }
  }, [backendSession]);

  //Refresh new tables every 15 seconds
  useEffect(() => {
    const autoSave = setInterval(() => {
      dispatch(
        autoSaveModelAction({
          modelId: modelId,
          data: { nodes: nodesRef.current, edges: edgesRef.current },
          sessionId: modelId,
        })
      );
    }, 300000);
    return () => clearInterval(autoSave);
  }, []);

  const showNotification = (header, subheader, icon) => {
    dispatch(
      addNotification({
        header,
        subheader,
        icon,
        timeout: 10000,
      })
    );
  };

  // We declare these callbacks as React Flow suggests,
  // but we don't set the state directly. Instead, we pass
  // it to the triggerUpdate function so that it alone can
  // handle the state updates.

  const alterNodeConfig = (node, changes) => {
    const alteredNode = structuredClone({ ...node, ...changes });
    setNodes(
      nodes.map((n) => {
        if (n.id === node.id) {
          // it's important to create a new object here, to inform React Flow about the changes
          return alteredNode;
        }
        n.selected = false;
        return n;
      })
    );
  };

  const getId = () => {
    let id = 0;
    if (nodes.length > 0) {
      id = Math.max(...nodes.map((item) => parseInt(item.id))) + 1;
    }
    return id.toString();
  };

  const onDeleteTool = useCallback(() => {
    setActiveNode({});
    setEditModal(null);
  }, [nodes, edges]);

  //Add copy paste functionality
  const { copy, paste } = useCopyPaste({ takeSnapshot: takeSnapshot });

  const deleteNode = (props) => {
    if (isModellingUser) {
      takeSnapshot();
      if (props?.node?.id) {
        setNodes((nodes) => nodes.filter((node) => node.id !== props?.node?.id));
        setEdges((edges) =>
          edges.filter((edge) => edge.source !== props?.node?.id && edge.target !== props?.node?.id)
        );
        onDeleteTool();
      }
    }
  };

  const deleteAndConnectAround = useCallback(
    (props) => {
      takeSnapshot();
      if (props?.node?.id) {
        let incomingTool = '';
        //Get incoming connections
        const incomingConnections = edges.filter((edge) => edge.target == props?.node?.id);
        if (incomingConnections.length > 0) {
          incomingTool = reactFlowInstance.getNode(incomingConnections[0]['source']);
        }

        //Delete node
        const filteredNodes = nodes.filter((node) => node.id !== props?.node?.id);
        const edgeIds = {};

        //Change edges
        const alteredEdges = edges
          .map((edge) => {
            if (edge.source == props?.node?.id) {
              edge.source = incomingTool.id;
              edgeIds[edge.target] = edge.targetHandle;
              return edge;
            } else {
              return edge;
            }
          })
          .filter((edge) => edge.target != props?.node?.id);

        filteredNodes.forEach((node) => {
          if (Object.keys(edgeIds).includes(node.id)) {
            node.data.configuration[edgeIds[node.id]] = incomingTool.id;
            node.data[edgeIds[node.id] + '_metadata'] = incomingTool.data.metadata;
            node.data.configuration.cache = false;
            node.data.status = null;
          }
        });

        setNodes(filteredNodes);
        setEdges(alteredEdges);

        // onDeleteTool()
      }
    },
    [menu, nodes, edges]
  );

  const alignNodes = () => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const selNodeIds = selectedNodes.map((node) => {
      return node.id;
    });
    // console.log(selNodeIds)
    const max = selectedNodes.reduce(function (prev, current) {
      return prev && prev.position.y > prev.position.y ? prev : current;
    });
    selectedNodes.map((node) => {
      node.position.y = max.position.y;
    });
    // console.log("selectedNodes", selectedNodes)
    // console.log("allNodes", [...nodes.filter((node) => !selNodeIds.includes(node.id)), ...selectedNodes])
    setNodes([...nodes.filter((node) => !selNodeIds.includes(node.id)), ...selectedNodes]);
  };

  // const distributeEvenly = () => {
  //   const selectedNodes = nodes.filter((node) => node.selected)
  //   const selNodeIds = selectedNodes.map((node) => { return node.id })
  //   const right = selectedNodes.reduce(function (prev, current) {
  //     return (prev && prev.position.x < prev.position.x) ? prev : current
  //   })
  //   const left = selectedNodes.reduce(function (prev, current) {
  //     return (prev && prev.position.x < prev.position.x) ? current : prev
  //   })

  //   const length = (right.position.x + right.width) - left.position.x
  //   let width = 0
  //   selectedNodes.forEach((node) => {
  //     width += node.width
  //   })

  //   console.log("selectedNodes", selectedNodes)
  //   console.log("left", left)
  //   console.log("right", right)
  //   console.log("length", length)
  //   console.log("width", width)

  // }

  const selectionRectPresent = () => {
    return document.getElementsByClassName('react-flow__nodesselection-rect').length > 0;
  };

  const inAndOut = (props) => {
    const nodeObject = reactFlowInstance.getNode(props?.node?.id);
    const incomingCon = edges.filter((edge) => edge.target == nodeObject.id).length > 0;
    const outGoingCon = edges.filter((edge) => edge.source == nodeObject.id).length > 0;
    return incomingCon && outGoingCon;
  };

  const nodeContextMenuItems = [
    {
      name: 'Delete',
      action: (props) => deleteNode(props),
      requirement: () => {
        return true;
      },
    },
    {
      name: 'Delete and autoconnect',
      action: (props) => deleteAndConnectAround(props),
      requirement: (props) => inAndOut(props),
    },
    {
      name: 'Copy',
      action: copy,
      requirement: () => {
        return true;
      },
    },
  ];

  const paneContextMenuItems = [
    {
      name: 'Top-align selected actions',
      action: () => {
        alignNodes();
      },
      requirement: selectionRectPresent,
    },
    // { name: "Distribute evenly", action: () => { distributeEvenly(), console.log("ALIGNING ITEMS") } },
    { name: 'Copy selected actions', action: copy, requirement: selectionRectPresent },
    {
      name: 'Paste',
      action: paste,
      requirement: () => {
        return true;
      },
    },
  ];

  const onNodeContextMenu = useCallback(
    (event, node) => {
      alterNodeConfig(node, { selected: true });
      // Prevent native context menu from showing
      event.preventDefault();
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      const boundingRect = event.target.closest('.react-flow__node').getBoundingClientRect();
      setMenu({
        id: node.id,
        node: node,
        items: nodeContextMenuItems,
        top:
          event.clientY < pane.height - 200
            ? boundingRect.top + boundingRect.height
            : boundingRect.top - boundingRect.height,
        left:
          event.clientX < pane.width - 200
            ? boundingRect.left + boundingRect.width
            : boundingRect.left - boundingRect.width,
      });
    },
    [menu, setMenu, nodeContextMenuItems]
  );

  const onPaneContextMenu = useCallback(
    (event) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      setMenu({
        items: paneContextMenuItems,
        top: event.clientY < pane.height - 200 ? event.clientY : event.clientY - 100,
        left: event.clientX < pane.width - 200 ? event.clientX : event.clientX - 200,
      });
    },
    [menu, setMenu, paneContextMenuItems, paste]
  );

  const onConnect = useCallback(
    (connection) => {
      // 👇 make adding edges undoable
      takeSnapshot();

      const checkConnections = edges.filter(
        (edge) =>
          (edge.target == connection.target) & (edge.targetHandle == connection.targetHandle)
      );

      if (checkConnections.length == 0 || connection.targetHandle == "dfs") {
        setEdges((eds) => addEdge(connection, eds));
        const source = reactFlowInstance.getNode(connection.source);
        const target = reactFlowInstance.getNode(connection.target);
        if (editModal && connection) {
          pendingNodeChanges[connection.targetHandle] = connection.source;
        }

        if (connection.targetHandle == "dfs") {
          target.data.configuration[connection.targetHandle] = [...checkConnections.map((edge) => { return edge.source }), connection.source];
        } else {
          target.data.configuration[connection.targetHandle] = source.id;
        }

        target.data[connection.targetHandle + '_metadata'] = source.data.metadata;
        target.data.configuration.cache = false;
        target.data.status = null;
        const subNodes = findNodes([target.data.configuration.tool_id]);
        subNodes.map((node) => {
          const nodeObject = reactFlowInstance.getNode(node);
          if (nodeObject) {
            nodeObject.data.configuration.cache = false;
            nodeObject.data.status = null;
          }
        });
        onNodesChange([]);
      }
    },
    [setEdges, takeSnapshot, reactFlowInstance, edges, nodes, pendingNodeChanges, editModal]
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      takeSnapshot();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const node = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - node.cursorOffsetX,
        y: event.clientY - reactFlowBounds.top - node.cursorOffsetY,
      });
      const toolId = getId();
      const newNode = {
        id: toolId,
        name: node.name,
        label: node.text,
        type: node.toolType,
        position,
        selected: true,
        data: {
          configured: false,
          configuration: {
            tool_id: toolId,
            tool_type: node.name,
            ...stdfields,
          },
        },
      };

      const edgeTarget = [];

      if (event.target instanceof Element) {
        //DROPPING ON OTHER NODE
        const targetId = event.target.closest('.react-flow__node')?.getAttribute('data-id');
        if (targetId) {
          const source = nodes.find((node) => node.id === targetId);

          if (source.type != 'commentNode' && newNode.type != 'commentNode') {
            // from the target element search for the node wrapper element which has the node id as attribute
            //const source = nodes.find((node) => node.id === targetId);
            const newPosition = { x: source.position.x + 200, y: source.position.y };
            newNode.position = newPosition;
            newNode.data[node.defaultAnchor + '_metadata'] = source.data.metadata;
            newNode.data.configuration[node.defaultAnchor] = source.id;
            const deleteEdges = edges.filter((edge) => edge.source == source.id);

            const newEdges = deleteEdges.map((edge) => {
              return {
                id: 'edge-' + toolId + '-' + edge.target + edge.targetHandle,
                source: toolId,
                sourceHandle: null,
                target: edge.target,
                targetHandle: edge.targetHandle,
              };
            });

            const targetNodes = newEdges.map((edge) => {
              return edge.target;
            });
            const affectedNodes = findNodes(targetNodes);
            const minDistance = Math.min(
              ...nodes
                .filter((node) => targetNodes.includes(node.id))
                .map((node) => {
                  if (targetNodes.includes(node.id)) {
                    return node.position.x;
                  }
                })
            );
            const distance =
              200 - (minDistance - newNode.position.x) > 0
                ? 200 - (minDistance - newNode.position.x)
                : 0;

            const updatedNodes = structuredClone(nodes);
            updatedNodes.map((node) => {
              if (targetNodes.includes(node.id)) {
                node.position.x = node.position.x + distance;
                newEdges
                  .filter((edge) => edge.target == node.id)
                  .map((edge) => {
                    node.data.configuration[edge.targetHandle] = toolId;
                  });
                return node;
              }
              if (affectedNodes.includes(node.id)) {
                node.position.x = node.position.x + distance;
                return node;
              }
            });

            setNodes(updatedNodes);

            const edgeArray = [
              ...edges.filter((edge) => edge.source != source.id),
              ...newEdges,
              ...[
                {
                  id: 'edge-' + targetId + '-' + toolId + node.defaultAnchor,
                  source: targetId,
                  sourceHandle: null,
                  target: toolId,
                  targetHandle: node.defaultAnchor,
                },
              ],
            ];

            edgeTarget.push(...targetNodes);

            // now we can create a connection to the drop target node
            setEdges(edgeArray);

            setActiveNode(newNode);
            placeEditModal({ ...newNode, ...{ event: event } });
          }
        }
      }

      if (event.target instanceof Element) {
        //DROPPING ON EDGE
        const targetId = event.target.closest('.react-flow__edge-path')?.getAttribute('id');
        if (targetId && newNode.type != 'commentNode') {
          const deleteEdge = edges.filter((edge) => edge.id == targetId)[0];
          const source = nodes.find((node) => node.id === deleteEdge.source);
          const target = structuredClone(nodes.find((node) => node.id === deleteEdge.target));
          const newPosition = { x: source.position.x + 200, y: newNode.position.y };
          newNode.position = newPosition;
          newNode.data[node.defaultAnchor + '_metadata'] = source.data.metadata;
          newNode.data.configuration[node.defaultAnchor] = source.id;
          target.data.configuration[deleteEdge.targetHandle] = toolId;

          const newEdges = [
            {
              id: 'edge-' + deleteEdge.source + '-' + toolId + node.defaultAnchor,
              source: deleteEdge.source,
              sourceHandle: null,
              target: toolId,
              targetHandle: node.defaultAnchor,
            },
            {
              id: 'edge-' + toolId + '-' + deleteEdge.target + node.defaultAnchor,
              source: toolId,
              sourceHandle: null,
              target: deleteEdge.target,
              targetHandle: deleteEdge.targetHandle,
            },
          ];

          const edgesArray = [...edges.filter((edge) => edge.id != targetId), ...newEdges];
          edgeTarget.push(deleteEdge.target);

          setEdges(edgesArray);
          const updateNodes = [deleteEdge.target, ...findNodes([deleteEdge.target])];
          const distanceIn =
            200 - (target.position.x - newNode.position.x) > 0
              ? 200 - (target.position.x - newNode.position.x)
              : 0;

          setNodes(
            nodes.map((node) => {
              if (node.id == target.id) {
                return target;
              } else if (updateNodes.includes(node.id)) {
                node.position.x = node.position.x + distanceIn;
                return node;
              } else {
                return node;
              }
            })
          );

          setActiveNode(newNode);
          placeEditModal({ ...newNode, ...{ event: event } });
        }
      }

      const subNodes = findNodes(edgeTarget);
      subNodes.push(...edgeTarget);
      subNodes.map((node) => {
        const nodeObject = reactFlowInstance.getNode(node);
        if (nodeObject) {
          nodeObject.data.status = '';
          nodeObject.data.configuration.cache = false;
        }
      });

      setNodes((nodes) => [...nodes.map((node) => ({ ...node, selected: false })), ...[newNode]]);
      // addUndoRedoHandler();
    },
    [reactFlowWrapper, reactFlowInstance, nodes, edges]
  );

  const configStateCallback = (state) => {
    modelFormState.current = state;
  };

  const { user, databases, connections, tables, folder, folders } = useSelector(
    ({ auth, databases, connections, tables, folder }) => {
      return {
        databases: databases.databases,
        connections: connections.connections,
        tables: tables.tables,
        folder: folder.current,
        folders: folder.folders,
        user: auth.user,
        //breadcrumbs: folder?.current?.breadcrumbs,
      };
    }
  );

  useEffect(() => {
    setIsModellingUser(user?.abilities?.includes('modelling-user'));

    if (user?.abilities?.includes('modelling-user')) {
      localStorage.removeItem('noModelling');
    } else {
      localStorage.setItem('noModelling', true);
    }
  }, [user]);

  const discardModelHandler = () => {
    localStorage.removeItem(cookieName);
    setRecoverModal(false);
  };

  const recoverClickHandler = () => {
    window.open(`${location.pathname}/recover`, '_blank');
    setRecoverModal(false);
  };

  const clearCacheHandler = useCallback(async () => {
    nodesRef.current.map((node) => {
      node.data.status = '';
      node.data.configuration.cache = false;
    });

    onNodesChange([]);
    return dispatch(runModellingClearCacheAction({ modelId: modelId }));
  }, [nodesRef]);

  const restartSessionHandler = useCallback(async () => {
    nodesRef.current.map((node) => {
      node.data.status = '';
      node.data.configuration.cache = false;
    });

    onNodesChange([]);
    return dispatch(runModellingRestartSessionAction()).then((data) => {
      const result = data.payload;
      setBackendSession(result);
    });
  }, [nodesRef]);

  const runFullModelHandler = useCallback(() => {
    return executeFullModelRef.current();
  }, [reactFlowInstance]);

  // const recoverSavedModel = () => {
  //   try {
  //     const unsavedModel = localStorage.getItem(cookieName);
  //     const { nodes, edges } = JSON.parse(unsavedModel).model;
  //     setNodes(nodes);
  //     setEdges(edges);
  //     setElements({ nodes, edges });
  //     elements;
  //   } catch (err) {
  //     //console.log(err);
  //   }
  // };

  // const autosaveCookieHandler = debounce(() => {
  //   const user = JSON.parse(localStorage.getItem('user'));

  //   try {
  //     localStorage.setItem(
  //       cookieName,
  //       JSON.stringify({
  //         modelId: modelId,
  //         sessionId: uuid.current,
  //         timestamp: Date.now(),
  //         userId: user.id,
  //       })
  //     );
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // });

  // const initModelRecoverFlow = () => {
  //   if (!recoverInit.current) {
  //     if (localStorage.getItem(cookieName)) {
  //       setRecoverModal(true);
  //     } else {
  //       recoverInit.current = true;
  //     }
  //   }
  // };

  useEffect(() => {
    function componentDidMount() {
      dispatch(getAllDatabasesAction());
      dispatch(getAllConnectionsAction());
      dispatch(getAllTablesAction());

      if (Number(localStorage.getItem('lastFolder'))) {
        setSourceFolder(Number(localStorage.getItem('lastFolder')));
      } else {
        setSourceFolder(null);
      }
      dispatch(runModellingGetSessionAction()).then((data) => {
        const result = data.payload;
        setBackendSession(result);
      });
    }
    function componentDidUnmount() {
      localStorage?.removeItem('model');
    }
    componentDidMount();

    // if (recover) {
    //   recoverSavedModel();
    //   // remove the cookie after recovering the modal
    //   localStorage.removeItem(cookieName);
    //   // letting states update
    //   setTimeout(() => {
    //     recoverInit.current = true;
    //   }, 1000);
    // } else {
    //   initModelRecoverFlow();
    // }

    return componentDidUnmount;
  }, []);

  useEffect(
    function nodesDidUpdate() {
      setMetrics({
        ...metrics,
        ...{ tools: nodes.length },
      });
    },
    [nodes]
  );

  const placeEditModal = (activeNode, event) => {
    setEditModal({
      id: activeNode?.id,
      pane: reactFlowWrapper.current?.getBoundingClientRect(),
      node: activeNode,
      reactFlowInstance,
    });
  };

  useEffect(() => {
    // Block navigation and register a callback that
    // fires when a navigation attempt is blocked.
    const unblock = history.block((tx) => {
      // Navigation was blocked! Let's show a confirmation dialog
      // so the user can decide if they actually want to navigate
      // away and discard changes they've made in the current page.

      // unblock request if there is no cookie
      if (!localStorage.getItem(cookieName)) {
        // Unblock the navigation.
        unblock();

        // Retry the transition.
        tx.retry();

        return 0;
      }

      // show custom modal
      setUnsavedModal(true);
      // create a callback for to unblock navigation
      setDiscardUnsavedHandler({
        fn: () => {
          // delete the cookie
          localStorage.removeItem(cookieName);

          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        },
      });

      return 0;
    });
  }, [cookieName]);

  const findNodes = (edgeArray) => {
    var result = [];
    function recurs(edgeArray) {
      var ids = edges.reduce((ids, edge) => {
        if (edge.source != edge.target) {
          if (edgeArray.includes(edge.source)) {
            ids.push(edge.target);
          }
        }
        return ids;
      }, []);
      result = result.concat(ids);
      if (ids.length > 0) {
        recurs(ids);
      }
    }
    recurs(edgeArray);
    return result;
  };

  const exportDatamodel = useCallback(() => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify({ nodes: nodesRef.current, edges: edgesRef.current })
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'Model.less';
    link.click();
  }, []);

  const activeNodeHandler = useCallback(
    ({ node, event }) => {
      // stop click event on node text part
      if (node != null) {
        if (event.target.classList.contains('canvasActionText') & node?.data?.configured) {
          event.preventDefault();
          if (isModellingUser) {
            node.data.status = 'running';
            onNodesChange([]);
            getActiveData(node);
          }
        } else {
          if (node !== null) {
            setActiveNode(node);
            const pane = reactFlowWrapper.current.getBoundingClientRect();
            const nodeBoundingRect = event.target
              .closest('.react-flow__node')
              .getBoundingClientRect();
            placeEditModal({ ...node, ...{ event: event } });
          }
        }
      }
    },
    [reactFlowWrapper, nodes, edges]
  );

  const exportActiveData = useCallback(
    async (format) => {
      const result = await dispatch(
        runModellingExportAction({
          modelId: modelId,
          toolId: activeDataNode.id,
          format: format,
        })
      );

      // window.open(result.payload, '_blank');
    },
    [activeNode, activeDataNode]
  );

  const callbackFunction = (event) => {
    if (event['event'] == 'tool_execution') {
      const data = event['data'];
      const nodeObject = reactFlowInstance.getNode(data['id']);
      nodeObject.data.metadata = data['columns'];
      nodeObject.data.status = data['status'];
      // nodeObject.data.errorMessage = data.payload.error_message;
      nodeObject.data.configuration.cache = true;
      updateMetadataSubNodes(data['id'], data['columns']);
      onNodesChange([]);
      if (data?.status == 'Error') {
        showNotification('Tool: ' + event.nodeId, data?.error_message, 'error');
      }
    } else if (event['event'] == 'data') {
      try {
        const data = JSON.parse(event['data']);
        setActiveData({ data: data, metadata: event['metadata'], nodeId: event['nodeId'] });
        setMetrics({
          ...metrics,
          ...{ rows: event.metadata.rows, columns: event.metadata.nmbColumns },
        });
        const nodeObject = reactFlowInstance.getNode(event['nodeId']);
        nodeObject.data.configuration.cache = true;
        nodeObject.data.status = 'OK';
        onNodesChange([]);
      } catch (err) {
        console.log('err', err);
        callbackFunctionError(err);
      }
    } else if (event['event'] == 'executing_tools') {
      event['execution_order'].forEach((n) => {
        const nodeObject = reactFlowInstance.getNode(n);
        nodeObject.data.configuration.cache = false;
        nodeObject.data.status = 'Running';
      });
      onNodesChange([]);
    } else if (event['event'] == 'warning') {
      const nodeObject = reactFlowInstance.getNode(event['tool_id']);
      nodeObject.data.configuration.cache = false;
      nodeObject.data.status = 'Warning';
      showNotification('Tool: ' + event['tool_id'], event['data'], 'warning');
      onNodesChange([]);
    } else if (event['event'] == 'error') {
      showNotification('Error', event['error_message'], 'error');
      onNodesChange([]);
    } else if (event['event'] == 'cached') {
      const nodeObject = reactFlowInstance.getNode(event['nodeId']);
      nodeObject.data.configuration.cache = true;
      nodeObject.data.status = 'OK';
      onNodesChange([]);
    } else {
      console.log('Not matching anything');
    }
  };

  const callbackFunctionStop = () => {
    const updateNodes = structuredClone(nodes);

    updateNodes.map((node) => {
      if (node.data.status == 'Running') {
        node.data.status = null;
      }
    });
    setNodes(updateNodes);
  };

  const callbackFunctionError = (err) => {
    console.log('Something unexpected happened');
    const updateNodes = structuredClone(nodes);

    updateNodes.map((node) => {
      if (node.data.status == 'Running') {
        node.data.status = null;
      }
    });
    setNodes(updateNodes);

    showNotification('An error ocurred', 'The error was: ' + err, 'error');
  };

  const executeTool = async (nodeId) => {
    // const nodeObject = reactFlowInstance.getNode(nodeId);
    await runModellingService({
      modelId: modelId,
      toolId: nodeId,
      data: { nodes: nodes, edges: edges },
      callbackFunction: callbackFunction,
      callbackFunctionStop: callbackFunctionStop,
      callbackFunctionError: callbackFunctionError,
    });

    return 'Done';
  };

  const updateMetadataSubNodes = (node, data) => {
    const targets = edges.filter((edge) => edge.source === node);
    targets.map((target) => {
      const nodeObject = reactFlowInstance.getNode(target.target);
      if (nodeObject) {
        nodeObject.data[target.targetHandle + '_metadata'] = data;
      }
    });
    onNodesChange([]);
  };

  const getActiveData = async (nodeObject) => {
    const node = reactFlowInstance.getNode(nodeObject.id);
    if (!nodeObject.data.configuration.cache) {
      const subNodes = [...new Set(findNodes([node.data.configuration.tool_id]))];
      subNodes.map((node) => {
        const nodeObject = reactFlowInstance.getNode(node);
        if (nodeObject) {
          nodeObject.data.configuration.cache = false;
          nodeObject.data.status = null;
          nodeObject.data.configuration.execute = true;
        }
      });
    }

    node.data.status = 'Running';
    runModellingService({
      modelId: modelId,
      toolId: node.data.configuration.tool_id,
      data: { nodes: nodes, edges: edges },
      callbackFunction: callbackFunction,
      callbackFunctionStop: callbackFunctionStop,
      callbackFunctionError: callbackFunctionError,
    });
    setActiveDataNode(nodeObject);
  };

  const executeFullModel = () => {
    const runTool = async () => {
      await executeTool('runall');
    };
    return runTool();
  };

  const importModel = useCallback((model) => {
    const datamodel = JSON.parse(model);
    const nodes = datamodel.nodes;
    const edges = datamodel.edges;
    setInitialElements(nodes, edges);
  }, []);

  const setInitialElements = (nodes, edges) => {
    //Clear Cache for all tools
    nodes.forEach(node => {
      const incomingEdges = edges.filter(edge => edge.target === node.id);
      incomingEdges.forEach(edge => {
        if (node.data.configuration[edge.targetHandle] !== edge.source) {
          // console.log(`Node ${node.id} has incoming edges:`, incomingEdges);
          // console.log(`Edge from ${edge.source} to ${edge.target}`);
          // console.log(`The configuration is ${edge.source === node.data.configuration[edge.targetHandle]}`);
          try {
            Sentry.captureException(`The configuration is for ${node.id} is ${edge.source === node.data.configuration[edge.targetHandle]}. Current is ${node.data.configuration[edge.targetHandle]}. Edge is from ${edge.source} to ${edge.target}`);
          } catch (error) {
            console.log(error);
          }


          node.data.configuration[edge.targetHandle] = edge.source;
        }
      });
    });

    nodes.forEach((n) => {
      n.data.configuration.cache = false;
      n.data.status = null;
      delete n.dragging;
    });
    setNodes(nodes);
    setEdges(edges);
    // setElements({ nodes: nodes, edges: edges });
  };

  const isViewerFunction = (modelMeta) => {
    if (!isModellingUser) {
      return false;
    }
    if (modelMeta?.abilities?.includes('datamodel-update')) {
      return false;
    }
    if (modelMeta?.user?.id == user?.id) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const isViewer = isViewerFunction(modelMeta);
    setViewer(isViewer);
  }, [modelMeta, user]);

  useEffect(() => {
    async function fetchDataModelEffect() {
      if (!recover && !isNaN(modelId) && modelId !== 'new') {
        const result = await dispatch(getModelAction({ modelId, versionId }));
        const model = result.payload;
        setViewer(!result.payload?.abilities?.includes('datamodel-update'));
        setModelMeta(result.payload);
        setReady(true);
        var nodes = JSON.parse(model.current_version?.nodes || '[]');
        var edges = JSON.parse(model.current_version?.edges || '[]');

        if (versionId) {
          const model_version = model.old_version;
          nodes = JSON.parse(model_version.nodes || '[]');
          edges = JSON.parse(model_version.edges || '[]');
        }

        setInitialElements(nodes, edges);
      } else {
        setViewer(false);
        setReady(true);
      }
    }

    fetchDataModelEffect();
  }, [modelId]);

  useEffect(() => {
    async function setBreadcrumbs() {
      const folder = searchFolder(folders, modelMeta?.folder?.id);
      if (folder) {
        // we also remove our null ID folder
        folder.breadcrumbs.pop();
        folder.breadcrumbs = folder.breadcrumbs.reverse();
      }
    }
    setBreadcrumbs();
  }, [modelId, folders]);

  const stateChangeCallback = (state) => {
    setPendingNodeChanges(state);
  };

  useEffect(
    function defaultModelUpdates() {
      localStorage.setItem(
        cookieName,
        JSON.stringify({
          modelId: modelId,
          //nodes: nodes,
          //edges: edges,
          timestamp: Date.now(),
        })
      );
    },
    [nodes, edges]
  );

  function closeEditTool() {
    setEditModal(null);
    setPendingNodeChanges([]);
  }

  const saveButtonClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      //convert schedule time to UTC
      if (modelId === 'new') {
        setCreateModal(true);
      } else {
        modelSaveHandler({ model: { nodes: nodesRef.current, edges: edgesRef.current } });
      }
    },
    [modelId]
  );

  const saveAsClickHandler = useCallback((e) => {
    e.preventDefault();
    //convert schedule time to UTC
    setCreateModal(true);
  }, []);

  // const addUndoRedoHandler = () => {
  //   setElements({ nodes: [...nodes], edges: [...edges] });
  // };

  const onDeleteEdge = useCallback(
    (edgeArray) => {
      takeSnapshot();
      const editNodes = structuredClone(nodes);

      edgeArray.forEach((edge) => {
        const target = editNodes.find((node) => node.id === edge.target);
        target.data.configuration[edge.targetHandle] = undefined;
        target.data.configuration.cache = false;
        target.data.status = null;
        target.data.configuration.execute = false;
        const subNodes = findNodes([target.data.configuration.tool_id]);
        subNodes.map((node) => {
          const nodeObject = editNodes.find((newNode) => node == newNode.id);
          if (nodeObject) {
            nodeObject.data.configuration.cache = false;
            nodeObject.data.status = null;
          }
        });
      });
      setNodes(editNodes);
    },
    [nodes, edges]
  );

  const annotationHandler = useCallback(() => {
    if (localStorage.getItem('annotations') == 'True') {
      localStorage.setItem('annotations', 'False');
      setAnnotations(false);
    } else {
      localStorage.setItem('annotations', 'True');
      setAnnotations(true);
    }
    setNodes(
      nodesRef.current.map((node) => {
        return node;
      })
    );

    onNodesChange([]);
  }, []);

  const snapToGridHandler = useCallback(() => {
    if (localStorage.getItem('snapToGrid') == 'True') {
      localStorage.setItem('snapToGrid', 'False');
      setSnapToGrid(false);
    } else {
      localStorage.setItem('snapToGrid', 'True');
      setSnapToGrid(true);
    }
  }, []);

  const modelSaveHandler = async (data = {}) => {
    // edit mode connection save
    await dispatch(saveModelVersionAction({ data: data, modelId: modelId }));
    localStorage.removeItem(cookieName);
  };

  const newModelSaveHandler = () => {
    modelFormState.current.time = timeToUTC(modelFormState.current.time);
    const data = {
      ...modelFormState.current,
      model: { nodes: nodes, edges: edges },
      ...{ folder_id: sourceFolder },
    };
    setCreateModal(false);
    //model save
    dispatch(allFolderAction());
    return dispatch(createModelAction(data)).then((data) => {
      localStorage.removeItem(cookieName);
      navigate(`/modelling/edit/${data.payload.data.id}`);
    });
  };

  const goBackHandler = useCallback(() => {
    let path = '/';
    localStorage.removeItem(cookieName);
    if (sourceFolder) {
      path = `/folder/${sourceFolder}`;
    } else {
      path = '/folder';
    }

    navigate(path);
  }, [cookieName, sourceFolder]);

  const undoHandler = useCallback(() => {
    undoRef.current();
  }, []);
  const redoHandler = useCallback(() => {
    redoRef.current();
  }, []);

  const [viewerModal, setViewerModal] = useState(true);

  if (ready) {
    return (
      <div className="flex flex-col rounded-md w-full h-screen ">
        <div
          tabIndex="1"
          className="absolute mx-auto transform -translate-x-1/2  left-1/2 top-3 w-[95vw] bg-zinc-50 dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-600 rounded-lg px-3 z-10 select-none"
        >
          <ActionBar
            saveHandler={saveButtonClickHandler}
            saveAsHandler={saveAsClickHandler}
            exportDatamodel={exportDatamodel}
            importModel={importModel} //CHECK
            clearCacheHandler={clearCacheHandler}
            restartSessionHandler={restartSessionHandler}
            runFullModelHandler={runFullModelHandler}
            model={modelMeta?.name ? modelMeta?.name : 'New Model'}
            exportActiveData={exportActiveData}
            undo={undoHandler}
            redo={redoHandler}
            goBack={goBackHandler}
            annotationHandler={annotationHandler}
            snapToGridHandler={snapToGridHandler}
            snapToGrid={snapToGrid}
            annotations={annotations}
            isModellingUser={isModellingUser}
          />
        </div>
        {backendSession?.sessionId == 'temporary' && (
          <div className="shadow left-1/2 -translate-x-1/2 absolute top-[115px] px-3 py-1.5 bg-yellow-100  border-yellow-400 rounded text-yellow-800 text-[12px] z-10">
            <p className="animate-pulse">
              We are waking a larger instance, it might take a few moments
            </p>
          </div>
        )}
        {backendSession?.deleted_at != null && (
          <div className="shadow left-1/2 -translate-x-1/2 absolute top-[115px] px-3 py-1.5 bg-red-100  border-red-400 rounded text-red-800 text-[12px] z-10">
            <p className="animate-pulse">
              Your session has been closed due to inactivity - please restart your session in the
              top-right corner on the button with a two arrows icon.
            </p>
          </div>
        )}
        <Canvas
          reactFlowWrapper={reactFlowWrapper}
          reactFlowInstance={reactFlowInstance}
          setReactFlowInstance={setReactFlowInstance}
          onNodesDelete={onDeleteTool}
          nodes={nodes}
          onNodesChange={onNodesChange}
          edges={edges}
          setEdges={setEdges}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onDeleteEdge}
          onConnect={onConnect}
          takeSnapshot={takeSnapshot}
          onDrop={onDrop}
          setActiveNode={activeNodeHandler}
          metrics={metrics}
          snapToGrid={snapToGrid}
          onNodeContextMenu={onNodeContextMenu}
          onPaneContextMenu={onPaneContextMenu}
          onMove={() => {
            setMenu(null);
          }}
          menu={menu}
          setMenu={setMenu}
          isModellingUser={isModellingUser}
        />
        {editModal && (
          <FlyingModal
            classes={
              [
                'select',
                'unique',
                'compare',
                'dateFormat',
                'diff',
                'parseJson',
                'pivot',
                //'join',
                'transpose',
                //'texttransform',
                'filler',
                'rowid',
                'replace',
                'runninginterval',
                'createRows',
                'today',
                'stringtodate',
              ].includes(activeNode?.name)
                ? ''
                : ['api', 'changeColumns', 'input'].includes(activeNode?.name)
                  ? '!max-w-[625px] !min-w-[625px] overflow-y-scroll overflow-x-visible'
                  : 'overflow-y-scroll overflow-x-visible'
            }
            open={editModal}
            position={{
              left: editModal.left,
              top: editModal.top,
              right: editModal.right,
              buttom: editModal.buttom,
            }}
            //classes="w-auto h-auto"
            // onClose={() => closeEditTool()}
            onClick={() => closeEditTool()}
            id={'configWindow'}
            content={
              <>
                <FlyingModalHeader
                  class
                  header="Configure"
                  content={
                    <p className="mt-0.5 max-w-4xl text-sm gap-1 inline-flex text-zinc-500 dark:text-zinc-200">
                      See our
                      <div className="relative border-b-2 border-transparent hover:border-[#5DA6FC]">
                        <a
                          target="_newtab"
                          href="https://resources.less.tech/less-tech/modelling/action-guides"
                          className="inline-flex items-center "
                        >
                          Action guide{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2.5"
                            stroke="currentColor"
                            className="ml-[1px] w-3 h-3"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                            />
                          </svg>
                        </a>
                      </div>
                      if you need any help
                    </p>
                  }
                  onClick={() => closeEditTool()}
                />
                <div id="confWindow" className="p-6">
                  <div className="space-y-6 py-6 sm:divide-y sm:divide-zinc-200 dark:divide-zinc-700 sm:py-0">
                    <FormBuilderModelling
                      key={`${activeNode?.name}_${activeNode.id}`}
                      {...{
                        inputs: actionInputs?.[activeNode?.name]?.fields,
                        initState: { ...activeNode?.data?.configuration },
                        activeNode: activeNode,
                        databases,
                        connections,
                        tables,
                        stateChangeCallback,
                      }}
                    />
                  </div>
                </div>
                {/* Action buttons */}
                <div className="w-full bg-zinc-100 dark:bg-zinc-900 bottom-0 z-[100] sticky border-t rounded-b-lg border-zinc-200 dark:border-zinc-700 px-4 py-4 sm:px-6">
                  <div className="flex justify-end space-x-3 mr-2">
                    <TertiaryBtn text="Cancel" onClick={() => closeEditTool()} />
                    {/* <SecondaryBtn text="Cancel" onClick={() => closeEditTool()} /> */}
                    {isModellingUser && (
                      <>
                        <SecondaryBtn
                          text="Save"
                          onClick={() => {
                            closeEditTool();
                            let nodeconfig = { ...activeNode?.data };
                            nodeconfig.configuration = { ...pendingNodeChanges };
                            nodeconfig.configured = true;
                            nodeconfig.configuration.cache = false;
                            setNodes(
                              nodes.map((node) => {
                                if (node.id === activeNode.id) {
                                  // it's important to create a new object here, to inform React Flow about the changes
                                  node.data = { ...nodeconfig };
                                }

                                return node;
                              })
                            );
                            activeNode.data = { ...nodeconfig };
                            onNodesChange([{ id: activeNode.id, type: 'dimensions' }]);

                            const node = reactFlowInstance.getNode(activeNode.id);
                            if (!activeNode.data.configuration.cache) {
                              const subNodes = [
                                ...new Set(findNodes([node.data.configuration.tool_id])),
                              ];
                              subNodes.map((node) => {
                                const nodeObject = reactFlowInstance.getNode(node);
                                if (nodeObject) {
                                  nodeObject.data.configuration.cache = false;
                                  nodeObject.data.status = null;
                                  nodeObject.data.configuration.execute = true;
                                }
                              });
                            }
                          }}
                        />
                        <PrimaryBtn
                          useEnter={true}
                          text="Save and Run"
                          onClick={() => {
                            closeEditTool();
                            let nodeconfig = { ...activeNode?.data };
                            nodeconfig.configuration = { ...pendingNodeChanges };
                            nodeconfig.configured = true;
                            nodeconfig.configuration.cache = false;
                            setNodes(
                              nodes.map((node) => {
                                if (node.id === activeNode.id) {
                                  // it's important to create a new object here, to inform React Flow about the changes
                                  node.data = { ...nodeconfig };
                                }

                                return node;
                              })
                            );

                            activeNode.data = { ...nodeconfig };
                            // addUndoRedoHandler();
                            onNodesChange([{ id: activeNode.id, type: 'dimensions' }]);
                            getActiveData(activeNode);
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            }
          />
        )}
        {createModal && (
          <BasicModal
            open
            classes="w-[600px] 2xl:w-[700px] min-w-lg"
            onClose={() => setCreateModal(false)}
            content={
              <>
                <div className="p-8 overflow-visible">
                  <ModalHeader header="Save new model" content={''} />
                  <ConfigModel stateChangeCallback={configStateCallback} />
                </div>
                {/* Action buttons */}
                <div className="w-full bg-zinc-100 dark:bg-zinc-900 bottom-0 sticky border-t border-zinc-200 dark:border-zinc-700 px-4 py-5 sm:px-6">
                  <div className="flex justify-end space-x-3 mr-2">
                    <SecondaryBtn text="Cancel" onClick={() => setCreateModal(false)} />
                    <PrimaryBtn text="Save Model" onClick={newModelSaveHandler} />
                  </div>
                </div>
              </>
            }
          />
        )}
        {viewer && viewerModal && (
          <BasicModal
            open
            classes="w-[400px]"
            //onClose={() => setViewerModal(false)}
            content={
              <>
                <div className="p-8 overflow-visible">
                  <ModalHeader header="Viewer role" content={''} />
                  <p className="mt-5 text-sm align-baseline text-zinc-500 dark:text-zinc-300">
                    You are a Viewer of this model. You can use it as a playground, but will not be
                    able to save it.
                  </p>
                </div>
                <div className="w-full bg-zinc-100 dark:bg-zinc-900 bottom-0 sticky border-t border-zinc-200 dark:border-zinc-700 px-4 py-5 sm:px-6">
                  <div className="flex justify-end space-x-3 mr-2">
                    <SecondaryBtn text="Go back" onClick={() => goBackHandler()} />
                    <PrimaryBtn text="Continue" onClick={() => setViewerModal(false)} />
                  </div>
                </div>
              </>
            }
          />
        )}
        {recoverModal && (
          <DeleteModal
            open
            header="Recovery Notice"
            description="You have an unsaved model from your previous activity. Do you want to recover it?"
            canceltxt="Recover"
            deletetxt="Discard"
            onCancel={recoverClickHandler}
            deletepress={discardModelHandler}
          />
        )}
        {unsavedModal && (
          <DeleteModal
            open
            header="Unsaved Changes"
            onClose={() => setUnsavedModal()}
            description="You have unsaved changes in your model. Are you sure you want to continue?"
            canceltxt="Stay"
            deletetxt="Leave"
            onCancel={() => setUnsavedModal(false)}
            deletepress={discardUnsavedHandler.fn}
          />
        )}
      </div>
    );
  }
};

const ReactFlowInstance2 = () => {
  const [activeData, setActiveData] = useState({ data: [] });
  const canvasProps = {
    setActiveData: setActiveData,
  };
  return (
    <ReactFlowProvider>
      <Modelling {...canvasProps} />
      <div tabIndex="3" className="absolute mx-auto transform -translate-x-1/2  left-1/2 bottom-3">
        <DataTable
          input={activeData}
          pager={{ size: 1000, total: 0 }}
          sidebar={false}
          expand={true}
        />
      </div>
    </ReactFlowProvider>
  );
};

Modelling.propTypes = {
  recover: PropTypes.bool,
  setActiveData: PropTypes.func,
};

export default ReactFlowInstance2;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import SecondaryBtn from 'components/Buttons/SecondaryBtn';
import ModalHeader from 'components/Layout/ModalHeader';
import TextDescInput from 'components/Inputs/TextDesc';
import PrimaryBtn from 'components/Buttons/PrimaryBtn';

const CreateFolderModal = ({ onClose, onSubmit }) => {
  const [name, setName] = useState();

  return (
    <div className="p-8">
      <ModalHeader
        header="Create Folder"
        content={
          <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
            Name your folder and you are good to go
          </p>
        }
      />
      <div>
        <div className="py-5 space-y-5">
          <TextDescInput
            header="Name folder"
            placeholder={'some_folder_name'}
            type="text"
            name="foldername"
            onChange={setName}
          />
        </div>
      </div>
      <div className="bg-zinc-50 dark:bg-zinc-900 border-t border-transparent dark:border-zinc-700 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <SecondaryBtn text="Cancel" onClick={onClose} />
          <PrimaryBtn
            text="Create"
            onClick={() => {
              onSubmit(name);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

CreateFolderModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateFolderModal.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
};

export default CreateFolderModal;

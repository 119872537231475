import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { Listbox, Transition } from '@headlessui/react';
//import { ChevronDownIcon } from '@heroicons/react/solid';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';
//import { Float } from '@headlessui-float/react';
import PrimaryTooltip from 'components/Tooltips/PrimaryTooltip';
import BasicDropdown from './BasicDropdown';

const GridRadioGroup = (props) => {
  //   const [columns] = useState([...columnsArr]);

  //   const handleChange = (e, id) => {
  //     const { value } = e.target;
  //     setColumn((col) => col?.map((list, index) => (index === id ? { ...list, col: value } : list)));
  // I'm just using console.log to show that it updates
  // Right now, the handleChange just creates a new array that we need to use for overwriting the incoming value when moving to production

  const [selected, setSelected] = useState(props.defaultSelected);

  const handleChange = (column, e) => {
    const selectedOption = { ...selected };
    if (e !== null) {
      selectedOption[column] = e;
    } else {
      delete selectedOption[column];
    }
    setSelected({ ...selectedOption });
    props.onChange(selectedOption);
  };

  const availableOptions = props.columns.map((item) => item.value);
  const notExisting = props.defaultSelected
    ? Object.keys(props.defaultSelected)
        .filter((option) => !availableOptions.includes(option))
        .map((key) => {
          return { label: key, value: key, type: props.defaultSelected[key], invalid: true };
        })
    : [];

  const allColumns = [...notExisting, ...props.columns];

  const found = (value) => {
    return props.items.find((obj) => {
      return obj.value === value
    });
  };


  return (
    <ModelingInputTemplate {...props}>
      <div className="flex flex-col shadow-sm ring-1 dark:bg-zinc-700 dark:shadow-zinc-600/50 ring-zinc-200 dark:ring-zinc-600 rounded-md overflow-hidden">
        <div className="-my-2 px-1 w-full max-h-80 overflow-y-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full text-left divide-y divide-zinc-200 dark:divide-zinc-600">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="text-xs border-b px-2 font-medium text-zinc-700 dark:text-zinc-50"
                    >
                      Column
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 border-b text-left text-xs font-medium text-zinc-700 dark:text-zinc-50"
                    >
                      {props.SecondColumnHeader}
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-600  bg-white dark:bg-zinc-700 ">
                  {allColumns.map((column, i) => {
                    return (
                      <tr key={column.value}>
                        {column.label.length < 20 ? (
                          <td
                            className={`px-2 py-1.5  w-[150px] truncate max-w-[150px] rounded-md text-xs font-normal ${
                              column?.invalid ? 'text-red-500' : 'text-zinc-600 dark:text-zinc-100'
                            }`}
                          >
                            {column.label}
                          </td>
                        ) : (
                          <td
                            className={`px-2 py-1.5  w-[150px] max-w-[150px] rounded-md text-xs font-normal ${
                              column?.invalid ? 'text-red-500' : 'text-zinc-600 dark:text-zinc-100'
                            }`}
                          >
                            <PrimaryTooltip tooltipText={column.label} topClasses="!justify-start">
                              <div className="truncate max-w-[150px] pr-2">{column.label}</div>
                            </PrimaryTooltip>
                          </td>
                        )}

                        <td className="px-2 py-1.5 text-xs rounded-md">
                          <BasicDropdown items={props.items} placeholder={props.placeholder} show={found(selected?.[column.value])?.label} defaultSelected={selected?.[column.value]} onChange={(e) => handleChange(column.value, e, i)}/>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModelingInputTemplate>
  );
};

GridRadioGroup.propTypes = {
  header: PropTypes.string,
  columns: PropTypes.array,
  SecondColumnHeader: PropTypes.array,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  defaultSelected: PropTypes.object,
  inputArr: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default GridRadioGroup;

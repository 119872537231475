import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PrimaryBtn from 'components/Buttons/PrimaryBtn';
import PasswordInput from 'components/Inputs/PasswordInput';
import TextDescInput from 'components/Inputs/TextDesc';

const ChangeNameModal = ({ submitHandler }) => {
  const [password, setPassword] = useState();
  const [name, setName] = useState();

  const onClick = () => {
    submitHandler({ name, password });
  };

  return (
    <div className="p-8">
      <div className="space-y-5 mb-5">
        <PasswordInput
          header="Enter password"
          placeholder="Enter your password"
          value={password}
          onChange={setPassword}
        />
        <TextDescInput
          type="text"
          header="New name"
          placeholder="Enter your new name"
          value={name}
          onChange={setName}
        />
      </div>

      <div className="dark:bg-zinc-900 dark:border-zinc-700 border-t bg-zinc-50 px-3 rounded-b-lg -mb-8 -mx-8 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div className="mr-2 space-x-3">
          <PrimaryBtn text="Update name" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

ChangeNameModal.propTypes = {
  onClose: PropTypes.func,
  submitHandler: PropTypes.func,
};

ChangeNameModal.defaultProps = {
  onClose: () => null,
  submitHandler: () => null,
};

export default ChangeNameModal;

import { localTime, timeToLocal, stringToDate } from 'utils/unitConversion';

export function lastToString(lastUpdate) {
  const sinceLast = new Date() - stringToDate(localTime(lastUpdate));

  let days, total_hours, total_minutes, total_seconds;

  total_seconds = parseInt(Math.floor(sinceLast / 1000));
  total_minutes = parseInt(Math.floor(total_seconds / 60));
  total_hours = parseInt(Math.floor(total_minutes / 60));
  days = parseInt(Math.floor(total_hours / 24));

  // let minutes
  // minutes = parseInt(total_minutes % 60);
  // console.log(minutes);
  if (!lastUpdate) {
    return 'Never';
  } else if (days >= 365) {
    return 'More than 1 year ago';
  } else if (days < 365 && days > 1) {
    return `${days} days ago`;
  } else if (total_hours == 1) {
    return `${total_hours} hour ago`;
  } else if (days <= 1 && total_hours > 0) {
    return `${total_hours} hours ago`;
  } else if (total_minutes < 60 && total_minutes >= 1) {
    return `${total_minutes + 1} minutes ago`;
  } else if (total_minutes <= 0) {
    return 'Less than a minute ago';
  } else {
    return 'Never';
  }
}

export function nextToString(nextUpdate) {
  if (nextUpdate) {
    const toNext = stringToDate(localTime(nextUpdate)) - new Date();
    let days, total_hours, total_minutes, total_seconds;

    total_seconds = parseInt(Math.floor(toNext / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));

    // let minutes
    // minutes = parseInt(total_minutes % 60);
    // console.log(hours, minutes);
    // let hours
    // hours = parseInt(total_hours % 24);

    if (days >= 365) {
      return 'In more than 1 year';
    } else if (days < 365 && days > 1) {
      return `In ${days} days`;
    } else if (days < 1 && total_hours > 0) {
      return `In ${total_hours} hours`;
    } else if (total_minutes < 60 && total_minutes >= 1) {
      return `In ${total_minutes + 1} minutes`;
    } else if (total_minutes <= 0) {
      return 'In less than a minute';
    } else {
      return 'Never';
    }
  } else {
    return 'Never';
  }
}

export function frequencyToString(schedule) {
  if (schedule?.time) {
    const localTime = timeToLocal(schedule.time).substring(0, 5);

    switch (schedule?.frequency) {
      case 'hourly':
        return `Hourly @ ${localTime.substring(5, 2)}`;
      case 'daily':
        return `Daily @ ${localTime}`;
      case 'weekly':
        return `${schedule?.days} @ ${localTime}`;
      case 'montly':
        return `Montly @ ${localTime}`;
      case 'first_of_month':
        return `First of month @ ${localTime}`;
      default:
        return 'None';
    }
  } else {
    return 'None';
  }
}

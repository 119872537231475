import React from 'react';
import PropTypes from 'prop-types';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import TinyBadge from '../Badges/TinyBadge';

const ConnectorCard = ({ name, description, categories, logo, onClick }) => {
  return (
    <div
      className={`transition-all duration-300 hover:ring-1 dark:bg-zinc-800/50 bg-transparent hover:ring-[#5DA6FC] hover:border-[#5DA6FC] dark:hover:ring-white dark:hover:border-white hover:ease-in-out border border-zinc-200 dark:border-zinc-600 hover:scale-[1.01] hover:shadow-[0_15px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl`}
      onClick={onClick}
      role="button"
    >
      <div className="group cursor-pointer flex items-center rounded-xl justify-center ml-3 my-5">
        <div className="px-4 bg-white dark:bg-zinc-50 border dark:border-zinc-600 border-transparent rounded-full">
          <img
            className="w-36 h-44 object-center align-middle justify-center content-center"
            src={logo}
            alt=""
          />
        </div>
        <div className="flex flex-col flex-1 justify-between rounded-r-xl w-[250px] h-[223px] px-4 pt-6 pb-3">
          <div className="space-y-2 w-full">
            <p className="font-bold text-base text-zinc-700 dark:text-zinc-50">{name}</p>

            <div className="space-x-1">
              {categories.map((cat) => (
                <TinyBadge key={cat.id} text={cat.name} />
              ))}
            </div>

            <p className="font-extralight text-xs text-zinc-700 dark:text-zinc-50">{description}</p>
          </div>
          <div className="mt-2 inline-flex items-center ">
            <a className="transition-all duration-50 ease-in font-semibold text-normal text-xs group-hover:underline text-zinc-500 dark:text-zinc-200 group-hover:text-zinc-700 dark:group-hover:text-zinc-50">
              Connect to {name}
            </a>
            <ArrowNarrowRightIcon
              className="invisible transition-all duration-50 ease-in group-hover:visible ml-1 h-5 w-5 text-zinc-400 dark:text-zinc-300 group-hover:text-zinc-700 dark:group-hover:text-zinc-50"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConnectorCard.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

ConnectorCard.defaultProps = {
  logo: '',
  name: 'Connector Name',
  tag: [],
  description: 'Connector detail',
  onClick: () => null,
};

export default ConnectorCard;

const calcModelStatus = (model) => {
  let statusCode = 0

  if (model?.in_queue?.length > 0) {
    statusCode = 50
    return statusCode
  }

  if (!model?.last_task) {
    statusCode = 0
    return statusCode
  }

  if (model.last_task.run_code === null) {
    statusCode = 100
    return statusCode
  }

  return model.last_task.run_code
}


const calcConnectionStatus = (connection) => {
  let statusCode = 0

  if (connection?.in_queue?.length > 0) {
    statusCode = 50
    return statusCode
  }

  if (!connection?.last_task) {
    statusCode = 0
    return statusCode
  }

  if (connection.last_task.run_code === null) {
    statusCode = 100
    return statusCode
  }

  return connection.last_task.run_code
}

export {
  calcModelStatus,
  calcConnectionStatus
};
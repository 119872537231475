/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import * as Tooltip from '@radix-ui/react-tooltip';

const RadixTooltip = ({ side, sideOffset, children, tooltipText, childClasses, classes }) => {
  return (
    <Tooltip.TooltipProvider>
      <Tooltip.Root delayDuration={150}>
        <Tooltip.Trigger className={`${childClasses} cursor-default`}>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side={side}
            sideOffset={sideOffset}
            className={`${classes} max-w-[220px] z-[1000000] pointer-events-none`}
          >
            <span className="border border-zinc-400/80 absolute p-[1px] h-full w-full rounded-lg bg-gradient-to-tr bg-zinc-100/50 dark:bg-zinc-800/80 backdrop-blur-sm"></span>
            <span className="relative rounded-lg bg-black dark:bg-zinc-200 opacity-90 dark:opacity-0 w-fit max-w-[220px]">
              <div className="max-w-fit font-medium text-xs text-left text-zinc-800 dark:text-red-100 break-words p-2 whitespace-normal">
                {tooltipText}
              </div>
            </span>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.TooltipProvider>
  );
};

RadixTooltip.propTypes = {
  children: PropTypes.any,
  tooltipText: PropTypes.string,
  childClasses: PropTypes.string,
  classes: PropTypes.string,
  side: PropTypes.string,
  sideOffset: PropTypes.number,
};

RadixTooltip.defaultProps = {
  children: null,
  tooltipText: 'REMEMBER TEXT',
  childClasses: null,
  side: 'top',
  sideOffset: 4,
};

export default RadixTooltip;

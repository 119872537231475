/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// components
// import MultiToggle from 'components/Layout/EmptyHeader';
// import SelectCard from 'components/Cards/SelectCard';
import FormBuilder from 'components/Inputs/FormBuilder';
import { timeToLocal } from 'utils/unitConversion';

import {
  ConnectorIdHiddenField,
  ConnectorNameInputField,
  DatabaseSelectField,
  DatabaseHiddenField,
  FrequencySelectFields,
} from 'components/Inputs/DynamicInputFields';

const ConfigConnector = ({ connector, connection, inputs, ...props }) => {
  const { databases, token, mode } = props;
  const { id, name } = connector;
  const connectorId = connection?.connectorId || id;
  const connectionName = connection?.name || name;
  const databaseId = useMemo(() => connection?.databaseId || databases?.[0]?.id, [databases]);

  const connectorIdField = ConnectorIdHiddenField(connectorId);
  const frequencySelector =
    connector.id == 53 || connector.id == 83
      ? {}
      : connector.id == 72
        ? FrequencySelectFields({
          types: [
            {
              value: 'weekly',
              label: 'Weekly',
            },
            {
              value: 'first_of_month',
              label: 'First Day of Month',
            },
            {
              value: 'never',
              label: 'Never',
            },
          ],
        })
        : FrequencySelectFields({
          types: [
            {
              value: 'hourly',
              label: 'Hourly',
            },
            {
              value: 'daily',
              label: 'Daily',
            },
            {
              value: 'weekly',
              label: 'Weekly',
            },
            {
              value: 'first_of_month',
              label: 'First Day of Month',
            },
            {
              value: 'never',
              label: 'Never',
            },
          ],
        });

  const optionalConnectorField = ConnectorNameInputField(connectorId, connectionName);

  const databaseIdField =
    databases.length > 1
      ? DatabaseSelectField(databases, connectorId, databaseId)
      : DatabaseHiddenField(connectorId, databaseId);

  const tableInput = inputs.find((input) => input.fieldname === 'tablename') ? [] : [{
    fieldname: 'tablename',
    formname: 'Table prefix',
    description:
      'Input a prefix for your tables. A prefix helps you distinguish between tables if you have multiple connections to the same Connector',
    fieldtype: 'string',
    withtooltip: true,
    tooltip:
      'A prefix will help you distinguish between duplicate tables from the same Connector when you work with them in Less modelling or any other 3rd party tool. In other words, if you create two Meta Ads connections, you will have two of each table (one for each connection). Prefixes help you distinguish between those tables.',
    placeholder: 'Meta_US_',
  }];

  const sortedInputs = [
    connectorIdField,
    optionalConnectorField,
    databaseIdField,
    ...inputs,
    ...tableInput,
    frequencySelector,
  ];

  const getInitState = () => {
    let state = {};

    if (connection) {
      //edit mode
      state = {
        ...JSON.parse(connection?.values || {}),
        // for edit mode we need to change connector to connection ID
        // connection_id: connection?.id,
        name: connection?.name,
        frequency: connection?.schedule?.frequency,
        days: connection?.schedule?.days,
        time: timeToLocal(connection?.schedule?.time),
        databaseId,
        token: null,
        connection_id: connection.id,
      };
    } else {
      const defaultValues = inputs.reduce(function (object, input) {
        if (input.id != -1 && input.defaultvalue) {
          object[input.fieldname] = input.defaultvalue;
        }
        return object;
      }, {});
      // add mode
      // default frequency values
      state = {
        ...defaultValues,
        databaseId,
        connection_id: connectorId,
        name: connectionName,
        frequency: 'daily',
        time: dayjs().format('HH:mm'),
        updateAction: 'replace',
      };

      if (connector.id != 53 && connector.id != 83) {
        state.frequency = 'daily';
      }

      if (token) {
        state.token = token;
      }
    }
    return state;
  };

  // edit mode customizations
  if (mode === 'edit') {
    // change done button label to "Save"
    props.submitButtonLabel = 'Save';
  }

  return (
    <div>
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-zinc-200 dark:divide-zinc-600 sm:py-0">
        <FormBuilder
          {...{
            ...props,
            inputs: sortedInputs,
            initState: getInitState(),
          }}
        />
      </div>
    </div>
  );
};

ConfigConnector.propTypes = {
  cancelHandler: PropTypes.func,
  connection: PropTypes.shape({
    connectorId: PropTypes.any,
    databaseId: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    schedule: PropTypes.shape({
      days: PropTypes.any,
      frequency: PropTypes.any,
      time: PropTypes.any,
    }),
    values: PropTypes.string,
  }),
  connector: PropTypes.shape({
    help_text: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  deleteHandler: PropTypes.func,
  open: PropTypes.bool,
  submitHandler: PropTypes.func,
  inputs: PropTypes.array,
};

ConfigConnector.defaultProps = {
  inputs: [],
};

export default ConfigConnector;

import React from 'react';
import PropTypes from 'prop-types';

const Description = (props) => {
  return (
    <p className="text-xs text-justify font-medium text-zinc-700 dark:text-zinc-50">
      {props.header}
    </p>
  );
};

Description.propTypes = {
  header: PropTypes.string.isRequired,
};

Description.defaultProps = {
  header: '',
};

export default Description;

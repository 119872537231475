import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// components
import ConnectorCard from 'components/Cards/ConnectorCard';
import SearchInput from 'components/Inputs/SearchInput';
import SmallBadge from 'components/Badges/SmallBadge';

const ConnectorGrid = ({ connectors, connectorCategories, connectHandler }) => {
  const [selectedTag, setSelectedTag] = useState();
  const [filteredConnectors, setFilteredConnectors] = useState(connectors);
  const [activeItem, setActiveItem] = useState(-1);

  const ConnectionFilter = () => {
    const filtered = selectedTag
      ? connectors.filter((c) => c.categories.some((cat) => selectedTag === cat.name))
      : connectors;
    setFilteredConnectors(filtered);
  };

  useEffect(ConnectionFilter, [selectedTag]);
  useEffect(ConnectionFilter, [connectors]);

  const ConsAsc = [...filteredConnectors].sort((a, b) => (a.name > b.name ? 1 : -1));
  const CatAsc = [...connectorCategories].sort((a, b) => (a.name > b.name ? 1 : -1));

  const onSearchInputChange = (val) => {
    if (val === '') {
      setFilteredConnectors(connectors);
    }

    const filtered = connectors.filter(
      (c) => c.name.toLowerCase().match(val) || c.description.match(val)
    );
    setSelectedTag('');
    setFilteredConnectors(filtered);
  };

  const handler = (name) => {
    // filters
    name === selectedTag ? setSelectedTag('') : setSelectedTag(name);
    // changes class styling
    setActiveItem((currentItem) => (currentItem !== name ? name : -1));
  };

  return (
    <div className="h-[600px] 2xl:h-[700px] rounded-lg">
      <div>
        <div className="sticky top-0 backdrop-blur-lg px-10 pt-8 z-50 bg-white dark:bg-zinc-900">
          <div className="flex justify-between items-end">
            <div>
              <h3 className="text-xl leading-6 font-bold text-zinc-700 dark:text-zinc-50">
                Create New Connector
              </h3>
              <p className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-200">
                Create and add Connectors to your platform. Visit{' '}
                <span>
                  <a
                    href="https://resources.less.tech/less-tech/connectors/connector-guides"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm underline decoration-2 decoration-[#5DA6FC]
                                  underline-offset-[3px] text-zinc-500 dark:text-zinc-200 font-medium "
                  >
                    our guide
                  </a>
                </span>{' '}
                to get help with any Connector
              </p>
            </div>
            <div className="w-1/4 h-auto">
              <SearchInput placeholder="Search Connectors" onChange={onSearchInputChange} />
            </div>
          </div>
          <div className="mt-7 justify-between flex">
            {CatAsc.map(({ name, ...params }) => (
              <SmallBadge
                key={name}
                text={name}
                classes={activeItem === name ? 'shadow-xl' : '!border-transparent'}
                onClick={() => handler(name)}
                isSelected={name === selectedTag}
                {...params}
              />
            ))}
          </div>
          <div className="my-6 -mx-10 relative flex-1 flex flex-row">
            <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
            <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
          </div>
        </div>
        <ul
          role="list"
          className="mt-5	pb-10 px-10 grid grid-cols-1 gap-6 content-center lg:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 3xl:grid-cols-3"
        >
          {ConsAsc.map((con) => (
            <li key={con.name}>
              <ConnectorCard {...{ ...con, onClick: () => connectHandler(con) }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ConnectorGrid.propTypes = {
  connectors: PropTypes.array,
  connectorCategories: PropTypes.array,
  connectHandler: PropTypes.func,
};

ConnectorGrid.defaultProps = {
  connectors: [],
  connectorCategories: [],
  connectHandler: () => null,
};

export default ConnectorGrid;

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { removeNotification } from 'redux/reducers/notification';
import Notification from './Notification';

const TOAST_HEIGHT = 80; // Adjust this based on your actual toast height
const GAP = 8; // Gap between toasts

const NotificationManager = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(({ notifications }) => notifications);
  const [expanded, setExpanded] = useState(false);
  const [heights, setHeights] = useState([]);
  const containerRef = useRef(null);

  const closeHandler = (id) => {
    dispatch(removeNotification(id));
  };

  useEffect(() => {
    setHeights(notifications.map(() => TOAST_HEIGHT));
  }, [notifications]);

  const getToastOffset = (index) => {
    return heights.slice(0, index).reduce((sum, height) => sum + height + GAP, 0);
  };

  // Sort notifications to ensure the newest is at the top
  const sortedNotifications = [...notifications].sort((a, b) => b.id - a.id);

  // If there are no notifications, render nothing
  if (sortedNotifications.length === 0) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className="z-[100] fixed inset-x-0 top-0 flex justify-center items-start px-4 py-6 pointer-events-none sm:p-6"
    >
      <div
        className="w-full max-w-sm relative pointer-events-auto"
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
        style={{
          height: expanded
            ? `${sortedNotifications.length * (TOAST_HEIGHT + GAP)}px`
            : `${TOAST_HEIGHT}px`,
          transition: 'height 300ms ease-in-out',
        }}
      >
        {sortedNotifications.map((item, index) => (
          <div
            key={item.id}
            className="absolute w-full transition-all duration-300 ease-in-out"
            style={{
              top: expanded ? `${getToastOffset(index)}px` : `${index * 20}px`,
              opacity: expanded || index === 0 ? 1 : 0.7,
              zIndex: sortedNotifications.length - index,
            }}
          >
            <Notification
              id={item.id}
              open={true}
              icon={<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
              {...item}
              onClose={closeHandler}
              isPaused={expanded}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationManager;

import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { localTime } from 'utils/unitConversion';

//import KPICard from 'components/Cards/KPICard';
import { getHomeDataAction, getKPIsAction } from 'redux/actions/home';

import KPICard2 from 'components/Cards/KPICard2';
import StatusBadge from 'components/Badges/StatusBadge';

import { lastToString } from 'utils/datetimeHumanzie';
import { toDate, toReadDate } from 'utils/unitConversion';
import {
  ArrowCircleDownIcon,
  CubeTransparentIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import LongLog from 'components/Modals/LongLog';
import LongLogModel from 'components/Modals/ModelModals/LongLog';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Example = () => {
  // eslint-disable-next-line no-unused-vars
  const { home, kpis, user } = useSelector(({ home, auth }) => {
    return {
      home: home.home,
      kpis: home.kpis,
      user: auth.user,
    };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [resultObject, setResultObject] = useState([]);
  const [connectorModal, setConnectorModal] = useState(false);
  const [modelModal, setModelModal] = useState(false);
  const [activeTask, setActiveTask] = useState({});
  const [queryParameters] = useSearchParams();

  function openConnectionlog(task) {
    setActiveTask(task);
    setConnectorModal(true);
  }

  function openModellog(task) {
    setActiveTask(task);
    setModelModal(true);
  }

  function closeJobLogHandler() {
    setActiveTask({});
    setConnectorModal(false);
    setModelModal(false);
  }

  useEffect(() => {
    dispatch(getHomeDataAction());
    dispatch(getKPIsAction());

    if (queryParameters.get('log')) {
      if (queryParameters.get('log') == 'connection') {
        const test = {
          id: 339,
          container_instance_arn:
            'arn:aws:ecs:eu-central-1:320475265668:container-instance/less-execution-cluster/4627bc7e90784cd7a92ef2de154884e0',
          task_arn:
            'arn:aws:ecs:eu-central-1:320475265668:task/less-execution-cluster/b7fac6e470204c73b0bc73172740117a',
          connection_id: 70,
          container_status: 'STOPPED',
          records_retrieved: 626,
          from_date: null,
          to_date: null,
          run_code: 200,
          executed_at: '2023-11-07 13:22:04',
          finished_at: '2023-11-07 13:22:05',
          created_at: '2023-11-07 13:22:00',
          updated_at: '2023-11-07 13:24:00',
          error_description: '',
          deleted_at: null,
          deleted_by: null,
          task_source: 'Schedule',
          task_source_id: 37,
          task_origin_user: 1,
          origin_user: {
            id: 1,
            name: 'Team Less',
            email: 'admin@less.tech',
          },
          connection_object: {
            id: 70,
            name: 'RDSINSTANCE',
            connectorId: 53,
            databaseId: 1,
            user_id: 1,
            user: {
              id: 1,
              name: 'Team Less',
              email: 'admin@less.tech',
            },
            connector: {
              id: 53,
              name: 'File Input',
              logo: 'https://less-logos.s3.eu-central-1.amazonaws.com/Logos/File.svg',
            },
          },
        };
        openConnectionlog(test);
      } else if (queryParameters.get('log') == 'model') {
        //openModellog()
      }
    }
  }, []);

  useEffect(() => {
    if (home && kpis) {
      setIsLoading(false);
    }
  }, [home, kpis]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getHomeDataAction());
      dispatch(getKPIsAction());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const current = new Date();

  const goToFolder = ((folderId) => {
    navigate(`/folder/${folderId}`, { replace: true })
  })

  const mapConnectionTask = (item) => {
    return {
      type: 'Connection',
      name: `${item?.connection_object?.name}`,
      folderId: item.connection_object?.folders?.length > 0 ? item.connection_object?.folders[0].id : 0,
      connector_name: item?.connection_object?.connector?.name,
      owner: item?.connection_object?.user?.id,
      owner_name: item?.connection_object?.user?.name,
      origin_user: item?.origin_user,
      executed_at: item?.executed_at || item?.created_at,
      finished_at: item?.finished_at || item?.updated_at,
      task_source: item?.task_source,
      status: item?.run_code || 100,
      id: item?.connection_object?.connectorId,
      error: item?.error_description,
      original: item,
    };
  };

  const mapModelTask = (item) => {
    return {
      type: 'Datamodel',
      name: item.datamodel?.name,
      folderId: item.datamodel?.folders?.length > 0 ? item.datamodel?.folders[0].id : 0,
      owner: item.datamodel?.user.id,
      owner_name: item.datamodel?.user.name,
      origin_user: item?.origin_user,
      executed_at: item?.executed_at || item?.created_at,
      finished_at: item?.finished_at || item?.updated_at,
      task_source: item?.task_source,
      status: item?.run_code || 100,
      id: item?.datamodel?.id,
      error: item?.error_description,
      original: item,
    };
  };

  // const filterByDateAndDays = (tasks, dateFilter, daysFilter) => {
  //   const filteredTasks = tasks?.filter((task) => {
  //     const executedAt = new Date(task.executed_at);
  //     const daysDifference = (current - executedAt) / (1000 * 60 * 60 * 24);
  //     return dateFilter(executedAt) && daysDifference <= daysFilter;
  //   });
  //   return filteredTasks?.reverse();
  // };
  let tabs = [
    { id: '1', label: 'All' },
    { id: '2', label: 'Mine' },
  ];

  let tabsLeft = [
    { id: '1', label: 'All' },
    { id: '2', label: 'Connections' },
    { id: '3', label: 'Models' },
  ];

  let tabsRight = [
    { id: '1', label: 'All' },
    { id: '2', label: 'Success' },
    { id: '3', label: 'Errors' },
    { id: '4', label: 'Warnings' },
  ];

  let [activeTab, setActiveTab] = useState(tabs[0].id);
  let [activeTabLeft, setActiveTabLeft] = useState(tabsLeft[0].id);
  let [activeTabRight, setActiveTabRight] = useState(tabsRight[0].id);

  const connectionTasks = kpis?.user?.job_history?.connection_tasks
    .map(mapConnectionTask)
    .reverse();
  const modelTasks = kpis?.user?.job_history?.datemodel_tasks.map(mapModelTask).reverse();

  function getNum(val) {
    if (isNaN(val)) {
      return 0;
    }
    return parseInt(val);
  }

  useEffect(() => {
    if (connectionTasks?.length > 0 || modelTasks?.length > 0) {
      const tabConditions = {
        111: () => true,
        112: (item) => item.status === 200,
        113: (item) => item.status === 500,
        114: (item) => item.status === 300,
        121: (item) => item.type === 'Connection',
        131: (item) => item.type === 'Datamodel',
        211: (item) => user.id === item?.owner || item?.origin_user?.id === user.id,

        122: (item) => item.status === 200 && item.type === 'Connection',
        132: (item) => item.status === 200 && item.type === 'Datamodel',

        123: (item) => item.status === 500 && item.type === 'Connection',
        133: (item) => item.status === 500 && item.type === 'Datamodel',

        124: (item) => item.status === 300 && item.type === 'Connection',
        134: (item) => item.status === 300 && item.type === 'Datamodel',

        212: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) && item.status === 200,
        222: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Connection' &&
          item.status === 200,
        232: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Datamodel' &&
          item.status === 200,

        213: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) && item.status === 500,
        223: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Connection' &&
          item.status === 500,
        233: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Datamodel' &&
          item.status === 500,

        214: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) && item.status === 300,
        224: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Connection' &&
          item.status === 300,
        234: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Datamodel' &&
          item.status === 300,

        221: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Connection',
        231: (item) =>
          (user.id === item?.owner || item?.origin_user?.id === user.id) &&
          item.type === 'Datamodel',
      };

      const conditionKey = `${activeTab}${activeTabLeft}${activeTabRight}`;
      const filterFunction = tabConditions[conditionKey] || (() => true);

      const connectionTasksFiltered = connectionTasks.filter(filterFunction);
      const modelTasksFiltered = modelTasks.filter(filterFunction);

      const todaysConnectorTasks = connectionTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        return executedAt.toDateString() === current.toDateString();
      });
      const todaysModelTasks = modelTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        return executedAt.toDateString() === current.toDateString();
      });
      const sevenDayConnectorTasks = connectionTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        const daysDifference = (current - executedAt) / (1000 * 60 * 60 * 24);
        return executedAt.toDateString() !== current.toDateString() && daysDifference <= 7;
      });
      const sevenDayModelTasks = modelTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        const daysDifference = (current - executedAt) / (1000 * 60 * 60 * 24);
        return executedAt.toDateString() !== current.toDateString() && daysDifference <= 7;
      });
      const olderThanSevenDaysConnectorTasks = connectionTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        const daysDifference = (current - executedAt) / (1000 * 60 * 60 * 24);
        return daysDifference > 7;
      });
      const olderThanSevenDaysModelTasks = modelTasksFiltered.filter((task) => {
        const executedAt = new Date(task.executed_at);
        const daysDifference = (current - executedAt) / (1000 * 60 * 60 * 24);
        return daysDifference > 7;
      });

      const sortedResultObject = [
        {
          date: 'Today',
          items: [...todaysConnectorTasks, ...todaysModelTasks],
        },
        {
          date: 'Last 7 Days',
          items: [...sevenDayConnectorTasks, ...sevenDayModelTasks],
        },
        {
          date: 'Older',
          items: [...olderThanSevenDaysConnectorTasks, ...olderThanSevenDaysModelTasks],
        },
      ].map((group) => ({
        ...group,
        items: group.items.sort((a, b) => {
          const dateA = new Date(a.executed_at);
          const dateB = new Date(b.executed_at);
          return dateB - dateA; // Sort in descending order
        }),
      }));

      setResultObject(sortedResultObject);
    }
  }, [connectionTasks, modelTasks, activeTab, activeTabLeft, activeTabRight]);

  const statusDef = {
    500: { color: 'red', text: 'Error' },
    200: { color: 'green', text: 'Active' },
    300: { color: 'yellow', text: 'Pending' },
    null: { color: 'yellow', text: 'Pending' },
    undefined: { color: 'yellow', text: 'Pending' },
  };

  return (
    <div className="-mx-8 -px-8 overflow-hidden">
      {kpis && (
        <div className="relative isolate">
          <div className="border-b bg-transparent border-b-zinc-900/10 dark:border-b-zinc-50/10 lg:border-t lg:border-t-zinc-900/5">
            <dl className=" z-10 mx-auto grid w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <KPICard2
                value={getNum(kpis?.global?.users[0]?.value)}
                from={getNum(kpis?.global?.users[1]?.value)}
                time="last month"
                title="Users"
                tt="The number of activated Less users"
              />
              <KPICard2
                value={getNum(kpis?.global?.connectors[0]?.value)}
                from={getNum(kpis?.global?.connectors[1]?.value)}
                time="last month"
                title="Connections"
                classes="border-l"
                tt="Current number of Connections"
              />

              <KPICard2
                value={getNum(kpis?.global?.models[0].value)}
                from={getNum(kpis?.global?.models[1].value)}
                time="last month"
                title="Models"
                classes="border-l"
                tt="Current number of Models"
              />
              {/* <KPICard2
                value={kpis?.global?.connectors_python[0]?.value}
                from={kpis?.global?.connectors_python[1]?.value}
                time="last month"
                title="Python Connectors"
                classes="border-l"
                tt="Current number of Python Connections"
              /> */}
              <KPICard2
                value={
                  getNum(kpis?.global?.manual_refresh_connector[0]?.jobs) +
                  getNum(kpis?.global?.manual_refresh_model[0]?.jobs)
                }
                from={
                  getNum(kpis?.global?.manual_refresh_connector[1]?.jobs) +
                  getNum(kpis?.global?.manual_refresh_model[1]?.jobs)
                }
                time="last month"
                title="Manual Refreshes"
                classes=""
                tt="Number of manual refreshes in this month"
              />
              <KPICard2
                value={getNum(kpis?.global?.connectors_jobs[0]?.jobs)}
                from={getNum(kpis?.global?.connectors_jobs[1]?.jobs)}
                time="last month"
                title="Connector Jobs"
                classes="border-l"
                tt="Number of connector jobs in this month"
              />
              <KPICard2
                value={getNum(kpis?.global?.models_jobs[0]?.jobs)}
                from={getNum(kpis?.global?.models_jobs[1]?.jobs)}
                time="last month"
                title="Model Jobs"
                classes="border-l"
                tt="Number of model jobs in this month"
              />
              {/* <KPICard2
                value={kpis?.global?.connectors_python[0]?.value}
                from={kpis?.global?.connectors_python[1]?.value}
                time="last month"
                title="Python Connectors"
                classes="border-l"
              /> */}
            </dl>
          </div>
          <div
            className="absolute left-0 top-36 -z-10  origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-30 blur-3xl sm:left-1/2 sm:-ml-[600px] sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu "
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="relative overflow-hidden  h-[calc(100vh-212px)]  bg-white dark:bg-zinc-800 top-0 block z-30">
          <div className="overflow-y-scroll h-full ">
            <div className="mx-auto flex flex-col flex-1 gap-3 bg-white dark:bg-zinc-800 sticky top-0 pt-8 pb-4 px-8">
              <div className="flex flex-row gap-3">
                <h2 className="text-lg font-semibold leading-6 text-zinc-700 dark:text-zinc-50 ">
                  Recent activity
                </h2>
                <RadixTooltip tooltipText="Private assets are never visible to anyone but their owner">
                  <InformationCircleIcon className="text-zinc-400 w-4 h-4 mt-0.5" />
                </RadixTooltip>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex space-x-1 bg-zinc-100 dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-500 p-1 rounded-3xl w-fit">
                  {tabs.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`${activeTab === tab.id
                        ? 'text-zinc-50 dark:text-zinc-900'
                        : 'hover:text-zinc-700/60 dark:text-zinc-200 dark:hover:text-zinc-50/60'
                        } relative rounded-full px-3 py-1.5 text-xs font-medium  transition z-20 `}
                    >
                      {activeTab === tab.id && (
                        <motion.span
                          layoutId="bubble3"
                          className="absolute rounded-2xl inset-0 -z-10 bg-zinc-600 dark:bg-zinc-200"
                          //style={{ borderRadius: 9999 }}
                          transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
                        />
                      )}
                      {tab.label}
                    </button>
                  ))}
                </div>
                <div className="flex space-x-1 bg-zinc-100 dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-500 p-1 rounded-3xl w-fit">
                  {tabsLeft.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTabLeft(tab.id)}
                      className={`${activeTabLeft === tab.id
                        ? 'text-zinc-50 dark:text-zinc-900'
                        : 'hover:text-zinc-700/60 dark:text-zinc-200 dark:hover:text-zinc-50/60'
                        } relative rounded-full px-3 py-1.5 text-xs font-medium  transition z-20 `}
                    >
                      {activeTabLeft === tab.id && (
                        <motion.span
                          layoutId="bubble"
                          className="absolute rounded-2xl inset-0 -z-10 bg-zinc-600 dark:bg-zinc-200 "
                          //style={{ borderRadius: 9999 }}
                          transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
                        />
                      )}
                      {tab.label}
                    </button>
                  ))}
                </div>

                <div className="flex space-x-1 bg-zinc-100 dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-500 p-1 rounded-3xl w-fit">
                  {tabsRight.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTabRight(tab.id)}
                      className={`${activeTabRight === tab.id
                        ? 'text-zinc-50 dark:text-zinc-900'
                        : 'hover:text-zinc-700/60 dark:text-zinc-200 dark:hover:text-zinc-50/60'
                        } relative rounded-full px-3 py-1.5 text-xs font-medium  transition z-20 `}
                    >
                      {activeTabRight === tab.id && (
                        <motion.span
                          layoutId="bubble2"
                          className="absolute rounded-2xl inset-0 -z-10 bg-zinc-600 dark:bg-zinc-200"
                          //style={{ borderRadius: 9999 }}
                          transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
                        />
                      )}
                      {tab.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="mx-auto px-8">
                <div className="mx-auto lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left ">
                    <thead className="sr-only"></thead>
                    <tbody className="overflow-hidden w-full ">
                      {resultObject.map((day) => (
                        <Fragment key={day.date}>
                          {day?.items?.length > 0 && (
                            <tr className="text-sm w-full relative leading-6 text-zinc-700 dark:text-zinc-50">
                              <th
                                scope="colgroup"
                                colSpan={5}
                                className="isolate py-2 font-semibold sticky top-[122px]"
                              >
                                <time className="block z-20">{day.date}</time>
                                <div className="absolute w-screen bg-zinc-50 dark:bg-zinc-700 inset-y-0 right-0 -z-10 border-b border-t border-zinc-200 dark:border-zinc-600 " />

                                <div className="absolute w-full  bg-zinc-50 dark:bg-zinc-700 inset-y-0 left-8 -z-10 border-b border-t border-zinc-200 dark:border-zinc-600 " />
                              </th>
                            </tr>
                          )}
                          {day.items.map((item, index) => (
                            <tr
                              className={`${index == day.items.length - 1 ? '' : 'border-b'
                                } border-zinc-100 dark:border-zinc-600`}
                              key={index}
                            >
                              <td className=" py-2 pr-6  w-[250px] ">
                                <div className="flex items-start gap-x-3">
                                  <StatusBadge
                                    classes="-z-10 "
                                    status={item.status}
                                    statusDef={statusDef}
                                  />

                                  <div
                                    className={`${item.type === 'Connection'
                                      ? 'text-zinc-50 bg-zinc-700 border-zinc-600'
                                      : 'text-zinc-700 bg-zinc-50 border-zinc-200'
                                      } w-[100px] -z-10 h-auto inline-flex items-center tracking-wide border justify-center rounded-lg relative px-1 py-1 text-[10px] font-medium`}
                                  >
                                    {item.type === 'Connection' ? (
                                      <ArrowCircleDownIcon className="h-3 w-3 text-zinc-50" />
                                    ) : (
                                      <CubeTransparentIcon
                                        className="h-3 w-3 text-zinc-700 "
                                        aria-hidden="true"
                                      />
                                    )}
                                    <p className="px-1.5 py-0 capitalize">{item.type == "Connection" ? "Connection" : "Model"}</p>
                                  </div>
                                </div>
                              </td>
                              <td className=" py-2 pr-6 w-[225px] sm:table-cell ">
                                <div className="text-xs -mb-0.5 font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                                  {localTime(item.executed_at)}
                                </div>
                                <div className="text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                                  {lastToString(item.executed_at)}
                                </div>
                              </td>
                              <td className="w-[250px] py-2 pr-6 sm:table-cell">
                                <div className="max-w-[250px] truncate text-xs -mb-0.5 font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                                  {item.name.length > 35 ? (
                                    <RadixTooltip
                                      childClasses="max-w-[250px] truncate"
                                      tooltipText={item.name}
                                    >
                                      {item.name}
                                    </RadixTooltip>
                                  ) : (
                                    item.name
                                  )}
                                </div>
                                <div className="text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                                  {item.owner_name && <p>Owner: {item.owner_name}</p>}{' '}
                                  {/* {item.connector_name && <p>Connector: {item.connector_name}</p>} */}
                                </div>
                              </td>
                              <td className="w-[300px] py-2 pr-6 sm:table-cell">
                                <div className="text-xs -mb-0.5 font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                                  {item.task_source === 'Schedule' && item?.origin_user?.name
                                    ? `Updated on schedule by ${item?.origin_user?.name}`
                                    : item.task_source === 'Manual' && item?.origin_user?.name
                                      ? `Updated manually by ${item?.origin_user?.name}`
                                      : `Updated`}
                                </div>
                                {item.finished_at && (
                                  <div className="text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                                    Duration:{' '}
                                    {toDate(item.finished_at) - toDate(item.executed_at) < -3600
                                      ? ''
                                      : toReadDate(
                                        toDate(item.finished_at) - toDate(item.executed_at)
                                      )}
                                  </div>
                                )}
                              </td>
                              <td className="text-right text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                                <p className='hover:text-zinc-900 select-none hover:dark:text-zinc-50 hover:underline hover:cursor-pointer duration-300 transition-all'
                                  onClick={
                                    item.type === 'Connection'
                                      ? () => openConnectionlog(item.original)
                                      : () => openModellog(item.original)
                                  }
                                >
                                  Open Log
                                </p>
                                <p onClick={() => { goToFolder(item.folderId) }} className='hover:text-zinc-900  select-none hover:dark:text-zinc-50 hover:underline hover:cursor-pointer duration-300 transition-all'>Go to {item.type == "Connection" ? "Connection" : "Model"}</p>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {connectorModal && (
        <LongLog
          activeTask={activeTask}
          connection={{ ...activeTask.connection_object, type: 'Connection' }}
          closeJobLogHandler={closeJobLogHandler}
        />
      )}
      {modelModal && (
        <LongLogModel
          activeTask={activeTask}
          model={{ ...activeTask.datamodel, type: 'Datamodel' }}
          closeJobLogHandler={closeJobLogHandler}
        />
      )}
    </div>
  );
};
export default React.memo(Example);

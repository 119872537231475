import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBezierPath } from 'reactflow';

const ConnectionLine = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    markerEnd,
}) => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const [isDropzoneActive, setDropzoneActive] = useState(false);


    const style = {
        strokeWidth: isDropzoneActive ? '8' : '3'
    }


    const onDrop = (evt) => {
        evt.preventDefault();
        setDropzoneActive(false)
    };

    const onDragEnter = () => {
        setDropzoneActive(true);
    };

    const onDragLeave = () => {
        setDropzoneActive(false);
    };


    const onDragOver = (evt) => {
        evt.preventDefault();
        setDropzoneActive(true)

    };

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath[0]}
                markerEnd={markerEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
            />
            <text>
                <textPath
                    href={`#${id}`}
                    style={{ fontSize: '12px' }}
                    startOffset="50%"
                    textAnchor="middle"
                >
                    {data?.text}
                </textPath>
            </text>
        </>
    );
};

ConnectionLine.propTypes = {
    id: PropTypes.string,
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    data: PropTypes.string,
    markerEnd: PropTypes.string,
};

export default ConnectionLine;

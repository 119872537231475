import Axios from './axios';

export const getHomeDataService = () => {
  return Axios('api/home').then((res) => {
    return res?.data;
  });
};

export const getKPIsService = () => {
  return Axios('api/home/kpis').then((res) => {
    return res?.data;
  });
};

export const getUserIpService = () => {
  return Axios('api/home/ip').then((res) => {
    return res?.data;
  });
};

export const getWelcomeService = () => {
  return Axios('api/welcome').then((res) => {
    return res?.data;
  });
};
import React from 'react';
import PropTypes from 'prop-types';
//import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import BasicDropdown from 'components/Modelling/Config/BasicDropdown';

const Pagination = ({
  maxObjects,
  setMaxObjects,
  //pageSize,
  total,
  currentPage,
  handlePageClick,
  //onClick,
  //showPaginationWithOnePage,
  type,
}) => {
  const pageCount = Math.ceil(total / maxObjects);
  //const [maxObjects, setMaxObjects] = React.useState(pageSize);

  // if (!showPaginationWithOnePage && pageCount <= 1) {
  //   return null;
  // }

  const items = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  const numberArray = Array.from({ length: pageCount }, (_, index) => index + 1);

  const showingFrom = currentPage * maxObjects + 1;
  const showingTo = currentPage * maxObjects + maxObjects;

  return (
    <div className="rounded-b-lg w-full">
      <div className="p-2 w-full rounded-b-lg sticky left-0 items-center justify-between sm:px-6">
        <div className="flex-row flex">
          <div className="w-full gap-10 flex-1 flex items-center justify-between ">
            <div>
              <p className="text-xs text-zinc-700 dark:text-zinc-50">
                Showing <span className="font-medium">{showingFrom}</span> to{' '}
                <span className="font-medium">{showingTo > total ? total : showingTo}</span> (of{' '}
                {total})
              </p>
            </div>
            {pageCount > 1 && (
              <div className="flex flex-row">
                <button
                  disabled={currentPage == 0}
                  onClick={() => handlePageClick(currentPage - 1)}
                  className={`${currentPage == 0 ? 'opacity-50  cursor-not-allowed' : ''
                    } w-full h-full px-1  py-2 border bg-white border-zinc-100 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-800/50 rounded-l-md justify-center items-center flex flex-1`}
                >
                  <ChevronLeftIcon
                    className="h-4 w-4 text-xs text-zinc-500 dark:text-zinc-200"
                    aria-hidden="true"
                  />
                </button>
                {numberArray.map((num) => (
                  <button
                    onClick={() => handlePageClick(num - 1)}
                    className={`  relative inline-flex items-center px-4 py-2 border text-xs font-medium ${currentPage + 1 == num
                      ? 'bg-zinc-100 dark:bg-zinc-700/20 !border-[#5DA6FC]/50 !text-[#5DA6FC] dark:border-[#5DA6FC]/50 dark:text-[#5DA6FC] rounded-sm '
                      : 'bg-white dark:bg-zinc-900 border-zinc-100 text-zinc-500 dark:border-zinc-700 dark:text-zinc-200 hover:bg-zinc-50 dark:hover:bg-zinc-800/50'
                      }`}
                    key={num}
                  >
                    {num}
                  </button>
                ))}
                <button
                  disabled={currentPage + 1 == pageCount}
                  onClick={() => handlePageClick(currentPage + 1)}
                  className={`${currentPage + 1 == pageCount ? 'opacity-50  cursor-not-allowed' : ''
                    } w-full px-1 py-2 h-full border bg-white border-zinc-100 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-800/50 rounded-r-md justify-center items-center flex flex-1`}
                >
                  <ChevronRightIcon
                    className="h-4 w-4 text-xs text-zinc-500 dark:text-zinc-200"
                    aria-hidden="true"
                  />
                </button>
              </div>
            )}

            <BasicDropdown
              show={`Show ${maxObjects} ${type}`}
              defaultSelected={maxObjects ? maxObjects : 20}
              classes={'w-min'}
              items={items}
              onChange={setMaxObjects}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  total: PropTypes.number,
  type: PropTypes.string,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func,
  maxObjects: PropTypes.any,
  setMaxObjects: PropTypes.any,
  onClick: PropTypes.func,
  showPaginationWithOnePage: PropTypes.bool,
};

Pagination.defaultProps = {
  pageSize: 5,
  total: 0,
  type: 'results',
  currentPage: 0,
  handlePageClick: () => null,
  onClick: () => null,
  showPaginationWithOnePage: false,
};

export default Pagination;

import { combineReducers } from 'redux';
import auth from './auth';
import connectors from './connector';
import connections from './connection';
import databases from './database';
import models from './model';
import home from './home';
import tables from './table';
import notifications from './notification';
import folder from './folder';
import platform from './platform';

const rootReducer = combineReducers({
  auth,
  connectors,
  connections,
  databases,
  models,
  home,
  tables,
  notifications,
  folder,
  platform,
});

export default (state, action) =>
  rootReducer(action.type === 'LOGOUT_USER' ? undefined : state, action);

import Axios, { jsonHeader } from './axios';

export const updateProfileService = (data) => {
  return Axios.post(`api/profile/update`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const updateSettingsService = (data) => {
  return Axios.post(`api/profile/update/settings`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const resetPasswordService = (data) => {
  return Axios.post(`api/profile/resetpassword`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const getTokensService = () => {
  return Axios.get(`api/profile/getTokens`).then((res) => {
    return res?.data;
  });
};

export const createTokenService = (data) => {
  return Axios.post(`api/profile/createToken`, data, jsonHeader).then((res) => {
    return res?.data;
  });
};

export const deleteTokenService = (tokenId) => {
  return Axios.delete(`api/profile/deleteToken/${tokenId}`).then((res) => {
    return res?.data;
  });
};
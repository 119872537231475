import React from 'react';
import PropTypes from 'prop-types';
import { KeyIcon, TrashIcon, ShieldCheckIcon, IdentificationIcon } from '@heroicons/react/solid';
// components
import Table from 'components/Tables/Table';
import TableRow from 'components/Tables/TableRow';
import StatusBadge from 'components/Badges/StatusBadge';
import ContextMenu from 'components/Dropdowns/ContextMenu';
// utils
import { dataSize } from 'utils/unitConversion';
import ShareWith from 'components/Tables/TableContent/SharedWith';

const MyDatabasesGrid = ({
  databases,
  whitelistIP,
  credentials,
  deleteDatabase,
  shareObject,
  emptyMessage,
}) => {
  const tableHeaders = [
    {
      headerName: 'Status',
      classes: 'justify-center inline-flex text-center max-w-[125px] w-[125px]',
    },
    { headerName: 'Database', classes: 'w-[350px]' },
    { headerName: 'Data Stored', classes: 'w-[150px]' },
    { headerName: 'Sharing', classes: 'w-[150px]' },
    { headerName: 'Created By', classes: 'w-[150px]' },
    { headerName: '', classes: '' },
  ];

  const options = [
    {
      id: 'share',
      text: 'Sharing',
      logo: <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      ability: 'database-manage',
      action: (object) => {
        shareObject('Database', object);
      },
    },
    {
      id: 'credentials',
      text: 'View Credentials',
      ability: 'database-credentials',
      logo: <IdentificationIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        credentials(object);
      },
    },

    {
      id: 'whitelistIp',
      text: 'Whitelist IPs',
      ability: 'database-manage',
      logo: <ShieldCheckIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        whitelistIP(object);
      },
    },
    {
      id: 'delete',
      text: 'Delete',
      ability: 'database-delete',
      classes: '',
      logo: <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      action: (object) => {
        deleteDatabase(object);
      },
    },
  ];

  const tableBody = databases.map((database) => (
    <tr key={database.id}>
      <td className="text-center !w-[125px] !max-w-[125px]">
        <div className="text-center !w-[125px] !max-w-[125px]">
          <StatusBadge
            status={
              ['available', 'external'].includes(database?.dbinstancestatus)
                ? 200
                : database?.dbinstancestatus == 'creating'
                ? 300
                : 500
            }
          />
        </div>
      </td>
      <TableRow classes="!py-1" content={database?.name} />
      <TableRow classes="!py-1" content={dataSize(database?.size)} />
      <TableRow content={<ShareWith rulesets={database.rulesets} />} />
      <TableRow classes="!py-1" content={database?.user?.name} />
      <TableRow
        content={
          <div className="justify-end flex flex-row flex-1 gap-2">
            <ContextMenu options={options} object={database} />
          </div>
        }
      />
    </tr>
  ));

  return <Table type="databases" {...{ headers: tableHeaders, tableBody, emptyMessage }} />;
};

MyDatabasesGrid.propTypes = {
  databases: PropTypes.array,
  whitelistIP: PropTypes.func,
  credentials: PropTypes.func,
  deleteDatabase: PropTypes.func,
  shareObject: PropTypes.func,
  emptyMessage: PropTypes.string,
};

MyDatabasesGrid.defaultProps = {
  databases: [],
  whitelistIP: () => null,
  credentials: () => null,
  deleteDatabase: () => null,
  shareObject: null,
  emptyMessage: '',
};

export default MyDatabasesGrid;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTasksService,
  getLogsService,
  stopTaskService,
} from 'services/task';

// First, create the thunk
export const getTasksAction = createAsyncThunk('task/get', async ({object_type, object_id}) => {
  const response = await getTasksService(object_type, object_id);
  return response;
});

export const getLogsAction = createAsyncThunk('task/logs', async ({ object_type, object_id, task_id }) => {
  const response = await getLogsService(object_type, object_id, task_id);
  return response;
});


export const stopTaskAction = createAsyncThunk('task/stop', async ({ object_type, object_id, task_id }) => {
  const response = await stopTaskService(object_type, object_id, task_id);
  return response;
});

// Then, handle actions in your reducer

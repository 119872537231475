import Axios, { jsonHeader } from './axios';

export const userLoginService = ({ username, password }) => {
  return Axios.post(
    'api/auth/login',
    new URLSearchParams({
      email: username,
      password,
    })
  ).then((res) => {
    return res?.data;
  });
};

export const userProfileService = () => {
  return Axios.get('api/auth/user-profile').then((res) => {
    return res?.data;
  });
};

export const refreshTokenService = () => {
  return Axios.post(`api/auth/refresh`).then((res) => {
    return res.data;
  });
};

export const forgotPasswordService = (email) => {
  return Axios.post(`api/auth/forgot-password`, { email: email }, jsonHeader).then((res) => {
    return res.data;
  });
};

export const resetPasswordService = (data) => {
  return Axios.post(`api/auth/reset-password`, data, jsonHeader).then((res) => {
    return res.data;
  });
};

export const getUsersService = () => {
  return Axios.get(`api/auth/users`).then((res) => {
    return res.data?.data;
  });
};

export const getGroupsService = () => {
  return Axios.get(`api/auth/groups`).then((res) => {
    return res.data?.data;
  });
};

export const sendInviteService = ( data ) => {
  return Axios.post(
    'api/invite',data, jsonHeader
  ).then((res) => {
    return res?.data;
  });
};

export const acceptInviteService = ( data ) => {
  return Axios.post(
    'api/invite/accept',data, jsonHeader
  ).then((res) => {
    return res?.data;
  });
};
import React from 'react';
import PropTypes from 'prop-types';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input
        className="rounded-md !ring-0 focus:!ring-0 active:!ring-0 !ring-transparent"
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    </>
  );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any,
};

export default IndeterminateCheckbox;

import { React, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  // FolderOpenIcon,
  // CubeIcon,
  HomeIcon,
  MenuIcon,
  // ArrowCircleDownIcon,
  XIcon,
  DatabaseIcon,
  BookmarkIcon,
  ChevronDoubleLeftIcon,
  // MoonIcon,
  // SunIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
// redux
import { userProfileAction } from 'redux/actions/auth';
import { updateProfileAction, resetPasswordAction } from 'redux/actions/profile';
// import { getFolderAction } from 'redux/actions/folder'
// components
import LeftNavAvatar from 'components/Avatars/LeftNavAvatar';
import BasicModal from 'components/Modals/BasicModal';
import SettingsModal from 'components/Modals/SettingsModal';
import ChangePWModal from 'components/Modals/ChangePWModal';
import ChangeEmailModal from 'components/Modals/ChangeEmailModal';
import ChangeNameModal from 'components/Modals/ChangeNameModal';
import Folders from 'components/Layout/Folders';
// helper
import useQuery from 'hooks/useQuery';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import searchFolder from 'utils/searchFolder';

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon, current: true },
  { name: 'Storage', href: '/database', icon: DatabaseIcon, current: false },
  // {
  //   name: 'Connections',
  //   href: '/connectors',
  //   icon: ArrowCircleDownIcon,
  //   current: false,
  // },
  {
    name: 'Catalogue',
    href: '/datacatalogueV2',
    icon: BookmarkIcon,
    current: false,
  },
  // { name: 'Modelling', href: '/modelling', icon: CubeIcon, current: false },
  // {
  //   name: 'Data Catalogue',
  //   href: '/datacatalogue',
  //   icon: FolderOpenIcon,
  //   current: false,
  // },
];

export default function LeftNav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const query = useQuery();
  const oAuthToken = query.get('token') ? query.get('token') : null;
  if (oAuthToken && location.pathname == '/') {
    navigate(location.pathname, { replace: true });
    localStorage.setItem('token', oAuthToken);
    localStorage.removeItem('auth');
  }

  const { user, folders } = useSelector(({ auth, home, folder }) => {
    return {
      user: auth.user,
      initialized: home.init,
      folders: folder.folders,
    };
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [nameModal, setnameModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [currentFolder, setCurrentFolder] = useState({})

  function openSettingsModal() {
    setSettingsModal(true);
  }
  function closeSettingsModal() {
    setSettingsModal(false);
  }

  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    localStorage.setItem('theme', theme);
    window.dispatchEvent(new Event('storage'));
  }, [theme]);

  function openEmailModal() {
    setEmailModal(true);
  }
  function closeEmailModal() {
    setEmailModal(false);
  }

  function openNameModal() {
    setnameModal(true);
  }

  function closeNameModal() {
    setnameModal(false);
  }

  function openPasswordModal() {
    setPasswordModal(true);
  }
  function closePasswordModal() {
    setPasswordModal(false);
  }

  async function saveProfileHandler(data) {
    const userObject = { name: user.name, email: user.email };
    const requestData = { ...userObject, ...data };
    await dispatch(updateProfileAction(requestData));
    await dispatch(userProfileAction());
    setnameModal(false);
    setEmailModal(false);
  }

  async function resetPasswordHandler(data) {
    const requestData = {
      old_password: data.password,
      password: data.newPw,
      password_confirmation: data.confirmPw,
    };
    await dispatch(resetPasswordAction(requestData));
    setPasswordModal(false);
  }

  function logoutHandler() {
    localStorage?.removeItem('token');
    localStorage?.removeItem('user');
    localStorage.removeItem('expires');
    window.location.pathname = '/login';
  }

  const openFolderHandler = (folderId) => {
    if (folderId === null) {
      navigate('/folder/');
    } else {
      navigate('/folder/' + folderId);
    }
  };

  useEffect(() => {
    if (folders.length > 0 && params.folderId) {
      openFolderHandler(parseInt(params.folderId));
    }
  }, [folders]);

  useEffect(() => {
    const foundFolder = searchFolder(folders, Number(params.paramFolder))
    setCurrentFolder(foundFolder)
  }, [params, folders]);

  return (
    <>
      {settingsModal && (
        <BasicModal
          open={settingsModal}
          classes="w-[90vw] !max-h-[95vh] "
          onClose={() => closeSettingsModal()}
          content={
            <SettingsModal
              user={user}
              name={user.name}
              email={user.email}
              NameOnClick={openNameModal}
              EMOnClick={openEmailModal}
              PWOnClick={openPasswordModal}
              SecOnClick={closeSettingsModal}
              LogoutOnClick={logoutHandler}
              theme={theme}
              changeTheme={() => {
                theme == `light` ? setTheme(`dark`) : setTheme(`light`);
              }}
            />
          }
        />
      )}
      {nameModal && (
        <BasicModal
          open={nameModal}
          classes="w-[400px] h-max 2xl:w-[500px]"
          onClose={() => closeNameModal()}
          content={<ChangeNameModal submitHandler={(data) => saveProfileHandler(data)} />}
        />
      )}
      {emailModal && (
        <BasicModal
          open={emailModal}
          classes="w-[400px] h-max 2xl:w-[500px]"
          onClose={() => closeEmailModal()}
          content={<ChangeEmailModal submitHandler={(data) => saveProfileHandler(data)} />}
        />
      )}
      {passwordModal && (
        <BasicModal
          open={passwordModal}
          classes="w-[400px] h-max 2xl:w-[500px]"
          onClose={() => closePasswordModal()}
          content={<ChangePWModal submitHandler={(data) => resetPasswordHandler(data)} />}
        />
      )}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        {/* Mobile */}
        <Dialog as="div" className="relative z-50 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-zinc-900">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 opacity-50  flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://less-logos.s3.eu-central-1.amazonaws.com/random/logoLight.svg"
                      alt="Less"
                    />
                  </div>

                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            {
                              'bg-zinc-800 text-white': isActive,
                              'text-white hover:bg-zinc-700 hover:bg-opacity-75': !isActive,
                            },
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )
                        }
                      >
                        <item.icon
                          className="mr-4 flex-shrink-0 h-6 w-6 text-gray-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-800 p-4">
                  <div className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <LeftNavAvatar fullName={user?.name} />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">{user?.name}</p>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openSettingsModal();
                          }}
                          className="text-sm font-medium text-gray-200 group-hover:text-white"
                        >
                          View profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        id="nav-bar"
        className={`hidden overflow-hidden md:flex ${open ? 'md:w-52' : 'md:w-16'
          }  md:flex-col md:fixed md:inset-y-0 duration-200 dark:bg-zinc-900 bg-white`}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 relative flex duration-200 flex-col min-h-0 bg-zinc-50/50 dark:bg-zinc-800/50 border-r border-zinc-200 dark:border-zinc-600">
          <RadixTooltip
            sideOffset={0}
            side="right"
            tooltipText={open ? 'Close sidebar' : 'Open sidebar'}
          >
            <ChevronDoubleLeftIcon
              onClick={() => setOpen(!open)}
              className={`${!open ? 'rotate-180 mx-auto inset-x-0' : 'right-2'
                } w-5 p-1 cursor-pointer h-auto rounded duration-200 hover:text-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-700/50 dark:text-zinc-200/80 text-zinc-500 absolute z-[1000000] top-1`}
            />
          </RadixTooltip>

          <div className="flex-1 duration-200 flex flex-col pt-8 pb-4 overflow-y-auto">
            <div className={`flex items-center ${!open && 'justify-center'} flex-shrink-0 px-4`}>
              <img
                className="h-7 w-auto"
                src={
                  open
                    ? 'https://less-logos.s3.eu-central-1.amazonaws.com/random/logoMedium.svg'
                    : 'https://less-logos.s3.eu-central-1.amazonaws.com/random/logoIcon.svg'
                }
                alt="Less"
              />
            </div>

            <div className="flex flex-col flex-1">
              <div className="mt-2 flex-1 px-2">
                <nav>
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          {
                            'bg-zinc-200 dark:bg-zinc-700 dark:text-zinc-50 text-zinc-700':
                              isActive,
                            'text-zinc-500 dark:text-zinc-200/80 hover:text-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-700/50 hover:bg-opacity-75 dark:hover:bg-opacity-75 ':
                              !isActive,
                            'justify-center': !open,
                          },

                          'group flex items-center duration-200  mt-1 px-2 py-1.5 text-[13px] font-medium rounded'
                        )
                      }
                    // onClick={() => dispatch(getFolderAction())}
                    >
                      <item.icon
                        className={`${open ? 'mr-1.5' : ''} duration-200 flex-shrink-0 h-5 w-5`}
                        aria-hidden="true"
                      />
                      {open && item.name}
                    </NavLink>
                  ))}
                </nav>
                <div className="h-px my-4 border-b dark:border-zinc-500" />
                {/* New Folders Menu */}
                <div id="folders" className="overflow-hidden">
                  {/* <p
                    className={`${open ? 'px-2' : 'opacity-0  hidden'
                      }  text-zinc-400 text-xs font-medium duration-200 uppercase tracking-wide`}
                  >
                    Workspaces
                  </p> */}
                  <div
                    onClick={() => {
                      openFolderHandler(0);
                    }}
                    className={`mt-1 px-2 py-1.5 flex items-center ${open ? '' : 'justify-center mt-0.5'
                      } rounded text-zinc-500 dark:text-zinc-200/80 duration-200 hover:text-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-700/50 hover:bg-opacity-75 dark:hover:bg-opacity-75 cursor-pointer`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={`w-5 h-5 ${open ? 'mr-1.5' : ''}`}
                    >
                      <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                    </svg>
                    {open && <p className=" text-[13px] font-medium duration-200 ">Workspaces</p>}
                  </div>
                  {open && (
                    <Folders
                      folders={folders}
                      openFolderHandler={openFolderHandler}
                      selected={currentFolder?.id}
                      breadcrumbs={currentFolder?.breadcrumbs}
                    />
                  )}
                  {/* <div
                    onClick={() => {
                      openFolderHandler(-1);
                    }}
                    className={`mt-1 px-2 py-1.5 flex items-center ${open ? '' : 'justify-center'
                      } rounded text-zinc-500 dark:text-zinc-200/80 hover:text-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-700/50 hover:bg-opacity-75 dark:hover:bg-opacity-75 cursor-pointer`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={`w-5 h-5 ${open ? 'mr-1.5' : ''}`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <p className={`${!open && 'hidden'} duration-200  font-medium text-[13px] `}>
                      Private
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-shrink-0 flex border-t border-zinc-200 dark:border-zinc-600 p-4">
            <div className="flex-shrink-0 w-full group block">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  openSettingsModal();
                }}
                className="flex items-center cursor-pointer"
              >
                <div>
                  <LeftNavAvatar fullName={user?.name} />
                </div>

                <div className={`${!open && 'hidden'} ml-1.5 flex flex-col duration-200`}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openSettingsModal();
                    }}
                    className="text-xs font-medium text-zinc-500 hover:text-zinc-700 dark:text-zinc-200 dark:hover:text-zinc-50"
                  >
                    {user?.name}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openSettingsModal();
                    }}
                    className="text-xs font-normal text-zinc-400 dark:text-zinc-300 hover:text-zinc-700 dark:hover:text-zinc-50"
                  >
                    View profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${open ? 'md:pl-52' : 'md:pl-16'} flex flex-col flex-1`}>
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 bg-white dark:bg-zinc-900 min-h-screen">
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <Outlet />
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { createGroupService, deleteGroupService, addUsersFromGroupService, removeUsersFromGroupService } from 'services/group';

export const createGroupAction = createAsyncThunk('group/remove', async (data) => {
  const response = await createGroupService(data);
  return response;
});

export const deleteGroupAction = createAsyncThunk('group/delete', async (data) => {
  const response = await deleteGroupService(data);
  return response;
});


export const addUsersFromGroupAction = createAsyncThunk('group/remove', async (data) => {
  const response = await addUsersFromGroupService(data);
  return response;
});

export const removeUsersFromGroupAction = createAsyncThunk('group/remove', async (data) => {
  const response = await removeUsersFromGroupService(data);
  return response;
});
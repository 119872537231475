import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = (props) => {
  return (
    <div>
      <div className={props.classes}>
      <h3 className="text-lg leading-6 font-bold text-zinc-700 dark:text-zinc-50">
        {props.header}
      </h3>
      <div className="mt-2 max-w-4xl text-sm align-baseline text-zinc-500 dark:text-zinc-300">
        {props.content}
        </div>
        </div>
      <div className="mt-3 relative flex-1 flex flex-row">
        <div className="absolute opacity-60 h-[1px] w-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]"></div>
        <div className="relative bg-white sm:flex sm:items-center sm:justify-between"></div>
      </div>
    </div>
  );
};
ModalHeader.propTypes = {
  header: PropTypes.string,
  content: PropTypes.any,
  classes: PropTypes.string,
};
export default ModalHeader;

import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';

const TableAccordion = (props) => {
  return (
    <div className="w-full z-1">
      <div className="mx-auto w-full rounded-2xl ">
        <Disclosure defaultOpen={props.open} open={props.childOpen} >
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${props.headerClasses} flex w-full tracking-wide justify-between rounded-lg bg-zinc-100 dark:bg-zinc-700/50 px-4 py-3 text-left text-xs font-medium text-zinc-700 dark:text-zinc-50 hover:bg-zinc-200 dark:hover:bg-zinc-700 focus:outline-none focus-visible:ring focus-visible:ring-zinc-500 focus-visible:ring-opacity-75`}
                onClick={props.onToggle}
              >
                <p className={`font-semibold ${props.headerPtagClasses}`}>
                  {props.header}{' '}
                  <span className="normal-case font-normal text-zinc-500 dark:text-zinc-200">
                    {props.description}
                  </span>
                </p>

                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180' : ''
                  } duration-200 transition-all h-4 w-4 text-zinc-500 dark:text-zinc-200 z-1`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className={`${props.childrenClasses} mt-2 px-2 py-3 border border-zinc-100 text-zinc-500 bg-zinc-50/50 dark:bg-zinc-800/40 dark:text-zinc-200 dark:border-zinc-700 rounded-lg`}
              >
                {props.children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

TableAccordion.propTypes = {
  header: PropTypes.any,
  description: PropTypes.string,
  headerClasses: PropTypes.string,
  childrenClasses: PropTypes.string,
  headerPtagClasses: PropTypes.string,
  open: PropTypes.any,
  children: PropTypes.any,
  onToggle: PropTypes.any,
  childOpen: PropTypes.any,
};

TableAccordion.defaultProps = {
  header: 'REMEMBER A HEADER!',
  description: '',
  headerClasses: '',
  headerPtagClasses: '',
  childrenClasses: '',
  open: true,
  children: null,
};

export default TableAccordion;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '@heroicons/react/solid';

const SearchInput = ({ placeholder, value: defaultValue, onChange: onChangeCallback }) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallback(e.target.value);
  };

  return (
    <div className="w-full min-w-[300px] h-full relative rounded-md shadow-xs">
      <div className="absolute max-h-fit inset-y-0 top-1/2 transform -translate-y-1/2 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-4 w-4 text-zinc-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="search"
        id="search"
        className="w-full h-full dark:bg-zinc-700 dark:placeholder:text-zinc-500 dark:border-zinc-600 dark:text-zinc-50 text-xs text-zinc-700 focus:ring-[#5DA6FC] active:border-[#5DA6FC] focus:border-[#5DA6FC] block placeholder:text-zinc-400 placeholder:font-light pl-10 border-transparent ring-1 ring-zinc-950/10 rounded-md"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;

import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon, CodeIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { BsQuestionLg } from "react-icons/bs";
import { ClockIcon } from '@heroicons/react/outline';


const FormatIcon = (props) => {
  return (
    <RadixTooltip
      tooltipText={props.format}
    >
      <div className="rounded-lg border border-slate-300 bg-slate-100 w-max h-max drop-shadow-sm">
        <div>
          {(() => {
            if (props.format == "Integer") {
              return (
                <svg
                  className="h-5 w-5 p-1 text-slate-800 "
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.14218 6.10469V13.762H2.29284V7.83207H2.24791L0.533333 8.87896V7.27871L2.42386 6.10469H4.14218Z"
                    fill="currentColor"
                  />
                  <path
                    d="M6.36421 13.762V12.4309L9.16069 9.98568C9.37034 9.79624 9.54878 9.623 9.69603 9.46597C9.84328 9.30644 9.95559 9.14692 10.033 8.98739C10.1103 8.82537 10.149 8.64964 10.149 8.4602C10.149 8.24833 10.1028 8.06762 10.0105 7.91806C9.91816 7.76601 9.79087 7.64886 9.62865 7.5666C9.46642 7.48435 9.28049 7.44322 9.07085 7.44322C8.85871 7.44322 8.67278 7.48684 8.51305 7.57408C8.35332 7.65883 8.22853 7.78221 8.13869 7.94423C8.05134 8.10625 8.00766 8.30317 8.00766 8.53498H6.2519C6.2519 8.01403 6.3692 7.56411 6.6038 7.18523C6.8384 6.80636 7.16784 6.51472 7.59212 6.31033C8.01889 6.10344 8.5143 6 9.07834 6C9.65984 6 10.1652 6.09721 10.5945 6.29164C11.0238 6.48606 11.3557 6.75775 11.5903 7.10672C11.8274 7.45319 11.9459 7.85575 11.9459 8.31439C11.9459 8.60602 11.8873 8.89516 11.77 9.18181C11.6527 9.46846 11.4418 9.78502 11.1373 10.1315C10.8353 10.478 10.4061 10.893 9.84952 11.3766L8.93233 12.2215V12.2702H12.0395V13.762H6.36421Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.5302 13.8667C15.9462 13.8667 15.4283 13.767 14.9766 13.5676C14.5274 13.3657 14.173 13.0877 13.9134 12.7338C13.6539 12.3798 13.5216 11.9723 13.5166 11.5112H15.3809C15.3884 11.6782 15.4421 11.8265 15.5419 11.9561C15.6417 12.0832 15.7777 12.1829 15.9499 12.2552C16.1221 12.3275 16.3181 12.3636 16.5377 12.3636C16.7573 12.3636 16.9507 12.325 17.1179 12.2477C17.2877 12.168 17.4199 12.0595 17.5148 11.9224C17.6096 11.7828 17.6558 11.6233 17.6533 11.4439C17.6558 11.2644 17.6046 11.1049 17.4998 10.9653C17.395 10.8257 17.2465 10.7173 17.0543 10.64C16.8646 10.5627 16.64 10.5241 16.3805 10.5241H15.6355V9.20799H16.3805C16.6076 9.20799 16.8072 9.1706 16.9794 9.09582C17.1541 9.02104 17.2902 8.91635 17.3875 8.78175C17.4848 8.64466 17.5322 8.48762 17.5297 8.31065C17.5322 8.13866 17.4911 7.98785 17.4062 7.85824C17.3238 7.72613 17.2078 7.62393 17.0581 7.55165C16.9108 7.47936 16.7398 7.44322 16.5452 7.44322C16.3405 7.44322 16.1546 7.47936 15.9874 7.55165C15.8227 7.62393 15.6916 7.72613 15.5943 7.85824C15.497 7.99035 15.4458 8.14364 15.4408 8.31812H13.6701C13.6751 7.86198 13.8023 7.46067 14.0519 7.1142C14.3015 6.76523 14.6409 6.49229 15.0702 6.29537C15.502 6.09846 15.9936 6 16.5452 6C17.0942 6 17.5772 6.09597 17.994 6.2879C18.4107 6.47983 18.7352 6.74155 18.9673 7.07307C19.1994 7.40209 19.3155 7.77474 19.3155 8.191C19.318 8.62222 19.1769 8.97742 18.8924 9.25659C18.6104 9.53576 18.2473 9.70775 17.803 9.77256V9.83238C18.397 9.90218 18.845 10.0941 19.147 10.4082C19.4515 10.7222 19.6025 11.1148 19.6 11.5859C19.6 12.0296 19.4689 12.4234 19.2069 12.7674C18.9473 13.1089 18.5854 13.3781 18.1212 13.575C17.6595 13.7695 17.1292 13.8667 16.5302 13.8667Z"
                    fill="currentColor"
                  />
                </svg>
              );
            } else if (
              props.format == "String"
            ) {
              return (
                <svg
                  className="h-5 w-5 p-1 text-slate-800 "
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.52249 14.9396H0.933333L4.29631 4.93333H7.50355L10.8665 14.9396H8.27738L5.93643 7.45445H5.85856L3.52249 14.9396ZM4.75152 11.0016H7.04578L7.62032 12.8387H4.17817L4.75152 11.0016Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.7323 15.0667C14.2553 15.0667 13.8319 14.9869 13.462 14.8273C13.0954 14.6644 12.805 14.4201 12.5908 14.0944C12.38 13.7654 12.2745 13.3533 12.2745 12.8582C12.2745 12.4413 12.3475 12.0895 12.4935 11.8029C12.6395 11.5163 12.8407 11.2834 13.097 11.1042C13.3533 10.9251 13.6486 10.7899 13.9828 10.6987C14.317 10.6042 14.6739 10.5407 15.0535 10.5081C15.4785 10.469 15.8208 10.4283 16.0804 10.386C16.3399 10.3404 16.5281 10.2769 16.6449 10.1954C16.765 10.1107 16.825 9.99186 16.825 9.83877V9.81434C16.825 9.56353 16.739 9.36972 16.567 9.23292C16.3951 9.09611 16.1631 9.02771 15.8711 9.02771C15.5564 9.02771 15.3033 9.09611 15.1119 9.23292C14.9204 9.36972 14.7988 9.55864 14.7469 9.79968L12.5519 9.72151C12.6168 9.26549 12.7839 8.85833 13.0532 8.50003C13.3257 8.13848 13.6989 7.8551 14.1726 7.64989C14.6495 7.44142 15.2222 7.33719 15.8906 7.33719C16.3675 7.33719 16.8071 7.39419 17.2095 7.5082C17.6118 7.61894 17.9622 7.78181 18.2607 7.99679C18.5592 8.20851 18.7896 8.46909 18.9518 8.77853C19.1173 9.08797 19.2 9.44138 19.2 9.83877V14.9396H16.9613V13.8941H16.9029C16.7698 14.1481 16.5995 14.3631 16.3918 14.539C16.1874 14.7149 15.9457 14.8468 15.6667 14.9347C15.3909 15.0227 15.0794 15.0667 14.7323 15.0667ZM15.4671 13.5032C15.7235 13.5032 15.9538 13.4511 16.1582 13.3468C16.3659 13.2426 16.5314 13.0993 16.6547 12.9169C16.7779 12.7312 16.8396 12.5162 16.8396 12.2719V11.5586C16.7715 11.5944 16.6887 11.627 16.5914 11.6563C16.4973 11.6856 16.3935 11.7133 16.2799 11.7394C16.1663 11.7654 16.0495 11.7882 15.9295 11.8078C15.8094 11.8273 15.6943 11.8452 15.5839 11.8615C15.3601 11.8974 15.1686 11.9527 15.0097 12.0276C14.8539 12.1026 14.7339 12.2003 14.6495 12.3208C14.5684 12.4381 14.5278 12.5781 14.5278 12.741C14.5278 12.9885 14.6154 13.1775 14.7907 13.3077C14.9691 13.438 15.1946 13.5032 15.4671 13.5032Z"
                    fill="currentColor"
                  />
                </svg>
              );
            } else if (props.format == "Decimal (Float)") {
              return (
                <svg
                  className="h-5 w-5 p-1 text-slate-800 "
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.94989 14.5977C4.96881 14.5977 4.12317 14.3655 3.41298 13.9009C2.7028 13.4327 2.15551 12.7616 1.77113 11.8874C1.38675 11.0095 1.19639 9.95428 1.20005 8.72164C1.20371 7.48899 1.3959 6.44289 1.77662 5.58333C2.161 4.72012 2.70646 4.06356 3.41298 3.61367C4.12317 3.16011 4.96881 2.93333 5.94989 2.93333C6.93098 2.93333 7.77662 3.16011 8.4868 3.61367C9.20065 4.06356 9.74977 4.72012 10.1341 5.58333C10.5185 6.44655 10.7089 7.49265 10.7052 8.72164C10.7052 9.95794 10.513 11.015 10.1287 11.8929C9.74428 12.7707 9.19699 13.4419 8.4868 13.9064C7.78028 14.3673 6.93464 14.5977 5.94989 14.5977ZM5.94989 12.3757C6.53562 12.3757 7.00969 12.0776 7.3721 11.4814C7.73452 10.8815 7.91389 9.9616 7.91023 8.72164C7.91023 7.90963 7.82787 7.24027 7.66313 6.71356C7.4984 6.1832 7.2696 5.78816 6.97674 5.52847C6.68388 5.26877 6.3416 5.13892 5.94989 5.13892C5.36783 5.13892 4.89742 5.43337 4.53867 6.02226C4.17991 6.60749 3.99871 7.50728 3.99505 8.72164C3.99138 9.54462 4.07009 10.2268 4.23116 10.7681C4.3959 11.3095 4.62653 11.7136 4.92305 11.9806C5.21957 12.244 5.56185 12.3757 5.94989 12.3757Z"
                    fill="currentColor"
                  />
                  <path
                    d="M13.7676 12.7872L13.7182 13.3962C13.6743 13.9009 13.5846 14.3929 13.4492 14.8721C13.3174 15.3549 13.1764 15.7883 13.0263 16.1724C12.8762 16.5564 12.7573 16.8545 12.6694 17.0667H10.9781C11.0367 16.8545 11.1154 16.5564 11.2143 16.1724C11.3168 15.7883 11.4119 15.3549 11.4998 14.8721C11.5877 14.3892 11.6371 13.8991 11.6481 13.4017L11.659 12.7872H13.7676Z"
                    fill="currentColor"
                  />
                  <path
                    d="M18.8 3.08696V14.3234H16.0874V5.62174H16.0215L13.5065 7.15797V4.80973L16.2796 3.08696H18.8Z"
                    fill="currentColor"
                  />
                </svg>
              );

            } else if (props.format == "Date") {
              return <CalendarIcon className="h-5 w-5 p-1 text-slate-800 " aria-hidden="true" />;
            }
            else if (props.format == "Unknown") {
              return <BsQuestionLg className="h-5 w-5 p-[5px] text-slate-800 " aria-hidden="true" />;

            }

            else if (props.format == "JSON") {
              return <CodeIcon className="h-5 w-5 p-1 text-slate-800 " />;
            } else if (props.format == 'Boolean') {
              return (
                <svg
                  className="h-5 w-5 p-1 text-slate-800 "
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.06289 0.653292V9.4274H4.94892V2.63261H4.89757L2.93765 3.83219V1.99854L5.0987 0.653292H7.06289Z"
                    fill="currentColor"
                  />
                  <path
                    d="M13.2274 9.64161C12.4629 9.64161 11.8038 9.46024 11.2504 9.09751C10.6969 8.73192 10.2704 8.20782 9.97088 7.5252C9.67133 6.83972 9.52299 6.01572 9.52584 5.0532C9.52869 4.09067 9.67847 3.27381 9.97516 2.60262C10.2747 1.92856 10.6998 1.41588 11.2504 1.06458C11.8038 0.710415 12.4629 0.533333 13.2274 0.533333C13.992 0.533333 14.651 0.710415 15.2045 1.06458C15.7608 1.41588 16.1887 1.92856 16.4882 2.60262C16.7878 3.27667 16.9361 4.09353 16.9333 5.0532C16.9333 6.01858 16.7835 6.84401 16.484 7.52948C16.1844 8.21496 15.7579 8.73906 15.2045 9.10179C14.6539 9.46167 13.9948 9.64161 13.2274 9.64161ZM13.2274 7.90649C13.6839 7.90649 14.0533 7.67372 14.3358 7.20816C14.6182 6.73975 14.758 6.02143 14.7551 5.0532C14.7551 4.41913 14.6909 3.89645 14.5626 3.48517C14.4342 3.07103 14.2559 2.76256 14.0277 2.55977C13.7994 2.35699 13.5327 2.25559 13.2274 2.25559C12.7738 2.25559 12.4072 2.48551 12.1276 2.94536C11.8481 3.40234 11.7069 4.10495 11.704 5.0532C11.7011 5.69583 11.7625 6.2285 11.888 6.65121C12.0164 7.07393 12.1961 7.38953 12.4272 7.59803C12.6583 7.80367 12.925 7.90649 13.2274 7.90649Z"
                    fill="currentColor"
                  />
                  <path
                    d="M6.63496 19.3333C5.8704 19.3333 5.21138 19.152 4.65793 18.7892C4.10447 18.4237 3.67797 17.8995 3.37842 17.2169C3.07887 16.5314 2.93052 15.7074 2.93337 14.7449C2.93623 13.7824 3.086 12.9655 3.3827 12.2943C3.68225 11.6203 4.10733 11.1076 4.65793 10.7563C5.21138 10.4021 5.8704 10.2251 6.63496 10.2251C7.39953 10.2251 8.05854 10.4021 8.612 10.7563C9.1683 11.1076 9.59623 11.6203 9.89578 12.2943C10.1953 12.9684 10.3437 13.7853 10.3408 14.7449C10.3408 15.7103 10.1911 16.5357 9.8915 17.2212C9.59195 17.9067 9.16545 18.4308 8.612 18.7935C8.06139 19.1534 7.40238 19.3333 6.63496 19.3333ZM6.63496 17.5982C7.09142 17.5982 7.46087 17.3654 7.7433 16.8999C8.02573 16.4315 8.16552 15.7132 8.16267 14.7449C8.16267 14.1109 8.09848 13.5882 7.9701 13.1769C7.84172 12.7628 7.66342 12.4543 7.43519 12.2515C7.20696 12.0487 6.94022 11.9473 6.63496 11.9473C6.18136 11.9473 5.81476 12.1772 5.53518 12.6371C5.2556 13.0941 5.11439 13.7967 5.11153 14.7449C5.10868 15.3876 5.17002 15.9202 5.29554 16.3429C5.42392 16.7657 5.60365 17.0813 5.83473 17.2898C6.06582 17.4954 6.33256 17.5982 6.63496 17.5982Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.209 10.345V19.1191H14.0951V12.3243H14.0437L12.0838 13.5239V11.6903L14.2448 10.345H16.209Z"
                    fill="currentColor"
                  />
                </svg>
              );
            } else if (props.format == "Datetime") {
              return <ClockIcon className="h-5 w-5 p-1 text-slate-800 " aria-hidden="true" />;
            }
          })()}
        </div>
      </div>
    </RadixTooltip>
  );
};
FormatIcon.propTypes = {
  format: PropTypes.string,
};
export default FormatIcon;

import React from 'react';
import PropTypes from 'prop-types';

const SecondaryBtn = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="items-center duration-150 hover:shadow justify-center bg-white dark:bg-transparent text-sm text-zinc-500 dark:text-zinc-200 border-zinc-300 dark:border-zinc-500 border-2 px-4 py-[5px] rounded-full font-normal hover:bg-black hover:border-black dark:hover:bg-zinc-50 dark:hover:border-zinc-50 focus:ring-0 focus:ring-transparent hover:text-zinc-50 dark:hover:text-zinc-700"
    >
      {text}
    </button>
  );
};
SecondaryBtn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

SecondaryBtn.defaultProps = {
  onClick: () => null,
  text: 'Button',
};

export default SecondaryBtn;

import React from 'react';
import CanvasAction from 'components/Actions/CanvasAction';
import actionArray from 'data/tools';

import { Handle, Position } from 'reactflow';

const handleStylesRight = {
  height: 15,
  width: 15,
  right: -20,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const handleStylesLeft = {
  height: 15,
  width: 15,
  left: -20,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(113, 113, 121, 1)',
};

const JoinNode = (data) => {
  const { category, tooltip, text, icon } = actionArray.filter(
    (tool) => tool.name === data.data.configuration.tool_type
  )[0];
  const status = data.data.status;
  const selected = data.selected;
  const errorMessage = data?.data.errorMessage;
  const annotation = localStorage.getItem('annotations') == 'True';


  function desc() {
    const newArr = [];

    function printLoop() {
      let i = 0;

      data.data.configuration.joinColumns.forEach((element) => {
        i++;
        newArr.push(`${i}: ${element['left']} = ${element['right']}\n`);
      });

      return newArr.join('');
    }

    return annotation && data.data.configuration.joinColumns && data.data.configuration.joinType
      ? `${data.data.configuration.joinType} join\n${printLoop()}`
      : '';
  }

  return (
    <div>
      <CanvasAction
        data={data}
        desc={errorMessage || tooltip}
        icon={icon}
        name={text}
        category={category}
        status={status}
        selected={selected}
        description={text == "Combine" ? desc() : ""}
      />
      <Handle type="source" position={Position.Right} style={handleStylesRight} />
      <Handle
        type="target"
        position={Position.Left}
        className="hover:!bg-zinc-200 delay-150 text-[8px] text-center text-[#717179]"
        id="df_l"
        style={{ ...handleStylesLeft, top: '20%' }}
      >
        {text === "Combine" ? "L" : "D"}
      </Handle>
      <Handle
        type="target"
        position={Position.Left}
        className="hover:!bg-zinc-200 delay-150 text-[8px] text-center text-[#717179]"
        id="df_r"
        style={{ ...handleStylesLeft, top: '80%' }}
      >
        {text === "Combine" ? "R" : "H"}
      </Handle>
    </div>
  );
};

export default JoinNode;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllConnectorsService,
  getConnectorCategoriesService,
  getConnectorInputsService,
} from 'services/connector';

// First, create the thunk
export const getAllConnectorsAction = createAsyncThunk('connector/fetch', async () => {
  const response = await getAllConnectorsService();
  return response;
});

export const getConnectorCategoriesAction = createAsyncThunk(
  'connector/categories/fetch',
  async () => {
    const response = await getConnectorCategoriesService();
    return response;
  }
);

export const getConnectorsInputsAction = createAsyncThunk(
  'connector/inputs/fetch',
  async (connectorId) => {
    const response = await getConnectorInputsService(connectorId);
    return response;
  }
);
// Then, handle actions in your reducer

import Axios, { jsonHeader } from './axios';

const filesHeader = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const getAllConnectionsService = () => {
  return Axios.get('api/connections').then((res) => {
    return res?.data?.connections;
  });
};

export const getConnectionService = (connectionId) => {
  return Axios.get(`api/connections/${connectionId}`).then((res) => {
    return res?.data?.data;
  });
};

export const executeConnectionService = (connectionId) => {
  return Axios.get(`api/connections/${connectionId}/execute`).then((res) => {
    return res?.data;
  });
};

export const exportConnectionTableService = (databaseId, table) => {
  return Axios.get(`api/tables/export/${databaseId}?tablename=${table}`).then((res) => {
    return res?.data?.data;
  });
};

export const addNewConnectionService = (data) => {
  const header = (Object.keys(data).includes("files") && data['files'].length) ? filesHeader : jsonHeader
  return Axios.post(`api/connections/`, data, header).then((res) => {
    return res?.data?.data;
  });
};

export const updateConnectionService = (connectionId, data) => {
  const header = (Object.keys(data).includes("files") && data['files'].length) ? filesHeader : jsonHeader
  return Axios.post(`api/connections/${connectionId}`, data, header).then((res) => {
    return res.data;
  });
};

export const deleteConnectionService = (connectionId) => {
  return Axios.delete(`api/connections/${connectionId}`).then((res) => {
    return res.data;
  });
};

import { createSlice } from '@reduxjs/toolkit';
import { getUsersAction, getGroupsAction } from 'redux/actions/auth';
import { getRolesAction } from 'redux/actions/role';

const initialState = {
  users: [],
  groups: [],
  roles:[],
  loading: false,
};

// First import your thunk then add thunk lifecycle handlers
export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getUsersAction.pending]: (state) => {
      state.loading = true;
    },
    [getUsersAction.fulfilled]: (state, action) => {
      state.users = action.payload;
      state.loading = false;
    },
    [getUsersAction.rejected]: (state) => {
      state.loading = false;
    },
    [getGroupsAction.pending]: (state) => {
      state.loading = true;
    },
    [getGroupsAction.fulfilled]: (state, action) => {
      state.groups = action.payload;
      state.loading = false;
    },
    [getGroupsAction.rejected]: (state) => {
      state.loading = false;
    },
    [getRolesAction.fulfilled]: (state, action) => {
      state.roles = action.payload;
      state.loading = false;
    },
    [getRolesAction.rejected]: (state) => {
      state.loading = false;
    },
  },
});


// export the reducer to be passed to configureStore
export default platformSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';

const SmallBadge = ({ classes, text, ...rest }) => {
  return (
    <span
      {...rest}
      className={`${classes} inline-flex w-fit text-center justify-center items-center px-3 py-1.5 rounded-full border border-transparent shadow-zinc-200/60 dark:shadow-zinc-600/60 tracking-wider	hover:border hover:cursor-pointer text-[10px] font-semibold uppercase ${
        text === 'Other'
          ? 'bg-gray-100'
          : text === 'Product Analytics'
          ? 'bg-purple-100'
          : text === 'Customer Support'
          ? 'bg-orange-100'
          : text === 'Marketing'
          ? 'bg-red-100'
          : text === 'Sales'
          ? 'bg-pink-100'
          : text === 'Databases'
          ? 'bg-green-100'
          : text === 'Productivity'
          ? 'bg-blue-100'
          : text === 'Finance'
          ? 'bg-lime-100'
          : text === 'Human Resources'
          ? 'bg-amber-100'
          : 'bg-gray-600'
      } ${
        text === 'Other'
          ? 'text-gray-800'
          : text === 'Product Analytics'
          ? 'text-purple-800'
          : text === 'Customer Support'
          ? 'text-orange-800'
          : text === 'Marketing'
          ? 'text-red-800'
          : text === 'Sales'
          ? 'text-pink-800'
          : text === 'Databases'
          ? 'text-green-800'
          : text === 'Productivity'
          ? 'text-blue-800'
          : text === 'Finance'
          ? 'text-lime-800'
          : text === 'Human Resources'
          ? 'text-amber-800'
          : 'text-white'
      } 
      ${
        text === 'Other'
          ? 'hover:border-gray-300'
          : text === 'Product Analytics'
          ? 'hover:border-purple-300'
          : text === 'Customer Support'
          ? 'hover:border-orange-300'
          : text === 'Marketing'
          ? 'hover:border-red-300'
          : text === 'Sales'
          ? 'hover:border-pink-300'
          : text === 'Databases'
          ? 'hover:border-green-300'
          : text === 'Productivity'
          ? 'hover:border-blue-300'
          : text === 'Finance'
          ? 'hover:border-lime-300'
          : text === 'Human Resources'
          ? 'hover:border-amber-300'
          : 'hover:border-white'
      }
      ${
        text === 'Other'
          ? 'border-gray-300 dark:border-gray-500'
          : text === 'Product Analytics'
          ? 'border-purple-300 dark:border-purple-500'
          : text === 'Customer Support'
          ? 'border-orange-300 dark:border-orange-500'
          : text === 'Marketing'
          ? 'border-red-300 dark:border-red-500'
          : text === 'Sales'
          ? 'border-pink-300 dark:border-pink-500'
          : text === 'Databases'
          ? 'border-green-300 dark:border-green-500'
          : text === 'Productivity'
          ? 'border-blue-300 dark:border-blue-500'
          : text === 'Finance'
          ? 'border-lime-300 dark:border-lime-500'
          : text === 'Human Resources'
          ? 'border-amber-300 dark:border-amber-500'
          : 'border-white'
      }`}
    >
      {text}
    </span>
  );
};

SmallBadge.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  classes: PropTypes.string,
};

SmallBadge.defaultProps = {
  isSelected: false,
};

export default SmallBadge;

import React from 'react';
import PropTypes from 'prop-types';
import { statusLabel } from 'utils/statusLabel';

const StatusBadge = ({ status, classes }) => {

  const statLabel = statusLabel(status)

  return (
    <div
      className={`${statLabel == 'Success'
        ? 'bg-green-50 dark:bg-green-200 text-green-600 border-green-200 dark:bg-green-400/10 dark:text-green-400 dark:border-green-400/20'
        : statLabel == 'Error'
          ? 'bg-red-50 dark:bg-red-200 text-red-600 border-red-200 dark:bg-red-400/10 dark:text-red-400 dark:border-red-400/20'
          : statLabel == 'Warning'
            ? 'bg-amber-50 dark:bg-yellow-200 text-yellow-600 border-yellow-200 dark:bg-yellow-400/10 dark:text-yellow-400 dark:border-yellow-400/20'
            : statLabel == 'Updating'
              ? 'bg-zinc-700 dark:bg-zinc-700 text-zinc-100 animate-pulse border-zinc-200  dark:text-zinc-100 dark:border-zinc-400/20'
              : statLabel == 'In Queue'
                ? 'bg-zinc-300 dark:bg-zinc-700 text-zinc-600 animate-pulse border-zinc-200  dark:text-zinc-100 dark:border-zinc-400/20'
                : statLabel == 'Not Run'
                  ? 'bg-zinc-50 dark:bg-zinc-200 text-zinc-500 border-zinc-400 dark:bg-zinc-400/10 dark:text-zinc-400 dark:border-zinc-400/20'
                  : 'bg-white text-zinc-800 border-zinc-200'
        } ${classes} w-[68px] h-auto inline-flex items-center tracking-wide border justify-center rounded-lg relative px-1 py-1 text-[10px] font-medium`}
    >
      <p className="px-1.5 py-0">
        {statLabel}
      </p>

    </div>
  );
};

StatusBadge.propTypes = {
  errorMessage: PropTypes.string,
  classes: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StatusBadge.defaultProps = {
  errorMessage: '',
  status: null,
  classes: '',
};

export default StatusBadge;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';
import { ClockIcon } from '@heroicons/react/solid';

const TextDescInput = ({
    value: defaultValue,
    onChange: onChangeCallback,
    optional,
    type,
    name,
    ...props
}) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
        setValue(e.target.checked);
        onChangeCallback(e.target.checked);
    };

    useEffect(() => setValue(defaultValue), [defaultValue]);

    return (
        <RegularInputTemplate {...props} optional={optional}>
            <div className="relative">
                <input
                    id={name}
                    aria-describedby="comments-description"
                    name={name}
                    checked={value}
                    type="checkbox"
                    className="h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                    onChange={onChange}
                />
                <div className="absolute top-[9px] left-2">
                    {type === 'time' ? (
                        <ClockIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-200" aria-hidden="true" />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </RegularInputTemplate>
    );
};

TextDescInput.propTypes = {
    read: PropTypes.bool,
    optional: PropTypes.bool,
    readValue: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    minutes: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

TextDescInput.defaultProps = {
    read: false,
    type: '',
    placeholder: '',
    name: '',
    minutes: '',
    value: '',
    readValue: '',
    onChange: () => null,
};
export default TextDescInput;

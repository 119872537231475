import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import RegularInputTemplate from 'components/Layout/RegularInputTemplate';

const PasswordInput = ({ value: defaultValue, onChange: onChangeCallback, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallback(e.target.value);
  };

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <RegularInputTemplate {...props}>
      <React.Fragment>
        <section>
          <div className="relative">
            <input
              className="text-zinc-700 dark:bg-zinc-700 dark:text-zinc-50 shadow-sm placeholder:text-zinc-300 dark:placeholder:text-zinc-500 placeholder:font-light focus:ring-[#5DA6FC] focus:border-[#5DA6FC] block w-full sm:text-sm border-zinc-200 dark:border-zinc-600 border rounded-md"
              value={value}
              type={open === false ? 'password' : 'text'}
              onChange={onChange}
              placeholder={props.placeholder}
              id={props.name}
            />
            <div className="absolute top-2.5 right-8">
              {open === false ? (
                <EyeIcon onClick={toggle} className="h-5 w-5 text-zinc-700" aria-hidden="true" />
              ) : (
                <EyeOffIcon onClick={toggle} className="h-5 w-5 text-zinc-700" aria-hidden="true" />
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    </RegularInputTemplate>
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};
export default PasswordInput;

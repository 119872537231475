import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { useTable, useRowSelect, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { SelectorIcon } from '@heroicons/react/solid';
//import SingleSelect from '../SingleSelect';
import BasicDropdown from '../BasicDropdown';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);


  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 400);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-zinc-700 dark:text-zinc-100 text-xs">Search: </span>
      <input
        type="text"
        className="text-xs mt-1 block w-full rounded-md border-zinc-300 dark:border-zinc-400 bg-zinc-100 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-100 focus:ring-0 placeholder:text-zinc-400"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} columns...`}
      />
    </label>
  );
}

// const DND_ITEM_TYPE = 'row';

const Row = ({
  row,
  index,
  moveRow,
  changeHandler,
  onTextInputChange,
  formatItems,
  onSelectChange,
  onCheckboxChange,
  filter
}) => {

  const hidden = row?.column.toLowerCase().match(filter)

  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'row',
    drop: () => changeHandler(),
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    // item: { type: 'row' },
    type: 'row',
    item: { type: 'row', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  const found = (value) => {
    return formatItems.find((obj) => {
      return obj.value === value
    });
  };

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <>
      {hidden && (
        <tr
          className={`${row?.missing ? '!bg-red-100' : ''} ${row?.new ? '!bg-green-100 ' : ''
            }`}
          ref={dropRef}
          style={{ opacity }}
        >
          <td
            className={`${row?.new
              ? 'hover:!bg-green-200 '
              : row?.missing
                ? 'hover:!bg-red-200'
                : ''
              } hover:bg-zinc-100 border-r dark:hover:bg-zinc-600 w-[50px] min-w-[50px] max-w-[50px] text-center duration-100 hover:cursor-move`}
            ref={dragRef}
          >

            <SelectorIcon className="h-3 w-3 ml-[17px] text-zinc-600 dark:text-zinc-100" />
          </td>
          <td
            className={`w-[50px] min-w-[50px] max-w-[50px] border-r text-center py-1.5 px-2 text-xs font-normal text-zinc-600 dark:text-zinc-100 truncate ${row?.new ? '!text-green-800 !font-medium !italic' : ''
              } ${row?.missing ? '!text-red-800 !font-medium !italic' : ''}`}
            key={"column0"}
            role={"cell"}
          >
            <input
              id={`checkbox`}
              aria-describedby="comments-description"
              name={`checkbox`}
              checked={row.select}
              type="checkbox"
              className="h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
              onChange={(e) => onCheckboxChange(index, e.target.checked)}
            />
          </td>
          <td>
            <td
              className={`w-[158px] py-1.5 px-2 text-xs font-normal text-zinc-600 dark:text-zinc-100 truncate ${row?.new ? '!text-green-800 !font-medium !italic' : ''
                } ${row?.missing ? '!text-red-800 !font-medium !italic' : ''}`}
              key={"column1"}
              role={"cell"}
            >
              {row?.column}
            </td>
          </td>
          <td
            className="text-xs w-[158px]  py-1.5 px-2 font-normal text-zinc-600 dark:text-zinc-100"
            key={"column2"}
            role={"cell"}
          >
            <input
              name="columns"
              value={row.name}
              onChange={(e) => {
                onTextInputChange(index, e.target.value)
              }}
              type="text"
              disabled={row.column == "**Unknown Columns**" ? true : false}
              placeholder={row.column == "**Unknown Columns**" ? "" : "New column name"}
              className="bg-transparent -ml-2.5 text-zinc-600 dark:text-zinc-100 placeholder:text-zinc-300 dark:placeholder:text-zinc-400 placeholder:font-light outline-none focus:ring-0 block sm:text-xs border-none min-w-full"
            />
          </td>
          <td
            className="w-[158px] py-1.5 px-2 text-xs font-normal text-zinc-600 dark:text-zinc-100"
            key={"column3"}
            role={"cell"}
          >
            <BasicDropdown
              classes={"!max-w-[140px]"}
              placeholder={
                row.format?.includes('Struct') ||
                  row.format?.includes('List')
                  ? 'JSON'
                  : "Default"
              }
              items={formatItems}
              show={found(row.format)?.label}
              defaultSelected={row.format}
              onChange={(e) => onSelectChange(index, e)}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default function TableSelect2({ columns, data, onChangeHandler, formatItems }, ...props) {
  // DND

  const [records, setRecords] = React.useState(data);
  const [checkAll, setCheckAll] = React.useState({ "checked": false, "indeterminate": false });
  const [showSelectedOnly, setShowSelectedOnly] = React.useState(false);
  const checkboxRef = React.useRef();

  const selectedRecords = {};
  data.map((item) => {
    selectedRecords[item.id] = item.select;
  });

  const changeFieldOrder = (update) => {
    setRecords(update);
  };


  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state: { selectedRowIds },
    state, // new
    preGlobalFilteredRows, // new
    setGlobalFilter, // new
  } = useTable(
    {
      data: records,
      initialState: { selectedRowIds: selectedRecords },
      columns,
      getRowId,
      autoResetHiddenColumns: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
    },
    useGlobalFilter, // new
    useRowSelect,
  );

  const changeHandler = () => {

    // const updatedData = initialRows
    //   .map((item) => {
    //     if (!selectedRowIds[item.id] && item.original.missing) {
    //       return false;
    //     } else {
    //       return { ...item.original, ...{ select: selectedRowIds[item.id] } };
    //     }
    //   })
    //   .filter((item) => item != false);


    onChangeHandler(records.filter((item) => !item.missing && !item.selected)); // Pass the updated `records` array
  };

  useEffect(() => {
    changeHandler();
    updateCheckAll();
    //console.log("selectedRowIds", selectedRowIds)
  }, [selectedRowIds, records]);


  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    changeFieldOrder(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  // const onTextInputChange = (index, value) => {
  //   const updatedRecords = [...records];
  //   if (index >= 0 && index < updatedRecords.length) {
  //     updatedRecords[index].name = value;
  //     setRecords(updatedRecords);
  //   }

  //   // setRecords(updatedRecords)
  // };

  const onTextInputChange = (index, value) => {
    const updatedRecords = [...records];
    if (index >= 0 && index < updatedRecords.length) {
      updatedRecords[index].name = value;
      setRecords(updatedRecords);
    }
  };

  const onSelectChange = (index, value) => {
    const updatedRecords = [...records];
    if (index >= 0 && index < updatedRecords.length) {
      updatedRecords[index].format = value;
      setRecords(updatedRecords);
    }
    // setRecords(updatedRecords)
  };

  const onCheckboxChange = (index, value) => {
    const updatedRecords = [...records];
    if (index >= 0 && index < updatedRecords.length) {
      updatedRecords[index].select = value;
      setRecords(updatedRecords);
    }
    // setRecords(updatedRecords)
  };

  const updateCheckAll = () => {
    const selectAllState = checkAll
    const checked = records.filter((record) =>
      record.select == true).length
    if (checked == records.length) {
      selectAllState['checked'] = true
    } else if (checked == 0) {
      selectAllState['checked'] = false
    } else {
      selectAllState['indeterminate'] = true
      checkboxRef.current.indeterminate = true
    }

    setCheckAll(selectAllState)
  }

  const checkBoxes = (direction) => {
    const checkRecords = records.map((record) => {
      record.select = !direction
      return record
    })
    setRecords(checkRecords)
  }

  // Render the UI for your table
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className='text-zinc-700 dark:text-zinc-100 text-xs pt-4 pb-2 flex flex-row items-center'>
        <p>Show selected columns:</p>
        <input
          type="checkbox"
          onChange={(e) => setShowSelectedOnly(e.target.checked)}
          checked={showSelectedOnly}
          className="ml-1.5 h-3.5 w-3.5 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC] focus:ring-1"
        />
        </div>
      <ModelingInputTemplate {...props}>
        <div className="flex flex-col shadow ring-1 dark:bg-zinc-700 dark:shadow-zinc-600/50 ring-zinc-200 dark:ring-zinc-600 rounded-md overflow-hidden">
          <div className="-my-2 w-full max-h-80 overflow-y-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden">
                <DndProvider backend={HTML5Backend}>
                  <table
                    className="min-w-full text-left divide-y divide-zinc-200 dark:divide-zinc-600"
                    {...getTableProps()}
                  >
                    <thead className="bg-zinc-50 dark:bg-zinc-700">
                      {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          <th className="border-b" />
                          <th
                            scope="col"
                            className={`text-center text-xs border-b py-2 font-medium text-zinc-700 dark:text-zinc-50`}
                            key={"select"}
                          >
                            <input
                              ref={checkboxRef}
                              id={`selectAll`}
                              aria-describedby="comments-description"
                              name={`selectAll`}
                              checked={checkAll.checked}
                              type="checkbox"
                              className="h-4 w-4 rounded border-zinc-300 text-[#5DA6FC] focus:ring-[#5DA6FC]"
                              onChange={() => checkBoxes(checkAll.checked)}
                            />
                          </th>
                          {headerGroup.headers.map((column) => {
                            return (
                              <th
                                scope="col"
                                className={`${column.id === 'selection' ? 'text-center' : 'px-2'
                                  } text-xs border-b py-2 font-medium text-zinc-700 dark:text-zinc-50`}
                                key={column.id}
                                {...column.getHeaderProps()}
                              >
                                {column.render('Header')}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="divide-y divide-zinc-200 dark:divide-zinc-600 bg-white dark:bg-zinc-700 "
                      {...getTableBodyProps()}
                    >
                      {

                        records.filter(row => !showSelectedOnly || row.select).map((row, index) => {
                          return (
                            <Row
                              key={row.id}
                              index={index}
                              row={row}
                              moveRow={moveRow}
                              formatItems={formatItems}
                              changeHandler={changeHandler}
                              onSelectChange={onSelectChange}
                              onTextInputChange={onTextInputChange}
                              onCheckboxChange={onCheckboxChange}
                              filter={state.globalFilter}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </DndProvider>
              </div>
            </div>
          </div>
        </div>
      </ModelingInputTemplate>
    </>
  );
}

TableSelect2.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  onChangeHandler: PropTypes.func,
  items: PropTypes.any,
  // updateOrderChange: PropTypes.func,
  indeterminate: PropTypes.any,
  row: PropTypes.any,
  getToggleAllRowsSelectedProps: PropTypes.any,
  formatItems: PropTypes.any,
};

GlobalFilter.propTypes = {
  globalFilter: PropTypes.any,
  setGlobalFilter: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

Row.propTypes = {
  row: PropTypes.any,
  index: PropTypes.any,
  moveRow: PropTypes.any,
  changeHandler: PropTypes.func,
  onTextInputChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  formatItems: PropTypes.any,
  onCheckboxChange: PropTypes.func,
  filter: PropTypes.any
};

TableSelect2.defaultProps = {};

import React from 'react';
import PropTypes from 'prop-types';

const ModellingConfigBtn = (props) => {
  return (
    <button
      className="items-center hover:shadow justify-center bg-white dark:bg-transparent text-[10px] text-zinc-700 dark:text-zinc-50 border-zinc-300 dark:border-zinc-500 border-2 px-3 py-1.5 rounded-full p-0.5 font-normal hover:bg-black hover:border-black dark:hover:bg-zinc-50 dark:hover:border-zinc-50 focus:ring-0 focus:ring-transparent hover:text-zinc-50 dark:hover:text-zinc-700 duration-150 transition-all"
      onClick={props.onClick}
    >
      {props.buttonText}
    </button>
  );
};

ModellingConfigBtn.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

ModellingConfigBtn.defaultProps = {
  onClick: () => null,
  text: 'Button',
};

export default ModellingConfigBtn;

import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  getResourceRulesetCollectionService,
  setResourceRulesetService,
} from 'services/ruleset';

// First, create the thunk
export const getResourceRulesetCollectionAction = createAsyncThunk('rulesets/fetch', async (data) => {
  const response = await getResourceRulesetCollectionService(data);
  return response;
});

export const setResourceRulesetAction = createAsyncThunk('rulesets/set', async (data, { rejectWithValue }) => {
  try {
    const response = await setResourceRulesetService(data);
    return response;
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err)
  }
});

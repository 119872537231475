import React from 'react';
import PropTypes from 'prop-types';
import RadixTooltip from 'components/Tooltips/RadixTooltip';

const KPICard2 = (props) => {
  //   const curNumber = parseInt(curValue);
  //   const prevNumber = parseInt(prevValue);
  //   const direction =
  //     curNumber > prevNumber ? 'increase' : curNumber === prevNumber ? 'same' : 'decrease';
  //   const hasDirection = !isNaN(prevNumber);

  //   const deltaPercent =
  //     prevNumber !== 0 ? Math.round(((curNumber - prevNumber) / prevNumber) * 100 * 100) / 100 : 100;

  return (
    <div
      className={`${props.classes} bg-transparent  z-20 flex items-baseline flex-wrap justify-between  border-t border-zinc-900/10 dark:border-zinc-100/10 p-5 `}
    >
      <RadixTooltip tooltipText={props.tt}>
        <dt className="text-sm cursor-pointer font-medium leading-6 text-zinc-500 dark:text-zinc-200">
          {props.title}
        </dt>
      </RadixTooltip>

      <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-zinc-900 dark:text-zinc-50">
        {props.value}
        {props.from != 0 && (
          <a className="ml-2 text-zinc-400 dark:text-zinc text-xs font-medium tracking-normal leading-none">
            from {props.from} {props.time}
          </a>
        )}
      </dd>
    </div>
  );
};

KPICard2.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  classes: PropTypes.string,
  tt: PropTypes.string,
  time: PropTypes.string,
  outof: PropTypes.number,
  from: PropTypes.number,
};

export default KPICard2;

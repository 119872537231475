import React from 'react';
import PropTypes from 'prop-types';

const TinyBadge = ({ text }) => {
  return (
    <span
      className={`inline-flex w-fit text-center justify-center items-center px-2 py-1  rounded-full border border-transparent tracking-wider text-[9px] font-medium uppercase ${
        text === 'Other'
          ? 'bg-gray-100'
          : text === 'Product Analytics'
          ? 'bg-purple-100'
          : text === 'Customer Support'
          ? 'bg-orange-100'
          : text === 'Marketing'
          ? 'bg-red-100'
          : text === 'Sales'
          ? 'bg-pink-100'
          : text === 'Databases'
          ? 'bg-green-100'
          : text === 'Productivity'
          ? 'bg-blue-100'
          : text === 'Finance'
          ? 'bg-lime-100'
          : text === 'Human Resources'
          ? 'bg-amber-100'
          : 'bg-gray-600'
      } ${
        text === 'Other'
          ? 'text-gray-800'
          : text === 'Product Analytics'
          ? 'text-purple-800'
          : text === 'Customer Support'
          ? 'text-orange-800'
          : text === 'Marketing'
          ? 'text-red-800'
          : text === 'Sales'
          ? 'text-pink-800'
          : text === 'Databases'
          ? 'text-green-800'
          : text === 'Productivity'
          ? 'text-blue-800'
          : text === 'Finance'
          ? 'text-lime-800'
          : text === 'Human Resources'
          ? 'text-amber-800'
          : 'text-white'
      } 
      `}
    >
      {text}
    </span>
  );
};

TinyBadge.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TinyBadge;

/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBezierPath } from 'reactflow';

const ConnectionLineConnect = (data) => {


    const edgePath = getBezierPath({
        sourceX: data.fromX,
        sourceY: data.fromY,
        sourcePosition: data.fromPosition,
        targetX: data.toX,
        targetY: data.toY,
        targetPosition: data.toPosition,
    });

    const [isDropzoneActive, setDropzoneActive] = useState(false);


    const style = {
        strokeWidth: isDropzoneActive ? '8' : '3'
    }


    const onDrop = (evt) => {
        evt.preventDefault();
        setDropzoneActive(false)
    };

    const onDragEnter = () => {
        setDropzoneActive(true);
    };

    const onDragLeave = () => {
        setDropzoneActive(false);
    };


    const onDragOver = (evt) => {
        evt.preventDefault();
        setDropzoneActive(true)

    };

    return (
        <>
            <path
                style={style}
                className="react-flow__edge-path"
                d={edgePath[0]}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
            />
        </>
    );
};

ConnectionLineConnect.propTypes = {
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    data: PropTypes.string,
    markerEnd: PropTypes.string,
};

export default ConnectionLineConnect;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import IndeterminateCheckbox from './IndeterminateCheckbox';
import ModelingInputTemplate from 'components/Layout/ModelingInputTemplate';

import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { useTable, useRowSelect, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { SelectorIcon } from '@heroicons/react/solid';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 400);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-zinc-700 dark:text-zinc-100 text-xs">Search: </span>
      <input
        type="text"
        className="text-xs mt-1 block w-full rounded-md border-zinc-300 dark:border-zinc-400 bg-zinc-100 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-100 focus:ring-0 placeholder:text-zinc-400"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} columns...`}
      />
    </label>
  );
}

// const DND_ITEM_TYPE = 'row';

const Row = ({ row, index, moveRow, changeHandler }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: 'row',
    drop: () => changeHandler(),
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    // item: { type: 'row' },
    type: 'row',
    item: { type: 'row', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);
  return (
    <tr
      className={`${row.original?.missing ? '!bg-red-100' : ''} ${row.original?.new ? '!bg-green-100 ' : ''
        }`}
      ref={dropRef}
      style={{ opacity }}
    >
      <td
        className={`${row.original?.new
            ? 'hover:!bg-green-200 '
            : row.original?.missing
              ? 'hover:!bg-red-200'
              : ''
          } hover:bg-zinc-100 border-r dark:hover:bg-zinc-600 w-[50px] min-w-[50px] max-w-[50px] text-center duration-100 hover:cursor-move`}
        ref={dragRef}
      >
        <SelectorIcon className="h-3 w-3 ml-[17px] text-zinc-600 dark:text-zinc-100" />
      </td>
      {row.cells.map((cell) => {
        return row.original?.new | row.original?.missing ? (
          <td
            className={`${typeof cell.value === 'undefined'
                ? 'w-[50px] min-w-[50px] max-w-[50px] border-r text-center'
                : ''
              } py-2 px-2 text-xs font-normal text-zinc-600 dark:text-zinc-100 truncate ${row.original?.new ? '!text-green-800 !font-medium !italic' : ''
              } ${row.original?.missing ? '!text-red-800 !font-medium !italic' : ''}`}
            key={cell.id}
            {...cell.getCellProps()}
          >
            {cell.value !== undefined ? (
              <div className="flex items-center">
                <div
                  className={`mr-2 not-italic py-1 px-2 ${row.original?.missing
                      ? 'bg-red-900 text-red-100'
                      : row.original?.new
                        ? 'bg-green-900 text-green-100'
                        : 'hidden'
                    } rounded`}
                >
                  {row.original?.missing ? 'Missing' : row.original?.new ? 'New' : ''}
                </div>
                {cell.render('Cell')}
              </div>
            ) : (
              cell.render('Cell')
            )}
          </td>
        ) : (
          <td
            className={`${typeof cell.value === 'undefined'
                ? 'w-[50px] min-w-[50px] max-w-[50px] border-r text-center'
                : ''
              } py-2 px-2 text-xs font-normal text-zinc-600 dark:text-zinc-100 truncate ${row.original?.new ? '!text-green-800 !font-medium !italic' : ''
              } ${row.original?.missing ? '!text-red-800 !font-medium !italic' : ''}`}
            key={cell.id}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

export default function TableSelect({ columns, data, onChangeHandler, defaultSelected }, ...props) {
  // DND
  const [records, setRecords] = React.useState(data);

  const changeFieldOrder = (update) => {
    setRecords(update);
  };
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    state, // new
    preGlobalFilteredRows, // new
    setGlobalFilter, // new
    initialRows,
  } = useTable(
    {
      data: records,
      initialState: { selectedRowIds: defaultSelected },
      columns,
      getRowId,
      autoResetHiddenColumns: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
    },
    useGlobalFilter, // new
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const changeHandler = () => {
    // updateOrderChange(records)
    onChangeHandler(initialRows, selectedRowIds);
  };

  useEffect(() => {
    changeHandler();
  }, [selectedRowIds]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    changeFieldOrder(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  // Render the UI for your table
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <ModelingInputTemplate {...props}>
        <div className="flex flex-col shadow ring-1 dark:bg-zinc-700 dark:shadow-zinc-600/50 ring-zinc-200 dark:ring-zinc-600 rounded-md overflow-hidden">
          <div className="-my-2 w-full max-h-80 overflow-y-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden">
                <DndProvider backend={HTML5Backend}>
                  <table
                    className="min-w-full text-left divide-y divide-zinc-200 dark:divide-zinc-600"
                    {...getTableProps()}
                  >
                    <thead className="bg-zinc-50 dark:bg-zinc-700">
                      {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          <th className="border-b" />
                          {headerGroup.headers.map((column) => {
                            return (
                              <th
                                scope="col"
                                className={`${column.id === 'selection' ? 'text-center' : 'px-2'
                                  } text-xs border-b py-2 font-medium text-zinc-700 dark:text-zinc-50`}
                                key={column.id}
                                {...column.getHeaderProps()}
                              >
                                {column.render('Header')}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="divide-y divide-zinc-200 dark:divide-zinc-600 bg-white dark:bg-zinc-700 "
                      {...getTableBodyProps()}
                    >
                      {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Row
                            key={row.id}
                            index={index}
                            row={row}
                            moveRow={moveRow}
                            changeHandler={changeHandler}
                            {...row.getRowProps()}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </DndProvider>
              </div>
            </div>
          </div>
        </div>
      </ModelingInputTemplate>
    </>
  );
}

TableSelect.propTypes = {
  defaultSelected: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  onChangeHandler: PropTypes.func,
  // updateOrderChange: PropTypes.func,
  indeterminate: PropTypes.any,
  row: PropTypes.any,
  getToggleAllRowsSelectedProps: PropTypes.any,
};

GlobalFilter.propTypes = {
  globalFilter: PropTypes.any,
  setGlobalFilter: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

Row.propTypes = {
  row: PropTypes.any,
  index: PropTypes.any,
  moveRow: PropTypes.any,
  changeHandler: PropTypes.func,
};

TableSelect.defaultProps = {};

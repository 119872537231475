import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';

const LeafFolder = ({ id, name, onClick, selected, level }) => {
  const inActiveStyles =
    'text-zinc-500 dark:text-zinc-200 hover:bg-zinc-100 dark:hover:bg-zinc-700/50 w-full flex items-center px-2 py-1.5 text-left text-xs font-medium rounded';
  const activeStyles =
    'text-zinc-500 dark:text-zinc-200 dark:hover:bg-zinc-700/50 w-full flex items-center px-2 py-1.5 text-left text-xs font-medium rounded bg-zinc-200 text-zinc-700 dark:text-zinc-50 dark:bg-zinc-700';

  return (
    <Disclosure as="div" className={`space-y-1 my-0.5 pl-${level}`}>
      <div
        className={`invisible hover:bg-zinc-100 bg-zinc-200 hover:dark:bg-zinc-800 dark:text-zinc-200/80 dark:bg-zinc-700`}
      />
      <button
        id={id}
        role="open"
        className={classNames({
          [inActiveStyles]: id !== selected,
          [activeStyles]: id === selected,
        })}
        onClick={onClick}
      >
        {name}
      </button>
    </Disclosure>
  );
};

LeafFolder.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.number,
  level: PropTypes.number,
};

export default LeafFolder;

export function unixTime(unix) {
  let formattedTime = new Date(unix).toLocaleTimeString();

  return formattedTime;
}

export function unixDate(unix) {
  let formattedTime = new Date(unix).toLocaleDateString();

  return formattedTime;
}

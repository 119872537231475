import Axios from './axios';
import { jsonHeader } from './axios';

export const changeUserCreatorService = (userId, data) => {
  return Axios.post(`api/users/${userId}/creator`, data, jsonHeader).then((res) => {
    return res?.data;
  });
}

export const deleteUserService = (userId) => {
  return Axios.delete(`api/users/${userId}`, jsonHeader).then((res) => {
    return res?.data;
  });
}
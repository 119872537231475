import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PrimaryBtn = ({ text, onClick, useEnter, loader }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (useEnter && event.key === 'Enter' && (event.metaKey || event.ctrlKey)) ||
        (!useEnter && event.key === 'Enter')
      ) {
        event.preventDefault();
        onClickHandler();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClick, useEnter]);

  const onClickHandler = (e) => {
    if (loader) {
      setLoading(true);
      onClick(e).then(() => {
        setLoading(false);
      });
    } else {
      onClick(e);
    }
  };

  const buttonContent = loading ? (
    <p className="relative animate-pulse inline-flex items-center text-sm text-zinc-700 group-hover:text-zinc-50 dark:text-zinc-50 dark:group-hover:text-zinc-700">
      Loading...
    </p>
  ) : (
    <p className="relative inline-flex text-sm text-zinc-700 group-hover:text-zinc-50 dark:text-zinc-50 dark:group-hover:text-zinc-700">
      {text}
    </p>
  );

  return (
    <button
      onClick={onClickHandler}
      className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg p-0.5 font-normal"
      disabled={loading}
    >
      <div className="absolute h-full w-full rounded-full bg-gradient-to-tr from-[#5DA6FC] via-[#FFC6BA] to-[#ff5478]  group-hover:bg-gradient-to-br group-hover:from-black group-hover:to-black dark:group-hover:from-zinc-50 dark:group-hover:to-zinc-50"></div>
      <div className="duration-400 relative rounded-full bg-white dark:bg-zinc-900 px-[13px] pt-[3px] pb-[4px] transition-all ease-out delay-50 duration-300 hover:shadow dark:group-hover:bg-zinc-50 group-hover:bg-black">
        {buttonContent}
      </div>
    </button>
  );
};

PrimaryBtn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  useEnter: PropTypes.bool,
  loader: PropTypes.bool,
};

PrimaryBtn.defaultProps = {
  onClick: () => null,
  text: 'Button',
  useEnter: false,
  loader: false,
};

export default PrimaryBtn;

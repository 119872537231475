import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  runModellingExportService,
  runModellingGetSessionService,
  runModellingCheckSessionService,
  runModellingClearCacheService,
  runModellingRestartSessionService
} from 'services/modelling';

// First, create the thunk

export const runModellingExportAction = createAsyncThunk(
  'modelling/export',
  async ({ modelId, toolId, format }) => {
    const response = await runModellingExportService(modelId, toolId, format);
    return response;
  }
);

export const runModellingGetSessionAction = createAsyncThunk(
  'modelling/getSession',
  async () => {
    const response = await runModellingGetSessionService();
    return response;
  }
);

export const runModellingCheckSessionAction = createAsyncThunk(
  'modelling/checkSession',
  async (session_id) => {
    const response = await runModellingCheckSessionService(session_id);
    return response;
  }
);

export const runModellingClearCacheAction = createAsyncThunk(
  'modelling/ClearCache',
  async ({ modelId }) => {
    const response = await runModellingClearCacheService(modelId);
    return response;
  }
);

export const runModellingRestartSessionAction = createAsyncThunk(
  'modelling/RestartSession',
  async () => {
    const response = await runModellingRestartSessionService();
    return response;
  }
);
// Then, handle actions in your reducer

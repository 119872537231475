export default function FormInput(params) {
  const data = {
    id: -1,
    connectorId: -1,
    fieldname: '',
    formname: '',
    description: '',
    sort: '',
    fieldtype: '',
    defaultvalue: '',
    created_at: null,
    updated_at: null,
  };
  return {
    ...data,
    ...params,
  };
}
